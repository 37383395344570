import * as React from 'react'
import { Box, ResponsiveContext, Text, Image, BoxProps } from 'grommet'
import { useStore } from '../../api/cosmosStores'
import './ConnectWallet.css'
import GradientBox from 'components/common/GradientBox/GradientBox'
import { observer } from 'mobx-react-lite'
import { PrimaryBtn } from 'components/common'
import SignOutIcon from '../../public/images/sidebar/sign-out.png'
import { Chain, useChain } from 'lib/hooks/chain/context'
import { useWeb3 } from 'api/web3'
import { connect } from 'api/web3/providers'
import truncate from 'lib/truncate'
import { Theme } from 'lib/hooks/theme/context'
import { useAccountConnection } from 'api/cosmosStores/account-init-management'
import { useState } from 'react'

const ConnectWallet = ({ ...props }: BoxProps) => {
  const { disconnectAccount, connectAccount } = useAccountConnection()
  const [hovered, setHovered] = useState<boolean>(false)
  const { accountStore, chainStore } = useStore()
  const umeeAccount = accountStore.getAccount(chainStore.current.chainId)
  const size = React.useContext(ResponsiveContext)
  const { account, disconnect } = useWeb3()
  const { chainMode } = useChain()

  const accountConnected = React.useMemo(() => {
    let connected: boolean = false
    if (chainMode === Chain.cosmos && umeeAccount.bech32Address) connected = true
    if (chainMode === Chain.ethereum && account) connected = true
    return connected
  }, [chainMode, umeeAccount.bech32Address, account])

  const connectToAccount = () => {
    chainMode === Chain.ethereum ? connect() : connectAccount()
  }

  const disconnectFromAccount = () => {
    chainMode === Chain.cosmos ? disconnectAccount() : disconnect && disconnect()
    setHovered(false)
  }

  return (
    <Box {...props}>
      {accountConnected ? (
        <GradientBox
          pad={size === 'small' ? 'medium' : { vertical: '14px', horizontal: 'small' }}
          onClick={() => disconnectFromAccount()}
          defaultTheme={Theme.dark}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Box direction="row" justify={size === 'small' || size === 'medium' ? 'between' : 'center'} align="center">
            <Text alignSelf="center" size={'small'} color={'clrWhite'}>
              {hovered
                ? 'Disconnect'
                : chainMode === Chain.cosmos
                ? umeeAccount.name
                : size === 'small'
                ? truncate(account, 11, 5)
                : truncate(account, 7, 3)}
            </Text>
            {(size === 'small' || size === 'medium') && <Image width={'30px'} src={SignOutIcon} alt="sign out" />}
          </Box>
        </GradientBox>
      ) : (
        <PrimaryBtn
          pad={size === 'small' ? 'medium' : { vertical: '16px', horizontal: 'large' }}
          textSize={'small'}
          text="Connect"
          round="xlarge"
          onClick={() => connectToAccount()}
        />
      )}
    </Box>
  )
}

export default observer(ConnectWallet)
