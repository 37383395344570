import { Box, BoxProps } from 'grommet';
import * as React from 'react';

export type TextValueType = 'decimal' | 'currency' | 'percent' | 'text';

export interface TextItemProps extends BoxProps {
  handleClick?: React.Dispatch<any>;
}

const TextItem: React.FC<TextItemProps> = ({
  handleClick,
  children,
  ...other
}) => (
  <Box onClick={handleClick} {...other} >
    {children}
  </Box>
);

TextItem.defaultProps = {
  direction: 'row',
  justify: 'center',
  align: 'center'
};

export default TextItem;
