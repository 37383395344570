import React, { useEffect, useMemo, useState, useContext } from 'react'
import Styled from '@emotion/styled'
import { ResponsiveContext } from 'grommet'
import { BigNumber } from 'ethers'
import { DelegateType } from 'lib/types'
import { OperatorGrant, Validator } from 'api/stake/data'
import { useStake } from 'api/stake'
import { useTheme } from 'lib/hooks/theme/context'
import DetailMobile from './DetailMobile'
import DetailDesktop from './Detail'

const tokenDecimals = BigNumber.from(6)

export const BlockContainer = Styled.div`
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(19px, 1fr));
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1.5px;
  padding: 1rem 0.5em;
`

export const Block = Styled.div<{ filled: boolean | null }>`
  width: 17px;
  height: 17px;
  border-radius: 2px;
  background: ${({ filled }) => ('var(--umee-full-green)')}; // filled === true ? 'var(--umee-full-green)' : filled === false ? 'red' : 'initial'
  margin: 2px;
}`

interface ValidatorDetailProps {
  validator: Validator
  restakePossible?: boolean
  grants?: OperatorGrant
  onClose: () => void
  onMenuClick: (menu: DelegateType, validator: Validator) => void
  claimRewards?: (validator: Validator, compounding: boolean) => void
  autoCompound: (validator: Validator, grants: OperatorGrant) => void
}

const ValidatorDetail = ({
  validator,
  onClose,
  onMenuClick,
  claimRewards,
  restakePossible,
  grants,
  autoCompound,
}: ValidatorDetailProps) => {
  const { Delegations, Rewards, Operators } = useStake()
  const [openMenu, setOpenMenu] = useState<boolean>()
  const [blocks, setBlocks] = useState(Array(100).fill({ value: null, block: undefined }))

  const size = useContext(ResponsiveContext)

  const { themeMode } = useTheme()

  const delegation = useMemo(() => {
    if (Delegations) {
      const delegation = Delegations.find(
        (delegation) => delegation.delegation.validator_address === validator.operator_address,
      )
      if (delegation) {
        const balance = typeof delegation.balance === 'string' ? delegation.balance : delegation.balance.amount
        return BigNumber.from(balance)
      }
    }
  }, [Delegations, validator.operator_address])

  const rewards = useMemo(() => {
    if (Rewards && Rewards.rewards) {
      const rewards = Rewards.rewards.find((delegation) => delegation.validator_address === validator.operator_address)
      if (rewards && rewards.reward) return BigNumber.from(Math.round(Number(rewards.reward[0].amount)))
    }
  }, [Rewards, validator.operator_address])

  useEffect(() => {
    const API_URL = process.env.API_ENDPOINT || 'http://127.0.0.1:3001'
    fetch(`${API_URL}/validator/${validator.address}`)
      .then((res) => res.json())
      .then(setBlocks)
  }, [validator])

  const uptime = blocks.filter((val) => val.value).length

  if (!validator) {
    return null
  }

  return (
    <>
      {size === 'small' ? (
        <DetailMobile
          validator={validator}
          restakePossible={restakePossible}
          grants={grants}
          onClose={onClose}
          onMenuClick={onMenuClick}
          claimRewards={claimRewards}
          autoCompound={autoCompound}
          uptime={uptime}
          blocks={blocks}
          delegation={delegation}
          rewards={rewards}
        />
      ) : (
        <DetailDesktop
          validator={validator}
          restakePossible={restakePossible}
          grants={grants}
          onClose={onClose}
          onMenuClick={onMenuClick}
          claimRewards={claimRewards}
          autoCompound={autoCompound}
          uptime={uptime}
          blocks={blocks}
          delegation={delegation}
          rewards={rewards}
        />
      )}
    </>
  )
}

export default ValidatorDetail
