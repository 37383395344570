import React, { useState, useMemo } from 'react'
import { ProposalData, ProposalDataStatus, ProposalType } from 'api/stake/data'
import BottomSheet from 'components/common/BottomSheet/BottomSheet'
import { Text, Box } from 'grommet'
import ShowMore from 'components/ShowMore'
import { usePaginate } from 'lib/hooks/usePaginate'
import GradientBox from 'components/common/GradientBox/GradientBox'
import ProposalAvatar from './ProposalAvatar'
import ProposalDetail from './ProposalDetail'
import { SkeletonBar } from 'components/Skeleton'

interface ProposalListProps {
  passedProposals?: ProposalData[]
  activeProposals?: ProposalData[]
}

const ProposalList: React.FC<ProposalListProps> = ({ passedProposals: ps, activeProposals }) => {
  const [selectedProposal, setSelectedProposal] = useState<ProposalData | null>(null)
  const [statusFilter, setStatusFilter] = useState<ProposalType | 0>(0)
  const passedProposals = useMemo(() => {
    if (ps === undefined) {
      return undefined
    } else if (statusFilter === 0) {
      return ps
    } else {
      return ps.filter(item => item.status === statusFilter)
    }
  }, [ps, statusFilter])
  const { isShowMore, filter, reset, goToNext } = usePaginate(passedProposals ? passedProposals.length : 0, 5, 1)

  return (
    <>
      <BottomSheet
        open={!!selectedProposal}
        onDismiss={() => setSelectedProposal(null)}
        header={selectedProposal && (
          <Box direction='row' align='center'>
            <ProposalAvatar
              title={'#' + selectedProposal.proposal_id.toString()}
              status={selectedProposal.status}
            >
              {selectedProposal.proposal_id}
            </ProposalAvatar>
            <Text size='medium' color='clrTextPrimary' margin={{ left: 'small' }} style={{ fontFamily: 'Moret' }}>
              Proposal
            </Text>
          </Box>
        )}
      >
        {selectedProposal && (
          <ProposalDetail proposal={selectedProposal} />
        )}
      </BottomSheet>

      {activeProposals === undefined ? (
        <Box margin={{ bottom: 'large' }}>
          <Text size='medium1' color='clrTextPrimary' textAlign='center' style={{ fontFamily: 'Moret' }}>
            Active Proposals
          </Text>

          <Box margin={{ top: 'small' }}>
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
          </Box>
        </Box>
      ) : activeProposals.length > 0 && (
        <Box margin={{ bottom: 'large' }}>
          <Text size='medium1' color='clrTextPrimary' textAlign='center' style={{ fontFamily: 'Moret' }}>
            Active Proposals
          </Text>
          {activeProposals.map(proposal => (
            <GradientBox
              key={`row-${proposal.proposal_id}`}
              noGradient
              onClick={() => setSelectedProposal(proposal)}
              direction='row'
              pad={{ vertical: 'medium', horizontal: 'small' }}
              margin={{ top: 'medium' }}
              align='center'
            >
              <ProposalAvatar
                title={'#' + proposal.proposal_id.toString()}
                status={ProposalType.VOTING_PERIOD}
              >
                {proposal.proposal_id}
              </ProposalAvatar>
              <Box pad={{ left: 'medium' }}>
                <Text size='small' color='clrTextPrimary'>
                  {proposal.title}
                </Text>
              </Box>
            </GradientBox>
          ))}
        </Box>
      )}

      {ps === undefined ? (
        <>
          <Text size='medium1' color='clrTextPrimary' textAlign='center' style={{ fontFamily: 'Moret' }}>
            Past Proposals
          </Text>

          <Box margin={{ top: 'small' }}>
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
          </Box>
        </>
      ) : ps.length > 0 && (
        <>
          <Text size='medium1' color='clrTextPrimary' textAlign='center' style={{ fontFamily: 'Moret' }}>
            Past Proposals
          </Text>
          <Box direction='row' margin={{ top: 'medium' }}>
            {[0, ProposalType.PASSED, ProposalType.REJECTED, ProposalType.FAILED].map(status => (
              <GradientBox
                margin={{ right: 'small', bottom: 'small' }}
                selected={status === statusFilter}
                onClick={() => {
                  setStatusFilter(status)
                  reset()
                }}
                flex={{ grow: 1 }}
                align='center'
                key={status}
                filter
              >
                <Text size="small" color='clrTextPrimary'>
                  {status === 0 ? 'All' : (ProposalDataStatus as any)[status]}
                </Text>
              </GradientBox>
            ))}
          </Box>
          {passedProposals && passedProposals.filter((_, index) => filter(index)).map(
            proposal => (
              <GradientBox
                key={`row-${proposal.proposal_id}`}
                noGradient
                onClick={() => setSelectedProposal(proposal)}
                direction='row'
                pad={{ vertical: 'medium', horizontal: 'small' }}
                margin={{ top: 'medium' }}
                align='center'
              >
                <ProposalAvatar
                  title={'#' + proposal.proposal_id.toString()}
                  status={proposal.status}
                >
                  {proposal.proposal_id}
                </ProposalAvatar>
                <Box pad={{ left: 'medium' }}>
                  <Text size='small' color='clrTextPrimary'>
                    {proposal.title}
                  </Text>
                </Box>
              </GradientBox>
            )
          )}
          <Box margin={{ top: 'large' }}>
            <ShowMore isShow={isShowMore} onClick={goToNext} />
          </Box>
        </>
      )}
    </>
  )
}

export default ProposalList
