/**
 * Generic typed comparison fn for use with `Array.sort`
 * This is passed as the comparison function with
 * the following syntax:
 * MyArray.sort(compareObjectsByKey<MyObjectType>('myObjectKey', true/false)`
 * @type T
 * @param key: keyof T
 * @param ascending: boolean
 */
export function compareObjectsByKey<T>(
  key: keyof T | ((record: T) => any),
  ascending = true
) {
  const value = (record: T) => {
    if (typeof key === 'function') {
      return key(record)
    } else if (typeof record[key] === 'string') {
      return (record[key] as string).toLowerCase().trim()
    } else {
      return record[key]
    }
  }

  return function innerSort(objectA: T, objectB: T) {
    const first = value(objectA)
    const second = value(objectB)

    const sortValue = first > second ? 1 : first < second ? -1 : 0;
    return ascending ? sortValue : -1 * sortValue;
  };
}
