import InfoTooltip from 'components/common/InfoTooltip/InfoTooltip'
import { Box, Grid, Text } from 'grommet'
import { ItemKey, SortDirection } from 'lib/hooks/sort/types'
import * as React from 'react'
import { IDataListColumn } from './DataList'
import { CaretDownFill, CaretUpFill } from 'grommet-icons'

export interface DataListHeaderProps<T> {
  columns: IDataListColumn[]
  sortKey?: ItemKey<T>
  sortDirection?: SortDirection
  handleSortChange?: (sort: string) => void
}

function Column<T>({
  column,
  sortKey,
  sortDirection,
}: {
  column: IDataListColumn
  sortKey?: ItemKey<T>
  sortDirection?: SortDirection
}) {
  return (
    <>
      <Text
        size="xsmall"
        style={{ letterSpacing: '0.1em' }}
        color="clrTextPrimary"
        textAlign={column.jusitfy === 'end' ? 'end' : column.jusitfy === 'center' ? 'center' : 'start'}
      >
        {column.title}
      </Text>
      {column.sortable && sortKey && (
        <Box margin={{ left: 'xsmall', vertical: '-10px' }}>
          {sortKey == column.sortLabel ? (
            sortDirection === 'asc' || !sortDirection ? (
              <CaretUpFill color={!sortDirection ? 'clrBorder' : 'clrTextPrimary'} />
            ) : (
              <CaretDownFill color="clrTextPrimary" />
            )
          ) : (
            <CaretUpFill color="clrBorder" />
          )}
        </Box>
      )}
    </>
  )
}

function DataListHeader<T>({ columns, sortKey, sortDirection, handleSortChange }: DataListHeaderProps<T>) {
  const columnSizes = columns.map((col) => col.size)

  return (
    <Box
      direction="row"
      border={{ side: 'bottom', size: '1px', color: 'clrDataListHeader' }}
      align="center"
      justify="start"
      fill="horizontal"
    >
      <Grid columns={columnSizes} gap="small" fill="horizontal">
        {columns.map((column, i) => (
          <Box key={`column-${i}`} direction="row" align="center" justify={column.jusitfy || 'start'}>
            <Box
              pad={{ vertical: 'small' }}
              onClick={() =>
                column.sortable && handleSortChange && column.sortLabel ? handleSortChange(column.sortLabel) : null
              }
              direction="row"
              align="center"
            >
              {column.tooltip ? (
                <InfoTooltip content={column.tooltip}>
                  <Box align="center" direction="row" pad={column.pad || ''} margin={column.margin || ''}>
                    <Column {...{ column, sortKey, sortDirection }} />
                  </Box>
                </InfoTooltip>
              ) : (
                <Box align="center" direction="row" pad={column.pad || ''} margin={column.margin || ''}>
                  <Column {...{ column, sortKey, sortDirection }} />
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

export default DataListHeader
