import TokenLogo from 'components/TokenLogo'
import { BoxProps } from 'grommet'
import React from 'react'
import DefaultLogo from './DefaultLogo'

interface ValidatorAvatarProps {
  url?: string
  width?: BoxProps['width']
  height?: BoxProps['height']
  isList?: boolean
  title: string
}

const ValidatorAvatar = ({ url, width, height, isList, title }: ValidatorAvatarProps) => {
  return (
    <>
      {url ? (
        <TokenLogo width="100%" height="100%" src={url} />
      ) : (
        <DefaultLogo
          textSize={!isList ? 'medium1' : 'medium'}
          width={width || '36px'}
          height={height || '36px'}
          title={title.charAt(0).toUpperCase()}
        />
      )}
    </>
  )
}

export default ValidatorAvatar
