import { MsgOpt } from '@keplr-wallet/stores'

export interface UmeeMsgOpts {
  readonly sendToEth: MsgOpt
  readonly cancelSendToEth: MsgOpt
  readonly lendToken: MsgOpt
  readonly withdrawToken: MsgOpt
  readonly setCollateral: MsgOpt
  readonly removeCollateral: MsgOpt
  readonly borrowToken: MsgOpt
  readonly repayToken: MsgOpt
  readonly compoundReward: MsgOpt
  readonly ibcTransfer: MsgOpt
}

export const defaultMsgOpts: UmeeMsgOpts = {
  sendToEth: {
    type: 'gravity/MsgSendToEth',
    gas: 200000,
  },
  lendToken: {
    type: 'umee/leverage/MsgSupplyCollateral',
    gas: 300000,
  },
  withdrawToken: {
    type: 'umee/leverage/MsgWithdraw',
    gas: 300000,
  },
  borrowToken: {
    type: 'umee/leverage/MsgBorrow',
    gas: 300000,
  },
  repayToken: {
    type: 'umee/leverage/MsgRepay',
    gas: 300000,
  },
  setCollateral: {
    type: 'umee/leverage/MsgCollateralize',
    gas: 300000,
  },
  removeCollateral: {
    type: 'umee/leverage/MsgDecollateralize',
    gas: 300000,
  },
  cancelSendToEth: {
    type: 'gravity/MsgCancelSendToEth',
    gas: 200000,
  },
  compoundReward: {
    type: 'cosmos-sdk/MsgDelegate',
    gas: 300000,
  },
  ibcTransfer: {
    type: 'cosmos-sdk/MsgTransfer',
    gas: 1000000,
  },
}
