import React from 'react'
import { Box, Text } from 'grommet'
import capitalize from 'lodash/capitalize'
import TokenLogo from 'components/TokenLogo'
import MenuOpen from 'components/common/MenuOpen'
import { ETxnType } from 'lib/types'
import { Close, Previous } from 'grommet-icons'
import { TokenItem } from 'components/DataList'
import HoverEffect from 'components/common/HoverEffect'
import { Validator } from 'api/stake/data'
import Token from 'components/DataList/Token'
import truncate from 'lib/truncate'

interface TransactionHeaderProps {
  txnType?: ETxnType
  symbol?: string
  logo?: string
  openMenu?: () => void
  isPreview?: boolean
  setIsPreview?: (isPreview: boolean) => void
  validator?: Validator
}

const TransactionHeader = ({
  txnType,
  symbol,
  openMenu,
  isPreview,
  setIsPreview,
  logo,
  validator,
}: TransactionHeaderProps) => {
  return txnType === ETxnType.markets ? (
    <Box direction="row" justify="between">
      <TokenItem validator={!!validator} logo={logo} symbol={symbol || ''} textSize="24px" fontFamily="Moret" />
      {openMenu && <MenuOpen onClick={openMenu} />}
    </Box>
  ) : (
    <Box style={{ position: 'relative' }}>
      {isPreview && setIsPreview && (
        <HoverEffect
          pad="medium"
          round="small"
          style={{ position: 'absolute', left: 0, cursor: 'pointer', top: '50%', transform: 'translateY(-50%)' }}
          onClick={() => setIsPreview(false)}
        >
          <Previous color="clrTextPrimary" size="16px" />
        </HoverEffect>
      )}
      <Box align="center" direction="row" justify="center">
        <Box style={{ position: 'relative' }}>
          <Text
            color="clrTextPrimary"
            margin={{ right: 'small' }}
            style={{ position: 'absolute', right: '100%', top: '50%', transform: 'translateY(-50%)' }}
            className="font-moret"
            size="24px"
          >
            {txnType && capitalize(txnType.toString())}
          </Text>
          <Token validator={!!validator} logo={logo} symbol={symbol || ''} />
          <Text
            color="clrTextPrimary"
            margin={{ left: 'small' }}
            style={{ position: 'absolute', left: '100%', top: '50%', transform: 'translateY(-50%)', whiteSpace: 'pre' }}
            className="font-moret"
            size="24px"
          >
            {capitalize(symbol && symbol.length > 10 ? truncate(symbol, 6, 5) : symbol)}
          </Text>
        </Box>
      </Box>
      <Box style={{ position: 'absolute', right: 0, borderRadius: '5px', top: '50%', transform: 'translateY(-50%)' }}>
        {isPreview && setIsPreview ? (
          <HoverEffect pad="medium" round="small" onClick={() => setIsPreview(false)}>
            <Close size="16px" style={{ cursor: 'pointer' }} color="clrTextPrimary" />
          </HoverEffect>
        ) : (
          openMenu && <MenuOpen onClick={openMenu} />
        )}
      </Box>
    </Box>
  )
}

export default TransactionHeader
