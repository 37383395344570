import {
  AccountStore,
  CosmosQueries,
  CosmosAccount,
  CosmwasmQueries,
  CosmwasmAccount,
  QueriesStore,
} from '@keplr-wallet/stores'
import { IndexedDBKVStore } from '@keplr-wallet/common'
import { ChainStore } from './chain'
import { ChainInfo, Keplr } from '@keplr-wallet/types'
import { EmbedChainInfos } from '../../config'
import { UmeeQueries } from './cosmos/query'
import { UmeeAccount } from './cosmos/account'
import { KeplrWalletConnectV1 } from '@keplr-wallet/wc-client'
import { PoolIntermediatePriceStore } from './price'
import EventEmitter from 'eventemitter3'
import { displayToast, TToastType } from 'components/common/toasts'
import { isSlippageError } from '../../utils/tx'
import { prettifyTxError } from './prettify-tx-error'
import { suggestChainFromWindow } from './utils'
import semver from 'semver'

export class RootStore {
  public readonly chainStore: ChainStore
  public readonly queriesStore: QueriesStore<[CosmosQueries, CosmwasmQueries, UmeeQueries]>
  public readonly priceStore: PoolIntermediatePriceStore
  public readonly accountStore: AccountStore<[CosmosAccount, CosmwasmAccount, UmeeAccount]>

  constructor(getKeplr: () => Promise<Keplr | undefined> = () => Promise.resolve(undefined)) {
    this.chainStore = new ChainStore(EmbedChainInfos, EmbedChainInfos[0].chainId)
    const eventListener = (() => {
      // On client-side (web browser), use the global window object.
      if (typeof window !== 'undefined') {
        return window
      }

      // On server-side (nodejs), there is no global window object.
      // Alternatively, use the event emitter library.
      const emitter = new EventEmitter()
      return {
        addEventListener: (type: string, fn: () => unknown) => {
          emitter.addListener(type, fn)
        },
        removeEventListener: (type: string, fn: () => unknown) => {
          emitter.removeListener(type, fn)
        },
      }
    })()
    this.queriesStore = new QueriesStore(
      new IndexedDBKVStore('store_web_queries'),
      this.chainStore,
      CosmosQueries.use(),
      CosmwasmQueries.use(),
      UmeeQueries.use(EmbedChainInfos[0].chainId),
    )

    this.accountStore = new AccountStore(
      eventListener,
      this.chainStore,
      () => {
        return {
          suggestChain: true,
          suggestChainFn: async (keplr, chainInfo) => {
            if (
              keplr.mode === 'mobile-web' &&
              // In keplr mobile below 0.10.9, there is no receiver for the suggest chain.
              // Therefore, it cannot be processed because it takes infinite pending.
              // As of 0.10.10, experimental support was added.
              !semver.satisfies(keplr.version, '>=0.10.10')
            ) {
              return
            }

            if (keplr instanceof KeplrWalletConnectV1) {
              return
            }
            await suggestChainFromWindow(keplr, chainInfo.raw)
          },
          autoInit: false,
          getKeplr,
        }
      },
      CosmosAccount.use({
        queriesStore: this.queriesStore,
        msgOptsCreator: (chainId) =>
          chainId.startsWith('evmos_') ? { ibcTransfer: { gas: 160000 } } : { ibcTransfer: { gas: 1000000 } },
        preTxEvents: {
          onBroadcastFailed: (chainId, e?: Error) => {
            let message: string = 'Unknown error'
            if (e instanceof Error) {
              message = e.message
            } else if (typeof e === 'string') {
              message = e
            }
            try {
              message = prettifyTxError(message, this.chainStore?.current?.currencies)
            } catch (e) {
              console.log(e)
            }

            displayToast('Transaction Failed', TToastType.TX_FAILED, {
              message,
            })
          },
          onFulfill: (cainId, tx: any) => {
            if (tx.code) {
              let message: string = tx.log

              if (isSlippageError(tx)) {
                message = 'Swap failed. Liquidity may not be sufficient. Try adjusting the allowed slippage.'
              } else {
                try {
                  message = prettifyTxError(message, this.chainStore?.current?.currencies)
                } catch (e) {
                  console.log(e)
                }
              }

              displayToast('Transaction Failed', TToastType.TX_FAILED, { message })
            }
          },
        },
      }),
      CosmwasmAccount.use({ queriesStore: this.queriesStore }),
      UmeeAccount.use({ queriesStore: this.queriesStore }),
    )

    this.priceStore = new PoolIntermediatePriceStore(
      EmbedChainInfos[0].chainId,
      this.chainStore,
      new IndexedDBKVStore('store_web_prices'),
      {
        usd: {
          currency: 'usd',
          symbol: '$',
          maxDecimals: 2,
          locale: 'en-US',
        },
      },
      'usd',
    )
  }
}

export function createRootStore() {
  return new RootStore()
}
