import { ChainGetter, IQueriesStore, AccountSetBaseSuper, CosmosAccount, CosmosQueries } from '@keplr-wallet/stores'
import { MsgDelegate, MsgUndelegate, MsgBeginRedelegate } from '@keplr-wallet/proto-types/cosmos/staking/v1beta1/tx'
import { DeepReadonly } from 'utility-types'
import deepmerge from 'deepmerge'
import { UmeeQueries } from '../query'
import { umee } from '../../../types/leverage/bundle.js'
import { gravity } from './bundle.js'
import { displayToast, TToastType } from 'components/common/toasts'
import { EmbedChainInfos } from '../../../../config'
import { ChainStore } from '../../chain'
import { UmeeMsgOpts, defaultMsgOpts } from './types'
import { DeepPartial } from 'utility-types'
import { stringToBigNumber } from 'lib/number-utils'
import { math } from 'polished'
import { Msg } from '@cosmjs/launchpad'
import axios from 'axios'

export interface UmeeAccount {
  umee: UmeeAccountImpl
}
export interface KeplrIntereactionOptions {
  readonly sign?: KeplrSignOptions
}

export interface KeplrSignOptions {
  readonly preferNoSetFee?: boolean
  readonly preferNoSetMemo?: boolean
  readonly disableBalanceCheck?: boolean
}

export const UmeeAccount = {
  use(options: {
    msgOptsCreator?: (chainId: string) => DeepPartial<UmeeMsgOpts> | undefined
    queriesStore: IQueriesStore<CosmosQueries & UmeeQueries>
  }): (base: AccountSetBaseSuper & CosmosAccount, chainGetter: ChainGetter, chainId: string) => UmeeAccount {
    return (base, chainGetter, chainId) => {
      const msgOptsFromCreator = options.msgOptsCreator ? options.msgOptsCreator(chainId) : undefined

      return {
        umee: new UmeeAccountImpl(
          base,
          chainGetter,
          chainId,
          options.queriesStore,
          deepmerge<UmeeMsgOpts, DeepPartial<UmeeMsgOpts>>(
            defaultMsgOpts,
            msgOptsFromCreator ? msgOptsFromCreator : {},
          ),
        ),
      }
    }
  },
}
export class UmeeAccountImpl {
  constructor(
    protected readonly base: AccountSetBaseSuper & CosmosAccount,
    protected readonly chainGetter: ChainGetter,
    protected readonly chainId: string,
    protected readonly queriesStore: IQueriesStore<CosmosQueries & UmeeQueries>,
    protected readonly _msgOpts: UmeeMsgOpts,
  ) {}

  protected get queries() {
    // eslint-disable-next-line
    return this.queriesStore.get(this.chainId)
  }

  async compoundReward(
    delegatorAddress: string,
    data: { validatorAddress: string; amount: { amount: string; denom: string } }[],
    onFulfill: () => void,
  ) {
    let msg: any[] = []

    data.map((data) => {
      msg.push({
        type: this._msgOpts.compoundReward.type,
        value: {
          delegator_address: delegatorAddress,
          validator_address: data.validatorAddress,
          amount: data.amount,
        },
      })
    })

    let protoMsgs: any[] = []

    data.map((data) => {
      protoMsgs.push({
        typeUrl: '/cosmos.staking.v1beta1.MsgDelegate',
        value: MsgDelegate.encode({
          delegatorAddress: delegatorAddress,
          validatorAddress: data.validatorAddress,
          amount: data.amount,
        }).finish(),
      })
    })

    const chainStore = new ChainStore(EmbedChainInfos, EmbedChainInfos[0].chainId)
    const chainInfo = chainStore.getChain('umee-1')

    await this.base.cosmos.sendMsgs(
      this._msgOpts.compoundReward.type,
      {
        aminoMsgs: msg,
        protoMsgs: protoMsgs,
      },
      '',
      {
        amount: [],
        gas: this._msgOpts.compoundReward.gas.toString(),
      },
      {
        disableBalanceCheck: true,
      },
      {
        onBroadcasted: (txHash: Uint8Array) => {
          displayToast('Compounding Reward', TToastType.TX_BROADCASTING)
        },
        onFulfill: (tx: any) => {
          if (!tx.code) {
            displayToast('Transaction Included in the Block', TToastType.TX_SUCCESSFUL, {
              customLink: chainInfo.raw.explorerUrlToTx.replace('{txHash}', tx.hash.toUpperCase()),
            })
            onFulfill()
          }
        },
      },
    )
  }

  async sendToEthereum(ethereumAddress: string, denom: string, amount: string, fee: string, onFulfill: () => void) {
    const msg = {
      type: this._msgOpts.sendToEth.type,
      value: {
        sender: this.base.bech32Address.toLowerCase(),
        eth_dest: ethereumAddress.toLowerCase(),
        amount: {
          denom: denom,
          amount: amount,
        },
        bridge_fee: {
          denom: denom,
          amount: fee,
        },
      },
    }

    const chainStore = new ChainStore(EmbedChainInfos, EmbedChainInfos[0].chainId)
    const chainInfo = chainStore.getChain(this.chainId)

    await this.base.cosmos.sendMsgs(
      this._msgOpts.sendToEth.type,
      {
        aminoMsgs: [msg],
        protoMsgs: [
          {
            typeUrl: '/gravity.v1.MsgSendToEth',
            value: gravity.v1.MsgSendToEth.encode({
              sender: msg.value.sender,
              ethDest: msg.value.eth_dest,
              amount: msg.value.amount,
              bridgeFee: msg.value.bridge_fee,
            }).finish(),
          },
        ],
      },
      '',
      {
        amount: [],
        gas: this._msgOpts.sendToEth.gas.toString(),
      },
      undefined,
      {
        onBroadcasted: (txHash: Uint8Array) => {
          displayToast('Transferring', TToastType.TX_BROADCASTING)
        },
        onFulfill: (tx: any) => {
          if (!tx.code) {
            displayToast('Transaction Included in the Block', TToastType.TX_SUCCESSFUL, {
              customLink: chainInfo.raw.explorerUrlToTx.replace('{txHash}', tx.hash.toUpperCase()),
            })
            displayToast(
              'Bridging in Progress',
              TToastType.TX_BROADCASTING,
              {
                message: 'This process may take a minute',
              },
              { delay: 3000 },
            )
            onFulfill()
          }
        },
      },
    )
  }

  async cancelSendToEth(txId: number) {
    const msg = {
      type: this._msgOpts.cancelSendToEth.type,
      value: {
        sender: this.base.bech32Address.toLowerCase(),
        transaction_id: txId,
      },
    }

    const chainStore = new ChainStore(EmbedChainInfos, EmbedChainInfos[0].chainId)
    const chainInfo = chainStore.getChain(this.chainId)

    await this.base.cosmos.sendMsgs(
      this._msgOpts.cancelSendToEth.type,
      {
        aminoMsgs: [msg],
        protoMsgs: [
          {
            typeUrl: '/gravity.v1.MsgCancelSendToEth',
            value: gravity.v1.MsgCancelSendToEth.encode({
              sender: msg.value.sender,
              transactionId: msg.value.transaction_id,
            }).finish(),
          },
        ],
      },
      '',
      {
        amount: [],
        gas: this._msgOpts.cancelSendToEth.gas.toString(),
      },
      undefined,
      {
        onFulfill: (tx: any) => {
          if (!tx.code) {
            displayToast('Transaction Included in the Block', TToastType.TX_SUCCESSFUL, {
              customLink: chainInfo.raw.explorerUrlToTx.replace('{txHash}', tx.hash.toUpperCase()),
            })
          }
        },
      },
    )
  }

  async lendToken(umeeAddress: string, amount: string, denom: string, onFulfill: () => void) {
    const chainStore = new ChainStore(EmbedChainInfos, EmbedChainInfos[0].chainId)
    const chainInfo = chainStore.getChain(this.chainId)
    let aminoMsgs: Msg[] = []
    let protoMsgs: any[] = []

    aminoMsgs.push({
      type: 'umee/leverage/MsgSupplyCollateral',
      value: {
        supplier: umeeAddress,
        asset: {
          denom: denom,
          amount: amount,
        },
      },
    })
    protoMsgs.push({
      typeUrl: '/umee.leverage.v1.MsgSupplyCollateral',
      value: umee.leverage.v1.MsgSupplyCollateral.encode({
        supplier: umeeAddress,
        asset: {
          denom: denom,
          amount: amount,
        },
      }).finish(),
    })

    await this.base.cosmos.sendMsgs(
      this._msgOpts.lendToken.type,
      {
        aminoMsgs: aminoMsgs,
        protoMsgs: protoMsgs,
      },
      '',
      {
        amount: [],
        gas: this._msgOpts.lendToken.gas.toString(),
      },
      undefined,
      {
        onBroadcastFailed: (error: any) => {
          console.log('onBroadcastFailed', error)
        },
        onBroadcasted: (txHash: Uint8Array) => {
          displayToast('Supplying', TToastType.TX_BROADCASTING)
        },
        onFulfill: (tx) => {
          if (!tx.code) {
            displayToast('Supply Successful', TToastType.TX_SUCCESSFUL, {
              customLink: chainInfo.raw.explorerUrlToTx.replace('{txHash}', tx.hash.toUpperCase()),
            })
            onFulfill()
          }
        },
      },
    )
    console.log('sending message done')
  }

  async withdrawToken(umeeAddress: string, amount: string, denom: string, onFulfill: () => void) {
    const msg = {
      type: 'umee/leverage/MsgWithdraw',
      value: {
        supplier: umeeAddress,
        asset: {
          denom: denom,
          amount: amount,
        },
      },
    }

    let protoMsgs: any[] = [
      {
        typeUrl: '/umee.leverage.v1.MsgWithdraw',
        value: umee.leverage.v1.MsgWithdraw.encode({
          supplier: umeeAddress,
          asset: {
            denom: denom,
            amount: amount,
          },
        }).finish(),
      },
    ]

    const chainStore = new ChainStore(EmbedChainInfos, EmbedChainInfos[0].chainId)
    const chainInfo = chainStore.getChain(this.chainId)
    await this.base.cosmos.sendMsgs(
      this._msgOpts.withdrawToken.type,
      {
        aminoMsgs: [msg],
        protoMsgs: protoMsgs,
      },
      '',
      {
        amount: [],
        gas: this._msgOpts.withdrawToken.gas.toString(),
      },
      undefined,
      {
        onBroadcasted: (txHash: Uint8Array) => {
          displayToast('Withdrawing', TToastType.TX_BROADCASTING)
        },
        onFulfill: (tx) => {
          if (!tx.code) {
            displayToast('Withdraw Successful', TToastType.TX_SUCCESSFUL, {
              customLink: chainInfo.raw.explorerUrlToTx.replace('{txHash}', tx.hash.toUpperCase()),
            })
            onFulfill()
          }
        },
      },
    )
  }

  async setCollateral(umeeAddress: string, denom: string, amount: string, onFulfill: () => void) {
    const msg = {
      type: 'umee/leverage/MsgCollateralize',
      value: {
        borrower: umeeAddress,
        asset: {
          denom: `u/${denom}`,
        },
      },
    }

    const chainStore = new ChainStore(EmbedChainInfos, EmbedChainInfos[0].chainId)
    const chainInfo = chainStore.getChain(this.chainId)

    await this.base.cosmos.sendMsgs(
      this._msgOpts.setCollateral.type,
      {
        aminoMsgs: [msg],
        protoMsgs: [
          {
            typeUrl: '/umee.leverage.v1.MsgCollateralize',
            value: umee.leverage.v1.MsgCollateralize.encode({
              borrower: umeeAddress,
              asset: {
                denom: `u/${denom}`,
              },
            }).finish(),
          },
        ],
      },
      '',
      {
        amount: [],
        gas: this._msgOpts.setCollateral.gas.toString(),
      },
      undefined,
      {
        onBroadcasted: (txHash: Uint8Array) => {
          displayToast(`Enabling use of reserve as collateral`, TToastType.TX_BROADCASTING)
        },
        onFulfill: (tx) => {
          if (!tx.code) {
            displayToast(`Enabling use of reserve as collateral`, TToastType.TX_SUCCESSFUL, {
              customLink: chainInfo.raw.explorerUrlToTx.replace('{txHash}', tx.hash.toUpperCase()),
            })
            onFulfill()
          }
        },
      },
    )
  }

  async removeCollateral(umeeAddress: string, denom: string, amount: string, onFulfill: () => void) {
    const msg = {
      type: 'umee/leverage/MsgDecollateralize',
      value: {
        borrower: umeeAddress,
        asset: {
          denom: `u/${denom}`,
          amount: amount,
        },
      },
    }

    const chainStore = new ChainStore(EmbedChainInfos, EmbedChainInfos[0].chainId)
    const chainInfo = chainStore.getChain(this.chainId)

    await this.base.cosmos.sendMsgs(
      this._msgOpts.removeCollateral.type,
      {
        aminoMsgs: [msg],
        protoMsgs: [
          {
            typeUrl: '/umee.leverage.v1.MsgDecollateralize',
            value: umee.leverage.v1.MsgDecollateralize.encode({
              borrower: umeeAddress,
              asset: {
                denom: `u/${denom}`,
                amount: amount,
              },
            }).finish(),
          },
        ],
      },
      '',
      {
        amount: [],
        gas: this._msgOpts.removeCollateral.gas.toString(),
      },
      undefined,
      {
        onBroadcasted: (txHash: Uint8Array) => {
          displayToast(`Disabling use of reserve as collateral`, TToastType.TX_BROADCASTING)
        },
        onFulfill: (tx) => {
          if (!tx.code) {
            displayToast(`Disabling use of reserve as collateral`, TToastType.TX_SUCCESSFUL, {
              customLink: chainInfo.raw.explorerUrlToTx.replace('{txHash}', tx.hash.toUpperCase()),
            })
            onFulfill()
          }
        },
      },
    )
  }

  async borrowToken(umeeAddress: string, amount: string, denom: string, onFulfill: () => void) {
    const msg = {
      type: 'umee/leverage/MsgBorrow',
      value: {
        borrower: umeeAddress,
        asset: {
          denom: denom,
          amount: amount,
        },
      },
    }

    const chainStore = new ChainStore(EmbedChainInfos, EmbedChainInfos[0].chainId)
    const chainInfo = chainStore.getChain(this.chainId)

    await this.base.cosmos.sendMsgs(
      this._msgOpts.borrowToken.type,
      {
        aminoMsgs: [msg],
        protoMsgs: [
          {
            typeUrl: '/umee.leverage.v1.MsgBorrow',
            value: umee.leverage.v1.MsgBorrow.encode({
              borrower: umeeAddress,
              asset: {
                denom: denom,
                amount: amount,
              },
            }).finish(),
          },
        ],
      },
      '',
      {
        amount: [],
        gas: this._msgOpts.borrowToken.gas.toString(),
      },
      undefined,
      {
        onBroadcasted: (txHash: Uint8Array) => {
          displayToast('Borrowing', TToastType.TX_BROADCASTING)
        },
        onFulfill: (tx) => {
          displayToast('Borrow Successful', TToastType.TX_SUCCESSFUL, {
            customLink: chainInfo.raw.explorerUrlToTx.replace('{txHash}', tx.hash.toUpperCase()),
          })
          if (!tx.code) {
            onFulfill()
          }
        },
      },
    )
  }

  async repayToken(umeeAddress: string, amount: string, denom: string, onFulfill: () => void) {
    const msg = {
      type: 'umee/leverage/MsgRepay',
      value: {
        borrower: umeeAddress,
        asset: {
          denom: denom,
          amount: amount,
        },
      },
    }

    const chainStore = new ChainStore(EmbedChainInfos, EmbedChainInfos[0].chainId)
    const chainInfo = chainStore.getChain(this.chainId)

    await this.base.cosmos.sendMsgs(
      'umee/leverage/MsgRepay',
      {
        aminoMsgs: [msg],
        protoMsgs: [
          {
            typeUrl: '/umee.leverage.v1.MsgRepay',
            value: umee.leverage.v1.MsgRepay.encode({
              borrower: umeeAddress,
              asset: {
                denom: denom,
                amount: amount,
              },
            }).finish(),
          },
        ],
      },
      '',
      {
        amount: [],
        gas: this._msgOpts.repayToken.gas.toString(),
      },
      undefined,
      {
        onBroadcasted: (txHash: Uint8Array) => {
          displayToast('Repaying', TToastType.TX_BROADCASTING)
        },
        onFulfill: (tx) => {
          if (!tx.code) {
            displayToast('Repay Successful', TToastType.TX_SUCCESSFUL, {
              customLink: chainInfo.raw.explorerUrlToTx.replace('{txHash}', tx.hash.toUpperCase()),
            })
            onFulfill()
          }
        },
      },
    )
  }

  protected hasNoLegacyStdFeature(): boolean {
    const chainInfo = this.chainGetter.getChain(this.chainId)
    return chainInfo.features != null && chainInfo.features.includes('no-legacy-stdTx')
  }
}

export * from './types'
