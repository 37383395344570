import React, { useState } from 'react'
import { ProposalData, ProposalDataStatus, VoteResultStatus } from 'api/stake/data'
import { DataList, DataListRow, PrimaryText, TextItem } from 'components/DataList'
import { IDataListColumn } from 'components/DataList/DataList'
import { SecondaryBtn } from 'components/common'
import ProposalDetail from './ProposalDetail'
import { Box } from 'grommet'
import VoteDialog from '../VoteDialog'
import { VoteResultColor } from 'api/stake/data'
import { getTimeRemaining } from 'lib/timeRemaining'
import ShowMore from 'components/ShowMore'
import { usePaginate } from 'lib/hooks/usePaginate'
import { useSort } from 'lib/hooks/sort/useSort'
import { SortOption } from 'lib/hooks/sort/types'
import { Theme, useTheme } from 'lib/hooks/theme/context'
import Modal from 'components/common/Modals/Modal'
import { SkeletonBar } from 'components/Skeleton'
import { useAccountConnection } from 'api/cosmosStores/account-init-management'

interface ProposalListProps {
  passedProposals?: ProposalData[]
  columns: IDataListColumn[]
  activeProposals?: ProposalData[]
  activeColumns: IDataListColumn[]
}

const ProposalList: React.FC<ProposalListProps> = ({ passedProposals, columns, activeProposals, activeColumns }) => {
  const columnSizes = columns.map((col) => col.size)
  const { themeMode } = useTheme()
  const [selectedProposal, setSelectedProposal] = useState<string>('')
  const [selectedVotingProposal, setSelectedVotingProposal] = useState<string>('')
  const { isShowMore, filter, goToNext } = usePaginate(passedProposals ? passedProposals.length : 0, 5, 1)
  const { isAccountConnected } = useAccountConnection()
  const { sortedData, sortDirection, sortKey, handleSortChange } = useSort({
    data: passedProposals ?? [],
    sortOptions: columns,
  })

  return (
    <>
      {selectedProposal && (
        <ProposalDetail
          onVote={(id) => {
            setSelectedProposal('')
            setSelectedVotingProposal(id)
          }}
          proposal_id={selectedProposal}
          onClose={() => setSelectedProposal('')}
        />
      )}
      {selectedVotingProposal && (
        <Modal onClose={() => setSelectedVotingProposal('')} className='proposal-vote-modal'>
          <Box border={{ size: '1px', color: 'clrSideNavBorder' }} round="10px" overflow='hidden'>
            <VoteDialog proposal_id={selectedVotingProposal} onClose={() => setSelectedVotingProposal('')} />
          </Box>
        </Modal>
      )}
      {activeProposals === undefined ? (
        <Box margin={{ bottom: 'medium' }}>
          <DataList columns={activeColumns} title={{ title: '', children: null }}>
            <SkeletonBar width='100%' margin={{ top: 'medium', bottom: 'small' }} height='1em' />
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
          </DataList>
        </Box>
      ) : activeProposals.length > 0 && (
        <Box margin={{ bottom: 'medium' }}>
          <DataList columns={activeColumns} title={{ title: '', children: null }}>
            {activeProposals.map((proposal) => (
              <DataListRow
                key={`row-${proposal.proposal_id}`}
                columnSizes={columnSizes}
                select={() => setSelectedProposal(proposal.proposal_id.toString())}
              >
                <TextItem justify="start">
                  <PrimaryText color="clrTextPrimary" size="small">
                    {`#${proposal.proposal_id} ${proposal.title}`}
                  </PrimaryText>
                </TextItem>
                <TextItem justify="end">
                  <PrimaryText color="clrTextPrimary" size="small">
                    {getTimeRemaining(proposal.voting_end_time)}
                  </PrimaryText>
                </TextItem>
                <TextItem justify="end">
                  {!isAccountConnected ? (
                    <PrimaryText color="clrTextPrimary" size="small">
                      -
                    </PrimaryText>
                  ) : proposal.voted ? (
                    <PrimaryText
                      color={themeMode === Theme.light ? 'clrTextPrimary' : VoteResultColor[proposal.voted]}
                      size="small"
                    >
                      {/* {VoteResultStatus[proposal.voted]} */}
                    </PrimaryText>
                  ) : (
                    <SkeletonBar width='2em' />
                  )}
                </TextItem>
                <TextItem justify="center">
                  <SecondaryBtn
                    text="VOTE"
                    round="large"
                    pad={{ vertical: 'small', horizontal: 'small' }}
                    textSize="xsmall"
                    onClick={(e) => {
                      e.stopPropagation()
                      setSelectedVotingProposal(proposal.proposal_id.toString())
                    }}
                    disabled={!proposal.voted}
                  />
                </TextItem>
              </DataListRow>
            ))}
          </DataList>
        </Box>
      )}
      {passedProposals === undefined ? (
        <Box margin={{ top: 'medium' }}>
          <DataList<ProposalData> columns={columns} title={{ title: '', children: null }}>
            <SkeletonBar width='100%' margin={{ top: 'medium', bottom: 'small' }} height='1em' />
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
            <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
          </DataList>
        </Box>
      ) : sortedData.length > 0 && (
        <>
          <Box margin={{ top: activeProposals && activeProposals.length > 0 ? 'medium' : '' }}>
            <DataList<ProposalData>
              columns={columns}
              sortKey={sortKey}
              sortDirection={sortDirection}
              handleSortChange={(sort: string) => handleSortChange({ sortLabel: sort } as SortOption<ProposalData>)}
              title={{ title: '', children: null }}
            >
              {sortedData.filter((_, index) => filter(index)).map(
                proposal => (
                  <DataListRow
                    key={`row-${proposal.proposal_id}`}
                    columnSizes={columnSizes}
                    select={() => setSelectedProposal(proposal.proposal_id.toString())}
                  >
                    <TextItem justify="start">
                      <PrimaryText color="clrTextPrimary" size="small">
                        {`#${proposal.proposal_id} ${proposal.title}`}
                      </PrimaryText>
                    </TextItem>
                    <TextItem justify="end">
                      <PrimaryText color="clrTextPrimary" size="small">
                        {ProposalDataStatus[proposal.status]}
                      </PrimaryText>
                    </TextItem>
                    <TextItem justify="end">
                      {!isAccountConnected ? (
                        <PrimaryText color="clrTextPrimary" size="small">
                          -
                        </PrimaryText>
                      ) : proposal.voted ? (
                        <PrimaryText
                          color={themeMode === Theme.light ? 'clrTextPrimary' : VoteResultColor[proposal.voted]}
                          size="small"
                        >
                          {/* {VoteResultStatus[proposal.voted]} */}
                        </PrimaryText>
                      ) : (
                        <SkeletonBar width='2em' />
                      )}
                    </TextItem>
                    <TextItem justify="center">
                      <SecondaryBtn
                        text="DETAILS"
                        round="large"
                        pad={{ vertical: 'small', horizontal: 'small' }}
                        textSize="xsmall"
                        onClick={() => setSelectedProposal(proposal.proposal_id.toString())}
                      />
                    </TextItem>
                  </DataListRow>
                )
              )}
            </DataList>
          </Box>
          <Box margin={{ top: 'large' }}>
            <ShowMore isShow={isShowMore} onClick={goToNext} />
          </Box>
        </>
      )}
    </>
  )
}

export default ProposalList
