import React from 'react'
import Modal from 'components/common/Modals/Modal'
import { DelegateType } from 'lib/types'
import { OperatorGrant, Validator } from 'api/stake/data'
import ValidatorDetail from './Detail'

interface ValidatorDetailProps {
  validator: Validator
  restakePossible?: boolean
  grants?: OperatorGrant
  onClose: () => void
  onMenuClick: (menu: DelegateType, validator: Validator) => void
  claimRewards?: (validator: Validator, compounding: boolean) => void
  autoCompound: (validator: Validator, grants: OperatorGrant) => void
}

const ValidatorDetailDesktop = ({
  validator,
  onClose,
  onMenuClick,
  claimRewards,
  restakePossible,
  grants,
  autoCompound,
}: ValidatorDetailProps) => {
  return (
    <Modal onClose={onClose} className="validator-modal">
      <ValidatorDetail
        validator={validator}
        restakePossible={restakePossible}
        grants={grants}
        onClose={onClose}
        onMenuClick={onMenuClick}
        claimRewards={claimRewards}
        autoCompound={autoCompound}
      />
    </Modal>
  )
}

export default ValidatorDetailDesktop
