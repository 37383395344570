import React, { useContext, useState, useMemo } from 'react'
import { DataList, DataListRow, PrimaryText, SubText, TextItem, TokenItem } from 'components/DataList'
import { BigNumber } from 'ethers'
import BridgeModal from 'components/BridgeTransaction/BridgeModal'
import AxelarBridgeModal from 'components/Axelar/Modal'
import { bigNumberToString } from 'lib/number-utils'
import abbreviateNumber from 'lib/abbreviate'
import { SecondaryBtn } from 'components/common'
import { ResponsiveContext, Box } from 'grommet'
import { Chain } from 'lib/hooks/chain/context'
import { useData } from 'api/data'
import { useUmeeTokenAddress } from 'api/web3/chains'
import { useWeb3 } from 'api/web3'
import { useAccountConnection } from 'api/cosmosStores/account-init-management'
import { connect } from 'api/web3/providers'
import { Chains } from '../../config'
import InfoTooltip from 'components/common/InfoTooltip/InfoTooltip'
import { ETxnType } from 'lib/types'
import { TransferDialog } from 'dialogs/Transfer'
import Collateral from 'components/DataList/Collateral'
import AssetTooltip from './Tooltips'
import { SkeletonBar } from 'components/Skeleton'
import { useSort } from 'lib/hooks/sort/useSort'
import { SortOption } from 'lib/hooks/sort/types'
import { IMarketsData, MarketsDataListProps, ModalType, AssetType } from './MarketsDataList'
import { MarketsDetail } from './MarketsDetail'

const aprDecimals = BigNumber.from(25)

const MarketsDataListDesktop: React.FC<MarketsDataListProps> = ({ columns, data, chainType }) => {
  const size = useContext(ResponsiveContext)
  const [tokenAddress, setTokenAddress] = useState<string>('')
  const [tokenName, setTokenName] = useState<string>('')
  const [tokenLogo, setTokenLogo] = useState<string>('')
  const [isModalShow, setIsModalShow] = useState<ModalType | null>(null)
  const columnSizes = columns.map((col) => col.size)
  const { ReserveData } = useData()
  const { chainId, account } = useWeb3()
  const umeeTokenAddr = useUmeeTokenAddress(chainId)
  const { isAccountConnected, connectAccount } = useAccountConnection()
  const [availableMarketFilter, setAvailableMarketFilter] = useState<AssetType[]>(['collateral', 'leverage'])

  const getCustomSortValue = useMemo(
    () => ({
      marketSizeUsd: (record: IMarketsData) => Number(record.marketSizeUsd),
      totalBorrowedUsd: (record: IMarketsData) => Number(record.totalBorrowedUsd),
      borrowApy: (record: IMarketsData) =>
        chainType == Chain.ethereum
          ? bigNumberToString(record.variableBorrowAPR, aprDecimals)
          : record.variableBorrowAPR,
    }),
    [chainType],
  )

  const { sortedData, sortDirection, sortKey, handleSortChange } = useSort({
    data: data || [],
    sortOptions: columns,
    getCustomSortValue,
  })

  const collateralCount = useMemo(() => {
    if (data) {
      const collaterals = data.filter((token) => !token.ltv.isZero())
      return collaterals.length
    }
  }, [data])

  const leverageCount = useMemo(() => {
    if (data) {
      const leverages = data.filter((token) => token.ltv.isZero())
      return leverages.length
    }
  }, [data])

  const ethAddress = useMemo(() => {
    const result: any = { UMEE: umeeTokenAddr }
    ReserveData.forEach((acc) => {
      result[acc.symbol] = acc.address
    })
    return result
  }, [umeeTokenAddr, ReserveData])

  const setModalInfo = (name: string, address: any, modal: ModalType, logo?: string) => {
    setTokenAddress(address)
    setTokenName(name)
    setIsModalShow(modal)
    setTokenLogo(logo || '')
  }

  const closeModal = () => setIsModalShow(null)

  const filterAssets = (filter: AssetType) => {
    setAvailableMarketFilter((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((data) => data !== filter)
      } else {
        return prev.concat(filter)
      }
    })
  }

  return (
    <>
      {isModalShow === 'bridge' && (
        <BridgeModal
          address={tokenAddress}
          tokenName={tokenName}
          logo={tokenLogo}
          onClose={closeModal}
          direction={chainType === Chain.cosmos ? ETxnType.withdraw : ETxnType.deposit}
        />
      )}
      {isModalShow === 'ibc' && (
        <TransferDialog
          onClose={closeModal}
          isMobileView={false}
          chainId={Chains[tokenName]}
          coinMinimalDenom={tokenAddress}
          direction={true}
          logo={tokenLogo}
        />
      )}
      {isModalShow === 'axelar' && (
        <AxelarBridgeModal address={tokenAddress} tokenName={tokenName} logo={tokenLogo} onClose={closeModal} />
      )}
      {isModalShow === 'markets' && <MarketsDetail address={tokenAddress} onClose={closeModal} />}

      <DataList
        background="clrDefaultBg"
        columns={columns}
        sortDirection={sortDirection}
        sortKey={sortKey}
        handleSortChange={(sort: string) => handleSortChange({ sortLabel: sort } as SortOption<IMarketsData>)}
        title={{
          title: 'Available Assets',
          children: (
            <Box direction="row">
              <InfoTooltip content={<AssetTooltip assetType={'collateral'} />}>
                <Collateral
                  onClick={() => filterAssets('collateral')}
                  filtering
                  margin={{ left: 'xsmall' }}
                  collateral={true}
                  count={collateralCount}
                  selected={availableMarketFilter.includes('collateral')}
                />
              </InfoTooltip>
              <InfoTooltip content={<AssetTooltip assetType="leverage" />}>
                <Collateral
                  onClick={() => filterAssets('leverage')}
                  filtering
                  margin={{ left: 'xsmall' }}
                  collateral={false}
                  count={leverageCount}
                  selected={availableMarketFilter.includes('leverage')}
                />
              </InfoTooltip>
            </Box>
          ),
        }}
      >
        {!data ? (
          <Box margin={{ top: 'small' }}>
            <SkeletonBar width="100%" margin={{ vertical: 'small' }} height="1em" />
            <SkeletonBar width="100%" margin={{ vertical: 'small' }} height="1em" />
            <SkeletonBar width="100%" margin={{ vertical: 'small' }} height="1em" />
          </Box>
        ) : (
          sortedData.map(
            ({
              name,
              address,
              marketSizeUsd,
              marketSize,
              depositAPY,
              variableBorrowAPR,
              totalBorrowed,
              totalBorrowedUsd,
              ltv,
              decimals,
              chain,
              logo,
            }: any) => {
              const borrowApy =
                variableBorrowAPR &&
                (chainType == Chain.ethereum ? bigNumberToString(variableBorrowAPR, aprDecimals) : variableBorrowAPR)

              const filterCollateral = availableMarketFilter.includes('collateral')
              const filterLeverage = availableMarketFilter.includes('leverage')

              if (
                !(filterCollateral && filterLeverage) &&
                !(filterCollateral && !filterLeverage && !ltv.isZero()) &&
                !(!filterCollateral && filterLeverage && ltv.isZero())
              ) {
                return null
              }

              return (
                <DataListRow
                  select={() =>
                    !(chainType == Chain.ethereum && name == 'UMEE') && setModalInfo(name, address, 'markets', logo)
                  }
                  columnSizes={columnSizes}
                  tokenAddress={address}
                  key={`row-${name}`}
                >
                  <TokenItem logo={logo} textSize="small" symbol={name} collateral={!ltv.isZero()} chain={chain} />
                  {size !== 'medium' && (
                    <>
                      <TextItem align="end" direction="column">
                        <PrimaryText color="clrTextPrimary" size="small">
                          {`$${
                            chainType == Chain.ethereum
                              ? abbreviateNumber(marketSizeUsd)
                              : abbreviateNumber(Number(marketSizeUsd).toFixed(2))
                          }`}
                        </PrimaryText>
                        <SubText margin={{ top: 'xxsmall' }} size="xsmall" color={'clrTextSecondary'}>
                          {`${abbreviateNumber(marketSize)} ${name}`}
                        </SubText>
                      </TextItem>

                      <TextItem align="end" direction="column">
                        <PrimaryText color="clrTextPrimary" size="small">
                          {`$${abbreviateNumber(Number(totalBorrowedUsd).toFixed(2))}`}
                        </PrimaryText>
                        <SubText
                          margin={{ top: 'xxsmall' }}
                          size="xsmall"
                          color={'clrTextSecondary'}
                        >{`${abbreviateNumber(totalBorrowed)} ${name}`}</SubText>
                      </TextItem>
                    </>
                  )}

                  <TextItem justify="end">
                    <PrimaryText color="clrSupplyText" size="small">
                      {depositAPY}%
                    </PrimaryText>
                  </TextItem>
                  <TextItem justify="end">
                    <PrimaryText color="clrBorrowText" size="small">
                      {borrowApy}%
                    </PrimaryText>
                  </TextItem>

                  <TextItem justify="end">
                    {/* <InfoTooltip content={tooltip}> */}
                    <SecondaryBtn
                      isConnected={() => {
                        !isAccountConnected && connectAccount()

                        if (chainType === Chain.ethereum || ['UMEE', 'DAI'].includes(name)) {
                          !account && connect()
                        }
                      }}
                      onClick={(e) => {
                        e.stopPropagation()

                        if (chainType === Chain.ethereum) {
                          setModalInfo(name, address, 'bridge', logo)
                        } else if (['UMEE', 'DAI'].includes(name)) {
                          setModalInfo(name, ethAddress[name], 'bridge', logo)
                        } else {
                          setModalInfo(name, address, 'ibc', logo)
                        }
                      }}
                      round="large"
                      pad={{ vertical: 'small', horizontal: 'small' }}
                      text="Transfer"
                      textSize="xsmall"
                      disabled={(chainType === Chain.ethereum && !['UATOM'].includes(name)) || !['ATOM'].includes(name)}
                    />
                    {/* </InfoTooltip> */}
                  </TextItem>
                </DataListRow>
              )
            },
          )
        )}
        <DataListRow columnSizes={columnSizes}>
          <TokenItem
            logo={'https://storage.googleapis.com/umeedropzone/usd-coin-usdc-logo.svg'}
            textSize="small"
            symbol={'axlUSDC'}
          />
          <TextItem align="end" direction="column"></TextItem>
          <TextItem align="end" direction="column"></TextItem>
          <TextItem justify="end"></TextItem>
          <TextItem justify="end"></TextItem>
          <TextItem justify="end">
            <SecondaryBtn
              round="large"
              pad={{ vertical: 'small', horizontal: 'small' }}
              text="Transfer"
              textSize="xsmall"
              onClick={(e) => {
                e.stopPropagation()

                setModalInfo('USDC', '', 'axelar', 'https://storage.googleapis.com/umeedropzone/usd-coin-usdc-logo.svg')
              }}
            />
          </TextItem>
        </DataListRow>
      </DataList>
    </>
  )
}

export default MarketsDataListDesktop
