import { AmountConfig, IFeeConfig } from '@keplr-wallet/hooks';
import { action, computed, makeObservable, observable, override } from 'mobx';
import { AppCurrency } from '@keplr-wallet/types';
import {
  ChainGetter,
  QueriesStore,
  CosmosQueries,
  CosmwasmQueries
} from '@keplr-wallet/stores';
import { UmeeQueries } from 'api/cosmosStores/cosmos/query'
import { useState } from 'react';

type QStore = QueriesStore<[CosmosQueries, CosmwasmQueries, UmeeQueries]>
export class BasicAmountConfig extends AmountConfig {
  @observable.ref
  protected _currency: AppCurrency;

  constructor(
    chainGetter: ChainGetter,
    quriesStore: QStore,
    initialChainId: string,
    sender: string,
    currency: AppCurrency,
  ) {
    super(chainGetter, quriesStore, initialChainId, sender, undefined);

    this._currency = currency;

    makeObservable(this);
  }

  get currency(): AppCurrency {
    return this._currency;
  }

  @override
  setAmount(amount: string) {
    this.setIsMax(false);
    super.setAmount(amount);
  }

  @action
  setCurrency(currency: AppCurrency) {
    this._currency = currency;
  }

  @override
  get sendCurrency(): AppCurrency {
    return this.currency;
  }

  @computed
  get sendableCurrencies(): AppCurrency[] {
    return [this.sendCurrency];
  }
}

export const useBasicAmountConfig = (
  chainGetter: ChainGetter,
  queryStore: QStore,
  chainId: string,
  sender: string,
  currency: AppCurrency,
  feeConfig: IFeeConfig
) => {
  const [config] = useState(() => new BasicAmountConfig(chainGetter, queryStore, chainId, sender, currency))
  config.setChain(chainId)
  config.setSender(sender)
  config.setCurrency(currency)
  config.setFeeConfig(feeConfig)

  return config
}
