import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useWeb3 } from 'api/web3'
import { PrimaryBtn } from 'components/common'
import { InfoPanelItem } from 'components/InfoBar'
import InfoCustomMeter from 'components/InfoBar/InfoCustomMeter'
import { InfoPanelItemStyles } from 'components/InfoBar/InfoPanelItem'
import { InfoWindow, InfoWindowBody } from 'components/InfoWindow'
import TokenLogo from 'components/TokenLogo'
import { BigNumber } from 'ethers'
import { Box, ResponsiveContext, Text } from 'grommet'
import { bigNumberToString } from 'lib/number-utils'
import { MarketDetailsBox } from 'pages/markets/MarketDetailsBox'
import { Chain, useChain } from 'lib/hooks/chain/context'
import { useStore } from 'api/cosmosStores'
import { ETxnType } from 'lib/types'
import { observer } from 'mobx-react-lite'
import { MarketsDetailPanelProps } from '.'
import styled from '@emotion/styled'
import GradientBox from 'components/common/GradientBox/GradientBox'

const ColorBar = styled(Box)`
  width: 15px;
  height: 5px;
`

interface MarketsDetailMobileProps {
  marketsDetail: MarketsDetailPanelProps
}

const MarketsDetailDesktop: React.FC<MarketsDetailMobileProps> = ({ marketsDetail }) => {
  const history = useHistory()
  const size = useContext(ResponsiveContext)
  const { chainMode } = useChain()
  const { accountStore, chainStore } = useStore()
  const umeeAddress = accountStore.getAccount(chainStore.current.chainId).bech32Address
  const web3 = useWeb3()
  const loggedIn = useMemo(() => {
    let loggedIn = true
    if (chainMode === Chain.cosmos) {
      if (!umeeAddress) loggedIn = false
    } else {
      if (!web3.account) loggedIn = false
    }

    return loggedIn
  }, [chainMode, umeeAddress, web3.account])

  const {
    borrowedPortion,
    totalBorrowedUsd,
    availableLiquidityUsd,
    liquidationThreshold,
    ltv,
    liquidationBonus,
    symbol,
    canUseAsCollateral,
    balance,
    decimals,
    liquidityRate,
    variableBorrowRate,
    currentVariableDebt,
    currentStableDebt,
    currentUTokenBalance,
    availableBorrowAmount,
    tokenAddress,
    logo,
  } = marketsDetail

  return (
    <Box width='100%' direction="row" fill="horizontal" alignContent="center" alignSelf="center" align="center">
      <Box width='100%' direction="column">
        <InfoWindow flex round="5px">
          <InfoWindowBody
            border={{ size: '1px', color: 'clrSideNavBorder' }}
            round="5px"
            pad='small'
            background="clrModalBackground"
          >
            <Box round="5px" direction={size === 'small' ? 'column' : 'row'} align="center" justify="center">
              <Box direction='row' margin={{ right: '2em' }} width='10em' justify='end'>
                <ColorBar background='clrSupply' margin={{ top: 'xsmall' }} />
                <Text color='clrTextPrimary' size='small' margin={{ left: 'xsmall'}}>
                  Available Liquidity
                  <br/>
                  ${Number(availableLiquidityUsd).toLocaleString()}
                </Text>
              </Box>
              <Box style={{ position: 'relative', minWidth: 'auto' }}>
                <InfoCustomMeter value={borrowedPortion} />
                <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <TokenLogo width="74" height="74" symbol={symbol} src={logo} />
                </Box>
              </Box>
              <Box direction='row' margin={{ left: '2em' }} width='10em'>
                <ColorBar background='clrBorrow' margin={{ top: 'xsmall' }} />
                <Text color='clrTextPrimary' size='small' margin={{ left: 'xsmall'}}>
                  Total Borrowed
                  <br/>
                  ${Number(totalBorrowedUsd).toLocaleString()}
                </Text>
              </Box>
            </Box>
            <Box direction='row' justify="around">
              <GradientBox width='9em' margin={{ right: 'small' }}>
                <InfoPanelItem
                  align="center"
                  textSize="xsmall"
                  title="MAXIMUM LTV"
                  style={InfoPanelItemStyles.Vertical}
                  data={
                    ltv ? [
                      {
                        value: bigNumberToString(ltv, 2) + '%',
                        textSize: 'small',
                      }
                    ] : [{ value: '—', textSize: 'small' }]
                  }
                  tooltip="Maximum amount that can be borrowed against the asset supplied as collateral."
                />
              </GradientBox>
              <GradientBox width='9em' margin={{ right: 'small' }}>
                <InfoPanelItem
                  align="center"
                  textSize="xsmall"
                  title="LIQUIDATION THRESHOLD"
                  style={InfoPanelItemStyles.Vertical}
                  data={
                    liquidationThreshold ? [
                      {
                        value: liquidationThreshold && bigNumberToString(liquidationThreshold, 2) + '%',
                        textSize: 'small',
                      }
                    ] : [{ value: '—', textSize: 'small' }]
                  }
                  tooltip="The liquidation threshold is the LTV ratio at which a borrower should expect to be liquidated."
                />
              </GradientBox>
              <GradientBox width='9em' margin={{ right: 'small' }}>
                <InfoPanelItem
                  align="center"
                  textSize="xsmall"
                  title="LIQUIDATION PENALTY"
                  style={InfoPanelItemStyles.Vertical}
                  data={
                    liquidationBonus ? [{
                      value: chainMode == Chain.cosmos
                        ? bigNumberToString(liquidationBonus, 2) + '%'
                        : (parseFloat(bigNumberToString(liquidationBonus, 2)) - 100).toFixed(2) + '%',
                      textSize: 'small',
                    }] : [{ value: '—', textSize: 'small' }]
                  }
                  tooltip="The liquidation penalty is the fee paid by a borrower in the event that they are liquidated"
                />
              </GradientBox>
              <GradientBox width='9em'>
                <InfoPanelItem
                  align="center"
                  textSize="xsmall"
                  title="COLLATERAL"
                  data={[{ value: canUseAsCollateral ? 'Yes' : 'No', textSize: 'small' }]}
                  style={InfoPanelItemStyles.Vertical}
                />
              </GradientBox>
            </Box>
          </InfoWindowBody>
        </InfoWindow>

        <InfoWindow>
          <InfoWindowBody background="transparent">
            <Box margin={{ top: 'small' }} direction={size === 'small' ? 'column' : 'row'} gap="small" flex>
              <MarketDetailsBox
                background="clrModalBackground"
                borderColor="clrSupply"
                title={`${ETxnType.deposit} INFORMATION`}
                textSize="xsmall"
              >
                <InfoPanelItem
                  title={`${ETxnType.deposit} Position`}
                  textSize="small"
                  justify="between"
                  style={InfoPanelItemStyles.Horizontal}
                  data={
                    loggedIn
                      ? [
                        {
                          value: bigNumberToString(currentUTokenBalance, decimals),
                          textSize: 'small',
                        },
                        { value: symbol == 'WETH' ? 'ETH' : symbol, textSize: 'small' },
                      ]
                      : [{ value: '—', textSize: 'small' }]
                  }
                />
                <InfoPanelItem
                  title="Wallet Balance"
                  textSize="small"
                  justify="between"
                  style={InfoPanelItemStyles.Horizontal}
                  data={
                    loggedIn
                      ? [
                        {
                          value: bigNumberToString(balance, decimals),
                          textSize: 'small',
                        },
                        { value: symbol == 'WETH' ? 'ETH' : symbol, textSize: 'small' },
                      ]
                      : [{ value: '—', textSize: 'small' }]
                  }
                />
                <InfoPanelItem
                  title={`${ETxnType.deposit} ${chainMode === Chain.cosmos ? 'APR' : 'APY'}`}
                  textSize="small"
                  justify="between"
                  style={InfoPanelItemStyles.Horizontal}
                  data={[
                    {
                      value:
                        chainMode == Chain.cosmos
                          ? liquidityRate.toString()
                          : bigNumberToString(liquidityRate, BigNumber.from(25)),
                      textSize: 'small',
                    },
                    { value: '%', textSize: 'small' },
                  ]}
                />

                <Box margin={{ top: 'medium' }} direction="row" justify="center">
                  <PrimaryBtn
                    fullWidth
                    text={ETxnType.deposit}
                    pad={{ vertical: 'xsmall' }}
                    textSize="medium"
                    round="medium"
                    disabled={!loggedIn}
                    background='clrSupply'
                    onClick={() =>
                      history.push({
                        pathname: '/dashboard',
                        state: {
                          tokenAddress: tokenAddress,
                          symbol: symbol,
                          txnType: ETxnType.deposit,
                          logo: logo,
                        },
                      })
                    }
                  />
                </Box>
              </MarketDetailsBox>
              <MarketDetailsBox
                background="clrModalBackground"
                borderColor="clrBorrow"
                title={`${ETxnType.borrow} INFORMATION`}
                textSize="xsmall"
              >
                <InfoPanelItem
                  title={`${ETxnType.borrow} Position`}
                  textSize="small"
                  justify="between"
                  style={InfoPanelItemStyles.Horizontal}
                  data={
                    loggedIn
                      ? [
                        {
                          value: bigNumberToString(currentVariableDebt.add(currentStableDebt), decimals),
                          textSize: 'small',
                        },
                        { value: symbol == 'WETH' ? 'ETH' : symbol, textSize: 'small' },
                      ]
                      : [{ value: '—', textSize: 'small' }]
                  }
                />
                <InfoPanelItem
                  title="Available"
                  textSize="small"
                  justify="between"
                  style={InfoPanelItemStyles.Horizontal}
                  data={
                    loggedIn
                      ? [
                        {
                          value: bigNumberToString(availableBorrowAmount, decimals),
                          textSize: 'small',
                        },
                        { value: symbol == 'WETH' ? 'ETH' : symbol, textSize: 'small' },
                      ]
                      : [{ value: '—', textSize: 'small' }]
                  }
                />
                <InfoPanelItem
                  title={`${ETxnType.borrow} ${chainMode === Chain.cosmos ? 'APR' : 'APY'}`}
                  textSize="small"
                  justify="between"
                  style={InfoPanelItemStyles.Horizontal}
                  data={[
                    {
                      value:
                        chainMode == Chain.cosmos
                          ? variableBorrowRate.toString()
                          : bigNumberToString(variableBorrowRate, BigNumber.from(25)),
                      textSize: 'small',
                    },
                    { value: '%', textSize: 'small' },
                  ]}
                />
                <Box margin={{ top: 'medium' }} direction="row" justify="center">
                  <PrimaryBtn
                    fullWidth
                    text={ETxnType.borrow}
                    pad={{ vertical: 'xsmall' }}
                    textSize="medium"
                    round="medium"
                    background='clrBorrow'
                    disabled={!loggedIn}
                    onClick={() =>
                      history.push({
                        pathname: '/dashboard',
                        state: { tokenAddress: tokenAddress, symbol: symbol, txnType: ETxnType.borrow, logo: logo },
                      })
                    }
                  />
                </Box>
              </MarketDetailsBox>
            </Box>
          </InfoWindowBody>
        </InfoWindow>
      </Box>
    </Box>
  )
}

export default observer(MarketsDetailDesktop)
