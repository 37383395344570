import React, { MouseEvent, useState } from 'react'
import InfoTooltip from 'components/common/InfoTooltip/InfoTooltip'
import { Box, DropButton, Text } from 'grommet'
import { InfoPanelItem } from 'components'
import { InfoPanelItemStyles } from 'components/InfoBar/InfoPanelItem'
import truncate from 'lib/truncate'
import { bigNumberToNumber, bigNumberToString } from 'lib/number-utils'
import { BigNumber } from 'ethers'
import { MenuItem } from '../../DelegationList'
import { PrimaryBtn } from 'components/common'
import { DelegateType } from 'lib/types'
import { PrimaryBtnBox } from 'components/common/Buttons/PrimaryButtonBox'
import { OperatorGrant, Validator } from 'api/stake/data'
import { getTimePassed } from 'lib/timeRemaining'
import { Theme, useTheme } from 'lib/hooks/theme/context'
import ModalHeader from '../../ModalHeader'
import { BlockContainer, Block } from '.'

const tokenDecimals = BigNumber.from(6)

interface DetailDesktopProps {
  validator: Validator
  restakePossible?: boolean
  grants?: OperatorGrant
  onClose: () => void
  onMenuClick: (menu: DelegateType, validator: Validator) => void
  claimRewards?: (validator: Validator, compounding: boolean) => void
  autoCompound: (validator: Validator, grants: OperatorGrant) => void
  uptime: number
  blocks: any[]
  delegation: BigNumber | undefined
  rewards: BigNumber | undefined
}

const commissionRate = (value: string) => (Number(value) * 100).toFixed(2)

const DetailDesktop: React.FC<DetailDesktopProps> = ({
  validator,
  onClose,
  onMenuClick,
  claimRewards,
  restakePossible,
  grants,
  autoCompound,
  uptime,
  blocks,
  delegation,
  rewards,
}) => {
  const [openMenu, setOpenMenu] = useState<boolean>()
  const { themeMode } = useTheme()

  if (!validator) {
    return null
  }

  return (
    <>
      <ModalHeader validator={validator} isDetail uptime={uptime} />
      <Box direction="row" flex gap="small" margin={{ top: 'small' }}>
        <Box width={'60%'}>
          <Box
            border={{ size: '1px', color: 'clrBoxBorder' }}
            round="xsmall"
            background="clrModalBackground"
            pad={'small'}
          >
            <Box direction="row" justify="between">
              <Text size="small" color={'clrTextPrimary'}>
                UPTIME
              </Text>
              <Text size="small" color={'clrTextPrimary'}>
                Last 100 Blocks
              </Text>
            </Box>
            <BlockContainer>
              {blocks &&
                blocks.map((block, index) => (
                  <React.Fragment key={index}>
                    <InfoTooltip content={<Box pad="xsmall">Block {block.block}</Box>}>
                      <Block key={index} filled={block.value} />
                    </InfoTooltip>
                  </React.Fragment>
                ))}
            </BlockContainer>
          </Box>
          <Box flex direction="row" margin={{ top: 'small' }} gap="small">
            <Box
              flex
              border={{ size: '1px', color: 'clrBoxBorder' }}
              round="xsmall"
              background="clrModalBackground"
              pad={'small'}
            >
              <Text margin={{ bottom: 'small' }} size="xsmall" color={'clrTextPrimary'}>
                STAKE INFORMATION
              </Text>
              <InfoPanelItem
                title="Staked Amount"
                textSize="xsmall"
                justify="between"
                style={InfoPanelItemStyles.Horizontal}
                data={[{ value: delegation ? bigNumberToString(delegation, tokenDecimals) : '0', textSize: 'xsmall' }]}
              />
              <InfoPanelItem
                title="Current Rewards"
                textSize="xsmall"
                justify="between"
                style={InfoPanelItemStyles.Horizontal}
                data={[{ value: rewards ? bigNumberToString(rewards, tokenDecimals) : '0', textSize: 'xsmall' }]}
              />
              {delegation ? (
                <DropButton
                  className="stake-manage"
                  dropAlign={{ top: 'bottom', left: 'left' }}
                  open={openMenu}
                  onClose={() => setOpenMenu(false)}
                  label={
                    <PrimaryBtnBox
                      margin={{ top: 'small' }}
                      pad={'xsmall'}
                      round="large"
                      text="Manage"
                      textSize="small"
                      onClick={() => setOpenMenu(true)}
                    />
                  }
                  dropContent={
                    <Box border={{ color: 'clrButtonBorderGrey', size: '1px' }} background="clrModalBackground">
                      <MenuItem
                        onClick={() => (claimRewards ? claimRewards(validator, false) : null)}
                        title="Claim Rewards"
                      />
                      <MenuItem
                        onClick={() => (claimRewards ? claimRewards(validator, true) : null)}
                        title="Compound Rewards"
                      />
                      {(Object.keys(DelegateType) as Array<keyof typeof DelegateType>).map((key) => (
                        <MenuItem
                          key={key}
                          border={
                            key === 'delegate' && {
                              color: 'clrButtonBorderGrey',
                              side: 'top',
                              size: '1px',
                            }
                          }
                          onClick={(e: MouseEvent) => {
                            e.stopPropagation()
                            setOpenMenu(false)
                            onMenuClick(DelegateType[key], validator)
                          }}
                          title={DelegateType[key]}
                        />
                      ))}
                    </Box>
                  }
                />
              ) : (
                <PrimaryBtn
                  margin={{ top: 'small' }}
                  pad={'xsmall'}
                  round="large"
                  text="Stake"
                  textSize="small"
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation()
                    onMenuClick(DelegateType.delegate, validator)
                  }}
                />
              )}
            </Box>
            <Box
              flex
              border={{ size: '1px', color: 'clrBoxBorder' }}
              round="xsmall"
              background="clrModalBackground"
              pad={'small'}
            >
              <Text margin={{ bottom: 'small' }} size="xsmall" color={'clrTextPrimary'}>
                COMPOUND OPTIONS
              </Text>
              <InfoPanelItem
                title="APR"
                textSize="xsmall"
                justify="between"
                style={InfoPanelItemStyles.Horizontal}
                data={[{ value: `${validator.apr}%`, textSize: 'xsmall' }]}
              />
              <InfoPanelItem
                title="Auto APY"
                textSize="xsmall"
                justify="between"
                style={InfoPanelItemStyles.Horizontal}
                data={[
                  {
                    value: `${parseFloat(validator.restake_apy) > 0 ? validator.restake_apy + '%' : '-'}`,
                    textSize: 'xsmall',
                  },
                ]}
              />
              <PrimaryBtn
                margin={{ top: 'small' }}
                pad={'xsmall'}
                round="large"
                height={'30px'}
                text={grants && grants.grantsExist ? 'Disable Auto-compound' : 'Auto-compound'}
                textSize={grants && grants.grantsExist ? 'xsmall' : 'small'}
                disabled={!restakePossible}
                onClick={() => grants && autoCompound(validator, grants)}
              />
            </Box>
          </Box>
        </Box>
        <Box
          border={{ size: '1px', color: 'clrBoxBorder' }}
          round="xsmall"
          background="clrModalBackground"
          pad={'small'}
          width={'40%'}
        >
          <Text margin={{ bottom: 'small' }} size="small" color={'clrTextPrimary'}>
            VALIDATOR DETAILS
          </Text>
          <InfoPanelItem
            title="Website"
            textSize="xsmall"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[
              {
                value: validator.website.length ? validator.website : '-',
                link: validator.website.length ? validator.website : undefined,
                textSize: 'xsmall',
                color: themeMode === Theme.light || validator.website.length === 0 ? 'clrTextPrimary' : 'clrBorrow',
              },
            ]}
          />
          <InfoPanelItem
            title="Address"
            textSize="xsmall"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            tooltip={validator.operator_address}
            pad={{ vertical: '13.5px' }}
            data={[
              {
                value: truncate(validator.operator_address, 12, 6),
                textSize: 'xsmall',
                link: `https://www.mintscan.io/umee/validators/${validator.operator_address}`,
                color: themeMode === Theme.light ? 'clrTextPrimary' : 'clrBorrow',
              },
            ]}
          />
          <InfoPanelItem
            title="Total Staked"
            textSize="xsmall"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[
              {
                value: Number(
                  Math.round(bigNumberToNumber(BigNumber.from(validator.tokens), tokenDecimals)),
                ).toLocaleString(),
                textSize: 'xsmall',
              },
            ]}
          />
          {/* <InfoPanelItem
            title="Voting Participation"
            textSize="xsmall"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[{ value: '', textSize: 'xsmall' }]}
          /> */}
          <InfoPanelItem
            title="Validator Since"
            textSize="xsmall"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[{ value: 'Block ' + (validator.start_height ?? '-').toString(), textSize: 'xsmall' }]}
          />
          <InfoPanelItem
            title="Commission Rate"
            textSize="xsmall"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[{ value: `${commissionRate(validator.commission_rates.rate)}%`, textSize: 'xsmall' }]}
          />
          <InfoPanelItem
            title="Max Commission Rate"
            textSize="xsmall"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[{ value: `${commissionRate(validator.commission_rates.max_rate)}%`, textSize: 'xsmall' }]}
          />
          <InfoPanelItem
            title="Max Commission Change"
            textSize="xsmall"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[
              {
                value: `${commissionRate(validator.commission_rates.max_change_rate)}%`,
                textSize: 'xsmall',
              },
            ]}
          />
          <InfoPanelItem
            title="Last Commission Change"
            textSize="xsmall"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[{ value: getTimePassed(validator.last_commission_update), textSize: 'xsmall' }]}
          />
        </Box>
      </Box>
    </>
  )
}

export default DetailDesktop
