import React, { useState } from 'react'
import { Text, Box, Image } from 'grommet'
import { ProposalType, ProposalData } from 'api/stake/data'
import { VoteResultColor, VoteResultStatus, ProposalDataStatus } from 'api/stake/data'
import { bigNumberToNumber } from 'lib/number-utils'
import styled from '@emotion/styled'
import BottomSheet from 'components/common/BottomSheet/BottomSheet'
import { turnout } from '../ProposalDesktop/ProposalDetail'
import { useStake } from 'api/stake'
import { PrimaryBtn } from 'components/common'
import VectorWhite from '../../../../public/images/vector-white.svg'
import VectorDark from '../../../../public/images/vector-dark.svg'
import VoteDialog from '../VoteDialog'
import { Theme, useTheme } from 'lib/hooks/theme/context'
import ProposalDescription from '../ProposalDescription'

interface ProposalDetailProps {
  proposal: ProposalData
}

const r = 100 / Math.PI / 2

const GraphContainer = styled.div`
  position: relative;
  padding: 1em;
`

const GraphDesc = styled.div`
  flex-direction: column;
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const StatusSquare = styled.div`
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
`

const LogoContainer = styled.div`
  position: relative;
  padding-right: 2em;
  text-align: right;
  height: 3em;
  z-index: 1;
  opacity: 0.1;

  img {
    position: relative;
    top: -5em;
  }
`

const formatTime = (value: string) => value.slice(0, 10) + ' ' + value.slice(11, 19)

const turnoutLimit = 0.4

const ProposalDetail: React.FC<ProposalDetailProps> = ({ proposal }) => {
  const { TotalBonded } = useStake()
  const { themeMode } = useTheme()
  const [voteModal, setVoteModal] = useState<ProposalData | null>()

  const turnoutVal = turnout(proposal, TotalBonded)

  return (
    <>
      <BottomSheet
        open={!!voteModal}
        onDismiss={() => setVoteModal(null)}
        header={
          <Text size="medium" color="clrTextPrimary" style={{ fontFamily: 'Moret' }}>
            Vote
          </Text>
        }
      >
        {voteModal && (
          <VoteDialog proposal_id={voteModal.proposal_id.toString()} onClose={() => setVoteModal(null)} mobile />
        )}
      </BottomSheet>

      <Box pad="large" border={{ side: 'bottom', color: 'clrBorderGrey' }}>
        <Text size="medium" color="clrTextPrimary" textAlign="center">
          #{proposal.proposal_id} {proposal.title}
        </Text>
        <GraphContainer>
          <svg width="100%" height="100%" viewBox="0 0 34 34">
            {proposal.final_tally_result.map((result, index) => (
              <circle
                key={index}
                cx="17"
                cy="17"
                r={r}
                strokeWidth="0.5"
                fill="none"
                stroke={VoteResultColor[result.label]}
                strokeDasharray={`${result.value} ${100 - Number(result.value)}`}
                strokeDashoffset={
                  -proposal.final_tally_result.slice(0, index).reduce((acc, cur) => acc + Number(cur.value), 0)
                }
              >
                <title>
                  {VoteResultStatus[result.label]}, {result.value}%
                </title>
              </circle>
            ))}
          </svg>
          <GraphDesc>
            <Text size="medium" color="clrTextPrimary" textAlign="center">
              {bigNumberToNumber(proposal.total_vote, 6).toLocaleString()}
            </Text>
            <Box margin={{ vertical: 'small' }}>
              <Text size="xsmall" color="clrDarkGreyOnNavy" textAlign="center">
                Total Votes
              </Text>
            </Box>
            <Text size="small" color="clrTextPrimary" textAlign="center">
              Status:&nbsp;
              <Text size="small" color="red" textAlign="center">
                {ProposalDataStatus[proposal.status]}
              </Text>
            </Text>
          </GraphDesc>
        </GraphContainer>

        <Box direction="row" margin={{ top: 'small' }} justify="between">
          <Box
            pad="small"
            round="xsmall"
            border={{ color: turnoutVal < turnoutLimit ? 'red' : 'green' }}
            justify="center"
          >
            <Text size="xsmall" color={turnoutVal < turnoutLimit ? 'red' : 'green'}>
              Turnout: {(turnoutVal * 100).toFixed(2)}%
            </Text>
          </Box>
          <PrimaryBtn
            text="Vote"
            pad="small"
            textSize="small"
            round="large"
            width="7em"
            onClick={() => setVoteModal(proposal)}
            disabled={!(proposal.voted && proposal.status === ProposalType.VOTING_PERIOD)}
          />
        </Box>
      </Box>
      <Box pad="large" border={{ side: 'bottom', color: 'clrBorderGrey' }}>
        <Box justify="between" direction="row" margin={{ bottom: 'medium' }}>
          <Text size="small" color="clrTextPrimary" textAlign="center">
            Submit Time
          </Text>
          <Text size="small" color="clrTextPrimary" textAlign="center">
            {formatTime(proposal.submit_time)}
          </Text>
        </Box>
        <Box justify="between" direction="row" margin={{ bottom: 'medium' }}>
          <Text size="small" color="clrTextPrimary" textAlign="center">
            Voting Starts
          </Text>
          <Text size="small" color="clrTextPrimary" textAlign="center">
            {formatTime(proposal.voting_start_time)}
          </Text>
        </Box>
        <Box justify="between" direction="row">
          <Text size="small" color="clrTextPrimary" textAlign="center">
            Voting Ends
          </Text>
          <Text size="small" color="clrTextPrimary" textAlign="center">
            {formatTime(proposal.voting_end_time)}
          </Text>
        </Box>
      </Box>
      <Box pad="large">
        {proposal.final_tally_result.map((result) => (
          <Box key={result.label} justify="between" direction="row" margin={{ bottom: 'medium' }}>
            <Box direction="row">
              <StatusSquare style={{ backgroundColor: VoteResultColor[result.label] }} />
              <Text size="small" color="clrTextPrimary" textAlign="center">
                {VoteResultStatus[result.label]}
              </Text>
            </Box>
            <Text size="small" color="clrTextPrimary" textAlign="center">
              {result.value}%
            </Text>
          </Box>
        ))}

        <Box round="small" margin={{ top: 'medium' }} border={{ color: 'clrBorderGrey' }} pad="medium">
          <ProposalDescription description={proposal.description} />
        </Box>
      </Box>
      <LogoContainer>
        <Image width={75} src={themeMode === Theme.dark ? VectorWhite : VectorDark} alt="umee vector" />
      </LogoContainer>
    </>
  )
}

export default ProposalDetail
