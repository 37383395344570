import { useEffect, useState, useCallback } from 'react'
import { compareObjectsByKey } from './utils'
import { SortDirection, ItemKey, SortOption } from './types'
import { IDataListColumn } from 'components/DataList/DataList'

export interface SortProps<T> {
  data: T[]
  sortOptions: IDataListColumn[]
  defaultDirection?: SortDirection
  getCustomSortValue?: { [key: string]: (record: T) => any }
}

export function useSort<T>({ data, sortOptions, defaultDirection, getCustomSortValue }: SortProps<T>) {
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(defaultDirection)
  const initialSortKey = sortOptions[0].sortLabel as ItemKey<T>
  const [sortKey, setSortKey] = useState<ItemKey<T>>(initialSortKey)
  const [sortedData, setSortedData] = useState<T[]>([])

  useEffect(() => {
    const dataArr = data.concat()

    if (dataArr?.length) {
      if (sortDirection) {
        dataArr.sort(
          compareObjectsByKey(
            getCustomSortValue && getCustomSortValue[sortKey as string]
              ? getCustomSortValue[sortKey as string]
              : sortKey,
            sortDirection === 'asc',
          ),
        )

        setSortedData(dataArr)
      } else {
        setSortedData(data)
      }
    }
  }, [data, sortDirection, getCustomSortValue, sortKey])

  const handleSortChange = useCallback(
    (selectedOption: SortOption<T> | null, direction?: SortDirection) => {
      const newSortKey = selectedOption?.sortLabel
      if (newSortKey) {
        if (sortKey !== newSortKey) {
          setSortKey(newSortKey)
          if (direction) setSortDirection(direction)
          else setSortDirection('asc')
        } else {
          if (direction) setSortDirection(direction)
          else setSortDirection(!sortDirection ? 'asc' : sortDirection === 'asc' ? 'desc' : 'asc')
        }
      }
    },
    [sortDirection, sortKey],
  )

  return {
    handleSortChange,
    sortDirection,
    sortKey,
    sortedData,
  }
}
