import React, { useState } from 'react'
import { OperatorGrant, Validator } from 'api/stake/data'
import TransactionHeader from 'components/TransactionsMobile/Header'
import { ETxnType } from 'lib/types'
import Delegate from '../Delegate'
import BottomSheet from 'components/common/BottomSheet/BottomSheet'

interface DelegateMobileProps {
  txnType?: ETxnType
  onClose: () => void
  symbol: string
  logo?: string
  validator?: Validator
  grants?: OperatorGrant
}

const DelegateMobile = ({ txnType, onClose, symbol, logo, validator, grants }: DelegateMobileProps) => {
  return (
    <BottomSheet
      onDismiss={onClose}
      open={txnType === ETxnType.stake}
      header={<TransactionHeader symbol={symbol} logo={logo} txnType={txnType} validator={validator} />}
    >
      {txnType && (validator ? <Delegate validator={validator} grants={grants} onClose={onClose} /> : null)}
    </BottomSheet>
  )
}

export default DelegateMobile
