import * as React from 'react'
import { Box, BoxProps, Text } from 'grommet'
import InfoTooltip, { TooltipContainer } from 'components/common/InfoTooltip/InfoTooltip'
import { BigNumber } from 'ethers'

export interface InfoPanelData {
  value?: string | BigNumber
  link?: string
  title?: string
  textSize?: string
  bold?: boolean
  color?: string
}

export enum InfoPanelItemStyles {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export interface InfoPanelItemProps extends BoxProps {
  title: string
  color?: string
  data?: InfoPanelData[]
  style?: InfoPanelItemStyles
  align?: BoxProps['align']
  justify?: BoxProps['justify']
  subTitle?: string
  textSize?: string
  titleBg?: string
  titleDirection?: BoxProps['direction']
  tooltip?: string
  subData?: InfoPanelData[]
}

interface TitleProps {
  title: string
  textSize?: string
  color?: string
}

const Title: React.FC<TitleProps> = ({ title, textSize, color }) => (
  <Text color={color || 'clrTextPrimary'} size={textSize || 'medium'}>
    {title}
  </Text>
)

const Item: React.FC<InfoPanelItemProps> = ({
  titleBg,
  title,
  titleDirection,
  textSize,
  subTitle,
  data,
  color,
  subData,
  ...props
}) => (
  <>
    <Box justify="start" margin={{ right: 'xsmall' }} pad={props.pad}>
      {titleBg ? (
        <Box align="center" direction={titleDirection}>
          <Box margin={{ horizontal: '2px' }} width="10px" height="10px" background={titleBg} />
          <Title title={title} textSize={textSize} color={color} />
        </Box>
      ) : (
        <Title title={title} textSize={textSize} color={color} />
      )}
      {!!subTitle && (
        <Text color="clrTextPrimary" size="xsmall">
          {subTitle}
        </Text>
      )}
    </Box>
    <Box direction="column" align="end">
      {data && (
        <Box direction="row" align="end">
          {data.map((datum, index) => (
            <Text
              as={datum.link ? 'a' : undefined}
              key={`${title}-${datum.value}`}
              href={datum.link}
              target="_blank"
              rel="noreferrer"
              color={datum.color || 'clrTextPrimary'}
              size={datum.textSize || 'medium'}
              weight={datum.bold ? 'bold' : 'normal'}
              style={{ marginLeft: index > 0 ? '2px' : '' }}
            >
              {datum.value}
            </Text>
          ))}
        </Box>
      )}
      {subData && (
        <Box direction="row" align="end">
          {subData.map((datum, index) => (
            <Text
              as={datum.link ? 'a' : undefined}
              key={`${title}-${datum.value}`}
              color={datum.color || 'clrTextPrimary'}
              href={datum.link}
              target="_blank"
              rel="noreferrer"
              size={datum.textSize || 'medium'}
              weight={datum.bold ? 'bold' : 'normal'}
              style={{ marginLeft: index > 0 ? '2px' : '' }}
            >
              {datum.value}
            </Text>
          ))}
        </Box>
      )}
    </Box>
  </>
)

const VerticalItem: React.FC<InfoPanelItemProps> = ({
  data,
  title,
  color,
  justify,
  subTitle,
  textSize,
  align,
  titleBg,
  titleDirection,
  style = InfoPanelItemStyles.Vertical,
  tooltip,
  ...props
}) => (
  <Box
    justify={justify ? justify : 'center'}
    pad={props.pad || { vertical: 'xsmall' }}
    align={align ? align : 'end'}
    flex
  >
    {titleBg ? (
      <Box align="center" direction={titleDirection}>
        <Box margin={{ horizontal: '2px' }} width="10px" height="10px" background={titleBg} />
        <Title title={title} textSize={textSize} color={color} />
      </Box>
    ) : (
      <Title title={title} textSize={textSize} color={color} />
    )}
    <Box direction="row" align={align ? align : 'end'}>
      {data &&
        data.map((datum) => (
          <Text
            color={datum.color || 'clrTextPrimary'}
            key={`${title}-${datum.value}`}
            size={datum.textSize || 'medium'}
            weight={datum.bold ? 'bold' : 'normal'}
            style={{ lineHeight: '22px' }}
          >
            {datum.value}
          </Text>
        ))}
    </Box>
  </Box>
)

const HorizontalItem: React.FC<InfoPanelItemProps> = ({
  data,
  title,
  justify,
  subTitle,
  textSize,
  align,
  titleBg,
  titleDirection,
  style = InfoPanelItemStyles.Vertical,
  tooltip,
  subData,
  ...props
}) => (
  <Box
    pad={props.pad || { vertical: 'xxsmall' }}
    direction="row"
    align="center"
    flex
    wrap
    justify={justify ? justify : 'between'}
  >
    <Item
      titleBg={titleBg}
      title={title}
      titleDirection={titleDirection}
      textSize={textSize}
      subTitle={subTitle}
      data={data}
      subData={subData}
    />
  </Box>
)

const InfoPanelItem: React.FC<InfoPanelItemProps> = ({ tooltip, style, ...props }) => {
  const Item = style === InfoPanelItemStyles.Vertical ? VerticalItem : HorizontalItem

  if (tooltip) {
    return (
      <InfoTooltip content={<TooltipContainer title={props.title} content={tooltip} />}>
        <Item {...props} />
      </InfoTooltip>
    )
  } else {
    return <Item {...props} />
  }
}

export default InfoPanelItem
