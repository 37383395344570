import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Layout from 'pages/Layout'
import { useStake } from 'api/stake'
import { IDataListColumn } from 'components/DataList/DataList'
import ValidatorList, { restakePossible } from './components/ValidatorList'
import DelegationList, { ActiveDelegation } from './components/DelegationList'
import { BigNumber } from 'ethers'
import { observer } from 'mobx-react-lite'
import CompoundReward from './components/CompoundReward'
import { useStore } from 'api/cosmosStores'
import { Grant, Validator } from 'api/stake/data'
import { buildGrants } from 'api/stake/grants'
import Overview from './components/StakeOverview'
import { ResponsiveContext } from 'grommet'
import BottomMenus from 'components/common/BottomMenu/BottomMenus'
import { ETxnType } from 'lib/types'
import DelegateMobile from './components/Modals/Mobile/Delegate'
import DetailMobile from './components/Modals/Mobile/Detail'
import { bigNumberToNumber } from 'lib/number-utils'

const validatorColumns: IDataListColumn[] = [
  { title: 'Active Validators', size: '30%', sortLabel: 'moniker', sortable: true },
  { title: 'Annual APR', size: 'flex', jusitfy: 'end', sortLabel: 'apr', sortable: true },
  { title: 'Voting Power', size: 'flex', jusitfy: 'end', sortLabel: 'tokens', sortable: true },
  { title: 'Auto Compound', size: 'flex', jusitfy: 'end', sortLabel: 'auto-compound', sortable: true },
  { title: 'Compound APY', size: 'flex', jusitfy: 'end', sortLabel: 'compound-apy', sortable: true },
  { title: '', size: '15%', jusitfy: 'center' },
]

const delegationColumns: IDataListColumn[] = [
  { title: 'Your Staked Positions', size: '30%' },
  { title: 'Amount Staked', size: 'flex', jusitfy: 'end', pad: { right: '1.8em' } },
  { title: 'Pending Reward', size: 'flex', jusitfy: 'end', pad: { right: '1.8em' } },
  { title: 'Auto Compound', size: 'flex', jusitfy: 'end', pad: { right: '1.8em' } },
  { title: 'Compound APY', size: 'flex', jusitfy: 'end', pad: { right: '1.8em' } },
  { title: '', size: '15%', jusitfy: 'center' },
]

const Container = () => {
  const {
    Validators,
    Delegations,
    Rewards,
    Grants,
    Operators,
    TotalStaked,
    userAPR,
    fetchValidators,
    fetchDelegations,
    fetchRewards,
    fetchOperators,
    fetchGrants,
  } = useStake()
  const [delegations, setDelegations] = useState<ActiveDelegation[]>()
  const [grants, setGrants] = useState<Grant>({})
  const [selectedValidator, setSelectedValidator] = useState<Validator>()
  const [clickedTxnType, setClickedTxnType] = useState<ETxnType>()
  const { accountStore, chainStore } = useStore()
  const account = accountStore.getAccount(chainStore.current.chainId)
  const chain = chainStore.current
  const size = useContext(ResponsiveContext)

  useEffect(() => {
    fetchValidators()
    fetchDelegations()
    fetchRewards()
    fetchOperators()
    fetchGrants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const rewardsInterval = setInterval(fetchRewards, 15_000)
    const delegationsInterval = setInterval(fetchDelegations, 30_000)
    const grantsInterval = setInterval(fetchGrants, 60_000)

    return () => {
      clearInterval(rewardsInterval)
      clearInterval(delegationsInterval)
      clearInterval(grantsInterval)
    }
  }, [fetchDelegations, fetchRewards, fetchGrants])

  const pendingReward = useMemo(() => {
    if (Rewards) {
      const total = Rewards.total
      if (total && total.length > 0) {
        return BigNumber.from(Math.round(Number(total[0].amount)))
      }
    }

    return BigNumber.from(0)
  }, [Rewards])

  useEffect(() => {
    if (!Grants || !Rewards) return

    const denom = chain.stakeCurrency.coinMinimalDenom
    const grants = Grants.reduce((sum, grants) => {
      const botAddress = grants.botAddress
      const operatorAddress = grants.operatorAddress
      const operatorGrant = buildGrants(grants, botAddress, account.bech32Address)
      const defaultGrant = {
        claimGrant: null,
        stakeGrant: null,
        validators: [],
        grantsValid: false,
        grantsExist: false,
      }
      const grant = operatorGrant || defaultGrant
      const rewardsArr = Rewards.rewards
      let reward = '0'

      if (rewardsArr) {
        const validatorReward = rewardsArr.find((reward) => reward.validator_address === operatorAddress)
        if (validatorReward && validatorReward.reward) {
          const rewardAmount = validatorReward.reward.find((el) => el.denom === denom)
          reward = rewardAmount ? rewardAmount.amount : '0'
        }
      }

      sum[operatorAddress] = {
        ...grant,
        grantsValid: !!(
          grant.stakeGrant &&
          (!grant.validators || grant.validators.includes(operatorAddress)) &&
          (grant.maxTokens === null || grant.maxTokens.gt(BigNumber.from(Number(reward).toFixed(0))))
        ),
        grantsExist: !!(grant.claimGrant || grant.stakeGrant),
      }

      return sum
    }, {})

    setGrants(grants)
  }, [Rewards, Grants, chain, account.bech32Address])

  useEffect(() => {
    if (Validators && Delegations) {
      let delegations = Delegations.sort((a, b) => {
        const firstBalance = typeof a.balance === 'string' ? a.balance : a.balance.amount
        const secondBalance = typeof b.balance === 'string' ? b.balance : b.balance.amount
        return Number(secondBalance) - Number(firstBalance)
      })

      let activeDelegations: ActiveDelegation[] = []
      delegations.map((delegation) => {
        const validator_address = delegation.delegation.validator_address
        const validator = Validators.find((validator) => validator.operator_address === validator_address)
        const balance = delegation.balance.amount

        const activeDelegation: any = {
          validator_address: validator_address,
          validator: validator,
          staked_amount: BigNumber.from(balance),
          reward_amount: BigNumber.from(0),
          restake_apy: validator?.restake_apy,
          apr: validator?.apr,
        }

        if (Rewards && Rewards.rewards) {
          const rewards = Rewards.rewards
          const rewardAmount = rewards.find((reward) => reward.validator_address === validator_address)
          if (rewardAmount && rewardAmount.reward)
            activeDelegation.reward_amount = BigNumber.from(
              rewardAmount.reward.length ? Number(rewardAmount.reward[0].amount).toFixed(0) : '0',
            )
        }

        if (validator) activeDelegations.push(activeDelegation)
      })

      setDelegations(activeDelegations)
    }
  }, [Validators, Delegations, Rewards])

  const apr = useMemo(() => {
    return userAPR(TotalStaked, delegations)
  }, [delegations, TotalStaked])

  const operatorGrants = useCallback(
    (operatorAddress: string) => {
      const operatorGrants = grants && grants[operatorAddress]
      return operatorGrants
    },
    [grants],
  )

  return (
    <Layout
      title="Stake"
      toggleChain={false}
      element={delegations && <CompoundReward pendingReward={pendingReward} delegations={delegations} />}
    >
      <Overview
        margin={{ bottom: 'large' }}
        pendingReward={Rewards ? pendingReward : null}
        currentAPR={apr === undefined ? null : apr.toFixed(2)}
      />
      {size !== 'small' && delegations && (
        <DelegationList allGrants={grants} delegations={delegations} columns={delegationColumns} />
      )}

      <ValidatorList
        selectedValidator={selectedValidator}
        setSelectedValidator={setSelectedValidator}
        activeColumns={validatorColumns}
        activeValidators={Validators}
      />

      {size === 'small' && (
        <>
          <BottomMenus
            open={!!selectedValidator}
            validator
            symbol={selectedValidator ? selectedValidator.moniker : ''}
            items={[ETxnType.stake, ETxnType.validator]}
            setOpenSheet={() => setSelectedValidator(undefined)}
            setSelectedMenu={setClickedTxnType}
          />
          <DelegateMobile
            txnType={clickedTxnType}
            onClose={() => setClickedTxnType(undefined)}
            symbol={selectedValidator?.moniker || ''}
            logo={selectedValidator?.img_url}
            validator={selectedValidator}
            grants={operatorGrants(selectedValidator?.operator_address || '')}
          />
          <DetailMobile
            txnType={clickedTxnType}
            onClose={() => setClickedTxnType(undefined)}
            symbol={selectedValidator?.moniker || ''}
            logo={selectedValidator?.img_url}
            validator={selectedValidator}
            grants={operatorGrants(selectedValidator?.operator_address || '')}
            restakePossible={
              Operators ? restakePossible(selectedValidator?.operator_address || '', Operators) : undefined
            }
          />
        </>
      )}
    </Layout>
  )
}

export default observer(Container)
