import React, { useMemo } from 'react'
import TransactionContainer from 'components/TransactionsMobile/TransactionContainer'
import { ETxnSteps, ETxnType } from 'lib/types'
import { BigNumber } from 'ethers'
import { Operator, OperatorGrant, Validator } from 'api/stake/data'
import { useStake } from 'api/stake'
import { InfoPanelItem } from 'components'
import { InfoPanelItemStyles } from 'components/InfoBar/InfoPanelItem'
import { Box } from 'grommet'
import { bigNumberToString, fixedLocaleString } from 'lib/number-utils'
import { useConvexityData } from 'api/convexity'

interface DelegateInputMobileProps {
  validator: Validator
  walletBalance: BigNumber
  txnStep: ETxnSteps
  txnAmount: string
  availableBalance: BigNumber
  setTxnAmount: (amount: string) => void
  delegate: () => void
  isPreview: boolean
  setIsPreview(isPreview: boolean): void
  tokenDecimals: BigNumber
  grants?: OperatorGrant
}

const DelegateInputMobile = ({
  validator,
  walletBalance,
  txnStep,
  txnAmount,
  availableBalance,
  setTxnAmount,
  delegate,
  isPreview,
  setIsPreview,
  tokenDecimals,
  grants,
}: DelegateInputMobileProps) => {
  const [isPending, setIsPending] = React.useState(false)
  const [isFinal, setIsFinal] = React.useState(false)
  const { Delegations, Rewards } = useStake()

  const { RegisteredTokens } = useConvexityData()
  const umeePrice = Number(RegisteredTokens.find((token) => token.name === 'UMEE')?.usdPrice ?? 0)

  React.useEffect(() => {
    txnStep === ETxnSteps.Pending || txnStep === ETxnSteps.PendingApprove || txnStep === ETxnSteps.PendingSubmit
      ? setIsPending(true)
      : setIsPending(false)

    txnStep === ETxnSteps.Failure || txnStep === ETxnSteps.Success ? setIsFinal(true) : setIsFinal(false)
  }, [txnStep])

  const delegation = useMemo(() => {
    if (Delegations) {
      const delegation = Delegations.find(
        (delegation) => delegation.delegation.validator_address === validator.operator_address,
      )
      if (delegation) {
        const balance = typeof delegation.balance === 'string' ? delegation.balance : delegation.balance.amount
        return BigNumber.from(balance)
      }
    }
  }, [Delegations, validator.operator_address])

  const rewards = useMemo(() => {
    if (Rewards && Rewards.rewards) {
      const rewards = Rewards.rewards.find((delegation) => delegation.validator_address === validator.operator_address)
      if (rewards && rewards.reward) return BigNumber.from(Math.round(Number(rewards.reward[0].amount)))
    }
  }, [Rewards, validator.operator_address])

  return (
    <TransactionContainer
      onClose={() => {}}
      symbol={validator.moniker}
      logo={validator.img_url}
      txnType={ETxnType.stake}
      withdrawModal={false}
      availableAmount={availableBalance}
      setTxnAmount={setTxnAmount}
      setIsPreview={setIsPreview}
      isPreview={isPreview}
      isPending={isPending}
      isFinal={isFinal}
      handleContinue={delegate}
      tokenDecimals={tokenDecimals}
      txnAvailability={{ tokenDecimals, availableAmount: availableBalance, token: { symbol: 'UMEE' } }}
      txnAmount={txnAmount}
      validator={validator}
    >
      {isPreview && (
        <Box>
          <InfoPanelItem
            align="center"
            textSize="small"
            title="Total Staked"
            subData={[
              { value: delegation ? bigNumberToString(delegation, tokenDecimals) : '0', textSize: 'small' },
              { value: 'UMEE', textSize: 'small', color: 'clrDarkGreyOnNavy' },
            ]}
            data={[
              {
                value: `$${fixedLocaleString(
                  Number(bigNumberToString(delegation || BigNumber.from(0), tokenDecimals)) * umeePrice,
                  2,
                )}`,
                textSize: 'small',
              },
            ]}
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
          />
          <Box margin={{ top: 'medium' }}>
            <InfoPanelItem
              align="center"
              textSize="small"
              title="Pending Rewards"
              data={[
                {
                  value: `$${fixedLocaleString(
                    Number(bigNumberToString(rewards || BigNumber.from(0), tokenDecimals)) * umeePrice,
                    2,
                  )}`,
                  textSize: 'small',
                },
              ]}
              subData={[
                { value: rewards ? bigNumberToString(rewards, tokenDecimals) : '0', textSize: 'small' },
                { value: 'UMEE', textSize: 'small', color: 'clrDarkGreyOnNavy' },
              ]}
              justify="between"
              style={InfoPanelItemStyles.Horizontal}
            />
          </Box>
          <Box margin={{ top: 'medium' }} pad={{ top: 'small' }}>
            <InfoPanelItem
              align="center"
              textSize="small"
              title="Current APR"
              data={[{ value: `${validator.apr} %`, textSize: 'small' }]}
              justify="between"
              style={InfoPanelItemStyles.Horizontal}
            />
          </Box>
          <Box margin={{ top: 'large' }}>
            <InfoPanelItem
              align="center"
              textSize="small"
              title="Auto-Compound"
              data={[{ value: grants && grants.grantsExist ? 'Active' : 'Inactive', textSize: 'small' }]}
              justify="between"
              style={InfoPanelItemStyles.Horizontal}
            />
          </Box>
        </Box>
      )}
    </TransactionContainer>
  )
}

export default DelegateInputMobile
