import { useStake } from 'api/stake'
import React from 'react'
import { fixedLocaleString } from 'lib/number-utils'
import { Text } from 'grommet'
import { SkeletonBar } from './Skeleton'
import { useAccountConnection } from 'api/cosmosStores/account-init-management'

const TotalStaked = ({ color, size }: { color?: string; size?: string }) => {
  const { Delegations, TotalStaked } = useStake()
  const { isAccountConnected } = useAccountConnection()

  if (!isAccountConnected) {
    return (
      <Text size={size || 'small'} color={color || 'clrTextPrimary'}>
        -
      </Text>
    )
  }

  if (Delegations === undefined) {
    return <SkeletonBar margin={{ top: 'small' }} />
  }

  return (
    <Text size={size || 'small'} color={color || 'clrTextPrimary'}>
      {fixedLocaleString(TotalStaked, 2)} UMEE
    </Text>
  )
}

export default TotalStaked
