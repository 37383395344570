import { useMemo, useState } from 'react'

export const usePaginate = (length: number, perPage: number, initPage: number) => {
  const [pageIndex, setPageIndex] = useState<number>(initPage)
  
  const pageNum = useMemo(() => {
    if (perPage <= 0) {
      console.error('perPage should be higher than 0')
      return 0
    }

    return Math.ceil(length / perPage)
  }, [length, perPage])

  const isShowMore = pageNum > pageIndex

  const goToNext = () => setPageIndex((pageIndex) => pageIndex + 1)

  const filter = (index: number) => (index >= 0 && index < pageIndex * perPage)

  const reset = () => setPageIndex(initPage)

  return { filter, goToNext, reset, isShowMore }
}
