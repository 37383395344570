import React, { useEffect, useState } from 'react'
import { Box, Image, Text } from 'grommet'
import { Chains } from 'lib/constants'
import { useWeb3 } from 'api/web3'
import { useStore } from 'api/cosmosStores'
import { Chain } from 'lib/hooks/chain/context'
import DirectionIcon from '../../public/images/direction.png'
import GradientBox from 'components/common/GradientBox/GradientBox'
import { InfoPanelItem } from 'components/InfoBar'
import { InfoPanelItemStyles } from 'components/InfoBar/InfoPanelItem'
import { PrimaryBtn } from 'components/common/Buttons/PrimaryButton'
import { BigNumber } from 'ethers'
import { ITokenData } from 'lib/types'
import { TxnAmountInput } from 'components/Transactions/TxnAmountInput'
import { bigNumberToString } from 'lib/number-utils'
import ChainSelector from './ChainSelector'

export interface BridgeChain {
  logo: string
  name: string
  chainId: string
  type: Chain
}

interface MenuOpen {
  start: boolean
  end: boolean
}

const BridgeDialog = ({ address, tokenName, onClose }: { address: string; tokenName: string; onClose: () => void }) => {
  const [startChain, setStartChain] = useState<BridgeChain>()
  const [endChain, setEndChain] = useState<BridgeChain>()
  const [startAddress, setStartAddress] = useState<string>()
  const [endAddress, setEndAddress] = useState<string>()
  const [menuOpen, setMenuOpen] = useState<MenuOpen>({ start: false, end: false })
  const [bridgeFee, setBridge] = useState<any>(5)
  const [time, setTime] = useState<any>(2)
  const [availableAmount, setAvailableAmount] = useState<BigNumber>(BigNumber.from(0))
  const [txnAmount, setTxnAmount] = useState<string>('')
  const [token, setToken] = useState<ITokenData>({
    symbol: 'USDC',
  })
  const [tokenDecimals, setTokenDecimals] = useState<BigNumber>(BigNumber.from(6))

  const { accountStore, chainStore } = useStore()
  const umeeAccount = accountStore.getAccount(chainStore.current.chainId).bech32Address
  const { account } = useWeb3()

  useEffect(() => {
    setStartChain(Chains.find((chain) => chain.name === 'Umee'))
    setEndChain(Chains.find((chain) => chain.name === 'Ethereum'))
  }, [])

  const changeDirection = () => {
    setStartChain(Chains.find((chain) => chain.name === endChain?.name))
    setEndChain(Chains.find((chain) => chain.name === startChain?.name))
  }

  const handleContinue = () => {}

  return (
    <Box pad={{ top: 'medium' }}>
      <Box pad={{ horizontal: 'medium', bottom: 'medium' }}>
        <Box direction="row" justify="between" style={{ position: 'relative' }}>
          <ChainSelector
            open={menuOpen.start}
            chain={startChain}
            chains={Chains.filter((chain) => chain.name !== startChain?.name && chain.name !== endChain?.name)}
            onClose={() => setMenuOpen({ ...menuOpen, start: false })}
            onOpen={() => setMenuOpen({ ...menuOpen, start: true })}
            setChain={(chain) => setStartChain(chain)}
          />

          <ChainSelector
            open={menuOpen.end}
            chain={endChain}
            chains={Chains.filter((chain) => chain.name !== startChain?.name && chain.name !== endChain?.name)}
            onClose={() => setMenuOpen({ ...menuOpen, end: false })}
            onOpen={() => setMenuOpen({ ...menuOpen, end: true })}
            setChain={(chain) => setEndChain(chain)}
          />
          <Box
            className="position-center"
            background={'clrModalBackground'}
            margin={{ top: '8px' }}
            onClick={() => changeDirection()}
          >
            <GradientBox pad={'0'} thick="medium">
              <Image width="38px" height="38px" src={DirectionIcon} />
            </GradientBox>
          </Box>
        </Box>

        {token && (
          <Box margin={{ top: 'medium', bottom: '-12px' }}>
            <Box direction="row" justify="between" margin={{ bottom: 'xsmall' }} align="center">
              <Text size="xsmall" color="clrTextPrimary">
                AMOUNT TO TRANSFER
              </Text>
              <Box direction="row" align="center">
                <Text size="xsmall" color="clrTextPrimary">
                  AVAILABLE:
                </Text>
                <Box
                  margin={{ left: 'xsmall' }}
                  onClick={() =>
                    setTxnAmount(
                      Number(bigNumberToString(availableAmount, tokenDecimals)) > 0
                        ? bigNumberToString(availableAmount, tokenDecimals)
                        : '0',
                    )
                  }
                >
                  <Text weight={'bold'} size="xsmall" color="clrBorrowText">
                    {bigNumberToString(availableAmount, tokenDecimals)} {token.symbol}
                  </Text>
                </Box>
              </Box>
            </Box>
            <TxnAmountInput
              txnAmount={txnAmount}
              txnAvailability={{ availableAmount, tokenDecimals, token }}
              setTxnAmount={setTxnAmount}
              isRangeInput={false}
              isMaxEnable={false}
            />
          </Box>
        )}
      </Box>
      <Box pad={{ horizontal: 'medium', top: 'medium' }} border={{ size: '1px', side: 'top', color: 'clrBorder' }}>
        <Text size="xsmall" color={'clrTextPrimary'}>
          TRANSFER INFORMATION
        </Text>
        <Box margin={{ top: 'small' }}>
          <InfoPanelItem
            align="center"
            textSize="small"
            title={'Send address'}
            data={[
              {
                value: startAddress,
                textSize: 'small',
              },
            ]}
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
          />
        </Box>
        <Box margin={{ top: 'small' }}>
          <InfoPanelItem
            align="center"
            textSize="small"
            title={'Receiving address'}
            data={[
              {
                value: endAddress,
                textSize: 'small',
              },
            ]}
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
          />
        </Box>
        <Box margin={{ top: 'small' }}>
          <InfoPanelItem
            align="center"
            textSize="small"
            title={'Bridge Fee'}
            data={[
              {
                value: bridgeFee,
                textSize: 'small',
              },
            ]}
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
          />
        </Box>
        <Box margin={{ top: 'small' }}>
          <InfoPanelItem
            align="center"
            textSize="small"
            title={'Estimated Wait'}
            data={[
              {
                value: `~ ${time}`,
                textSize: 'small',
              },
            ]}
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
          />
        </Box>
        <PrimaryBtn
          onClick={() => handleContinue()}
          round="xlarge"
          text={'Transfer'}
          pad={{ vertical: 'small' }}
          textSize="medium"
          disabled={!txnAmount || Number(txnAmount) === 0}
          fullWidth
          margin={{ top: 'medium' }}
        />
      </Box>
    </Box>
  )
}

export default BridgeDialog
