import React, { useContext } from 'react'
import { BigNumber } from 'ethers'
import { ResponsiveContext } from 'grommet'
import MarketsDetailDesktop from './Desktop'
import MarketsDetailMobile from './Mobile'
import Modal from 'components/common/Modals/Modal'

export interface MarketsDetailPanelProps {
  borrowedPortion: number
  totalBorrowedUsd: string
  availableLiquidityUsd: string
  liquidationThreshold: BigNumber
  ltv: BigNumber
  liquidationBonus: BigNumber
  symbol: string
  canUseAsCollateral: boolean
  balance: BigNumber
  decimals: BigNumber
  liquidityRate: BigNumber | string
  variableBorrowRate: BigNumber | string
  currentVariableDebt: BigNumber
  currentStableDebt: BigNumber
  currentUTokenBalance: BigNumber
  availableBorrowAmount: BigNumber | string
  tokenAddress: string
  title?: string
  description?: string
  logo?: string
  usdPrice?: number
}

const MarketsDetailPanel = ({
  marketsDetail,
  onClose,
}: {
  marketsDetail: MarketsDetailPanelProps
  onClose: () => void
}) => {
  const size = useContext(ResponsiveContext)

  return size !== 'small' ? (
    <Modal onClose={onClose}>
      <MarketsDetailDesktop marketsDetail={marketsDetail} />
    </Modal>
  ) : (
    <MarketsDetailMobile marketsDetail={marketsDetail} />
  )
}

export default MarketsDetailPanel
