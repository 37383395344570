import React, { useContext, useMemo } from 'react'
import { BigNumber } from 'ethers'
import { Box, BoxProps, ResponsiveContext, Text } from 'grommet'
import Overview from 'components/common/Overview'
import { bigNumberToNumber, fixedLocaleString, formatFixed } from 'lib/number-utils'
import { InfoBarBody } from 'components'
import TotalStaked from 'components/TotalStaked'
import { SkeletonBar } from 'components/Skeleton'
import GradientBox from 'components/common/GradientBox/GradientBox'
import abbreviateNumber from 'lib/abbreviate'
import { useStake } from 'api/stake'
import { useConvexityData } from 'api/convexity'

interface OverviewProps extends BoxProps {
  currentAPR: string | null
  pendingReward: BigNumber | null
}

interface OverviewMobileProps extends BoxProps {
  title: string
  value?: string | React.ReactChild
  percent?: boolean
  color?: string
  usdValue?: string | number | React.ReactChild
}

const OverviewMobile = ({ title, value, color, usdValue, percent = false, ...props }: OverviewMobileProps) => (
  <GradientBox pad={{ horizontal: 'medium', vertical: 'small' }} {...props}>
    <Text size="small" color="clrTextPrimary">
      {title}
    </Text>
    <Box justify="between" flex direction="row" align="baseline">
      <Text className={!color ? 'gradient-text' : ''} color={color} size="medium1" weight="normal">
        {usdValue}
      </Text>
      {!percent && (
        <Box direction="row" align="baseline">
          <Text size="small" color="clrTextPrimary">
            {abbreviateNumber(value)}
          </Text>
          <Text size="xsmall" margin={{ left: 'xsmall' }} color="clrDarkGreyOnNavy">
            UMEE
          </Text>
        </Box>
      )}
    </Box>
  </GradientBox>
)

const StakeOverview = ({ pendingReward, currentAPR, ...props }: OverviewProps) => {
  const size = useContext(ResponsiveContext)
  const { Delegations } = useStake()
  const { RegisteredTokens } = useConvexityData()
  const umeePrice = Number(RegisteredTokens.find((token) => token.name === 'UMEE')?.usdPrice ?? 0)

  const totalStaked = useMemo(() => {
    if (Delegations) {
      return bigNumberToNumber(
        BigNumber.from(Delegations.reduce((acc, cur) => acc + Number(cur.balance.amount), 0).toString()),
        6,
      )
    } else {
      return 0
    }
  }, [Delegations])

  return size === 'small' ? (
    <InfoBarBody gap={'medium'} direction={'column'} {...props}>
      <OverviewMobile
        title="Total Staked"
        value={totalStaked === null ? <SkeletonBar margin={{ top: 'medium' }} /> : `${totalStaked}`}
        usdValue={
          totalStaked === null ? (
            <SkeletonBar margin={{ top: 'medium' }} />
          ) : (
            `$${abbreviateNumber(formatFixed(totalStaked * umeePrice))}`
          )
        }
        color="clrSupply"
      />
      <OverviewMobile
        title="Pending Rewards"
        value={
          pendingReward === null ? (
            <SkeletonBar margin={{ top: 'medium' }} />
          ) : (
            `${formatFixed(bigNumberToNumber(pendingReward, 6))}`
          )
        }
        usdValue={
          pendingReward === null ? (
            <SkeletonBar margin={{ top: 'medium' }} />
          ) : (
            `$${abbreviateNumber(formatFixed(bigNumberToNumber(pendingReward, 6) * umeePrice))}`
          )
        }
        color="clrBorrow"
      />
      <OverviewMobile
        title="Current APR"
        usdValue={currentAPR === null ? <SkeletonBar margin={{ top: 'medium' }} /> : `${currentAPR}%`}
        percent
      />
    </InfoBarBody>
  ) : (
    <InfoBarBody gap={'medium'} direction={size === 'medium' || size === 'small' ? 'column' : 'row'} {...props}>
      <Overview pad={'medium'} title="Total Staked" value={<TotalStaked size="medium1" />} flex />
      <Overview
        pad={'medium'}
        unit={currentAPR === null ? '' : '%'}
        title="Current APR"
        value={currentAPR === null ? <SkeletonBar margin={{ top: 'medium' }} /> : currentAPR}
        flex
      />
      <Overview
        pad={'medium'}
        title="Pending Reward"
        value={
          pendingReward === null ? (
            <SkeletonBar margin={{ top: 'medium' }} />
          ) : (
            fixedLocaleString(bigNumberToNumber(pendingReward, 6), 2)
          )
        }
        color="clrBorrowText"
        unit={pendingReward === null ? '' : 'UMEE'}
        flex
      />
    </InfoBarBody>
  )
}

export default StakeOverview
