import React, { useEffect, useState, useContext } from 'react'
import { ProposalData, ProposalType } from 'api/stake/data'
import { IDataListColumn } from 'components/DataList/DataList'
import ProposalListDesktop from './components/ProposalDesktop/ProposalList'
import ProposalListMobile from './components/ProposalMobile/ProposalList'
import { useStake } from 'api/stake'
import Layout from 'pages/Layout'
import { observer } from 'mobx-react-lite'
import { useStore } from 'api/cosmosStores'
import VoteOverview from './components/VoteOverview'
import { ResponsiveContext } from 'grommet'

//FIX ME: this is a temporary fix for the issue with the vote page
const voteColumns: IDataListColumn[] = [
  { title: 'Past Proposals', size: '40%', sortable: true, sortLabel: 'proposal_id' },
  { title: 'Status', size: '20%', jusitfy: 'end', sortable: true, sortLabel: 'status' },
  { title: '', size: '15%', jusitfy: 'end',  sortLabel: 'voted' }, // your vote add back in: sortable: true,
  { title: '', size: '25%', jusitfy: 'center' },
]

const activeVoteColumns: IDataListColumn[] = [
  { title: 'Active Proposals', size: '40%' },
  { title: 'Time Left To Vote', size: '20%', jusitfy: 'end' },
  { title: '', size: '15%', jusitfy: 'end' }, //your vote
  { title: '', size: '25%', jusitfy: 'center' },
]

const Container = () => {
  const { Proposals, fetchProposals } = useStake()
  const [activeProposals, setActiveProposals] = useState<ProposalData[]>()
  const [passedProposals, setPassedProposals] = useState<ProposalData[]>()
  const { accountStore, chainStore } = useStore()
  const size = useContext(ResponsiveContext)
  const account = accountStore.getAccount(chainStore.current.chainId).bech32Address

  useEffect(() => {
    fetchProposals(account)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    if (Proposals) {
      setPassedProposals(
        Proposals.filter(
          (proposal) =>
            proposal.status === ProposalType.FAILED ||
            proposal.status === ProposalType.PASSED ||
            proposal.status === ProposalType.REJECTED,
        ),
      )
      setActiveProposals(Proposals.filter((proposal) => proposal.status === ProposalType.VOTING_PERIOD))
    }
  }, [Proposals])

  return (
    <Layout title="Vote" toggleChain={false}>
      <VoteOverview margin={{bottom: 'large'}} />
      {size === 'small' ? (
        <ProposalListMobile passedProposals={passedProposals} activeProposals={activeProposals} />
      ) : (
        <ProposalListDesktop
          columns={voteColumns}
          passedProposals={passedProposals}
          activeProposals={activeProposals}
          activeColumns={activeVoteColumns}
        />
      )}
    </Layout>
  )
}

export default observer(Container)
