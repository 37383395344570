import React, { useState } from 'react'
import { OperatorGrant, Validator } from 'api/stake/data'
import TransactionHeader from 'components/TransactionsMobile/Header'
import { ETxnType } from 'lib/types'
import BottomSheet from 'components/common/BottomSheet/BottomSheet'
import ValidatorDetail from '../Detail'

interface DetailMobileProps {
  txnType?: ETxnType
  onClose: () => void
  symbol: string
  logo?: string
  validator?: Validator
  grants?: OperatorGrant
  restakePossible?: boolean
}

const DetailMobile = ({ txnType, onClose, symbol, logo, validator, grants, restakePossible }: DetailMobileProps) => {
  return (
    <BottomSheet
      onDismiss={onClose}
      open={txnType === ETxnType.validator}
      header={<TransactionHeader symbol={symbol} logo={logo} txnType={txnType} validator={validator} />}
    >
      {txnType &&
        (validator ? (
          <ValidatorDetail
            validator={validator}
            onClose={onClose}
            grants={grants}
            onMenuClick={() => {}}
            autoCompound={() => {}}
            restakePossible={restakePossible}
          />
        ) : null)}
    </BottomSheet>
  )
}

export default DetailMobile
