import React from 'react'
import { Validator } from 'api/stake/data'
import { Box, Text } from 'grommet'
import { observer } from 'mobx-react-lite'
import { BigNumber } from 'ethers'
import { TxnAmountInput } from 'components/Transactions/TxnAmountInput'
import { AvailableToTxnInformationRow, PrimaryBtn } from 'components/common'
import { ETxnSteps } from 'lib/types'
import { TxnConfirm } from 'components/Transactions'
import ModalHeader from '../../ModalHeader'

export interface DelegateProps {
  validator: Validator
  walletBalance: BigNumber
  step: ETxnSteps
  txnAmount: string
  availableBalance: BigNumber
  setTxnAmount: (amount: string) => void
  delegate: () => void
}

const tokenDecimals = BigNumber.from('6')

const DelegateInput = ({
  validator,
  walletBalance,
  step,
  txnAmount,
  availableBalance,
  setTxnAmount,
  delegate,
}: DelegateProps) => {
  return (
    <>
      <ModalHeader validator={validator} />
      <Box
        border={{ size: '1px', color: 'clrBoxBorder' }}
        margin={{ top: 'small' }}
        round="xsmall"
        background="clrModalBackground"
      >
        {step === ETxnSteps.Pending ? (
          <Box pad={{ vertical: 'medium' }}>
            <TxnConfirm wallet="Keplr" />
          </Box>
        ) : (
          <>
            <Box pad={{ vertical: 'small', horizontal: 'medium' }}>
              <Text size="xsmall" color={'clrTextPrimary'}>
                STAKING WILL LOCK YOUR FUNDS UP TO 14 DAYS
              </Text>
              <Text margin={{ top: 'small' }} size="small" color={'clrTextPrimary'}>
                <div style={{ lineHeight: '144.5%' }}>
                  Once you undelegate your stake UMEE, you will need to wait 14 days for your tokens to become liquid.
                </div>
              </Text>
            </Box>
            <Box
              pad={{ vertical: 'small', horizontal: 'medium' }}
              border={{ side: 'top', size: '1px', color: 'clrButtonBorderGrey' }}
            >
              <AvailableToTxnInformationRow
                symbol="UMEE"
                wallet={true}
                availableAmount={walletBalance}
                tokenDecimals={tokenDecimals}
              />
              <TxnAmountInput
                txnAmount={txnAmount}
                txnAvailability={{
                  tokenDecimals: tokenDecimals,
                  availableAmount: availableBalance,
                  token: { symbol: 'UMEE' },
                }}
                setTxnAmount={setTxnAmount}
                isRangeInput={false}
              />
              <PrimaryBtn
                text="Delegate"
                fullWidth
                pad={{ vertical: 'small' }}
                textSize="medium"
                round="large"
                margin={{ top: 'medium' }}
                disabled={Number(txnAmount) === 0}
                onClick={() => delegate()}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default observer(DelegateInput)
