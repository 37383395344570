import React, { useState } from 'react'
import InfoTooltip from 'components/common/InfoTooltip/InfoTooltip'
import { Box, Text, Image } from 'grommet'
import { ButtonItem, InfoPanelItem } from 'components'
import { InfoPanelItemStyles } from 'components/InfoBar/InfoPanelItem'
import truncate from 'lib/truncate'
import { bigNumberToNumber } from 'lib/number-utils'
import { BigNumber } from 'ethers'
import { DelegateType, ETxnType } from 'lib/types'
import { OperatorGrant, Validator } from 'api/stake/data'
import { getTimePassed } from 'lib/timeRemaining'
import { Theme, useTheme } from 'lib/hooks/theme/context'
import { BlockContainer, Block } from '.'
import { useConvexityData } from 'api/convexity'
import abbreviateNumber from 'lib/abbreviate'
import GradientBox from 'components/common/GradientBox/GradientBox'
import Logo from '../../../../../public/images/Logo.svg'
import VectorWhite from '../../../../../public/images/vector-white.svg'
import VectorDark from '../../../../../public/images/vector-dark.svg'
import DelegateMobile from '../Mobile/Delegate'
import { useStore } from 'api/cosmosStores'
import { useAuthz } from 'lib/hooks/authz'
import { useStake } from 'api/stake'

const tokenDecimals = BigNumber.from(6)

interface DetailMobileProps {
  validator: Validator
  restakePossible?: boolean
  grants?: OperatorGrant
  onClose: () => void
  onMenuClick: (menu: DelegateType, validator: Validator) => void
  claimRewards?: (validator: Validator, compounding: boolean) => void
  autoCompound: (validator: Validator, grants: OperatorGrant) => void
  uptime: number
  blocks: any[]
  delegation: BigNumber | undefined
  rewards: BigNumber | undefined
}

const commissionRate = (value: string) => (Number(value) * 100).toFixed(2)

const DetailMobile = ({ validator, uptime, blocks, delegation, grants, restakePossible }: DetailMobileProps) => {
  const [openMenu, setOpenMenu] = useState<boolean>()
  const { themeMode } = useTheme()

  const [clickedTxnType, setClickedTxnType] = useState<ETxnType>()

  const { fetchGrants, fetchRewards, fetchDelegations } = useStake()

  const { sendGrantMsgs, sendRevokeMsgs } = useAuthz()
  const { accountStore, chainStore } = useStore()
  const account = accountStore.getAccount(chainStore.current.chainId)

  const autoCompound = async (validator: Validator, grants: OperatorGrant) => {
    if (!grants.grantsExist)
      await sendGrantMsgs(
        validator.restake?.address || '',
        account.bech32Address,
        validator.operator_address,
        fetchData,
      )
    else await sendRevokeMsgs(validator.restake?.address || '', account.bech32Address, grants, fetchData)
  }

  const fetchData = () => {
    fetchDelegations()
    fetchRewards()
    fetchGrants()
  }

  if (!validator) {
    return null
  }

  return (
    <>
      <Box pad={{ horizontal: 'large', vertical: 'large' }}>
        <Text size="medium1" color={'clrTextPrimary'} textAlign="center" className="font-moret">
          Validator Details
        </Text>
        <Box direction="row" justify="between" margin={{ top: 'large' }}>
          <Box direction="column">
            <Text size="medium1" className="gradient-text">
              {abbreviateNumber(bigNumberToNumber(delegation || BigNumber.from(0), tokenDecimals).toFixed(2))}
            </Text>
            <Text size="small" color={'clrTextGrey'}>
              Staked
            </Text>
          </Box>
          <Box direction="column" align="end">
            <Box direction="row">
              <Text size="medium1" className="gradient-text">
                {validator.apr}
              </Text>
              <Text size="small" color={'clrTextGrey'} margin={{ top: '3px', left: '5px' }}>
                %
              </Text>
            </Box>
            <Text size="small" color={'clrTextGrey'}>
              Annual APY
            </Text>
          </Box>
        </Box>
        <Box gap="medium" margin={{ top: 'large' }}>
          <InfoPanelItem
            title="Address"
            textSize="small"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            tooltip={validator.operator_address}
            data={[
              {
                value: truncate(validator.operator_address, 12, 6),
                textSize: 'small',
                link: `https://www.mintscan.io/umee/validators/${validator.operator_address}`,
                color: themeMode === Theme.light ? 'clrTextPrimary' : 'clrBorrow',
              },
            ]}
          />
          <InfoPanelItem
            title="Voting Participation"
            textSize="small"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[{ value: '', textSize: 'small' }]}
          />
          <InfoPanelItem
            title="Validator Since"
            textSize="small"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[{ value: 'Block ' + (validator.start_height ?? '-').toString(), textSize: 'small' }]}
          />
          <InfoPanelItem
            title="Commission Rate"
            textSize="small"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[{ value: `${commissionRate(validator.commission_rates.rate)}%`, textSize: 'small' }]}
          />
          <InfoPanelItem
            title="Max Commission Rate"
            textSize="small"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[{ value: `${commissionRate(validator.commission_rates.max_rate)}%`, textSize: 'small' }]}
          />
          <InfoPanelItem
            title="Max Commission Change"
            textSize="small"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[
              {
                value: `${commissionRate(validator.commission_rates.max_change_rate)}%`,
                textSize: 'small',
              },
            ]}
          />
          <InfoPanelItem
            title="Last Commission Change"
            textSize="small"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[{ value: getTimePassed(validator.last_commission_update), textSize: 'small' }]}
          />
        </Box>
      </Box>
      <Box pad={{ vertical: 'large', horizontal: 'large' }} border={{ side: 'top', color: 'clrBorderGrey' }}>
        <Text size="medium1" color={'clrTextPrimary'} textAlign="center" className="font-moret">
          Compound Options
        </Text>
        <Box gap="medium" margin={{ top: 'large' }}>
          <InfoPanelItem
            title="APR"
            textSize="small"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[{ value: `${validator.apy}%`, textSize: 'xsmall' }]}
          />
          <InfoPanelItem
            title="Auto APY"
            textSize="small"
            justify="between"
            style={InfoPanelItemStyles.Horizontal}
            data={[
              {
                value: `${parseFloat(validator.restake_apy) > 0 ? validator.restake_apy + '%' : '-'}`,
                textSize: 'xsmall',
              },
            ]}
          />
        </Box>
      </Box>
      <Box pad={{ vertical: 'large', horizontal: 'large' }} border={{ side: 'top', color: 'clrBorderGrey' }}>
        <Box pad={{ horizontal: 'medium' }}>
          <ButtonItem
            round="xlarge"
            margin="auto"
            width={'100%'}
            pad={'0'}
            background="clrBorrow"
            direction="row"
            justify="center"
            flex
          >
            <Box
              direction="row"
              justify="center"
              onClick={() => setClickedTxnType(ETxnType.stake)}
              pad={{ vertical: 'medium' }}
              flex
            >
              <Text size="xsmall" weight={'bold'}>
                Stake
              </Text>
            </Box>
            <Box
              style={{ cursor: restakePossible ? 'pointer' : 'not-allowed' }}
              pad={{ vertical: 'medium' }}
              onClick={() => {
                restakePossible && grants ? autoCompound(validator, grants) : null
              }}
              flex
            >
              <Box direction="row" justify="center" border={{ side: 'left', color: 'clrDarkGreyOnNavy' }}>
                <Text size="xsmall" weight={'bold'}>
                  {grants && grants.grantsExist ? 'Disable Auto-compound' : 'Auto-compound'}
                </Text>
              </Box>
            </Box>
          </ButtonItem>
        </Box>
        <Box margin={{ top: 'large' }}>
          <GradientBox pad={{ bottom: 'medium', horizontal: 'large', top: 'large' }}>
            <Box direction="row" justify="between">
              <Text size="small" color={'clrTextPrimary'}>
                UPTIME
              </Text>
              <Text size="small" color={'clrTextPrimary'}>
                {`(${uptime}%)`} Last 100 Blocks
              </Text>
            </Box>
            <BlockContainer>
              {blocks &&
                blocks.map((block, index) => (
                  <React.Fragment key={index}>
                    <InfoTooltip content={<Box pad="xsmall">Block {block.block}</Box>}>
                      <Block key={index} filled={block.value} />
                    </InfoTooltip>
                  </React.Fragment>
                ))}
            </BlockContainer>
          </GradientBox>
        </Box>
        <Box margin={{ top: 'large' }}>
          <Box border={{ color: 'clrBorderGrey' }} pad="medium" round="small">
            <Box direction="row" align="center">
              <Image src={Logo} width="17px" alt="umee logo" />
              <Text size="small" color="clrTextPrimary" className="font-moret" margin={{ left: 'xsmall' }}>
                Umeeversity
              </Text>
            </Box>
            <Text color="clrTextPrimary" margin={{ top: 'small' }} size="medium">
              {validator.moniker}
            </Text>
            <Text color="clrTextPrimary" margin={{ top: 'small' }} size="small">
              {validator.description}
            </Text>
            {validator.website.length > 0 && (
              <Box direction="row" justify="center" margin={{ top: 'large' }}>
                <a href={validator.website} target="_blank" rel="noreferrer">
                  <GradientBox selected={true} pad={'medium'} filter>
                    <Text size="small" color="clrTextPrimary">
                      View their Website
                    </Text>
                  </GradientBox>
                </a>
              </Box>
            )}
          </Box>
        </Box>
        <Box direction="row" justify="end" pad={{ horizontal: 'large', top: 'large' }}>
          {themeMode === Theme.dark ? (
            <Image width={'75'} src={VectorWhite} alt="umee vector" />
          ) : (
            <Image width={'75'} src={VectorDark} alt="umee vector" />
          )}
        </Box>
      </Box>

      <DelegateMobile
        txnType={clickedTxnType}
        onClose={() => setClickedTxnType(undefined)}
        symbol={validator?.moniker || ''}
        logo={validator?.img_url}
        validator={validator}
        grants={grants}
      />
    </>
  )
}

export default DetailMobile
