import React from 'react'
import Styled from '@emotion/styled'
import { keyframes} from '@emotion/react'
import { Theme, useTheme } from 'lib/hooks/theme/context'
import { Box, BoxProps } from 'grommet'

const animation = keyframes`
  0% {
    left: 0%;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
`

const SkeletonBarComp = Styled.div<{ theme: Theme }>`
  height: .5em;
  border-radius: 3px;
  width: 100%;
  flex-grow: 1;
  display: inline-block;
  position: relative;
  overflow: hidden;

  &::before {
    content: ' ';
    background-color: ${({ theme }) => theme === Theme.dark ? 'white' : 'black' };
    opacity: .1;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 20%;
    opacity: .1;
    background-color: ${({ theme }) => theme === Theme.dark ? 'white' : 'black' };
    animation: ${animation} 1s linear infinite;
    border-radius: 3px;
    left: 0%;
    transform: translateX(-100%);
  }
`

export const SkeletonBar: React.FC<BoxProps> = ({ width = '5em', ...other }) => {
  const { themeMode } = useTheme()
  return (
    <Box width={width} {...other}>
      <SkeletonBarComp theme={themeMode} />
    </Box>
  )
}
