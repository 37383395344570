import React from 'react'
import { BaseModal } from 'components/common/Modals/BaseModal'
import BridgeDialog from './BridgeDialog'

interface BridgeModalProps {
  address: string
  tokenName: string
  onClose: () => void
  logo?: string
}

const AxelarBridgeModal = ({ address, tokenName, onClose, logo }: BridgeModalProps) => {
  return (
    <BaseModal logo={logo} symbol={tokenName} onClose={onClose} width="546px">
      <BridgeDialog
        address={address}
        tokenName={tokenName}
        onClose={onClose}
      />
    </BaseModal>
  )
}

export default AxelarBridgeModal
