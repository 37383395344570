import React from 'react'
import { Box } from 'grommet'
import ValidatorLogo from './ValidatorLogo'
import { InfoPanelItem } from 'components'
import { InfoPanelItemStyles } from 'components/InfoBar/InfoPanelItem'
import { Validator } from 'api/stake/data'

interface ModalHeaderProps {
  validator: Validator
  uptime?: number
  isDetail?: boolean
}

const ModalHeader = ({ validator, uptime, isDetail = false }: ModalHeaderProps) => {
  return (
    <Box direction="row" justify="between">
      <ValidatorLogo
        title={validator.moniker}
        imgUrl={validator.img_url}
        width="45px"
        height="45px"
        fontFamily="Moret"
        textSize="24px"
        color="white"
      />
      <Box direction="row" width={isDetail ? '40%' : ''}>
        <InfoPanelItem
          style={InfoPanelItemStyles.Vertical}
          title="Annual APY"
          textSize="xsmall"
          color="clrMidGreyOnNavy"
          data={[{ value: `${validator.apy}%`, textSize: 'small', color: 'clrWhite', bold: true }]}
        />
        {isDetail && (
          <>
            {uptime !== undefined && (
              <InfoPanelItem
                style={InfoPanelItemStyles.Vertical}
                title="Uptime"
                textSize="xsmall"
                color="clrMidGreyOnNavy"
                data={[{ value: `${uptime}%`, textSize: 'small', color: 'clrWhite', bold: true }]}
              />
            )}
            <InfoPanelItem
              style={InfoPanelItemStyles.Vertical}
              title="Validator Rank"
              textSize="xsmall"
              color="clrMidGreyOnNavy"
              data={[{ value: `#${validator.rank}`, textSize: 'small', color: 'clrWhite', bold: true }]}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

export default ModalHeader
