import React from 'react'
import { Box, DropButton, Text, Image } from 'grommet'
import { FormUp, FormDown } from 'grommet-icons'
import { MenuItem } from 'pages/Stake/components/DelegationList'
import { BridgeChain } from './BridgeDialog'

interface ChainSelectorProps {
  open: boolean
  chains: BridgeChain[]
  onClose: () => void
  onOpen: () => void
  chain: BridgeChain | undefined
  setChain: (chain: BridgeChain) => void
}

const ChainSelector = ({ open, chains, onClose, chain, onOpen, setChain }: ChainSelectorProps) => {
  return (
    <Box width={'47.5%'}>
      <DropButton
        open={open}
        className="stake-manage"
        onClose={onClose}
        dropAlign={{ top: 'bottom', right: 'right' }}
        focusIndicator={false}
        hoverIndicator={false}
        label={
          <Box onClick={onOpen} hoverIndicator={false} focusIndicator={false}>
            <Text textAlign="start" size="xsmall" color="clrTextPrimary">
              FROM
            </Text>
            <Box
              margin={{ top: 'xsmall' }}
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              border={{ size: '1px', color: 'clrBorder' }}
              direction="row"
              align="center"
              style={{ borderRadius: open ? '10px 10px 0 0' : '10px' }}
            >
              <Image width={'36px'} height="36px" src={chain?.logo} />
              <Text size="24px" className="font-moret" margin={{ horizontal: 'small' }} color="clrTextPrimary">
                {chain?.name}
              </Text>
              {open ? (
                <FormUp color="clrTextPrimary" size="medium" />
              ) : (
                <FormDown color="clrTextPrimary" size="medium" />
              )}
            </Box>
          </Box>
        }
        dropContent={
          <Box
            border={{ color: 'clrBorder', size: '1px' }}
            background="clrModalBackground"
            style={{ overflow: 'hidden', borderRadius: '0 0 10px 10px', borderTop: 'none' }}
          >
            {chains.map((chain, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setChain(chain)
                  onClose()
                }}
                menu={
                  <Box direction="row" align="center">
                    <Image width="25px" height="25px" src={chain.logo} />
                    <Text size="small" color="clrTextPrimary" margin={{ left: 'small' }}>
                      {chain.name}
                    </Text>
                  </Box>
                }
              />
            ))}
          </Box>
        }
        dropProps={{ background: 'transparent' }}
      />
    </Box>
  )
}

export default ChainSelector
