import React from 'react'
import TokenLogo from 'components/TokenLogo'
import { Box } from 'grommet'
import ValidatorAvatar from 'pages/Stake/components/ValidatorAvatar'

const Token = ({ validator, logo, symbol }: { validator?: boolean; logo?: string; symbol: string }) => {
  return validator ? (
    <Box width="36px" height="36px" style={{ borderRadius: '50%', overflow: 'hidden', flexShrink: 0 }}>
      <ValidatorAvatar url={logo} title={symbol} />
    </Box>
  ) : (
    <TokenLogo width="36" height="36" src={logo} />
  )
}

export default Token
