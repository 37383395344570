import * as $protobuf from 'protobufjs'

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util

// Exported root namespace
const $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {})

export const cosmos = ($root.cosmos = (() => {
  /**
   * Namespace cosmos.
   * @exports cosmos
   * @namespace
   */
  const cosmos = {}

  cosmos.base = (function () {
    /**
     * Namespace base.
     * @memberof cosmos
     * @namespace
     */
    const base = {}

    base.v1beta1 = (function () {
      /**
       * Namespace v1beta1.
       * @memberof cosmos.base
       * @namespace
       */
      const v1beta1 = {}

      v1beta1.Coin = (function () {
        /**
         * Properties of a Coin.
         * @memberof cosmos.base.v1beta1
         * @interface ICoin
         * @property {string|null} [denom] Coin denom
         * @property {string|null} [amount] Coin amount
         */

        /**
         * Constructs a new Coin.
         * @memberof cosmos.base.v1beta1
         * @classdesc Represents a Coin.
         * @implements ICoin
         * @constructor
         * @param {cosmos.base.v1beta1.ICoin=} [properties] Properties to set
         */
        function Coin(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Coin denom.
         * @member {string} denom
         * @memberof cosmos.base.v1beta1.Coin
         * @instance
         */
        Coin.prototype.denom = ''

        /**
         * Coin amount.
         * @member {string} amount
         * @memberof cosmos.base.v1beta1.Coin
         * @instance
         */
        Coin.prototype.amount = ''

        /**
         * Creates a new Coin instance using the specified properties.
         * @function create
         * @memberof cosmos.base.v1beta1.Coin
         * @static
         * @param {cosmos.base.v1beta1.ICoin=} [properties] Properties to set
         * @returns {cosmos.base.v1beta1.Coin} Coin instance
         */
        Coin.create = function create(properties) {
          return new Coin(properties)
        }

        /**
         * Encodes the specified Coin message. Does not implicitly {@link cosmos.base.v1beta1.Coin.verify|verify} messages.
         * @function encode
         * @memberof cosmos.base.v1beta1.Coin
         * @static
         * @param {cosmos.base.v1beta1.ICoin} message Coin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Coin.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.denom != null && Object.hasOwnProperty.call(message, 'denom'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.denom)
          if (message.amount != null && Object.hasOwnProperty.call(message, 'amount'))
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.amount)
          return writer
        }

        /**
         * Encodes the specified Coin message, length delimited. Does not implicitly {@link cosmos.base.v1beta1.Coin.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cosmos.base.v1beta1.Coin
         * @static
         * @param {cosmos.base.v1beta1.ICoin} message Coin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Coin.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a Coin message from the specified reader or buffer.
         * @function decode
         * @memberof cosmos.base.v1beta1.Coin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cosmos.base.v1beta1.Coin} Coin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Coin.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.cosmos.base.v1beta1.Coin()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.denom = reader.string()
                break
              case 2:
                message.amount = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a Coin message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cosmos.base.v1beta1.Coin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cosmos.base.v1beta1.Coin} Coin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Coin.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a Coin message.
         * @function verify
         * @memberof cosmos.base.v1beta1.Coin
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Coin.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.denom != null && message.hasOwnProperty('denom'))
            if (!$util.isString(message.denom)) return 'denom: string expected'
          if (message.amount != null && message.hasOwnProperty('amount'))
            if (!$util.isString(message.amount)) return 'amount: string expected'
          return null
        }

        /**
         * Creates a Coin message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cosmos.base.v1beta1.Coin
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cosmos.base.v1beta1.Coin} Coin
         */
        Coin.fromObject = function fromObject(object) {
          if (object instanceof $root.cosmos.base.v1beta1.Coin) return object
          let message = new $root.cosmos.base.v1beta1.Coin()
          if (object.denom != null) message.denom = String(object.denom)
          if (object.amount != null) message.amount = String(object.amount)
          return message
        }

        /**
         * Creates a plain object from a Coin message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cosmos.base.v1beta1.Coin
         * @static
         * @param {cosmos.base.v1beta1.Coin} message Coin
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Coin.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.denom = ''
            object.amount = ''
          }
          if (message.denom != null && message.hasOwnProperty('denom')) object.denom = message.denom
          if (message.amount != null && message.hasOwnProperty('amount')) object.amount = message.amount
          return object
        }

        /**
         * Converts this Coin to JSON.
         * @function toJSON
         * @memberof cosmos.base.v1beta1.Coin
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Coin.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return Coin
      })()

      v1beta1.DecCoin = (function () {
        /**
         * Properties of a DecCoin.
         * @memberof cosmos.base.v1beta1
         * @interface IDecCoin
         * @property {string|null} [denom] DecCoin denom
         * @property {string|null} [amount] DecCoin amount
         */

        /**
         * Constructs a new DecCoin.
         * @memberof cosmos.base.v1beta1
         * @classdesc Represents a DecCoin.
         * @implements IDecCoin
         * @constructor
         * @param {cosmos.base.v1beta1.IDecCoin=} [properties] Properties to set
         */
        function DecCoin(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * DecCoin denom.
         * @member {string} denom
         * @memberof cosmos.base.v1beta1.DecCoin
         * @instance
         */
        DecCoin.prototype.denom = ''

        /**
         * DecCoin amount.
         * @member {string} amount
         * @memberof cosmos.base.v1beta1.DecCoin
         * @instance
         */
        DecCoin.prototype.amount = ''

        /**
         * Creates a new DecCoin instance using the specified properties.
         * @function create
         * @memberof cosmos.base.v1beta1.DecCoin
         * @static
         * @param {cosmos.base.v1beta1.IDecCoin=} [properties] Properties to set
         * @returns {cosmos.base.v1beta1.DecCoin} DecCoin instance
         */
        DecCoin.create = function create(properties) {
          return new DecCoin(properties)
        }

        /**
         * Encodes the specified DecCoin message. Does not implicitly {@link cosmos.base.v1beta1.DecCoin.verify|verify} messages.
         * @function encode
         * @memberof cosmos.base.v1beta1.DecCoin
         * @static
         * @param {cosmos.base.v1beta1.IDecCoin} message DecCoin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DecCoin.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.denom != null && Object.hasOwnProperty.call(message, 'denom'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.denom)
          if (message.amount != null && Object.hasOwnProperty.call(message, 'amount'))
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.amount)
          return writer
        }

        /**
         * Encodes the specified DecCoin message, length delimited. Does not implicitly {@link cosmos.base.v1beta1.DecCoin.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cosmos.base.v1beta1.DecCoin
         * @static
         * @param {cosmos.base.v1beta1.IDecCoin} message DecCoin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DecCoin.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a DecCoin message from the specified reader or buffer.
         * @function decode
         * @memberof cosmos.base.v1beta1.DecCoin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cosmos.base.v1beta1.DecCoin} DecCoin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DecCoin.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.cosmos.base.v1beta1.DecCoin()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.denom = reader.string()
                break
              case 2:
                message.amount = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a DecCoin message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cosmos.base.v1beta1.DecCoin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cosmos.base.v1beta1.DecCoin} DecCoin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DecCoin.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a DecCoin message.
         * @function verify
         * @memberof cosmos.base.v1beta1.DecCoin
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DecCoin.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.denom != null && message.hasOwnProperty('denom'))
            if (!$util.isString(message.denom)) return 'denom: string expected'
          if (message.amount != null && message.hasOwnProperty('amount'))
            if (!$util.isString(message.amount)) return 'amount: string expected'
          return null
        }

        /**
         * Creates a DecCoin message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cosmos.base.v1beta1.DecCoin
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cosmos.base.v1beta1.DecCoin} DecCoin
         */
        DecCoin.fromObject = function fromObject(object) {
          if (object instanceof $root.cosmos.base.v1beta1.DecCoin) return object
          let message = new $root.cosmos.base.v1beta1.DecCoin()
          if (object.denom != null) message.denom = String(object.denom)
          if (object.amount != null) message.amount = String(object.amount)
          return message
        }

        /**
         * Creates a plain object from a DecCoin message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cosmos.base.v1beta1.DecCoin
         * @static
         * @param {cosmos.base.v1beta1.DecCoin} message DecCoin
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DecCoin.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.denom = ''
            object.amount = ''
          }
          if (message.denom != null && message.hasOwnProperty('denom')) object.denom = message.denom
          if (message.amount != null && message.hasOwnProperty('amount')) object.amount = message.amount
          return object
        }

        /**
         * Converts this DecCoin to JSON.
         * @function toJSON
         * @memberof cosmos.base.v1beta1.DecCoin
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DecCoin.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return DecCoin
      })()

      v1beta1.IntProto = (function () {
        /**
         * Properties of an IntProto.
         * @memberof cosmos.base.v1beta1
         * @interface IIntProto
         * @property {string|null} [int] IntProto int
         */

        /**
         * Constructs a new IntProto.
         * @memberof cosmos.base.v1beta1
         * @classdesc Represents an IntProto.
         * @implements IIntProto
         * @constructor
         * @param {cosmos.base.v1beta1.IIntProto=} [properties] Properties to set
         */
        function IntProto(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * IntProto int.
         * @member {string} int
         * @memberof cosmos.base.v1beta1.IntProto
         * @instance
         */
        IntProto.prototype.int = ''

        /**
         * Creates a new IntProto instance using the specified properties.
         * @function create
         * @memberof cosmos.base.v1beta1.IntProto
         * @static
         * @param {cosmos.base.v1beta1.IIntProto=} [properties] Properties to set
         * @returns {cosmos.base.v1beta1.IntProto} IntProto instance
         */
        IntProto.create = function create(properties) {
          return new IntProto(properties)
        }

        /**
         * Encodes the specified IntProto message. Does not implicitly {@link cosmos.base.v1beta1.IntProto.verify|verify} messages.
         * @function encode
         * @memberof cosmos.base.v1beta1.IntProto
         * @static
         * @param {cosmos.base.v1beta1.IIntProto} message IntProto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntProto.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.int != null && Object.hasOwnProperty.call(message, 'int'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.int)
          return writer
        }

        /**
         * Encodes the specified IntProto message, length delimited. Does not implicitly {@link cosmos.base.v1beta1.IntProto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cosmos.base.v1beta1.IntProto
         * @static
         * @param {cosmos.base.v1beta1.IIntProto} message IntProto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntProto.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an IntProto message from the specified reader or buffer.
         * @function decode
         * @memberof cosmos.base.v1beta1.IntProto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cosmos.base.v1beta1.IntProto} IntProto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntProto.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.cosmos.base.v1beta1.IntProto()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.int = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an IntProto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cosmos.base.v1beta1.IntProto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cosmos.base.v1beta1.IntProto} IntProto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntProto.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an IntProto message.
         * @function verify
         * @memberof cosmos.base.v1beta1.IntProto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IntProto.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.int != null && message.hasOwnProperty('int'))
            if (!$util.isString(message.int)) return 'int: string expected'
          return null
        }

        /**
         * Creates an IntProto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cosmos.base.v1beta1.IntProto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cosmos.base.v1beta1.IntProto} IntProto
         */
        IntProto.fromObject = function fromObject(object) {
          if (object instanceof $root.cosmos.base.v1beta1.IntProto) return object
          let message = new $root.cosmos.base.v1beta1.IntProto()
          if (object.int != null) message.int = String(object.int)
          return message
        }

        /**
         * Creates a plain object from an IntProto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cosmos.base.v1beta1.IntProto
         * @static
         * @param {cosmos.base.v1beta1.IntProto} message IntProto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IntProto.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) object.int = ''
          if (message.int != null && message.hasOwnProperty('int')) object.int = message.int
          return object
        }

        /**
         * Converts this IntProto to JSON.
         * @function toJSON
         * @memberof cosmos.base.v1beta1.IntProto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IntProto.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return IntProto
      })()

      v1beta1.DecProto = (function () {
        /**
         * Properties of a DecProto.
         * @memberof cosmos.base.v1beta1
         * @interface IDecProto
         * @property {string|null} [dec] DecProto dec
         */

        /**
         * Constructs a new DecProto.
         * @memberof cosmos.base.v1beta1
         * @classdesc Represents a DecProto.
         * @implements IDecProto
         * @constructor
         * @param {cosmos.base.v1beta1.IDecProto=} [properties] Properties to set
         */
        function DecProto(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * DecProto dec.
         * @member {string} dec
         * @memberof cosmos.base.v1beta1.DecProto
         * @instance
         */
        DecProto.prototype.dec = ''

        /**
         * Creates a new DecProto instance using the specified properties.
         * @function create
         * @memberof cosmos.base.v1beta1.DecProto
         * @static
         * @param {cosmos.base.v1beta1.IDecProto=} [properties] Properties to set
         * @returns {cosmos.base.v1beta1.DecProto} DecProto instance
         */
        DecProto.create = function create(properties) {
          return new DecProto(properties)
        }

        /**
         * Encodes the specified DecProto message. Does not implicitly {@link cosmos.base.v1beta1.DecProto.verify|verify} messages.
         * @function encode
         * @memberof cosmos.base.v1beta1.DecProto
         * @static
         * @param {cosmos.base.v1beta1.IDecProto} message DecProto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DecProto.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.dec != null && Object.hasOwnProperty.call(message, 'dec'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.dec)
          return writer
        }

        /**
         * Encodes the specified DecProto message, length delimited. Does not implicitly {@link cosmos.base.v1beta1.DecProto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cosmos.base.v1beta1.DecProto
         * @static
         * @param {cosmos.base.v1beta1.IDecProto} message DecProto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DecProto.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a DecProto message from the specified reader or buffer.
         * @function decode
         * @memberof cosmos.base.v1beta1.DecProto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cosmos.base.v1beta1.DecProto} DecProto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DecProto.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.cosmos.base.v1beta1.DecProto()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.dec = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a DecProto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cosmos.base.v1beta1.DecProto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cosmos.base.v1beta1.DecProto} DecProto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DecProto.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a DecProto message.
         * @function verify
         * @memberof cosmos.base.v1beta1.DecProto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DecProto.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.dec != null && message.hasOwnProperty('dec'))
            if (!$util.isString(message.dec)) return 'dec: string expected'
          return null
        }

        /**
         * Creates a DecProto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cosmos.base.v1beta1.DecProto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cosmos.base.v1beta1.DecProto} DecProto
         */
        DecProto.fromObject = function fromObject(object) {
          if (object instanceof $root.cosmos.base.v1beta1.DecProto) return object
          let message = new $root.cosmos.base.v1beta1.DecProto()
          if (object.dec != null) message.dec = String(object.dec)
          return message
        }

        /**
         * Creates a plain object from a DecProto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cosmos.base.v1beta1.DecProto
         * @static
         * @param {cosmos.base.v1beta1.DecProto} message DecProto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DecProto.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) object.dec = ''
          if (message.dec != null && message.hasOwnProperty('dec')) object.dec = message.dec
          return object
        }

        /**
         * Converts this DecProto to JSON.
         * @function toJSON
         * @memberof cosmos.base.v1beta1.DecProto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DecProto.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return DecProto
      })()

      return v1beta1
    })()

    return base
  })()

  cosmos.bank = (function () {
    /**
     * Namespace bank.
     * @memberof cosmos
     * @namespace
     */
    const bank = {}

    bank.v1beta1 = (function () {
      /**
       * Namespace v1beta1.
       * @memberof cosmos.bank
       * @namespace
       */
      const v1beta1 = {}

      v1beta1.Params = (function () {
        /**
         * Properties of a Params.
         * @memberof cosmos.bank.v1beta1
         * @interface IParams
         * @property {Array.<cosmos.bank.v1beta1.ISendEnabled>|null} [sendEnabled] Params sendEnabled
         * @property {boolean|null} [defaultSendEnabled] Params defaultSendEnabled
         */

        /**
         * Constructs a new Params.
         * @memberof cosmos.bank.v1beta1
         * @classdesc Represents a Params.
         * @implements IParams
         * @constructor
         * @param {cosmos.bank.v1beta1.IParams=} [properties] Properties to set
         */
        function Params(properties) {
          this.sendEnabled = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Params sendEnabled.
         * @member {Array.<cosmos.bank.v1beta1.ISendEnabled>} sendEnabled
         * @memberof cosmos.bank.v1beta1.Params
         * @instance
         */
        Params.prototype.sendEnabled = $util.emptyArray

        /**
         * Params defaultSendEnabled.
         * @member {boolean} defaultSendEnabled
         * @memberof cosmos.bank.v1beta1.Params
         * @instance
         */
        Params.prototype.defaultSendEnabled = false

        /**
         * Creates a new Params instance using the specified properties.
         * @function create
         * @memberof cosmos.bank.v1beta1.Params
         * @static
         * @param {cosmos.bank.v1beta1.IParams=} [properties] Properties to set
         * @returns {cosmos.bank.v1beta1.Params} Params instance
         */
        Params.create = function create(properties) {
          return new Params(properties)
        }

        /**
         * Encodes the specified Params message. Does not implicitly {@link cosmos.bank.v1beta1.Params.verify|verify} messages.
         * @function encode
         * @memberof cosmos.bank.v1beta1.Params
         * @static
         * @param {cosmos.bank.v1beta1.IParams} message Params message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Params.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.sendEnabled != null && message.sendEnabled.length)
            for (let i = 0; i < message.sendEnabled.length; ++i)
              $root.cosmos.bank.v1beta1.SendEnabled.encode(
                message.sendEnabled[i],
                writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
              ).ldelim()
          if (message.defaultSendEnabled != null && Object.hasOwnProperty.call(message, 'defaultSendEnabled'))
            writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.defaultSendEnabled)
          return writer
        }

        /**
         * Encodes the specified Params message, length delimited. Does not implicitly {@link cosmos.bank.v1beta1.Params.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cosmos.bank.v1beta1.Params
         * @static
         * @param {cosmos.bank.v1beta1.IParams} message Params message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Params.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a Params message from the specified reader or buffer.
         * @function decode
         * @memberof cosmos.bank.v1beta1.Params
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cosmos.bank.v1beta1.Params} Params
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Params.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.cosmos.bank.v1beta1.Params()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                if (!(message.sendEnabled && message.sendEnabled.length)) message.sendEnabled = []
                message.sendEnabled.push($root.cosmos.bank.v1beta1.SendEnabled.decode(reader, reader.uint32()))
                break
              case 2:
                message.defaultSendEnabled = reader.bool()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a Params message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cosmos.bank.v1beta1.Params
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cosmos.bank.v1beta1.Params} Params
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Params.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a Params message.
         * @function verify
         * @memberof cosmos.bank.v1beta1.Params
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Params.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.sendEnabled != null && message.hasOwnProperty('sendEnabled')) {
            if (!Array.isArray(message.sendEnabled)) return 'sendEnabled: array expected'
            for (let i = 0; i < message.sendEnabled.length; ++i) {
              let error = $root.cosmos.bank.v1beta1.SendEnabled.verify(message.sendEnabled[i])
              if (error) return 'sendEnabled.' + error
            }
          }
          if (message.defaultSendEnabled != null && message.hasOwnProperty('defaultSendEnabled'))
            if (typeof message.defaultSendEnabled !== 'boolean') return 'defaultSendEnabled: boolean expected'
          return null
        }

        /**
         * Creates a Params message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cosmos.bank.v1beta1.Params
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cosmos.bank.v1beta1.Params} Params
         */
        Params.fromObject = function fromObject(object) {
          if (object instanceof $root.cosmos.bank.v1beta1.Params) return object
          let message = new $root.cosmos.bank.v1beta1.Params()
          if (object.sendEnabled) {
            if (!Array.isArray(object.sendEnabled))
              throw TypeError('.cosmos.bank.v1beta1.Params.sendEnabled: array expected')
            message.sendEnabled = []
            for (let i = 0; i < object.sendEnabled.length; ++i) {
              if (typeof object.sendEnabled[i] !== 'object')
                throw TypeError('.cosmos.bank.v1beta1.Params.sendEnabled: object expected')
              message.sendEnabled[i] = $root.cosmos.bank.v1beta1.SendEnabled.fromObject(object.sendEnabled[i])
            }
          }
          if (object.defaultSendEnabled != null) message.defaultSendEnabled = Boolean(object.defaultSendEnabled)
          return message
        }

        /**
         * Creates a plain object from a Params message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cosmos.bank.v1beta1.Params
         * @static
         * @param {cosmos.bank.v1beta1.Params} message Params
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Params.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.sendEnabled = []
          if (options.defaults) object.defaultSendEnabled = false
          if (message.sendEnabled && message.sendEnabled.length) {
            object.sendEnabled = []
            for (let j = 0; j < message.sendEnabled.length; ++j)
              object.sendEnabled[j] = $root.cosmos.bank.v1beta1.SendEnabled.toObject(message.sendEnabled[j], options)
          }
          if (message.defaultSendEnabled != null && message.hasOwnProperty('defaultSendEnabled'))
            object.defaultSendEnabled = message.defaultSendEnabled
          return object
        }

        /**
         * Converts this Params to JSON.
         * @function toJSON
         * @memberof cosmos.bank.v1beta1.Params
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Params.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return Params
      })()

      v1beta1.SendEnabled = (function () {
        /**
         * Properties of a SendEnabled.
         * @memberof cosmos.bank.v1beta1
         * @interface ISendEnabled
         * @property {string|null} [denom] SendEnabled denom
         * @property {boolean|null} [enabled] SendEnabled enabled
         */

        /**
         * Constructs a new SendEnabled.
         * @memberof cosmos.bank.v1beta1
         * @classdesc Represents a SendEnabled.
         * @implements ISendEnabled
         * @constructor
         * @param {cosmos.bank.v1beta1.ISendEnabled=} [properties] Properties to set
         */
        function SendEnabled(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * SendEnabled denom.
         * @member {string} denom
         * @memberof cosmos.bank.v1beta1.SendEnabled
         * @instance
         */
        SendEnabled.prototype.denom = ''

        /**
         * SendEnabled enabled.
         * @member {boolean} enabled
         * @memberof cosmos.bank.v1beta1.SendEnabled
         * @instance
         */
        SendEnabled.prototype.enabled = false

        /**
         * Creates a new SendEnabled instance using the specified properties.
         * @function create
         * @memberof cosmos.bank.v1beta1.SendEnabled
         * @static
         * @param {cosmos.bank.v1beta1.ISendEnabled=} [properties] Properties to set
         * @returns {cosmos.bank.v1beta1.SendEnabled} SendEnabled instance
         */
        SendEnabled.create = function create(properties) {
          return new SendEnabled(properties)
        }

        /**
         * Encodes the specified SendEnabled message. Does not implicitly {@link cosmos.bank.v1beta1.SendEnabled.verify|verify} messages.
         * @function encode
         * @memberof cosmos.bank.v1beta1.SendEnabled
         * @static
         * @param {cosmos.bank.v1beta1.ISendEnabled} message SendEnabled message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendEnabled.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.denom != null && Object.hasOwnProperty.call(message, 'denom'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.denom)
          if (message.enabled != null && Object.hasOwnProperty.call(message, 'enabled'))
            writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.enabled)
          return writer
        }

        /**
         * Encodes the specified SendEnabled message, length delimited. Does not implicitly {@link cosmos.bank.v1beta1.SendEnabled.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cosmos.bank.v1beta1.SendEnabled
         * @static
         * @param {cosmos.bank.v1beta1.ISendEnabled} message SendEnabled message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendEnabled.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a SendEnabled message from the specified reader or buffer.
         * @function decode
         * @memberof cosmos.bank.v1beta1.SendEnabled
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cosmos.bank.v1beta1.SendEnabled} SendEnabled
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendEnabled.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.cosmos.bank.v1beta1.SendEnabled()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.denom = reader.string()
                break
              case 2:
                message.enabled = reader.bool()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a SendEnabled message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cosmos.bank.v1beta1.SendEnabled
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cosmos.bank.v1beta1.SendEnabled} SendEnabled
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendEnabled.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a SendEnabled message.
         * @function verify
         * @memberof cosmos.bank.v1beta1.SendEnabled
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendEnabled.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.denom != null && message.hasOwnProperty('denom'))
            if (!$util.isString(message.denom)) return 'denom: string expected'
          if (message.enabled != null && message.hasOwnProperty('enabled'))
            if (typeof message.enabled !== 'boolean') return 'enabled: boolean expected'
          return null
        }

        /**
         * Creates a SendEnabled message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cosmos.bank.v1beta1.SendEnabled
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cosmos.bank.v1beta1.SendEnabled} SendEnabled
         */
        SendEnabled.fromObject = function fromObject(object) {
          if (object instanceof $root.cosmos.bank.v1beta1.SendEnabled) return object
          let message = new $root.cosmos.bank.v1beta1.SendEnabled()
          if (object.denom != null) message.denom = String(object.denom)
          if (object.enabled != null) message.enabled = Boolean(object.enabled)
          return message
        }

        /**
         * Creates a plain object from a SendEnabled message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cosmos.bank.v1beta1.SendEnabled
         * @static
         * @param {cosmos.bank.v1beta1.SendEnabled} message SendEnabled
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendEnabled.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.denom = ''
            object.enabled = false
          }
          if (message.denom != null && message.hasOwnProperty('denom')) object.denom = message.denom
          if (message.enabled != null && message.hasOwnProperty('enabled')) object.enabled = message.enabled
          return object
        }

        /**
         * Converts this SendEnabled to JSON.
         * @function toJSON
         * @memberof cosmos.bank.v1beta1.SendEnabled
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendEnabled.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return SendEnabled
      })()

      v1beta1.Input = (function () {
        /**
         * Properties of an Input.
         * @memberof cosmos.bank.v1beta1
         * @interface IInput
         * @property {string|null} [address] Input address
         * @property {Array.<cosmos.base.v1beta1.ICoin>|null} [coins] Input coins
         */

        /**
         * Constructs a new Input.
         * @memberof cosmos.bank.v1beta1
         * @classdesc Represents an Input.
         * @implements IInput
         * @constructor
         * @param {cosmos.bank.v1beta1.IInput=} [properties] Properties to set
         */
        function Input(properties) {
          this.coins = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Input address.
         * @member {string} address
         * @memberof cosmos.bank.v1beta1.Input
         * @instance
         */
        Input.prototype.address = ''

        /**
         * Input coins.
         * @member {Array.<cosmos.base.v1beta1.ICoin>} coins
         * @memberof cosmos.bank.v1beta1.Input
         * @instance
         */
        Input.prototype.coins = $util.emptyArray

        /**
         * Creates a new Input instance using the specified properties.
         * @function create
         * @memberof cosmos.bank.v1beta1.Input
         * @static
         * @param {cosmos.bank.v1beta1.IInput=} [properties] Properties to set
         * @returns {cosmos.bank.v1beta1.Input} Input instance
         */
        Input.create = function create(properties) {
          return new Input(properties)
        }

        /**
         * Encodes the specified Input message. Does not implicitly {@link cosmos.bank.v1beta1.Input.verify|verify} messages.
         * @function encode
         * @memberof cosmos.bank.v1beta1.Input
         * @static
         * @param {cosmos.bank.v1beta1.IInput} message Input message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Input.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.address != null && Object.hasOwnProperty.call(message, 'address'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.address)
          if (message.coins != null && message.coins.length)
            for (let i = 0; i < message.coins.length; ++i)
              $root.cosmos.base.v1beta1.Coin.encode(
                message.coins[i],
                writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
              ).ldelim()
          return writer
        }

        /**
         * Encodes the specified Input message, length delimited. Does not implicitly {@link cosmos.bank.v1beta1.Input.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cosmos.bank.v1beta1.Input
         * @static
         * @param {cosmos.bank.v1beta1.IInput} message Input message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Input.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an Input message from the specified reader or buffer.
         * @function decode
         * @memberof cosmos.bank.v1beta1.Input
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cosmos.bank.v1beta1.Input} Input
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Input.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.cosmos.bank.v1beta1.Input()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.address = reader.string()
                break
              case 2:
                if (!(message.coins && message.coins.length)) message.coins = []
                message.coins.push($root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32()))
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an Input message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cosmos.bank.v1beta1.Input
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cosmos.bank.v1beta1.Input} Input
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Input.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an Input message.
         * @function verify
         * @memberof cosmos.bank.v1beta1.Input
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Input.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.address != null && message.hasOwnProperty('address'))
            if (!$util.isString(message.address)) return 'address: string expected'
          if (message.coins != null && message.hasOwnProperty('coins')) {
            if (!Array.isArray(message.coins)) return 'coins: array expected'
            for (let i = 0; i < message.coins.length; ++i) {
              let error = $root.cosmos.base.v1beta1.Coin.verify(message.coins[i])
              if (error) return 'coins.' + error
            }
          }
          return null
        }

        /**
         * Creates an Input message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cosmos.bank.v1beta1.Input
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cosmos.bank.v1beta1.Input} Input
         */
        Input.fromObject = function fromObject(object) {
          if (object instanceof $root.cosmos.bank.v1beta1.Input) return object
          let message = new $root.cosmos.bank.v1beta1.Input()
          if (object.address != null) message.address = String(object.address)
          if (object.coins) {
            if (!Array.isArray(object.coins)) throw TypeError('.cosmos.bank.v1beta1.Input.coins: array expected')
            message.coins = []
            for (let i = 0; i < object.coins.length; ++i) {
              if (typeof object.coins[i] !== 'object')
                throw TypeError('.cosmos.bank.v1beta1.Input.coins: object expected')
              message.coins[i] = $root.cosmos.base.v1beta1.Coin.fromObject(object.coins[i])
            }
          }
          return message
        }

        /**
         * Creates a plain object from an Input message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cosmos.bank.v1beta1.Input
         * @static
         * @param {cosmos.bank.v1beta1.Input} message Input
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Input.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.coins = []
          if (options.defaults) object.address = ''
          if (message.address != null && message.hasOwnProperty('address')) object.address = message.address
          if (message.coins && message.coins.length) {
            object.coins = []
            for (let j = 0; j < message.coins.length; ++j)
              object.coins[j] = $root.cosmos.base.v1beta1.Coin.toObject(message.coins[j], options)
          }
          return object
        }

        /**
         * Converts this Input to JSON.
         * @function toJSON
         * @memberof cosmos.bank.v1beta1.Input
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Input.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return Input
      })()

      v1beta1.Output = (function () {
        /**
         * Properties of an Output.
         * @memberof cosmos.bank.v1beta1
         * @interface IOutput
         * @property {string|null} [address] Output address
         * @property {Array.<cosmos.base.v1beta1.ICoin>|null} [coins] Output coins
         */

        /**
         * Constructs a new Output.
         * @memberof cosmos.bank.v1beta1
         * @classdesc Represents an Output.
         * @implements IOutput
         * @constructor
         * @param {cosmos.bank.v1beta1.IOutput=} [properties] Properties to set
         */
        function Output(properties) {
          this.coins = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Output address.
         * @member {string} address
         * @memberof cosmos.bank.v1beta1.Output
         * @instance
         */
        Output.prototype.address = ''

        /**
         * Output coins.
         * @member {Array.<cosmos.base.v1beta1.ICoin>} coins
         * @memberof cosmos.bank.v1beta1.Output
         * @instance
         */
        Output.prototype.coins = $util.emptyArray

        /**
         * Creates a new Output instance using the specified properties.
         * @function create
         * @memberof cosmos.bank.v1beta1.Output
         * @static
         * @param {cosmos.bank.v1beta1.IOutput=} [properties] Properties to set
         * @returns {cosmos.bank.v1beta1.Output} Output instance
         */
        Output.create = function create(properties) {
          return new Output(properties)
        }

        /**
         * Encodes the specified Output message. Does not implicitly {@link cosmos.bank.v1beta1.Output.verify|verify} messages.
         * @function encode
         * @memberof cosmos.bank.v1beta1.Output
         * @static
         * @param {cosmos.bank.v1beta1.IOutput} message Output message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Output.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.address != null && Object.hasOwnProperty.call(message, 'address'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.address)
          if (message.coins != null && message.coins.length)
            for (let i = 0; i < message.coins.length; ++i)
              $root.cosmos.base.v1beta1.Coin.encode(
                message.coins[i],
                writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
              ).ldelim()
          return writer
        }

        /**
         * Encodes the specified Output message, length delimited. Does not implicitly {@link cosmos.bank.v1beta1.Output.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cosmos.bank.v1beta1.Output
         * @static
         * @param {cosmos.bank.v1beta1.IOutput} message Output message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Output.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an Output message from the specified reader or buffer.
         * @function decode
         * @memberof cosmos.bank.v1beta1.Output
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cosmos.bank.v1beta1.Output} Output
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Output.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.cosmos.bank.v1beta1.Output()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.address = reader.string()
                break
              case 2:
                if (!(message.coins && message.coins.length)) message.coins = []
                message.coins.push($root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32()))
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an Output message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cosmos.bank.v1beta1.Output
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cosmos.bank.v1beta1.Output} Output
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Output.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an Output message.
         * @function verify
         * @memberof cosmos.bank.v1beta1.Output
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Output.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.address != null && message.hasOwnProperty('address'))
            if (!$util.isString(message.address)) return 'address: string expected'
          if (message.coins != null && message.hasOwnProperty('coins')) {
            if (!Array.isArray(message.coins)) return 'coins: array expected'
            for (let i = 0; i < message.coins.length; ++i) {
              let error = $root.cosmos.base.v1beta1.Coin.verify(message.coins[i])
              if (error) return 'coins.' + error
            }
          }
          return null
        }

        /**
         * Creates an Output message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cosmos.bank.v1beta1.Output
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cosmos.bank.v1beta1.Output} Output
         */
        Output.fromObject = function fromObject(object) {
          if (object instanceof $root.cosmos.bank.v1beta1.Output) return object
          let message = new $root.cosmos.bank.v1beta1.Output()
          if (object.address != null) message.address = String(object.address)
          if (object.coins) {
            if (!Array.isArray(object.coins)) throw TypeError('.cosmos.bank.v1beta1.Output.coins: array expected')
            message.coins = []
            for (let i = 0; i < object.coins.length; ++i) {
              if (typeof object.coins[i] !== 'object')
                throw TypeError('.cosmos.bank.v1beta1.Output.coins: object expected')
              message.coins[i] = $root.cosmos.base.v1beta1.Coin.fromObject(object.coins[i])
            }
          }
          return message
        }

        /**
         * Creates a plain object from an Output message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cosmos.bank.v1beta1.Output
         * @static
         * @param {cosmos.bank.v1beta1.Output} message Output
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Output.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.coins = []
          if (options.defaults) object.address = ''
          if (message.address != null && message.hasOwnProperty('address')) object.address = message.address
          if (message.coins && message.coins.length) {
            object.coins = []
            for (let j = 0; j < message.coins.length; ++j)
              object.coins[j] = $root.cosmos.base.v1beta1.Coin.toObject(message.coins[j], options)
          }
          return object
        }

        /**
         * Converts this Output to JSON.
         * @function toJSON
         * @memberof cosmos.bank.v1beta1.Output
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Output.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return Output
      })()

      v1beta1.Supply = (function () {
        /**
         * Properties of a Supply.
         * @memberof cosmos.bank.v1beta1
         * @interface ISupply
         * @property {Array.<cosmos.base.v1beta1.ICoin>|null} [total] Supply total
         */

        /**
         * Constructs a new Supply.
         * @memberof cosmos.bank.v1beta1
         * @classdesc Represents a Supply.
         * @implements ISupply
         * @constructor
         * @param {cosmos.bank.v1beta1.ISupply=} [properties] Properties to set
         */
        function Supply(properties) {
          this.total = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Supply total.
         * @member {Array.<cosmos.base.v1beta1.ICoin>} total
         * @memberof cosmos.bank.v1beta1.Supply
         * @instance
         */
        Supply.prototype.total = $util.emptyArray

        /**
         * Creates a new Supply instance using the specified properties.
         * @function create
         * @memberof cosmos.bank.v1beta1.Supply
         * @static
         * @param {cosmos.bank.v1beta1.ISupply=} [properties] Properties to set
         * @returns {cosmos.bank.v1beta1.Supply} Supply instance
         */
        Supply.create = function create(properties) {
          return new Supply(properties)
        }

        /**
         * Encodes the specified Supply message. Does not implicitly {@link cosmos.bank.v1beta1.Supply.verify|verify} messages.
         * @function encode
         * @memberof cosmos.bank.v1beta1.Supply
         * @static
         * @param {cosmos.bank.v1beta1.ISupply} message Supply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Supply.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.total != null && message.total.length)
            for (let i = 0; i < message.total.length; ++i)
              $root.cosmos.base.v1beta1.Coin.encode(
                message.total[i],
                writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
              ).ldelim()
          return writer
        }

        /**
         * Encodes the specified Supply message, length delimited. Does not implicitly {@link cosmos.bank.v1beta1.Supply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cosmos.bank.v1beta1.Supply
         * @static
         * @param {cosmos.bank.v1beta1.ISupply} message Supply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Supply.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a Supply message from the specified reader or buffer.
         * @function decode
         * @memberof cosmos.bank.v1beta1.Supply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cosmos.bank.v1beta1.Supply} Supply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Supply.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.cosmos.bank.v1beta1.Supply()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                if (!(message.total && message.total.length)) message.total = []
                message.total.push($root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32()))
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a Supply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cosmos.bank.v1beta1.Supply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cosmos.bank.v1beta1.Supply} Supply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Supply.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a Supply message.
         * @function verify
         * @memberof cosmos.bank.v1beta1.Supply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Supply.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.total != null && message.hasOwnProperty('total')) {
            if (!Array.isArray(message.total)) return 'total: array expected'
            for (let i = 0; i < message.total.length; ++i) {
              let error = $root.cosmos.base.v1beta1.Coin.verify(message.total[i])
              if (error) return 'total.' + error
            }
          }
          return null
        }

        /**
         * Creates a Supply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cosmos.bank.v1beta1.Supply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cosmos.bank.v1beta1.Supply} Supply
         */
        Supply.fromObject = function fromObject(object) {
          if (object instanceof $root.cosmos.bank.v1beta1.Supply) return object
          let message = new $root.cosmos.bank.v1beta1.Supply()
          if (object.total) {
            if (!Array.isArray(object.total)) throw TypeError('.cosmos.bank.v1beta1.Supply.total: array expected')
            message.total = []
            for (let i = 0; i < object.total.length; ++i) {
              if (typeof object.total[i] !== 'object')
                throw TypeError('.cosmos.bank.v1beta1.Supply.total: object expected')
              message.total[i] = $root.cosmos.base.v1beta1.Coin.fromObject(object.total[i])
            }
          }
          return message
        }

        /**
         * Creates a plain object from a Supply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cosmos.bank.v1beta1.Supply
         * @static
         * @param {cosmos.bank.v1beta1.Supply} message Supply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Supply.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.total = []
          if (message.total && message.total.length) {
            object.total = []
            for (let j = 0; j < message.total.length; ++j)
              object.total[j] = $root.cosmos.base.v1beta1.Coin.toObject(message.total[j], options)
          }
          return object
        }

        /**
         * Converts this Supply to JSON.
         * @function toJSON
         * @memberof cosmos.bank.v1beta1.Supply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Supply.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return Supply
      })()

      v1beta1.DenomUnit = (function () {
        /**
         * Properties of a DenomUnit.
         * @memberof cosmos.bank.v1beta1
         * @interface IDenomUnit
         * @property {string|null} [denom] DenomUnit denom
         * @property {number|null} [exponent] DenomUnit exponent
         * @property {Array.<string>|null} [aliases] DenomUnit aliases
         */

        /**
         * Constructs a new DenomUnit.
         * @memberof cosmos.bank.v1beta1
         * @classdesc Represents a DenomUnit.
         * @implements IDenomUnit
         * @constructor
         * @param {cosmos.bank.v1beta1.IDenomUnit=} [properties] Properties to set
         */
        function DenomUnit(properties) {
          this.aliases = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * DenomUnit denom.
         * @member {string} denom
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @instance
         */
        DenomUnit.prototype.denom = ''

        /**
         * DenomUnit exponent.
         * @member {number} exponent
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @instance
         */
        DenomUnit.prototype.exponent = 0

        /**
         * DenomUnit aliases.
         * @member {Array.<string>} aliases
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @instance
         */
        DenomUnit.prototype.aliases = $util.emptyArray

        /**
         * Creates a new DenomUnit instance using the specified properties.
         * @function create
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @static
         * @param {cosmos.bank.v1beta1.IDenomUnit=} [properties] Properties to set
         * @returns {cosmos.bank.v1beta1.DenomUnit} DenomUnit instance
         */
        DenomUnit.create = function create(properties) {
          return new DenomUnit(properties)
        }

        /**
         * Encodes the specified DenomUnit message. Does not implicitly {@link cosmos.bank.v1beta1.DenomUnit.verify|verify} messages.
         * @function encode
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @static
         * @param {cosmos.bank.v1beta1.IDenomUnit} message DenomUnit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DenomUnit.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.denom != null && Object.hasOwnProperty.call(message, 'denom'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.denom)
          if (message.exponent != null && Object.hasOwnProperty.call(message, 'exponent'))
            writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.exponent)
          if (message.aliases != null && message.aliases.length)
            for (let i = 0; i < message.aliases.length; ++i)
              writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.aliases[i])
          return writer
        }

        /**
         * Encodes the specified DenomUnit message, length delimited. Does not implicitly {@link cosmos.bank.v1beta1.DenomUnit.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @static
         * @param {cosmos.bank.v1beta1.IDenomUnit} message DenomUnit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DenomUnit.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a DenomUnit message from the specified reader or buffer.
         * @function decode
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cosmos.bank.v1beta1.DenomUnit} DenomUnit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DenomUnit.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.cosmos.bank.v1beta1.DenomUnit()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.denom = reader.string()
                break
              case 2:
                message.exponent = reader.uint32()
                break
              case 3:
                if (!(message.aliases && message.aliases.length)) message.aliases = []
                message.aliases.push(reader.string())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a DenomUnit message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cosmos.bank.v1beta1.DenomUnit} DenomUnit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DenomUnit.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a DenomUnit message.
         * @function verify
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DenomUnit.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.denom != null && message.hasOwnProperty('denom'))
            if (!$util.isString(message.denom)) return 'denom: string expected'
          if (message.exponent != null && message.hasOwnProperty('exponent'))
            if (!$util.isInteger(message.exponent)) return 'exponent: integer expected'
          if (message.aliases != null && message.hasOwnProperty('aliases')) {
            if (!Array.isArray(message.aliases)) return 'aliases: array expected'
            for (let i = 0; i < message.aliases.length; ++i)
              if (!$util.isString(message.aliases[i])) return 'aliases: string[] expected'
          }
          return null
        }

        /**
         * Creates a DenomUnit message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cosmos.bank.v1beta1.DenomUnit} DenomUnit
         */
        DenomUnit.fromObject = function fromObject(object) {
          if (object instanceof $root.cosmos.bank.v1beta1.DenomUnit) return object
          let message = new $root.cosmos.bank.v1beta1.DenomUnit()
          if (object.denom != null) message.denom = String(object.denom)
          if (object.exponent != null) message.exponent = object.exponent >>> 0
          if (object.aliases) {
            if (!Array.isArray(object.aliases))
              throw TypeError('.cosmos.bank.v1beta1.DenomUnit.aliases: array expected')
            message.aliases = []
            for (let i = 0; i < object.aliases.length; ++i) message.aliases[i] = String(object.aliases[i])
          }
          return message
        }

        /**
         * Creates a plain object from a DenomUnit message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @static
         * @param {cosmos.bank.v1beta1.DenomUnit} message DenomUnit
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DenomUnit.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.aliases = []
          if (options.defaults) {
            object.denom = ''
            object.exponent = 0
          }
          if (message.denom != null && message.hasOwnProperty('denom')) object.denom = message.denom
          if (message.exponent != null && message.hasOwnProperty('exponent')) object.exponent = message.exponent
          if (message.aliases && message.aliases.length) {
            object.aliases = []
            for (let j = 0; j < message.aliases.length; ++j) object.aliases[j] = message.aliases[j]
          }
          return object
        }

        /**
         * Converts this DenomUnit to JSON.
         * @function toJSON
         * @memberof cosmos.bank.v1beta1.DenomUnit
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DenomUnit.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return DenomUnit
      })()

      v1beta1.Metadata = (function () {
        /**
         * Properties of a Metadata.
         * @memberof cosmos.bank.v1beta1
         * @interface IMetadata
         * @property {string|null} [description] Metadata description
         * @property {Array.<cosmos.bank.v1beta1.IDenomUnit>|null} [denomUnits] Metadata denomUnits
         * @property {string|null} [base] Metadata base
         * @property {string|null} [display] Metadata display
         * @property {string|null} [name] Metadata name
         * @property {string|null} [symbol] Metadata symbol
         * @property {string|null} [uri] Metadata uri
         * @property {string|null} [uriHash] Metadata uriHash
         */

        /**
         * Constructs a new Metadata.
         * @memberof cosmos.bank.v1beta1
         * @classdesc Represents a Metadata.
         * @implements IMetadata
         * @constructor
         * @param {cosmos.bank.v1beta1.IMetadata=} [properties] Properties to set
         */
        function Metadata(properties) {
          this.denomUnits = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Metadata description.
         * @member {string} description
         * @memberof cosmos.bank.v1beta1.Metadata
         * @instance
         */
        Metadata.prototype.description = ''

        /**
         * Metadata denomUnits.
         * @member {Array.<cosmos.bank.v1beta1.IDenomUnit>} denomUnits
         * @memberof cosmos.bank.v1beta1.Metadata
         * @instance
         */
        Metadata.prototype.denomUnits = $util.emptyArray

        /**
         * Metadata base.
         * @member {string} base
         * @memberof cosmos.bank.v1beta1.Metadata
         * @instance
         */
        Metadata.prototype.base = ''

        /**
         * Metadata display.
         * @member {string} display
         * @memberof cosmos.bank.v1beta1.Metadata
         * @instance
         */
        Metadata.prototype.display = ''

        /**
         * Metadata name.
         * @member {string} name
         * @memberof cosmos.bank.v1beta1.Metadata
         * @instance
         */
        Metadata.prototype.name = ''

        /**
         * Metadata symbol.
         * @member {string} symbol
         * @memberof cosmos.bank.v1beta1.Metadata
         * @instance
         */
        Metadata.prototype.symbol = ''

        /**
         * Metadata uri.
         * @member {string} uri
         * @memberof cosmos.bank.v1beta1.Metadata
         * @instance
         */
        Metadata.prototype.uri = ''

        /**
         * Metadata uriHash.
         * @member {string} uriHash
         * @memberof cosmos.bank.v1beta1.Metadata
         * @instance
         */
        Metadata.prototype.uriHash = ''

        /**
         * Creates a new Metadata instance using the specified properties.
         * @function create
         * @memberof cosmos.bank.v1beta1.Metadata
         * @static
         * @param {cosmos.bank.v1beta1.IMetadata=} [properties] Properties to set
         * @returns {cosmos.bank.v1beta1.Metadata} Metadata instance
         */
        Metadata.create = function create(properties) {
          return new Metadata(properties)
        }

        /**
         * Encodes the specified Metadata message. Does not implicitly {@link cosmos.bank.v1beta1.Metadata.verify|verify} messages.
         * @function encode
         * @memberof cosmos.bank.v1beta1.Metadata
         * @static
         * @param {cosmos.bank.v1beta1.IMetadata} message Metadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Metadata.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.description != null && Object.hasOwnProperty.call(message, 'description'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.description)
          if (message.denomUnits != null && message.denomUnits.length)
            for (let i = 0; i < message.denomUnits.length; ++i)
              $root.cosmos.bank.v1beta1.DenomUnit.encode(
                message.denomUnits[i],
                writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
              ).ldelim()
          if (message.base != null && Object.hasOwnProperty.call(message, 'base'))
            writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.base)
          if (message.display != null && Object.hasOwnProperty.call(message, 'display'))
            writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.display)
          if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
            writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.name)
          if (message.symbol != null && Object.hasOwnProperty.call(message, 'symbol'))
            writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.symbol)
          if (message.uri != null && Object.hasOwnProperty.call(message, 'uri'))
            writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.uri)
          if (message.uriHash != null && Object.hasOwnProperty.call(message, 'uriHash'))
            writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.uriHash)
          return writer
        }

        /**
         * Encodes the specified Metadata message, length delimited. Does not implicitly {@link cosmos.bank.v1beta1.Metadata.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cosmos.bank.v1beta1.Metadata
         * @static
         * @param {cosmos.bank.v1beta1.IMetadata} message Metadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Metadata.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a Metadata message from the specified reader or buffer.
         * @function decode
         * @memberof cosmos.bank.v1beta1.Metadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cosmos.bank.v1beta1.Metadata} Metadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Metadata.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.cosmos.bank.v1beta1.Metadata()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.description = reader.string()
                break
              case 2:
                if (!(message.denomUnits && message.denomUnits.length)) message.denomUnits = []
                message.denomUnits.push($root.cosmos.bank.v1beta1.DenomUnit.decode(reader, reader.uint32()))
                break
              case 3:
                message.base = reader.string()
                break
              case 4:
                message.display = reader.string()
                break
              case 5:
                message.name = reader.string()
                break
              case 6:
                message.symbol = reader.string()
                break
              case 7:
                message.uri = reader.string()
                break
              case 8:
                message.uriHash = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a Metadata message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cosmos.bank.v1beta1.Metadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cosmos.bank.v1beta1.Metadata} Metadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Metadata.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a Metadata message.
         * @function verify
         * @memberof cosmos.bank.v1beta1.Metadata
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Metadata.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.description != null && message.hasOwnProperty('description'))
            if (!$util.isString(message.description)) return 'description: string expected'
          if (message.denomUnits != null && message.hasOwnProperty('denomUnits')) {
            if (!Array.isArray(message.denomUnits)) return 'denomUnits: array expected'
            for (let i = 0; i < message.denomUnits.length; ++i) {
              let error = $root.cosmos.bank.v1beta1.DenomUnit.verify(message.denomUnits[i])
              if (error) return 'denomUnits.' + error
            }
          }
          if (message.base != null && message.hasOwnProperty('base'))
            if (!$util.isString(message.base)) return 'base: string expected'
          if (message.display != null && message.hasOwnProperty('display'))
            if (!$util.isString(message.display)) return 'display: string expected'
          if (message.name != null && message.hasOwnProperty('name'))
            if (!$util.isString(message.name)) return 'name: string expected'
          if (message.symbol != null && message.hasOwnProperty('symbol'))
            if (!$util.isString(message.symbol)) return 'symbol: string expected'
          if (message.uri != null && message.hasOwnProperty('uri'))
            if (!$util.isString(message.uri)) return 'uri: string expected'
          if (message.uriHash != null && message.hasOwnProperty('uriHash'))
            if (!$util.isString(message.uriHash)) return 'uriHash: string expected'
          return null
        }

        /**
         * Creates a Metadata message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cosmos.bank.v1beta1.Metadata
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cosmos.bank.v1beta1.Metadata} Metadata
         */
        Metadata.fromObject = function fromObject(object) {
          if (object instanceof $root.cosmos.bank.v1beta1.Metadata) return object
          let message = new $root.cosmos.bank.v1beta1.Metadata()
          if (object.description != null) message.description = String(object.description)
          if (object.denomUnits) {
            if (!Array.isArray(object.denomUnits))
              throw TypeError('.cosmos.bank.v1beta1.Metadata.denomUnits: array expected')
            message.denomUnits = []
            for (let i = 0; i < object.denomUnits.length; ++i) {
              if (typeof object.denomUnits[i] !== 'object')
                throw TypeError('.cosmos.bank.v1beta1.Metadata.denomUnits: object expected')
              message.denomUnits[i] = $root.cosmos.bank.v1beta1.DenomUnit.fromObject(object.denomUnits[i])
            }
          }
          if (object.base != null) message.base = String(object.base)
          if (object.display != null) message.display = String(object.display)
          if (object.name != null) message.name = String(object.name)
          if (object.symbol != null) message.symbol = String(object.symbol)
          if (object.uri != null) message.uri = String(object.uri)
          if (object.uriHash != null) message.uriHash = String(object.uriHash)
          return message
        }

        /**
         * Creates a plain object from a Metadata message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cosmos.bank.v1beta1.Metadata
         * @static
         * @param {cosmos.bank.v1beta1.Metadata} message Metadata
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Metadata.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.denomUnits = []
          if (options.defaults) {
            object.description = ''
            object.base = ''
            object.display = ''
            object.name = ''
            object.symbol = ''
            object.uri = ''
            object.uriHash = ''
          }
          if (message.description != null && message.hasOwnProperty('description'))
            object.description = message.description
          if (message.denomUnits && message.denomUnits.length) {
            object.denomUnits = []
            for (let j = 0; j < message.denomUnits.length; ++j)
              object.denomUnits[j] = $root.cosmos.bank.v1beta1.DenomUnit.toObject(message.denomUnits[j], options)
          }
          if (message.base != null && message.hasOwnProperty('base')) object.base = message.base
          if (message.display != null && message.hasOwnProperty('display')) object.display = message.display
          if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
          if (message.symbol != null && message.hasOwnProperty('symbol')) object.symbol = message.symbol
          if (message.uri != null && message.hasOwnProperty('uri')) object.uri = message.uri
          if (message.uriHash != null && message.hasOwnProperty('uriHash')) object.uriHash = message.uriHash
          return object
        }

        /**
         * Converts this Metadata to JSON.
         * @function toJSON
         * @memberof cosmos.bank.v1beta1.Metadata
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Metadata.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return Metadata
      })()

      return v1beta1
    })()

    return bank
  })()

  return cosmos
})())

export const umee = ($root.umee = (() => {
  /**
   * Namespace umee.
   * @exports umee
   * @namespace
   */
  const umee = {}

  umee.leverage = (function () {
    /**
     * Namespace leverage.
     * @memberof umee
     * @namespace
     */
    const leverage = {}

    leverage.v1 = (function () {
      /**
       * Namespace v1.
       * @memberof umee.leverage
       * @namespace
       */
      const v1 = {}

      v1.Msg = (function () {
        /**
         * Constructs a new Msg service.
         * @memberof umee.leverage.v1
         * @classdesc Represents a Msg
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function Msg(rpcImpl, requestDelimited, responseDelimited) {
          $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited)
        }

        ;(Msg.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Msg

        /**
         * Creates new Msg service using the specified rpc implementation.
         * @function create
         * @memberof umee.leverage.v1.Msg
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {Msg} RPC service. Useful where requests and/or responses are streamed.
         */
        Msg.create = function create(rpcImpl, requestDelimited, responseDelimited) {
          return new this(rpcImpl, requestDelimited, responseDelimited)
        }

        /**
         * Callback as used by {@link umee.leverage.v1.Msg#supply}.
         * @memberof umee.leverage.v1.Msg
         * @typedef SupplyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.MsgSupplyResponse} [response] MsgSupplyResponse
         */

        /**
         * Calls Supply.
         * @function supply
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgSupply} request MsgSupply message or plain object
         * @param {umee.leverage.v1.Msg.SupplyCallback} callback Node-style callback called with the error, if any, and MsgSupplyResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Msg.prototype.supply = function supply(request, callback) {
            return this.rpcCall(
              supply,
              $root.umee.leverage.v1.MsgSupply,
              $root.umee.leverage.v1.MsgSupplyResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'Supply' },
        )

        /**
         * Calls Supply.
         * @function supply
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgSupply} request MsgSupply message or plain object
         * @returns {Promise<umee.leverage.v1.MsgSupplyResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Msg#withdraw}.
         * @memberof umee.leverage.v1.Msg
         * @typedef WithdrawCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.MsgWithdrawResponse} [response] MsgWithdrawResponse
         */

        /**
         * Calls Withdraw.
         * @function withdraw
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgWithdraw} request MsgWithdraw message or plain object
         * @param {umee.leverage.v1.Msg.WithdrawCallback} callback Node-style callback called with the error, if any, and MsgWithdrawResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Msg.prototype.withdraw = function withdraw(request, callback) {
            return this.rpcCall(
              withdraw,
              $root.umee.leverage.v1.MsgWithdraw,
              $root.umee.leverage.v1.MsgWithdrawResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'Withdraw' },
        )

        /**
         * Calls Withdraw.
         * @function withdraw
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgWithdraw} request MsgWithdraw message or plain object
         * @returns {Promise<umee.leverage.v1.MsgWithdrawResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Msg#collateralize}.
         * @memberof umee.leverage.v1.Msg
         * @typedef CollateralizeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.MsgCollateralizeResponse} [response] MsgCollateralizeResponse
         */

        /**
         * Calls Collateralize.
         * @function collateralize
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgCollateralize} request MsgCollateralize message or plain object
         * @param {umee.leverage.v1.Msg.CollateralizeCallback} callback Node-style callback called with the error, if any, and MsgCollateralizeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Msg.prototype.collateralize = function collateralize(request, callback) {
            return this.rpcCall(
              collateralize,
              $root.umee.leverage.v1.MsgCollateralize,
              $root.umee.leverage.v1.MsgCollateralizeResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'Collateralize' },
        )

        /**
         * Calls Collateralize.
         * @function collateralize
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgCollateralize} request MsgCollateralize message or plain object
         * @returns {Promise<umee.leverage.v1.MsgCollateralizeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Msg#decollateralize}.
         * @memberof umee.leverage.v1.Msg
         * @typedef DecollateralizeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.MsgDecollateralizeResponse} [response] MsgDecollateralizeResponse
         */

        /**
         * Calls Decollateralize.
         * @function decollateralize
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgDecollateralize} request MsgDecollateralize message or plain object
         * @param {umee.leverage.v1.Msg.DecollateralizeCallback} callback Node-style callback called with the error, if any, and MsgDecollateralizeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Msg.prototype.decollateralize = function decollateralize(request, callback) {
            return this.rpcCall(
              decollateralize,
              $root.umee.leverage.v1.MsgDecollateralize,
              $root.umee.leverage.v1.MsgDecollateralizeResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'Decollateralize' },
        )

        /**
         * Calls Decollateralize.
         * @function decollateralize
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgDecollateralize} request MsgDecollateralize message or plain object
         * @returns {Promise<umee.leverage.v1.MsgDecollateralizeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Msg#borrow}.
         * @memberof umee.leverage.v1.Msg
         * @typedef BorrowCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.MsgBorrowResponse} [response] MsgBorrowResponse
         */

        /**
         * Calls Borrow.
         * @function borrow
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgBorrow} request MsgBorrow message or plain object
         * @param {umee.leverage.v1.Msg.BorrowCallback} callback Node-style callback called with the error, if any, and MsgBorrowResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Msg.prototype.borrow = function borrow(request, callback) {
            return this.rpcCall(
              borrow,
              $root.umee.leverage.v1.MsgBorrow,
              $root.umee.leverage.v1.MsgBorrowResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'Borrow' },
        )

        /**
         * Calls Borrow.
         * @function borrow
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgBorrow} request MsgBorrow message or plain object
         * @returns {Promise<umee.leverage.v1.MsgBorrowResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Msg#repay}.
         * @memberof umee.leverage.v1.Msg
         * @typedef RepayCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.MsgRepayResponse} [response] MsgRepayResponse
         */

        /**
         * Calls Repay.
         * @function repay
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgRepay} request MsgRepay message or plain object
         * @param {umee.leverage.v1.Msg.RepayCallback} callback Node-style callback called with the error, if any, and MsgRepayResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Msg.prototype.repay = function repay(request, callback) {
            return this.rpcCall(
              repay,
              $root.umee.leverage.v1.MsgRepay,
              $root.umee.leverage.v1.MsgRepayResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'Repay' },
        )

        /**
         * Calls Repay.
         * @function repay
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgRepay} request MsgRepay message or plain object
         * @returns {Promise<umee.leverage.v1.MsgRepayResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Msg#liquidate}.
         * @memberof umee.leverage.v1.Msg
         * @typedef LiquidateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.MsgLiquidateResponse} [response] MsgLiquidateResponse
         */

        /**
         * Calls Liquidate.
         * @function liquidate
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgLiquidate} request MsgLiquidate message or plain object
         * @param {umee.leverage.v1.Msg.LiquidateCallback} callback Node-style callback called with the error, if any, and MsgLiquidateResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Msg.prototype.liquidate = function liquidate(request, callback) {
            return this.rpcCall(
              liquidate,
              $root.umee.leverage.v1.MsgLiquidate,
              $root.umee.leverage.v1.MsgLiquidateResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'Liquidate' },
        )

        /**
         * Calls Liquidate.
         * @function liquidate
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgLiquidate} request MsgLiquidate message or plain object
         * @returns {Promise<umee.leverage.v1.MsgLiquidateResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Msg#supplyCollateral}.
         * @memberof umee.leverage.v1.Msg
         * @typedef SupplyCollateralCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.MsgSupplyCollateralResponse} [response] MsgSupplyCollateralResponse
         */

        /**
         * Calls SupplyCollateral.
         * @function supplyCollateral
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgSupplyCollateral} request MsgSupplyCollateral message or plain object
         * @param {umee.leverage.v1.Msg.SupplyCollateralCallback} callback Node-style callback called with the error, if any, and MsgSupplyCollateralResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Msg.prototype.supplyCollateral = function supplyCollateral(request, callback) {
            return this.rpcCall(
              supplyCollateral,
              $root.umee.leverage.v1.MsgSupplyCollateral,
              $root.umee.leverage.v1.MsgSupplyCollateralResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'SupplyCollateral' },
        )

        /**
         * Calls SupplyCollateral.
         * @function supplyCollateral
         * @memberof umee.leverage.v1.Msg
         * @instance
         * @param {umee.leverage.v1.IMsgSupplyCollateral} request MsgSupplyCollateral message or plain object
         * @returns {Promise<umee.leverage.v1.MsgSupplyCollateralResponse>} Promise
         * @variation 2
         */

        return Msg
      })()

      v1.MsgSupply = (function () {
        /**
         * Properties of a MsgSupply.
         * @memberof umee.leverage.v1
         * @interface IMsgSupply
         * @property {string|null} [supplier] MsgSupply supplier
         * @property {cosmos.base.v1beta1.ICoin|null} [asset] MsgSupply asset
         */

        /**
         * Constructs a new MsgSupply.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgSupply.
         * @implements IMsgSupply
         * @constructor
         * @param {umee.leverage.v1.IMsgSupply=} [properties] Properties to set
         */
        function MsgSupply(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgSupply supplier.
         * @member {string} supplier
         * @memberof umee.leverage.v1.MsgSupply
         * @instance
         */
        MsgSupply.prototype.supplier = ''

        /**
         * MsgSupply asset.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} asset
         * @memberof umee.leverage.v1.MsgSupply
         * @instance
         */
        MsgSupply.prototype.asset = null

        /**
         * Creates a new MsgSupply instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgSupply
         * @static
         * @param {umee.leverage.v1.IMsgSupply=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgSupply} MsgSupply instance
         */
        MsgSupply.create = function create(properties) {
          return new MsgSupply(properties)
        }

        /**
         * Encodes the specified MsgSupply message. Does not implicitly {@link umee.leverage.v1.MsgSupply.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgSupply
         * @static
         * @param {umee.leverage.v1.IMsgSupply} message MsgSupply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgSupply.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.supplier != null && Object.hasOwnProperty.call(message, 'supplier'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.supplier)
          if (message.asset != null && Object.hasOwnProperty.call(message, 'asset'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.asset,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgSupply message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgSupply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgSupply
         * @static
         * @param {umee.leverage.v1.IMsgSupply} message MsgSupply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgSupply.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgSupply message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgSupply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgSupply} MsgSupply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgSupply.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgSupply()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.supplier = reader.string()
                break
              case 2:
                message.asset = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgSupply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgSupply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgSupply} MsgSupply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgSupply.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgSupply message.
         * @function verify
         * @memberof umee.leverage.v1.MsgSupply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgSupply.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.supplier != null && message.hasOwnProperty('supplier'))
            if (!$util.isString(message.supplier)) return 'supplier: string expected'
          if (message.asset != null && message.hasOwnProperty('asset')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.asset)
            if (error) return 'asset.' + error
          }
          return null
        }

        /**
         * Creates a MsgSupply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgSupply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgSupply} MsgSupply
         */
        MsgSupply.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgSupply) return object
          let message = new $root.umee.leverage.v1.MsgSupply()
          if (object.supplier != null) message.supplier = String(object.supplier)
          if (object.asset != null) {
            if (typeof object.asset !== 'object') throw TypeError('.umee.leverage.v1.MsgSupply.asset: object expected')
            message.asset = $root.cosmos.base.v1beta1.Coin.fromObject(object.asset)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgSupply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgSupply
         * @static
         * @param {umee.leverage.v1.MsgSupply} message MsgSupply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgSupply.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.supplier = ''
            object.asset = null
          }
          if (message.supplier != null && message.hasOwnProperty('supplier')) object.supplier = message.supplier
          if (message.asset != null && message.hasOwnProperty('asset'))
            object.asset = $root.cosmos.base.v1beta1.Coin.toObject(message.asset, options)
          return object
        }

        /**
         * Converts this MsgSupply to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgSupply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgSupply.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgSupply
      })()

      v1.MsgWithdraw = (function () {
        /**
         * Properties of a MsgWithdraw.
         * @memberof umee.leverage.v1
         * @interface IMsgWithdraw
         * @property {string|null} [supplier] MsgWithdraw supplier
         * @property {cosmos.base.v1beta1.ICoin|null} [asset] MsgWithdraw asset
         */

        /**
         * Constructs a new MsgWithdraw.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgWithdraw.
         * @implements IMsgWithdraw
         * @constructor
         * @param {umee.leverage.v1.IMsgWithdraw=} [properties] Properties to set
         */
        function MsgWithdraw(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgWithdraw supplier.
         * @member {string} supplier
         * @memberof umee.leverage.v1.MsgWithdraw
         * @instance
         */
        MsgWithdraw.prototype.supplier = ''

        /**
         * MsgWithdraw asset.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} asset
         * @memberof umee.leverage.v1.MsgWithdraw
         * @instance
         */
        MsgWithdraw.prototype.asset = null

        /**
         * Creates a new MsgWithdraw instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgWithdraw
         * @static
         * @param {umee.leverage.v1.IMsgWithdraw=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgWithdraw} MsgWithdraw instance
         */
        MsgWithdraw.create = function create(properties) {
          return new MsgWithdraw(properties)
        }

        /**
         * Encodes the specified MsgWithdraw message. Does not implicitly {@link umee.leverage.v1.MsgWithdraw.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgWithdraw
         * @static
         * @param {umee.leverage.v1.IMsgWithdraw} message MsgWithdraw message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgWithdraw.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.supplier != null && Object.hasOwnProperty.call(message, 'supplier'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.supplier)
          if (message.asset != null && Object.hasOwnProperty.call(message, 'asset'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.asset,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgWithdraw message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgWithdraw.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgWithdraw
         * @static
         * @param {umee.leverage.v1.IMsgWithdraw} message MsgWithdraw message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgWithdraw.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgWithdraw message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgWithdraw
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgWithdraw} MsgWithdraw
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgWithdraw.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgWithdraw()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.supplier = reader.string()
                break
              case 2:
                message.asset = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgWithdraw message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgWithdraw
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgWithdraw} MsgWithdraw
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgWithdraw.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgWithdraw message.
         * @function verify
         * @memberof umee.leverage.v1.MsgWithdraw
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgWithdraw.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.supplier != null && message.hasOwnProperty('supplier'))
            if (!$util.isString(message.supplier)) return 'supplier: string expected'
          if (message.asset != null && message.hasOwnProperty('asset')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.asset)
            if (error) return 'asset.' + error
          }
          return null
        }

        /**
         * Creates a MsgWithdraw message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgWithdraw
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgWithdraw} MsgWithdraw
         */
        MsgWithdraw.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgWithdraw) return object
          let message = new $root.umee.leverage.v1.MsgWithdraw()
          if (object.supplier != null) message.supplier = String(object.supplier)
          if (object.asset != null) {
            if (typeof object.asset !== 'object')
              throw TypeError('.umee.leverage.v1.MsgWithdraw.asset: object expected')
            message.asset = $root.cosmos.base.v1beta1.Coin.fromObject(object.asset)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgWithdraw message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgWithdraw
         * @static
         * @param {umee.leverage.v1.MsgWithdraw} message MsgWithdraw
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgWithdraw.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.supplier = ''
            object.asset = null
          }
          if (message.supplier != null && message.hasOwnProperty('supplier')) object.supplier = message.supplier
          if (message.asset != null && message.hasOwnProperty('asset'))
            object.asset = $root.cosmos.base.v1beta1.Coin.toObject(message.asset, options)
          return object
        }

        /**
         * Converts this MsgWithdraw to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgWithdraw
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgWithdraw.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgWithdraw
      })()

      v1.MsgCollateralize = (function () {
        /**
         * Properties of a MsgCollateralize.
         * @memberof umee.leverage.v1
         * @interface IMsgCollateralize
         * @property {string|null} [borrower] MsgCollateralize borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [asset] MsgCollateralize asset
         */

        /**
         * Constructs a new MsgCollateralize.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgCollateralize.
         * @implements IMsgCollateralize
         * @constructor
         * @param {umee.leverage.v1.IMsgCollateralize=} [properties] Properties to set
         */
        function MsgCollateralize(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgCollateralize borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.MsgCollateralize
         * @instance
         */
        MsgCollateralize.prototype.borrower = ''

        /**
         * MsgCollateralize asset.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} asset
         * @memberof umee.leverage.v1.MsgCollateralize
         * @instance
         */
        MsgCollateralize.prototype.asset = null

        /**
         * Creates a new MsgCollateralize instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgCollateralize
         * @static
         * @param {umee.leverage.v1.IMsgCollateralize=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgCollateralize} MsgCollateralize instance
         */
        MsgCollateralize.create = function create(properties) {
          return new MsgCollateralize(properties)
        }

        /**
         * Encodes the specified MsgCollateralize message. Does not implicitly {@link umee.leverage.v1.MsgCollateralize.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgCollateralize
         * @static
         * @param {umee.leverage.v1.IMsgCollateralize} message MsgCollateralize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgCollateralize.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.borrower)
          if (message.asset != null && Object.hasOwnProperty.call(message, 'asset'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.asset,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgCollateralize message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgCollateralize.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgCollateralize
         * @static
         * @param {umee.leverage.v1.IMsgCollateralize} message MsgCollateralize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgCollateralize.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgCollateralize message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgCollateralize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgCollateralize} MsgCollateralize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgCollateralize.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgCollateralize()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.borrower = reader.string()
                break
              case 2:
                message.asset = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgCollateralize message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgCollateralize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgCollateralize} MsgCollateralize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgCollateralize.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgCollateralize message.
         * @function verify
         * @memberof umee.leverage.v1.MsgCollateralize
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgCollateralize.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.asset != null && message.hasOwnProperty('asset')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.asset)
            if (error) return 'asset.' + error
          }
          return null
        }

        /**
         * Creates a MsgCollateralize message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgCollateralize
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgCollateralize} MsgCollateralize
         */
        MsgCollateralize.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgCollateralize) return object
          let message = new $root.umee.leverage.v1.MsgCollateralize()
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.asset != null) {
            if (typeof object.asset !== 'object')
              throw TypeError('.umee.leverage.v1.MsgCollateralize.asset: object expected')
            message.asset = $root.cosmos.base.v1beta1.Coin.fromObject(object.asset)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgCollateralize message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgCollateralize
         * @static
         * @param {umee.leverage.v1.MsgCollateralize} message MsgCollateralize
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgCollateralize.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.borrower = ''
            object.asset = null
          }
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.asset != null && message.hasOwnProperty('asset'))
            object.asset = $root.cosmos.base.v1beta1.Coin.toObject(message.asset, options)
          return object
        }

        /**
         * Converts this MsgCollateralize to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgCollateralize
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgCollateralize.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgCollateralize
      })()

      v1.MsgDecollateralize = (function () {
        /**
         * Properties of a MsgDecollateralize.
         * @memberof umee.leverage.v1
         * @interface IMsgDecollateralize
         * @property {string|null} [borrower] MsgDecollateralize borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [asset] MsgDecollateralize asset
         */

        /**
         * Constructs a new MsgDecollateralize.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgDecollateralize.
         * @implements IMsgDecollateralize
         * @constructor
         * @param {umee.leverage.v1.IMsgDecollateralize=} [properties] Properties to set
         */
        function MsgDecollateralize(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgDecollateralize borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.MsgDecollateralize
         * @instance
         */
        MsgDecollateralize.prototype.borrower = ''

        /**
         * MsgDecollateralize asset.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} asset
         * @memberof umee.leverage.v1.MsgDecollateralize
         * @instance
         */
        MsgDecollateralize.prototype.asset = null

        /**
         * Creates a new MsgDecollateralize instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgDecollateralize
         * @static
         * @param {umee.leverage.v1.IMsgDecollateralize=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgDecollateralize} MsgDecollateralize instance
         */
        MsgDecollateralize.create = function create(properties) {
          return new MsgDecollateralize(properties)
        }

        /**
         * Encodes the specified MsgDecollateralize message. Does not implicitly {@link umee.leverage.v1.MsgDecollateralize.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgDecollateralize
         * @static
         * @param {umee.leverage.v1.IMsgDecollateralize} message MsgDecollateralize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgDecollateralize.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.borrower)
          if (message.asset != null && Object.hasOwnProperty.call(message, 'asset'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.asset,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgDecollateralize message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgDecollateralize.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgDecollateralize
         * @static
         * @param {umee.leverage.v1.IMsgDecollateralize} message MsgDecollateralize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgDecollateralize.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgDecollateralize message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgDecollateralize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgDecollateralize} MsgDecollateralize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgDecollateralize.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgDecollateralize()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.borrower = reader.string()
                break
              case 2:
                message.asset = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgDecollateralize message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgDecollateralize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgDecollateralize} MsgDecollateralize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgDecollateralize.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgDecollateralize message.
         * @function verify
         * @memberof umee.leverage.v1.MsgDecollateralize
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgDecollateralize.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.asset != null && message.hasOwnProperty('asset')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.asset)
            if (error) return 'asset.' + error
          }
          return null
        }

        /**
         * Creates a MsgDecollateralize message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgDecollateralize
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgDecollateralize} MsgDecollateralize
         */
        MsgDecollateralize.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgDecollateralize) return object
          let message = new $root.umee.leverage.v1.MsgDecollateralize()
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.asset != null) {
            if (typeof object.asset !== 'object')
              throw TypeError('.umee.leverage.v1.MsgDecollateralize.asset: object expected')
            message.asset = $root.cosmos.base.v1beta1.Coin.fromObject(object.asset)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgDecollateralize message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgDecollateralize
         * @static
         * @param {umee.leverage.v1.MsgDecollateralize} message MsgDecollateralize
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgDecollateralize.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.borrower = ''
            object.asset = null
          }
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.asset != null && message.hasOwnProperty('asset'))
            object.asset = $root.cosmos.base.v1beta1.Coin.toObject(message.asset, options)
          return object
        }

        /**
         * Converts this MsgDecollateralize to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgDecollateralize
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgDecollateralize.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgDecollateralize
      })()

      v1.MsgBorrow = (function () {
        /**
         * Properties of a MsgBorrow.
         * @memberof umee.leverage.v1
         * @interface IMsgBorrow
         * @property {string|null} [borrower] MsgBorrow borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [asset] MsgBorrow asset
         */

        /**
         * Constructs a new MsgBorrow.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgBorrow.
         * @implements IMsgBorrow
         * @constructor
         * @param {umee.leverage.v1.IMsgBorrow=} [properties] Properties to set
         */
        function MsgBorrow(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgBorrow borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.MsgBorrow
         * @instance
         */
        MsgBorrow.prototype.borrower = ''

        /**
         * MsgBorrow asset.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} asset
         * @memberof umee.leverage.v1.MsgBorrow
         * @instance
         */
        MsgBorrow.prototype.asset = null

        /**
         * Creates a new MsgBorrow instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgBorrow
         * @static
         * @param {umee.leverage.v1.IMsgBorrow=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgBorrow} MsgBorrow instance
         */
        MsgBorrow.create = function create(properties) {
          return new MsgBorrow(properties)
        }

        /**
         * Encodes the specified MsgBorrow message. Does not implicitly {@link umee.leverage.v1.MsgBorrow.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgBorrow
         * @static
         * @param {umee.leverage.v1.IMsgBorrow} message MsgBorrow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgBorrow.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.borrower)
          if (message.asset != null && Object.hasOwnProperty.call(message, 'asset'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.asset,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgBorrow message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgBorrow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgBorrow
         * @static
         * @param {umee.leverage.v1.IMsgBorrow} message MsgBorrow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgBorrow.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgBorrow message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgBorrow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgBorrow} MsgBorrow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgBorrow.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgBorrow()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.borrower = reader.string()
                break
              case 2:
                message.asset = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgBorrow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgBorrow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgBorrow} MsgBorrow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgBorrow.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgBorrow message.
         * @function verify
         * @memberof umee.leverage.v1.MsgBorrow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgBorrow.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.asset != null && message.hasOwnProperty('asset')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.asset)
            if (error) return 'asset.' + error
          }
          return null
        }

        /**
         * Creates a MsgBorrow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgBorrow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgBorrow} MsgBorrow
         */
        MsgBorrow.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgBorrow) return object
          let message = new $root.umee.leverage.v1.MsgBorrow()
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.asset != null) {
            if (typeof object.asset !== 'object') throw TypeError('.umee.leverage.v1.MsgBorrow.asset: object expected')
            message.asset = $root.cosmos.base.v1beta1.Coin.fromObject(object.asset)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgBorrow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgBorrow
         * @static
         * @param {umee.leverage.v1.MsgBorrow} message MsgBorrow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgBorrow.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.borrower = ''
            object.asset = null
          }
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.asset != null && message.hasOwnProperty('asset'))
            object.asset = $root.cosmos.base.v1beta1.Coin.toObject(message.asset, options)
          return object
        }

        /**
         * Converts this MsgBorrow to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgBorrow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgBorrow.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgBorrow
      })()

      v1.MsgRepay = (function () {
        /**
         * Properties of a MsgRepay.
         * @memberof umee.leverage.v1
         * @interface IMsgRepay
         * @property {string|null} [borrower] MsgRepay borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [asset] MsgRepay asset
         */

        /**
         * Constructs a new MsgRepay.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgRepay.
         * @implements IMsgRepay
         * @constructor
         * @param {umee.leverage.v1.IMsgRepay=} [properties] Properties to set
         */
        function MsgRepay(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgRepay borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.MsgRepay
         * @instance
         */
        MsgRepay.prototype.borrower = ''

        /**
         * MsgRepay asset.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} asset
         * @memberof umee.leverage.v1.MsgRepay
         * @instance
         */
        MsgRepay.prototype.asset = null

        /**
         * Creates a new MsgRepay instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgRepay
         * @static
         * @param {umee.leverage.v1.IMsgRepay=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgRepay} MsgRepay instance
         */
        MsgRepay.create = function create(properties) {
          return new MsgRepay(properties)
        }

        /**
         * Encodes the specified MsgRepay message. Does not implicitly {@link umee.leverage.v1.MsgRepay.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgRepay
         * @static
         * @param {umee.leverage.v1.IMsgRepay} message MsgRepay message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgRepay.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.borrower)
          if (message.asset != null && Object.hasOwnProperty.call(message, 'asset'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.asset,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgRepay message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgRepay.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgRepay
         * @static
         * @param {umee.leverage.v1.IMsgRepay} message MsgRepay message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgRepay.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgRepay message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgRepay
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgRepay} MsgRepay
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgRepay.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgRepay()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.borrower = reader.string()
                break
              case 2:
                message.asset = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgRepay message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgRepay
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgRepay} MsgRepay
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgRepay.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgRepay message.
         * @function verify
         * @memberof umee.leverage.v1.MsgRepay
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgRepay.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.asset != null && message.hasOwnProperty('asset')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.asset)
            if (error) return 'asset.' + error
          }
          return null
        }

        /**
         * Creates a MsgRepay message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgRepay
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgRepay} MsgRepay
         */
        MsgRepay.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgRepay) return object
          let message = new $root.umee.leverage.v1.MsgRepay()
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.asset != null) {
            if (typeof object.asset !== 'object') throw TypeError('.umee.leverage.v1.MsgRepay.asset: object expected')
            message.asset = $root.cosmos.base.v1beta1.Coin.fromObject(object.asset)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgRepay message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgRepay
         * @static
         * @param {umee.leverage.v1.MsgRepay} message MsgRepay
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgRepay.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.borrower = ''
            object.asset = null
          }
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.asset != null && message.hasOwnProperty('asset'))
            object.asset = $root.cosmos.base.v1beta1.Coin.toObject(message.asset, options)
          return object
        }

        /**
         * Converts this MsgRepay to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgRepay
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgRepay.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgRepay
      })()

      v1.MsgLiquidate = (function () {
        /**
         * Properties of a MsgLiquidate.
         * @memberof umee.leverage.v1
         * @interface IMsgLiquidate
         * @property {string|null} [liquidator] MsgLiquidate liquidator
         * @property {string|null} [borrower] MsgLiquidate borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [repayment] MsgLiquidate repayment
         * @property {string|null} [rewardDenom] MsgLiquidate rewardDenom
         */

        /**
         * Constructs a new MsgLiquidate.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgLiquidate.
         * @implements IMsgLiquidate
         * @constructor
         * @param {umee.leverage.v1.IMsgLiquidate=} [properties] Properties to set
         */
        function MsgLiquidate(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgLiquidate liquidator.
         * @member {string} liquidator
         * @memberof umee.leverage.v1.MsgLiquidate
         * @instance
         */
        MsgLiquidate.prototype.liquidator = ''

        /**
         * MsgLiquidate borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.MsgLiquidate
         * @instance
         */
        MsgLiquidate.prototype.borrower = ''

        /**
         * MsgLiquidate repayment.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} repayment
         * @memberof umee.leverage.v1.MsgLiquidate
         * @instance
         */
        MsgLiquidate.prototype.repayment = null

        /**
         * MsgLiquidate rewardDenom.
         * @member {string} rewardDenom
         * @memberof umee.leverage.v1.MsgLiquidate
         * @instance
         */
        MsgLiquidate.prototype.rewardDenom = ''

        /**
         * Creates a new MsgLiquidate instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgLiquidate
         * @static
         * @param {umee.leverage.v1.IMsgLiquidate=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgLiquidate} MsgLiquidate instance
         */
        MsgLiquidate.create = function create(properties) {
          return new MsgLiquidate(properties)
        }

        /**
         * Encodes the specified MsgLiquidate message. Does not implicitly {@link umee.leverage.v1.MsgLiquidate.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgLiquidate
         * @static
         * @param {umee.leverage.v1.IMsgLiquidate} message MsgLiquidate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgLiquidate.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.liquidator != null && Object.hasOwnProperty.call(message, 'liquidator'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.liquidator)
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.borrower)
          if (message.repayment != null && Object.hasOwnProperty.call(message, 'repayment'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.repayment,
              writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
            ).ldelim()
          if (message.rewardDenom != null && Object.hasOwnProperty.call(message, 'rewardDenom'))
            writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.rewardDenom)
          return writer
        }

        /**
         * Encodes the specified MsgLiquidate message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgLiquidate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgLiquidate
         * @static
         * @param {umee.leverage.v1.IMsgLiquidate} message MsgLiquidate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgLiquidate.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgLiquidate message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgLiquidate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgLiquidate} MsgLiquidate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgLiquidate.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgLiquidate()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.liquidator = reader.string()
                break
              case 2:
                message.borrower = reader.string()
                break
              case 3:
                message.repayment = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              case 4:
                message.rewardDenom = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgLiquidate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgLiquidate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgLiquidate} MsgLiquidate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgLiquidate.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgLiquidate message.
         * @function verify
         * @memberof umee.leverage.v1.MsgLiquidate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgLiquidate.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.liquidator != null && message.hasOwnProperty('liquidator'))
            if (!$util.isString(message.liquidator)) return 'liquidator: string expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.repayment != null && message.hasOwnProperty('repayment')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.repayment)
            if (error) return 'repayment.' + error
          }
          if (message.rewardDenom != null && message.hasOwnProperty('rewardDenom'))
            if (!$util.isString(message.rewardDenom)) return 'rewardDenom: string expected'
          return null
        }

        /**
         * Creates a MsgLiquidate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgLiquidate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgLiquidate} MsgLiquidate
         */
        MsgLiquidate.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgLiquidate) return object
          let message = new $root.umee.leverage.v1.MsgLiquidate()
          if (object.liquidator != null) message.liquidator = String(object.liquidator)
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.repayment != null) {
            if (typeof object.repayment !== 'object')
              throw TypeError('.umee.leverage.v1.MsgLiquidate.repayment: object expected')
            message.repayment = $root.cosmos.base.v1beta1.Coin.fromObject(object.repayment)
          }
          if (object.rewardDenom != null) message.rewardDenom = String(object.rewardDenom)
          return message
        }

        /**
         * Creates a plain object from a MsgLiquidate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgLiquidate
         * @static
         * @param {umee.leverage.v1.MsgLiquidate} message MsgLiquidate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgLiquidate.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.liquidator = ''
            object.borrower = ''
            object.repayment = null
            object.rewardDenom = ''
          }
          if (message.liquidator != null && message.hasOwnProperty('liquidator')) object.liquidator = message.liquidator
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.repayment != null && message.hasOwnProperty('repayment'))
            object.repayment = $root.cosmos.base.v1beta1.Coin.toObject(message.repayment, options)
          if (message.rewardDenom != null && message.hasOwnProperty('rewardDenom'))
            object.rewardDenom = message.rewardDenom
          return object
        }

        /**
         * Converts this MsgLiquidate to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgLiquidate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgLiquidate.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgLiquidate
      })()

      v1.MsgSupplyCollateral = (function () {
        /**
         * Properties of a MsgSupplyCollateral.
         * @memberof umee.leverage.v1
         * @interface IMsgSupplyCollateral
         * @property {string|null} [supplier] MsgSupplyCollateral supplier
         * @property {cosmos.base.v1beta1.ICoin|null} [asset] MsgSupplyCollateral asset
         */

        /**
         * Constructs a new MsgSupplyCollateral.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgSupplyCollateral.
         * @implements IMsgSupplyCollateral
         * @constructor
         * @param {umee.leverage.v1.IMsgSupplyCollateral=} [properties] Properties to set
         */
        function MsgSupplyCollateral(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgSupplyCollateral supplier.
         * @member {string} supplier
         * @memberof umee.leverage.v1.MsgSupplyCollateral
         * @instance
         */
        MsgSupplyCollateral.prototype.supplier = ''

        /**
         * MsgSupplyCollateral asset.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} asset
         * @memberof umee.leverage.v1.MsgSupplyCollateral
         * @instance
         */
        MsgSupplyCollateral.prototype.asset = null

        /**
         * Creates a new MsgSupplyCollateral instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgSupplyCollateral
         * @static
         * @param {umee.leverage.v1.IMsgSupplyCollateral=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgSupplyCollateral} MsgSupplyCollateral instance
         */
        MsgSupplyCollateral.create = function create(properties) {
          return new MsgSupplyCollateral(properties)
        }

        /**
         * Encodes the specified MsgSupplyCollateral message. Does not implicitly {@link umee.leverage.v1.MsgSupplyCollateral.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgSupplyCollateral
         * @static
         * @param {umee.leverage.v1.IMsgSupplyCollateral} message MsgSupplyCollateral message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgSupplyCollateral.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.supplier != null && Object.hasOwnProperty.call(message, 'supplier'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.supplier)
          if (message.asset != null && Object.hasOwnProperty.call(message, 'asset'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.asset,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgSupplyCollateral message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgSupplyCollateral.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgSupplyCollateral
         * @static
         * @param {umee.leverage.v1.IMsgSupplyCollateral} message MsgSupplyCollateral message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgSupplyCollateral.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgSupplyCollateral message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgSupplyCollateral
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgSupplyCollateral} MsgSupplyCollateral
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgSupplyCollateral.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgSupplyCollateral()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.supplier = reader.string()
                break
              case 2:
                message.asset = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgSupplyCollateral message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgSupplyCollateral
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgSupplyCollateral} MsgSupplyCollateral
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgSupplyCollateral.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgSupplyCollateral message.
         * @function verify
         * @memberof umee.leverage.v1.MsgSupplyCollateral
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgSupplyCollateral.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.supplier != null && message.hasOwnProperty('supplier'))
            if (!$util.isString(message.supplier)) return 'supplier: string expected'
          if (message.asset != null && message.hasOwnProperty('asset')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.asset)
            if (error) return 'asset.' + error
          }
          return null
        }

        /**
         * Creates a MsgSupplyCollateral message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgSupplyCollateral
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgSupplyCollateral} MsgSupplyCollateral
         */
        MsgSupplyCollateral.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgSupplyCollateral) return object
          let message = new $root.umee.leverage.v1.MsgSupplyCollateral()
          if (object.supplier != null) message.supplier = String(object.supplier)
          if (object.asset != null) {
            if (typeof object.asset !== 'object')
              throw TypeError('.umee.leverage.v1.MsgSupplyCollateral.asset: object expected')
            message.asset = $root.cosmos.base.v1beta1.Coin.fromObject(object.asset)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgSupplyCollateral message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgSupplyCollateral
         * @static
         * @param {umee.leverage.v1.MsgSupplyCollateral} message MsgSupplyCollateral
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgSupplyCollateral.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.supplier = ''
            object.asset = null
          }
          if (message.supplier != null && message.hasOwnProperty('supplier')) object.supplier = message.supplier
          if (message.asset != null && message.hasOwnProperty('asset'))
            object.asset = $root.cosmos.base.v1beta1.Coin.toObject(message.asset, options)
          return object
        }

        /**
         * Converts this MsgSupplyCollateral to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgSupplyCollateral
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgSupplyCollateral.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgSupplyCollateral
      })()

      v1.MsgSupplyResponse = (function () {
        /**
         * Properties of a MsgSupplyResponse.
         * @memberof umee.leverage.v1
         * @interface IMsgSupplyResponse
         * @property {cosmos.base.v1beta1.ICoin|null} [received] MsgSupplyResponse received
         */

        /**
         * Constructs a new MsgSupplyResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgSupplyResponse.
         * @implements IMsgSupplyResponse
         * @constructor
         * @param {umee.leverage.v1.IMsgSupplyResponse=} [properties] Properties to set
         */
        function MsgSupplyResponse(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgSupplyResponse received.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} received
         * @memberof umee.leverage.v1.MsgSupplyResponse
         * @instance
         */
        MsgSupplyResponse.prototype.received = null

        /**
         * Creates a new MsgSupplyResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgSupplyResponse
         * @static
         * @param {umee.leverage.v1.IMsgSupplyResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgSupplyResponse} MsgSupplyResponse instance
         */
        MsgSupplyResponse.create = function create(properties) {
          return new MsgSupplyResponse(properties)
        }

        /**
         * Encodes the specified MsgSupplyResponse message. Does not implicitly {@link umee.leverage.v1.MsgSupplyResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgSupplyResponse
         * @static
         * @param {umee.leverage.v1.IMsgSupplyResponse} message MsgSupplyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgSupplyResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.received != null && Object.hasOwnProperty.call(message, 'received'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.received,
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgSupplyResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgSupplyResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgSupplyResponse
         * @static
         * @param {umee.leverage.v1.IMsgSupplyResponse} message MsgSupplyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgSupplyResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgSupplyResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgSupplyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgSupplyResponse} MsgSupplyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgSupplyResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgSupplyResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.received = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgSupplyResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgSupplyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgSupplyResponse} MsgSupplyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgSupplyResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgSupplyResponse message.
         * @function verify
         * @memberof umee.leverage.v1.MsgSupplyResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgSupplyResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.received != null && message.hasOwnProperty('received')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.received)
            if (error) return 'received.' + error
          }
          return null
        }

        /**
         * Creates a MsgSupplyResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgSupplyResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgSupplyResponse} MsgSupplyResponse
         */
        MsgSupplyResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgSupplyResponse) return object
          let message = new $root.umee.leverage.v1.MsgSupplyResponse()
          if (object.received != null) {
            if (typeof object.received !== 'object')
              throw TypeError('.umee.leverage.v1.MsgSupplyResponse.received: object expected')
            message.received = $root.cosmos.base.v1beta1.Coin.fromObject(object.received)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgSupplyResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgSupplyResponse
         * @static
         * @param {umee.leverage.v1.MsgSupplyResponse} message MsgSupplyResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgSupplyResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) object.received = null
          if (message.received != null && message.hasOwnProperty('received'))
            object.received = $root.cosmos.base.v1beta1.Coin.toObject(message.received, options)
          return object
        }

        /**
         * Converts this MsgSupplyResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgSupplyResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgSupplyResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgSupplyResponse
      })()

      v1.MsgWithdrawResponse = (function () {
        /**
         * Properties of a MsgWithdrawResponse.
         * @memberof umee.leverage.v1
         * @interface IMsgWithdrawResponse
         * @property {cosmos.base.v1beta1.ICoin|null} [received] MsgWithdrawResponse received
         */

        /**
         * Constructs a new MsgWithdrawResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgWithdrawResponse.
         * @implements IMsgWithdrawResponse
         * @constructor
         * @param {umee.leverage.v1.IMsgWithdrawResponse=} [properties] Properties to set
         */
        function MsgWithdrawResponse(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgWithdrawResponse received.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} received
         * @memberof umee.leverage.v1.MsgWithdrawResponse
         * @instance
         */
        MsgWithdrawResponse.prototype.received = null

        /**
         * Creates a new MsgWithdrawResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgWithdrawResponse
         * @static
         * @param {umee.leverage.v1.IMsgWithdrawResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgWithdrawResponse} MsgWithdrawResponse instance
         */
        MsgWithdrawResponse.create = function create(properties) {
          return new MsgWithdrawResponse(properties)
        }

        /**
         * Encodes the specified MsgWithdrawResponse message. Does not implicitly {@link umee.leverage.v1.MsgWithdrawResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgWithdrawResponse
         * @static
         * @param {umee.leverage.v1.IMsgWithdrawResponse} message MsgWithdrawResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgWithdrawResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.received != null && Object.hasOwnProperty.call(message, 'received'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.received,
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgWithdrawResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgWithdrawResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgWithdrawResponse
         * @static
         * @param {umee.leverage.v1.IMsgWithdrawResponse} message MsgWithdrawResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgWithdrawResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgWithdrawResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgWithdrawResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgWithdrawResponse} MsgWithdrawResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgWithdrawResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgWithdrawResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.received = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgWithdrawResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgWithdrawResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgWithdrawResponse} MsgWithdrawResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgWithdrawResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgWithdrawResponse message.
         * @function verify
         * @memberof umee.leverage.v1.MsgWithdrawResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgWithdrawResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.received != null && message.hasOwnProperty('received')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.received)
            if (error) return 'received.' + error
          }
          return null
        }

        /**
         * Creates a MsgWithdrawResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgWithdrawResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgWithdrawResponse} MsgWithdrawResponse
         */
        MsgWithdrawResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgWithdrawResponse) return object
          let message = new $root.umee.leverage.v1.MsgWithdrawResponse()
          if (object.received != null) {
            if (typeof object.received !== 'object')
              throw TypeError('.umee.leverage.v1.MsgWithdrawResponse.received: object expected')
            message.received = $root.cosmos.base.v1beta1.Coin.fromObject(object.received)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgWithdrawResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgWithdrawResponse
         * @static
         * @param {umee.leverage.v1.MsgWithdrawResponse} message MsgWithdrawResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgWithdrawResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) object.received = null
          if (message.received != null && message.hasOwnProperty('received'))
            object.received = $root.cosmos.base.v1beta1.Coin.toObject(message.received, options)
          return object
        }

        /**
         * Converts this MsgWithdrawResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgWithdrawResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgWithdrawResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgWithdrawResponse
      })()

      v1.MsgCollateralizeResponse = (function () {
        /**
         * Properties of a MsgCollateralizeResponse.
         * @memberof umee.leverage.v1
         * @interface IMsgCollateralizeResponse
         */

        /**
         * Constructs a new MsgCollateralizeResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgCollateralizeResponse.
         * @implements IMsgCollateralizeResponse
         * @constructor
         * @param {umee.leverage.v1.IMsgCollateralizeResponse=} [properties] Properties to set
         */
        function MsgCollateralizeResponse(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Creates a new MsgCollateralizeResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgCollateralizeResponse
         * @static
         * @param {umee.leverage.v1.IMsgCollateralizeResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgCollateralizeResponse} MsgCollateralizeResponse instance
         */
        MsgCollateralizeResponse.create = function create(properties) {
          return new MsgCollateralizeResponse(properties)
        }

        /**
         * Encodes the specified MsgCollateralizeResponse message. Does not implicitly {@link umee.leverage.v1.MsgCollateralizeResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgCollateralizeResponse
         * @static
         * @param {umee.leverage.v1.IMsgCollateralizeResponse} message MsgCollateralizeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgCollateralizeResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          return writer
        }

        /**
         * Encodes the specified MsgCollateralizeResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgCollateralizeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgCollateralizeResponse
         * @static
         * @param {umee.leverage.v1.IMsgCollateralizeResponse} message MsgCollateralizeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgCollateralizeResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgCollateralizeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgCollateralizeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgCollateralizeResponse} MsgCollateralizeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgCollateralizeResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgCollateralizeResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgCollateralizeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgCollateralizeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgCollateralizeResponse} MsgCollateralizeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgCollateralizeResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgCollateralizeResponse message.
         * @function verify
         * @memberof umee.leverage.v1.MsgCollateralizeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgCollateralizeResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          return null
        }

        /**
         * Creates a MsgCollateralizeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgCollateralizeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgCollateralizeResponse} MsgCollateralizeResponse
         */
        MsgCollateralizeResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgCollateralizeResponse) return object
          return new $root.umee.leverage.v1.MsgCollateralizeResponse()
        }

        /**
         * Creates a plain object from a MsgCollateralizeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgCollateralizeResponse
         * @static
         * @param {umee.leverage.v1.MsgCollateralizeResponse} message MsgCollateralizeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgCollateralizeResponse.toObject = function toObject() {
          return {}
        }

        /**
         * Converts this MsgCollateralizeResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgCollateralizeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgCollateralizeResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgCollateralizeResponse
      })()

      v1.MsgDecollateralizeResponse = (function () {
        /**
         * Properties of a MsgDecollateralizeResponse.
         * @memberof umee.leverage.v1
         * @interface IMsgDecollateralizeResponse
         */

        /**
         * Constructs a new MsgDecollateralizeResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgDecollateralizeResponse.
         * @implements IMsgDecollateralizeResponse
         * @constructor
         * @param {umee.leverage.v1.IMsgDecollateralizeResponse=} [properties] Properties to set
         */
        function MsgDecollateralizeResponse(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Creates a new MsgDecollateralizeResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgDecollateralizeResponse
         * @static
         * @param {umee.leverage.v1.IMsgDecollateralizeResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgDecollateralizeResponse} MsgDecollateralizeResponse instance
         */
        MsgDecollateralizeResponse.create = function create(properties) {
          return new MsgDecollateralizeResponse(properties)
        }

        /**
         * Encodes the specified MsgDecollateralizeResponse message. Does not implicitly {@link umee.leverage.v1.MsgDecollateralizeResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgDecollateralizeResponse
         * @static
         * @param {umee.leverage.v1.IMsgDecollateralizeResponse} message MsgDecollateralizeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgDecollateralizeResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          return writer
        }

        /**
         * Encodes the specified MsgDecollateralizeResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgDecollateralizeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgDecollateralizeResponse
         * @static
         * @param {umee.leverage.v1.IMsgDecollateralizeResponse} message MsgDecollateralizeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgDecollateralizeResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgDecollateralizeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgDecollateralizeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgDecollateralizeResponse} MsgDecollateralizeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgDecollateralizeResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgDecollateralizeResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgDecollateralizeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgDecollateralizeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgDecollateralizeResponse} MsgDecollateralizeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgDecollateralizeResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgDecollateralizeResponse message.
         * @function verify
         * @memberof umee.leverage.v1.MsgDecollateralizeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgDecollateralizeResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          return null
        }

        /**
         * Creates a MsgDecollateralizeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgDecollateralizeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgDecollateralizeResponse} MsgDecollateralizeResponse
         */
        MsgDecollateralizeResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgDecollateralizeResponse) return object
          return new $root.umee.leverage.v1.MsgDecollateralizeResponse()
        }

        /**
         * Creates a plain object from a MsgDecollateralizeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgDecollateralizeResponse
         * @static
         * @param {umee.leverage.v1.MsgDecollateralizeResponse} message MsgDecollateralizeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgDecollateralizeResponse.toObject = function toObject() {
          return {}
        }

        /**
         * Converts this MsgDecollateralizeResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgDecollateralizeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgDecollateralizeResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgDecollateralizeResponse
      })()

      v1.MsgBorrowResponse = (function () {
        /**
         * Properties of a MsgBorrowResponse.
         * @memberof umee.leverage.v1
         * @interface IMsgBorrowResponse
         */

        /**
         * Constructs a new MsgBorrowResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgBorrowResponse.
         * @implements IMsgBorrowResponse
         * @constructor
         * @param {umee.leverage.v1.IMsgBorrowResponse=} [properties] Properties to set
         */
        function MsgBorrowResponse(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Creates a new MsgBorrowResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgBorrowResponse
         * @static
         * @param {umee.leverage.v1.IMsgBorrowResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgBorrowResponse} MsgBorrowResponse instance
         */
        MsgBorrowResponse.create = function create(properties) {
          return new MsgBorrowResponse(properties)
        }

        /**
         * Encodes the specified MsgBorrowResponse message. Does not implicitly {@link umee.leverage.v1.MsgBorrowResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgBorrowResponse
         * @static
         * @param {umee.leverage.v1.IMsgBorrowResponse} message MsgBorrowResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgBorrowResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          return writer
        }

        /**
         * Encodes the specified MsgBorrowResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgBorrowResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgBorrowResponse
         * @static
         * @param {umee.leverage.v1.IMsgBorrowResponse} message MsgBorrowResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgBorrowResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgBorrowResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgBorrowResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgBorrowResponse} MsgBorrowResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgBorrowResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgBorrowResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgBorrowResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgBorrowResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgBorrowResponse} MsgBorrowResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgBorrowResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgBorrowResponse message.
         * @function verify
         * @memberof umee.leverage.v1.MsgBorrowResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgBorrowResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          return null
        }

        /**
         * Creates a MsgBorrowResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgBorrowResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgBorrowResponse} MsgBorrowResponse
         */
        MsgBorrowResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgBorrowResponse) return object
          return new $root.umee.leverage.v1.MsgBorrowResponse()
        }

        /**
         * Creates a plain object from a MsgBorrowResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgBorrowResponse
         * @static
         * @param {umee.leverage.v1.MsgBorrowResponse} message MsgBorrowResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgBorrowResponse.toObject = function toObject() {
          return {}
        }

        /**
         * Converts this MsgBorrowResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgBorrowResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgBorrowResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgBorrowResponse
      })()

      v1.MsgRepayResponse = (function () {
        /**
         * Properties of a MsgRepayResponse.
         * @memberof umee.leverage.v1
         * @interface IMsgRepayResponse
         * @property {cosmos.base.v1beta1.ICoin|null} [repaid] MsgRepayResponse repaid
         */

        /**
         * Constructs a new MsgRepayResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgRepayResponse.
         * @implements IMsgRepayResponse
         * @constructor
         * @param {umee.leverage.v1.IMsgRepayResponse=} [properties] Properties to set
         */
        function MsgRepayResponse(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgRepayResponse repaid.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} repaid
         * @memberof umee.leverage.v1.MsgRepayResponse
         * @instance
         */
        MsgRepayResponse.prototype.repaid = null

        /**
         * Creates a new MsgRepayResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgRepayResponse
         * @static
         * @param {umee.leverage.v1.IMsgRepayResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgRepayResponse} MsgRepayResponse instance
         */
        MsgRepayResponse.create = function create(properties) {
          return new MsgRepayResponse(properties)
        }

        /**
         * Encodes the specified MsgRepayResponse message. Does not implicitly {@link umee.leverage.v1.MsgRepayResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgRepayResponse
         * @static
         * @param {umee.leverage.v1.IMsgRepayResponse} message MsgRepayResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgRepayResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.repaid != null && Object.hasOwnProperty.call(message, 'repaid'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.repaid,
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgRepayResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgRepayResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgRepayResponse
         * @static
         * @param {umee.leverage.v1.IMsgRepayResponse} message MsgRepayResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgRepayResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgRepayResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgRepayResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgRepayResponse} MsgRepayResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgRepayResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgRepayResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.repaid = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgRepayResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgRepayResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgRepayResponse} MsgRepayResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgRepayResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgRepayResponse message.
         * @function verify
         * @memberof umee.leverage.v1.MsgRepayResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgRepayResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.repaid != null && message.hasOwnProperty('repaid')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.repaid)
            if (error) return 'repaid.' + error
          }
          return null
        }

        /**
         * Creates a MsgRepayResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgRepayResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgRepayResponse} MsgRepayResponse
         */
        MsgRepayResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgRepayResponse) return object
          let message = new $root.umee.leverage.v1.MsgRepayResponse()
          if (object.repaid != null) {
            if (typeof object.repaid !== 'object')
              throw TypeError('.umee.leverage.v1.MsgRepayResponse.repaid: object expected')
            message.repaid = $root.cosmos.base.v1beta1.Coin.fromObject(object.repaid)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgRepayResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgRepayResponse
         * @static
         * @param {umee.leverage.v1.MsgRepayResponse} message MsgRepayResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgRepayResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) object.repaid = null
          if (message.repaid != null && message.hasOwnProperty('repaid'))
            object.repaid = $root.cosmos.base.v1beta1.Coin.toObject(message.repaid, options)
          return object
        }

        /**
         * Converts this MsgRepayResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgRepayResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgRepayResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgRepayResponse
      })()

      v1.MsgLiquidateResponse = (function () {
        /**
         * Properties of a MsgLiquidateResponse.
         * @memberof umee.leverage.v1
         * @interface IMsgLiquidateResponse
         * @property {cosmos.base.v1beta1.ICoin|null} [repaid] MsgLiquidateResponse repaid
         * @property {cosmos.base.v1beta1.ICoin|null} [collateral] MsgLiquidateResponse collateral
         * @property {cosmos.base.v1beta1.ICoin|null} [reward] MsgLiquidateResponse reward
         */

        /**
         * Constructs a new MsgLiquidateResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgLiquidateResponse.
         * @implements IMsgLiquidateResponse
         * @constructor
         * @param {umee.leverage.v1.IMsgLiquidateResponse=} [properties] Properties to set
         */
        function MsgLiquidateResponse(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgLiquidateResponse repaid.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} repaid
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @instance
         */
        MsgLiquidateResponse.prototype.repaid = null

        /**
         * MsgLiquidateResponse collateral.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} collateral
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @instance
         */
        MsgLiquidateResponse.prototype.collateral = null

        /**
         * MsgLiquidateResponse reward.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} reward
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @instance
         */
        MsgLiquidateResponse.prototype.reward = null

        /**
         * Creates a new MsgLiquidateResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @static
         * @param {umee.leverage.v1.IMsgLiquidateResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgLiquidateResponse} MsgLiquidateResponse instance
         */
        MsgLiquidateResponse.create = function create(properties) {
          return new MsgLiquidateResponse(properties)
        }

        /**
         * Encodes the specified MsgLiquidateResponse message. Does not implicitly {@link umee.leverage.v1.MsgLiquidateResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @static
         * @param {umee.leverage.v1.IMsgLiquidateResponse} message MsgLiquidateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgLiquidateResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.repaid != null && Object.hasOwnProperty.call(message, 'repaid'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.repaid,
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
            ).ldelim()
          if (message.collateral != null && Object.hasOwnProperty.call(message, 'collateral'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.collateral,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          if (message.reward != null && Object.hasOwnProperty.call(message, 'reward'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.reward,
              writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgLiquidateResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgLiquidateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @static
         * @param {umee.leverage.v1.IMsgLiquidateResponse} message MsgLiquidateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgLiquidateResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgLiquidateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgLiquidateResponse} MsgLiquidateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgLiquidateResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgLiquidateResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.repaid = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              case 2:
                message.collateral = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              case 3:
                message.reward = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgLiquidateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgLiquidateResponse} MsgLiquidateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgLiquidateResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgLiquidateResponse message.
         * @function verify
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgLiquidateResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.repaid != null && message.hasOwnProperty('repaid')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.repaid)
            if (error) return 'repaid.' + error
          }
          if (message.collateral != null && message.hasOwnProperty('collateral')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.collateral)
            if (error) return 'collateral.' + error
          }
          if (message.reward != null && message.hasOwnProperty('reward')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.reward)
            if (error) return 'reward.' + error
          }
          return null
        }

        /**
         * Creates a MsgLiquidateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgLiquidateResponse} MsgLiquidateResponse
         */
        MsgLiquidateResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgLiquidateResponse) return object
          let message = new $root.umee.leverage.v1.MsgLiquidateResponse()
          if (object.repaid != null) {
            if (typeof object.repaid !== 'object')
              throw TypeError('.umee.leverage.v1.MsgLiquidateResponse.repaid: object expected')
            message.repaid = $root.cosmos.base.v1beta1.Coin.fromObject(object.repaid)
          }
          if (object.collateral != null) {
            if (typeof object.collateral !== 'object')
              throw TypeError('.umee.leverage.v1.MsgLiquidateResponse.collateral: object expected')
            message.collateral = $root.cosmos.base.v1beta1.Coin.fromObject(object.collateral)
          }
          if (object.reward != null) {
            if (typeof object.reward !== 'object')
              throw TypeError('.umee.leverage.v1.MsgLiquidateResponse.reward: object expected')
            message.reward = $root.cosmos.base.v1beta1.Coin.fromObject(object.reward)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgLiquidateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @static
         * @param {umee.leverage.v1.MsgLiquidateResponse} message MsgLiquidateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgLiquidateResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.repaid = null
            object.collateral = null
            object.reward = null
          }
          if (message.repaid != null && message.hasOwnProperty('repaid'))
            object.repaid = $root.cosmos.base.v1beta1.Coin.toObject(message.repaid, options)
          if (message.collateral != null && message.hasOwnProperty('collateral'))
            object.collateral = $root.cosmos.base.v1beta1.Coin.toObject(message.collateral, options)
          if (message.reward != null && message.hasOwnProperty('reward'))
            object.reward = $root.cosmos.base.v1beta1.Coin.toObject(message.reward, options)
          return object
        }

        /**
         * Converts this MsgLiquidateResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgLiquidateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgLiquidateResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgLiquidateResponse
      })()

      v1.MsgSupplyCollateralResponse = (function () {
        /**
         * Properties of a MsgSupplyCollateralResponse.
         * @memberof umee.leverage.v1
         * @interface IMsgSupplyCollateralResponse
         * @property {cosmos.base.v1beta1.ICoin|null} [collateralized] MsgSupplyCollateralResponse collateralized
         */

        /**
         * Constructs a new MsgSupplyCollateralResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a MsgSupplyCollateralResponse.
         * @implements IMsgSupplyCollateralResponse
         * @constructor
         * @param {umee.leverage.v1.IMsgSupplyCollateralResponse=} [properties] Properties to set
         */
        function MsgSupplyCollateralResponse(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * MsgSupplyCollateralResponse collateralized.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} collateralized
         * @memberof umee.leverage.v1.MsgSupplyCollateralResponse
         * @instance
         */
        MsgSupplyCollateralResponse.prototype.collateralized = null

        /**
         * Creates a new MsgSupplyCollateralResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.MsgSupplyCollateralResponse
         * @static
         * @param {umee.leverage.v1.IMsgSupplyCollateralResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.MsgSupplyCollateralResponse} MsgSupplyCollateralResponse instance
         */
        MsgSupplyCollateralResponse.create = function create(properties) {
          return new MsgSupplyCollateralResponse(properties)
        }

        /**
         * Encodes the specified MsgSupplyCollateralResponse message. Does not implicitly {@link umee.leverage.v1.MsgSupplyCollateralResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.MsgSupplyCollateralResponse
         * @static
         * @param {umee.leverage.v1.IMsgSupplyCollateralResponse} message MsgSupplyCollateralResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgSupplyCollateralResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.collateralized != null && Object.hasOwnProperty.call(message, 'collateralized'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.collateralized,
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified MsgSupplyCollateralResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.MsgSupplyCollateralResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.MsgSupplyCollateralResponse
         * @static
         * @param {umee.leverage.v1.IMsgSupplyCollateralResponse} message MsgSupplyCollateralResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MsgSupplyCollateralResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a MsgSupplyCollateralResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.MsgSupplyCollateralResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.MsgSupplyCollateralResponse} MsgSupplyCollateralResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgSupplyCollateralResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.MsgSupplyCollateralResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.collateralized = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a MsgSupplyCollateralResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.MsgSupplyCollateralResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.MsgSupplyCollateralResponse} MsgSupplyCollateralResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MsgSupplyCollateralResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a MsgSupplyCollateralResponse message.
         * @function verify
         * @memberof umee.leverage.v1.MsgSupplyCollateralResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MsgSupplyCollateralResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.collateralized != null && message.hasOwnProperty('collateralized')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.collateralized)
            if (error) return 'collateralized.' + error
          }
          return null
        }

        /**
         * Creates a MsgSupplyCollateralResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.MsgSupplyCollateralResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.MsgSupplyCollateralResponse} MsgSupplyCollateralResponse
         */
        MsgSupplyCollateralResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.MsgSupplyCollateralResponse) return object
          let message = new $root.umee.leverage.v1.MsgSupplyCollateralResponse()
          if (object.collateralized != null) {
            if (typeof object.collateralized !== 'object')
              throw TypeError('.umee.leverage.v1.MsgSupplyCollateralResponse.collateralized: object expected')
            message.collateralized = $root.cosmos.base.v1beta1.Coin.fromObject(object.collateralized)
          }
          return message
        }

        /**
         * Creates a plain object from a MsgSupplyCollateralResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.MsgSupplyCollateralResponse
         * @static
         * @param {umee.leverage.v1.MsgSupplyCollateralResponse} message MsgSupplyCollateralResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MsgSupplyCollateralResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) object.collateralized = null
          if (message.collateralized != null && message.hasOwnProperty('collateralized'))
            object.collateralized = $root.cosmos.base.v1beta1.Coin.toObject(message.collateralized, options)
          return object
        }

        /**
         * Converts this MsgSupplyCollateralResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.MsgSupplyCollateralResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MsgSupplyCollateralResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return MsgSupplyCollateralResponse
      })()

      v1.UpdateRegistryProposal = (function () {
        /**
         * Properties of an UpdateRegistryProposal.
         * @memberof umee.leverage.v1
         * @interface IUpdateRegistryProposal
         * @property {string|null} [title] UpdateRegistryProposal title
         * @property {string|null} [description] UpdateRegistryProposal description
         * @property {Array.<umee.leverage.v1.IToken>|null} [registry] UpdateRegistryProposal registry
         */

        /**
         * Constructs a new UpdateRegistryProposal.
         * @memberof umee.leverage.v1
         * @classdesc Represents an UpdateRegistryProposal.
         * @implements IUpdateRegistryProposal
         * @constructor
         * @param {umee.leverage.v1.IUpdateRegistryProposal=} [properties] Properties to set
         */
        function UpdateRegistryProposal(properties) {
          this.registry = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * UpdateRegistryProposal title.
         * @member {string} title
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @instance
         */
        UpdateRegistryProposal.prototype.title = ''

        /**
         * UpdateRegistryProposal description.
         * @member {string} description
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @instance
         */
        UpdateRegistryProposal.prototype.description = ''

        /**
         * UpdateRegistryProposal registry.
         * @member {Array.<umee.leverage.v1.IToken>} registry
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @instance
         */
        UpdateRegistryProposal.prototype.registry = $util.emptyArray

        /**
         * Creates a new UpdateRegistryProposal instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @static
         * @param {umee.leverage.v1.IUpdateRegistryProposal=} [properties] Properties to set
         * @returns {umee.leverage.v1.UpdateRegistryProposal} UpdateRegistryProposal instance
         */
        UpdateRegistryProposal.create = function create(properties) {
          return new UpdateRegistryProposal(properties)
        }

        /**
         * Encodes the specified UpdateRegistryProposal message. Does not implicitly {@link umee.leverage.v1.UpdateRegistryProposal.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @static
         * @param {umee.leverage.v1.IUpdateRegistryProposal} message UpdateRegistryProposal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateRegistryProposal.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.title != null && Object.hasOwnProperty.call(message, 'title'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.title)
          if (message.description != null && Object.hasOwnProperty.call(message, 'description'))
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.description)
          if (message.registry != null && message.registry.length)
            for (let i = 0; i < message.registry.length; ++i)
              $root.umee.leverage.v1.Token.encode(
                message.registry[i],
                writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
              ).ldelim()
          return writer
        }

        /**
         * Encodes the specified UpdateRegistryProposal message, length delimited. Does not implicitly {@link umee.leverage.v1.UpdateRegistryProposal.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @static
         * @param {umee.leverage.v1.IUpdateRegistryProposal} message UpdateRegistryProposal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateRegistryProposal.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an UpdateRegistryProposal message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.UpdateRegistryProposal} UpdateRegistryProposal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateRegistryProposal.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.UpdateRegistryProposal()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.title = reader.string()
                break
              case 2:
                message.description = reader.string()
                break
              case 3:
                if (!(message.registry && message.registry.length)) message.registry = []
                message.registry.push($root.umee.leverage.v1.Token.decode(reader, reader.uint32()))
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an UpdateRegistryProposal message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.UpdateRegistryProposal} UpdateRegistryProposal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateRegistryProposal.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an UpdateRegistryProposal message.
         * @function verify
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateRegistryProposal.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.title != null && message.hasOwnProperty('title'))
            if (!$util.isString(message.title)) return 'title: string expected'
          if (message.description != null && message.hasOwnProperty('description'))
            if (!$util.isString(message.description)) return 'description: string expected'
          if (message.registry != null && message.hasOwnProperty('registry')) {
            if (!Array.isArray(message.registry)) return 'registry: array expected'
            for (let i = 0; i < message.registry.length; ++i) {
              let error = $root.umee.leverage.v1.Token.verify(message.registry[i])
              if (error) return 'registry.' + error
            }
          }
          return null
        }

        /**
         * Creates an UpdateRegistryProposal message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.UpdateRegistryProposal} UpdateRegistryProposal
         */
        UpdateRegistryProposal.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.UpdateRegistryProposal) return object
          let message = new $root.umee.leverage.v1.UpdateRegistryProposal()
          if (object.title != null) message.title = String(object.title)
          if (object.description != null) message.description = String(object.description)
          if (object.registry) {
            if (!Array.isArray(object.registry))
              throw TypeError('.umee.leverage.v1.UpdateRegistryProposal.registry: array expected')
            message.registry = []
            for (let i = 0; i < object.registry.length; ++i) {
              if (typeof object.registry[i] !== 'object')
                throw TypeError('.umee.leverage.v1.UpdateRegistryProposal.registry: object expected')
              message.registry[i] = $root.umee.leverage.v1.Token.fromObject(object.registry[i])
            }
          }
          return message
        }

        /**
         * Creates a plain object from an UpdateRegistryProposal message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @static
         * @param {umee.leverage.v1.UpdateRegistryProposal} message UpdateRegistryProposal
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateRegistryProposal.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.registry = []
          if (options.defaults) {
            object.title = ''
            object.description = ''
          }
          if (message.title != null && message.hasOwnProperty('title')) object.title = message.title
          if (message.description != null && message.hasOwnProperty('description'))
            object.description = message.description
          if (message.registry && message.registry.length) {
            object.registry = []
            for (let j = 0; j < message.registry.length; ++j)
              object.registry[j] = $root.umee.leverage.v1.Token.toObject(message.registry[j], options)
          }
          return object
        }

        /**
         * Converts this UpdateRegistryProposal to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.UpdateRegistryProposal
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateRegistryProposal.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return UpdateRegistryProposal
      })()

      v1.EventSupply = (function () {
        /**
         * Properties of an EventSupply.
         * @memberof umee.leverage.v1
         * @interface IEventSupply
         * @property {string|null} [supplier] EventSupply supplier
         * @property {cosmos.base.v1beta1.ICoin|null} [asset] EventSupply asset
         * @property {cosmos.base.v1beta1.ICoin|null} [utoken] EventSupply utoken
         */

        /**
         * Constructs a new EventSupply.
         * @memberof umee.leverage.v1
         * @classdesc Represents an EventSupply.
         * @implements IEventSupply
         * @constructor
         * @param {umee.leverage.v1.IEventSupply=} [properties] Properties to set
         */
        function EventSupply(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * EventSupply supplier.
         * @member {string} supplier
         * @memberof umee.leverage.v1.EventSupply
         * @instance
         */
        EventSupply.prototype.supplier = ''

        /**
         * EventSupply asset.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} asset
         * @memberof umee.leverage.v1.EventSupply
         * @instance
         */
        EventSupply.prototype.asset = null

        /**
         * EventSupply utoken.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} utoken
         * @memberof umee.leverage.v1.EventSupply
         * @instance
         */
        EventSupply.prototype.utoken = null

        /**
         * Creates a new EventSupply instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.EventSupply
         * @static
         * @param {umee.leverage.v1.IEventSupply=} [properties] Properties to set
         * @returns {umee.leverage.v1.EventSupply} EventSupply instance
         */
        EventSupply.create = function create(properties) {
          return new EventSupply(properties)
        }

        /**
         * Encodes the specified EventSupply message. Does not implicitly {@link umee.leverage.v1.EventSupply.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.EventSupply
         * @static
         * @param {umee.leverage.v1.IEventSupply} message EventSupply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventSupply.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.supplier != null && Object.hasOwnProperty.call(message, 'supplier'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.supplier)
          if (message.asset != null && Object.hasOwnProperty.call(message, 'asset'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.asset,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          if (message.utoken != null && Object.hasOwnProperty.call(message, 'utoken'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.utoken,
              writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified EventSupply message, length delimited. Does not implicitly {@link umee.leverage.v1.EventSupply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.EventSupply
         * @static
         * @param {umee.leverage.v1.IEventSupply} message EventSupply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventSupply.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an EventSupply message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.EventSupply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.EventSupply} EventSupply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventSupply.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.EventSupply()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.supplier = reader.string()
                break
              case 2:
                message.asset = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              case 3:
                message.utoken = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an EventSupply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.EventSupply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.EventSupply} EventSupply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventSupply.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an EventSupply message.
         * @function verify
         * @memberof umee.leverage.v1.EventSupply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventSupply.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.supplier != null && message.hasOwnProperty('supplier'))
            if (!$util.isString(message.supplier)) return 'supplier: string expected'
          if (message.asset != null && message.hasOwnProperty('asset')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.asset)
            if (error) return 'asset.' + error
          }
          if (message.utoken != null && message.hasOwnProperty('utoken')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.utoken)
            if (error) return 'utoken.' + error
          }
          return null
        }

        /**
         * Creates an EventSupply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.EventSupply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.EventSupply} EventSupply
         */
        EventSupply.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.EventSupply) return object
          let message = new $root.umee.leverage.v1.EventSupply()
          if (object.supplier != null) message.supplier = String(object.supplier)
          if (object.asset != null) {
            if (typeof object.asset !== 'object')
              throw TypeError('.umee.leverage.v1.EventSupply.asset: object expected')
            message.asset = $root.cosmos.base.v1beta1.Coin.fromObject(object.asset)
          }
          if (object.utoken != null) {
            if (typeof object.utoken !== 'object')
              throw TypeError('.umee.leverage.v1.EventSupply.utoken: object expected')
            message.utoken = $root.cosmos.base.v1beta1.Coin.fromObject(object.utoken)
          }
          return message
        }

        /**
         * Creates a plain object from an EventSupply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.EventSupply
         * @static
         * @param {umee.leverage.v1.EventSupply} message EventSupply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventSupply.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.supplier = ''
            object.asset = null
            object.utoken = null
          }
          if (message.supplier != null && message.hasOwnProperty('supplier')) object.supplier = message.supplier
          if (message.asset != null && message.hasOwnProperty('asset'))
            object.asset = $root.cosmos.base.v1beta1.Coin.toObject(message.asset, options)
          if (message.utoken != null && message.hasOwnProperty('utoken'))
            object.utoken = $root.cosmos.base.v1beta1.Coin.toObject(message.utoken, options)
          return object
        }

        /**
         * Converts this EventSupply to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.EventSupply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventSupply.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return EventSupply
      })()

      v1.EventWithdraw = (function () {
        /**
         * Properties of an EventWithdraw.
         * @memberof umee.leverage.v1
         * @interface IEventWithdraw
         * @property {string|null} [supplier] EventWithdraw supplier
         * @property {cosmos.base.v1beta1.ICoin|null} [utoken] EventWithdraw utoken
         * @property {cosmos.base.v1beta1.ICoin|null} [asset] EventWithdraw asset
         */

        /**
         * Constructs a new EventWithdraw.
         * @memberof umee.leverage.v1
         * @classdesc Represents an EventWithdraw.
         * @implements IEventWithdraw
         * @constructor
         * @param {umee.leverage.v1.IEventWithdraw=} [properties] Properties to set
         */
        function EventWithdraw(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * EventWithdraw supplier.
         * @member {string} supplier
         * @memberof umee.leverage.v1.EventWithdraw
         * @instance
         */
        EventWithdraw.prototype.supplier = ''

        /**
         * EventWithdraw utoken.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} utoken
         * @memberof umee.leverage.v1.EventWithdraw
         * @instance
         */
        EventWithdraw.prototype.utoken = null

        /**
         * EventWithdraw asset.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} asset
         * @memberof umee.leverage.v1.EventWithdraw
         * @instance
         */
        EventWithdraw.prototype.asset = null

        /**
         * Creates a new EventWithdraw instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.EventWithdraw
         * @static
         * @param {umee.leverage.v1.IEventWithdraw=} [properties] Properties to set
         * @returns {umee.leverage.v1.EventWithdraw} EventWithdraw instance
         */
        EventWithdraw.create = function create(properties) {
          return new EventWithdraw(properties)
        }

        /**
         * Encodes the specified EventWithdraw message. Does not implicitly {@link umee.leverage.v1.EventWithdraw.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.EventWithdraw
         * @static
         * @param {umee.leverage.v1.IEventWithdraw} message EventWithdraw message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventWithdraw.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.supplier != null && Object.hasOwnProperty.call(message, 'supplier'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.supplier)
          if (message.utoken != null && Object.hasOwnProperty.call(message, 'utoken'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.utoken,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          if (message.asset != null && Object.hasOwnProperty.call(message, 'asset'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.asset,
              writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified EventWithdraw message, length delimited. Does not implicitly {@link umee.leverage.v1.EventWithdraw.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.EventWithdraw
         * @static
         * @param {umee.leverage.v1.IEventWithdraw} message EventWithdraw message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventWithdraw.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an EventWithdraw message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.EventWithdraw
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.EventWithdraw} EventWithdraw
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventWithdraw.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.EventWithdraw()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.supplier = reader.string()
                break
              case 2:
                message.utoken = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              case 3:
                message.asset = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an EventWithdraw message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.EventWithdraw
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.EventWithdraw} EventWithdraw
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventWithdraw.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an EventWithdraw message.
         * @function verify
         * @memberof umee.leverage.v1.EventWithdraw
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventWithdraw.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.supplier != null && message.hasOwnProperty('supplier'))
            if (!$util.isString(message.supplier)) return 'supplier: string expected'
          if (message.utoken != null && message.hasOwnProperty('utoken')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.utoken)
            if (error) return 'utoken.' + error
          }
          if (message.asset != null && message.hasOwnProperty('asset')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.asset)
            if (error) return 'asset.' + error
          }
          return null
        }

        /**
         * Creates an EventWithdraw message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.EventWithdraw
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.EventWithdraw} EventWithdraw
         */
        EventWithdraw.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.EventWithdraw) return object
          let message = new $root.umee.leverage.v1.EventWithdraw()
          if (object.supplier != null) message.supplier = String(object.supplier)
          if (object.utoken != null) {
            if (typeof object.utoken !== 'object')
              throw TypeError('.umee.leverage.v1.EventWithdraw.utoken: object expected')
            message.utoken = $root.cosmos.base.v1beta1.Coin.fromObject(object.utoken)
          }
          if (object.asset != null) {
            if (typeof object.asset !== 'object')
              throw TypeError('.umee.leverage.v1.EventWithdraw.asset: object expected')
            message.asset = $root.cosmos.base.v1beta1.Coin.fromObject(object.asset)
          }
          return message
        }

        /**
         * Creates a plain object from an EventWithdraw message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.EventWithdraw
         * @static
         * @param {umee.leverage.v1.EventWithdraw} message EventWithdraw
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventWithdraw.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.supplier = ''
            object.utoken = null
            object.asset = null
          }
          if (message.supplier != null && message.hasOwnProperty('supplier')) object.supplier = message.supplier
          if (message.utoken != null && message.hasOwnProperty('utoken'))
            object.utoken = $root.cosmos.base.v1beta1.Coin.toObject(message.utoken, options)
          if (message.asset != null && message.hasOwnProperty('asset'))
            object.asset = $root.cosmos.base.v1beta1.Coin.toObject(message.asset, options)
          return object
        }

        /**
         * Converts this EventWithdraw to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.EventWithdraw
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventWithdraw.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return EventWithdraw
      })()

      v1.EventCollaterize = (function () {
        /**
         * Properties of an EventCollaterize.
         * @memberof umee.leverage.v1
         * @interface IEventCollaterize
         * @property {string|null} [borrower] EventCollaterize borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [utoken] EventCollaterize utoken
         */

        /**
         * Constructs a new EventCollaterize.
         * @memberof umee.leverage.v1
         * @classdesc Represents an EventCollaterize.
         * @implements IEventCollaterize
         * @constructor
         * @param {umee.leverage.v1.IEventCollaterize=} [properties] Properties to set
         */
        function EventCollaterize(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * EventCollaterize borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.EventCollaterize
         * @instance
         */
        EventCollaterize.prototype.borrower = ''

        /**
         * EventCollaterize utoken.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} utoken
         * @memberof umee.leverage.v1.EventCollaterize
         * @instance
         */
        EventCollaterize.prototype.utoken = null

        /**
         * Creates a new EventCollaterize instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.EventCollaterize
         * @static
         * @param {umee.leverage.v1.IEventCollaterize=} [properties] Properties to set
         * @returns {umee.leverage.v1.EventCollaterize} EventCollaterize instance
         */
        EventCollaterize.create = function create(properties) {
          return new EventCollaterize(properties)
        }

        /**
         * Encodes the specified EventCollaterize message. Does not implicitly {@link umee.leverage.v1.EventCollaterize.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.EventCollaterize
         * @static
         * @param {umee.leverage.v1.IEventCollaterize} message EventCollaterize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventCollaterize.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.borrower)
          if (message.utoken != null && Object.hasOwnProperty.call(message, 'utoken'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.utoken,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified EventCollaterize message, length delimited. Does not implicitly {@link umee.leverage.v1.EventCollaterize.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.EventCollaterize
         * @static
         * @param {umee.leverage.v1.IEventCollaterize} message EventCollaterize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventCollaterize.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an EventCollaterize message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.EventCollaterize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.EventCollaterize} EventCollaterize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventCollaterize.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.EventCollaterize()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.borrower = reader.string()
                break
              case 2:
                message.utoken = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an EventCollaterize message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.EventCollaterize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.EventCollaterize} EventCollaterize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventCollaterize.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an EventCollaterize message.
         * @function verify
         * @memberof umee.leverage.v1.EventCollaterize
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventCollaterize.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.utoken != null && message.hasOwnProperty('utoken')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.utoken)
            if (error) return 'utoken.' + error
          }
          return null
        }

        /**
         * Creates an EventCollaterize message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.EventCollaterize
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.EventCollaterize} EventCollaterize
         */
        EventCollaterize.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.EventCollaterize) return object
          let message = new $root.umee.leverage.v1.EventCollaterize()
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.utoken != null) {
            if (typeof object.utoken !== 'object')
              throw TypeError('.umee.leverage.v1.EventCollaterize.utoken: object expected')
            message.utoken = $root.cosmos.base.v1beta1.Coin.fromObject(object.utoken)
          }
          return message
        }

        /**
         * Creates a plain object from an EventCollaterize message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.EventCollaterize
         * @static
         * @param {umee.leverage.v1.EventCollaterize} message EventCollaterize
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventCollaterize.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.borrower = ''
            object.utoken = null
          }
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.utoken != null && message.hasOwnProperty('utoken'))
            object.utoken = $root.cosmos.base.v1beta1.Coin.toObject(message.utoken, options)
          return object
        }

        /**
         * Converts this EventCollaterize to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.EventCollaterize
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventCollaterize.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return EventCollaterize
      })()

      v1.EventDecollaterize = (function () {
        /**
         * Properties of an EventDecollaterize.
         * @memberof umee.leverage.v1
         * @interface IEventDecollaterize
         * @property {string|null} [borrower] EventDecollaterize borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [utoken] EventDecollaterize utoken
         */

        /**
         * Constructs a new EventDecollaterize.
         * @memberof umee.leverage.v1
         * @classdesc Represents an EventDecollaterize.
         * @implements IEventDecollaterize
         * @constructor
         * @param {umee.leverage.v1.IEventDecollaterize=} [properties] Properties to set
         */
        function EventDecollaterize(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * EventDecollaterize borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.EventDecollaterize
         * @instance
         */
        EventDecollaterize.prototype.borrower = ''

        /**
         * EventDecollaterize utoken.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} utoken
         * @memberof umee.leverage.v1.EventDecollaterize
         * @instance
         */
        EventDecollaterize.prototype.utoken = null

        /**
         * Creates a new EventDecollaterize instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.EventDecollaterize
         * @static
         * @param {umee.leverage.v1.IEventDecollaterize=} [properties] Properties to set
         * @returns {umee.leverage.v1.EventDecollaterize} EventDecollaterize instance
         */
        EventDecollaterize.create = function create(properties) {
          return new EventDecollaterize(properties)
        }

        /**
         * Encodes the specified EventDecollaterize message. Does not implicitly {@link umee.leverage.v1.EventDecollaterize.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.EventDecollaterize
         * @static
         * @param {umee.leverage.v1.IEventDecollaterize} message EventDecollaterize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventDecollaterize.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.borrower)
          if (message.utoken != null && Object.hasOwnProperty.call(message, 'utoken'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.utoken,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified EventDecollaterize message, length delimited. Does not implicitly {@link umee.leverage.v1.EventDecollaterize.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.EventDecollaterize
         * @static
         * @param {umee.leverage.v1.IEventDecollaterize} message EventDecollaterize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventDecollaterize.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an EventDecollaterize message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.EventDecollaterize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.EventDecollaterize} EventDecollaterize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventDecollaterize.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.EventDecollaterize()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.borrower = reader.string()
                break
              case 2:
                message.utoken = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an EventDecollaterize message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.EventDecollaterize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.EventDecollaterize} EventDecollaterize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventDecollaterize.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an EventDecollaterize message.
         * @function verify
         * @memberof umee.leverage.v1.EventDecollaterize
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventDecollaterize.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.utoken != null && message.hasOwnProperty('utoken')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.utoken)
            if (error) return 'utoken.' + error
          }
          return null
        }

        /**
         * Creates an EventDecollaterize message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.EventDecollaterize
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.EventDecollaterize} EventDecollaterize
         */
        EventDecollaterize.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.EventDecollaterize) return object
          let message = new $root.umee.leverage.v1.EventDecollaterize()
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.utoken != null) {
            if (typeof object.utoken !== 'object')
              throw TypeError('.umee.leverage.v1.EventDecollaterize.utoken: object expected')
            message.utoken = $root.cosmos.base.v1beta1.Coin.fromObject(object.utoken)
          }
          return message
        }

        /**
         * Creates a plain object from an EventDecollaterize message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.EventDecollaterize
         * @static
         * @param {umee.leverage.v1.EventDecollaterize} message EventDecollaterize
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventDecollaterize.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.borrower = ''
            object.utoken = null
          }
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.utoken != null && message.hasOwnProperty('utoken'))
            object.utoken = $root.cosmos.base.v1beta1.Coin.toObject(message.utoken, options)
          return object
        }

        /**
         * Converts this EventDecollaterize to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.EventDecollaterize
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventDecollaterize.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return EventDecollaterize
      })()

      v1.EventBorrow = (function () {
        /**
         * Properties of an EventBorrow.
         * @memberof umee.leverage.v1
         * @interface IEventBorrow
         * @property {string|null} [borrower] EventBorrow borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [asset] EventBorrow asset
         */

        /**
         * Constructs a new EventBorrow.
         * @memberof umee.leverage.v1
         * @classdesc Represents an EventBorrow.
         * @implements IEventBorrow
         * @constructor
         * @param {umee.leverage.v1.IEventBorrow=} [properties] Properties to set
         */
        function EventBorrow(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * EventBorrow borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.EventBorrow
         * @instance
         */
        EventBorrow.prototype.borrower = ''

        /**
         * EventBorrow asset.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} asset
         * @memberof umee.leverage.v1.EventBorrow
         * @instance
         */
        EventBorrow.prototype.asset = null

        /**
         * Creates a new EventBorrow instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.EventBorrow
         * @static
         * @param {umee.leverage.v1.IEventBorrow=} [properties] Properties to set
         * @returns {umee.leverage.v1.EventBorrow} EventBorrow instance
         */
        EventBorrow.create = function create(properties) {
          return new EventBorrow(properties)
        }

        /**
         * Encodes the specified EventBorrow message. Does not implicitly {@link umee.leverage.v1.EventBorrow.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.EventBorrow
         * @static
         * @param {umee.leverage.v1.IEventBorrow} message EventBorrow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventBorrow.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.borrower)
          if (message.asset != null && Object.hasOwnProperty.call(message, 'asset'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.asset,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified EventBorrow message, length delimited. Does not implicitly {@link umee.leverage.v1.EventBorrow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.EventBorrow
         * @static
         * @param {umee.leverage.v1.IEventBorrow} message EventBorrow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventBorrow.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an EventBorrow message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.EventBorrow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.EventBorrow} EventBorrow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventBorrow.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.EventBorrow()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.borrower = reader.string()
                break
              case 2:
                message.asset = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an EventBorrow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.EventBorrow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.EventBorrow} EventBorrow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventBorrow.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an EventBorrow message.
         * @function verify
         * @memberof umee.leverage.v1.EventBorrow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventBorrow.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.asset != null && message.hasOwnProperty('asset')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.asset)
            if (error) return 'asset.' + error
          }
          return null
        }

        /**
         * Creates an EventBorrow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.EventBorrow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.EventBorrow} EventBorrow
         */
        EventBorrow.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.EventBorrow) return object
          let message = new $root.umee.leverage.v1.EventBorrow()
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.asset != null) {
            if (typeof object.asset !== 'object')
              throw TypeError('.umee.leverage.v1.EventBorrow.asset: object expected')
            message.asset = $root.cosmos.base.v1beta1.Coin.fromObject(object.asset)
          }
          return message
        }

        /**
         * Creates a plain object from an EventBorrow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.EventBorrow
         * @static
         * @param {umee.leverage.v1.EventBorrow} message EventBorrow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventBorrow.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.borrower = ''
            object.asset = null
          }
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.asset != null && message.hasOwnProperty('asset'))
            object.asset = $root.cosmos.base.v1beta1.Coin.toObject(message.asset, options)
          return object
        }

        /**
         * Converts this EventBorrow to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.EventBorrow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventBorrow.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return EventBorrow
      })()

      v1.EventRepay = (function () {
        /**
         * Properties of an EventRepay.
         * @memberof umee.leverage.v1
         * @interface IEventRepay
         * @property {string|null} [borrower] EventRepay borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [repaid] EventRepay repaid
         */

        /**
         * Constructs a new EventRepay.
         * @memberof umee.leverage.v1
         * @classdesc Represents an EventRepay.
         * @implements IEventRepay
         * @constructor
         * @param {umee.leverage.v1.IEventRepay=} [properties] Properties to set
         */
        function EventRepay(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * EventRepay borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.EventRepay
         * @instance
         */
        EventRepay.prototype.borrower = ''

        /**
         * EventRepay repaid.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} repaid
         * @memberof umee.leverage.v1.EventRepay
         * @instance
         */
        EventRepay.prototype.repaid = null

        /**
         * Creates a new EventRepay instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.EventRepay
         * @static
         * @param {umee.leverage.v1.IEventRepay=} [properties] Properties to set
         * @returns {umee.leverage.v1.EventRepay} EventRepay instance
         */
        EventRepay.create = function create(properties) {
          return new EventRepay(properties)
        }

        /**
         * Encodes the specified EventRepay message. Does not implicitly {@link umee.leverage.v1.EventRepay.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.EventRepay
         * @static
         * @param {umee.leverage.v1.IEventRepay} message EventRepay message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventRepay.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.borrower)
          if (message.repaid != null && Object.hasOwnProperty.call(message, 'repaid'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.repaid,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified EventRepay message, length delimited. Does not implicitly {@link umee.leverage.v1.EventRepay.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.EventRepay
         * @static
         * @param {umee.leverage.v1.IEventRepay} message EventRepay message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventRepay.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an EventRepay message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.EventRepay
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.EventRepay} EventRepay
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventRepay.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.EventRepay()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.borrower = reader.string()
                break
              case 2:
                message.repaid = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an EventRepay message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.EventRepay
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.EventRepay} EventRepay
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventRepay.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an EventRepay message.
         * @function verify
         * @memberof umee.leverage.v1.EventRepay
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventRepay.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.repaid != null && message.hasOwnProperty('repaid')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.repaid)
            if (error) return 'repaid.' + error
          }
          return null
        }

        /**
         * Creates an EventRepay message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.EventRepay
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.EventRepay} EventRepay
         */
        EventRepay.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.EventRepay) return object
          let message = new $root.umee.leverage.v1.EventRepay()
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.repaid != null) {
            if (typeof object.repaid !== 'object')
              throw TypeError('.umee.leverage.v1.EventRepay.repaid: object expected')
            message.repaid = $root.cosmos.base.v1beta1.Coin.fromObject(object.repaid)
          }
          return message
        }

        /**
         * Creates a plain object from an EventRepay message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.EventRepay
         * @static
         * @param {umee.leverage.v1.EventRepay} message EventRepay
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventRepay.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.borrower = ''
            object.repaid = null
          }
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.repaid != null && message.hasOwnProperty('repaid'))
            object.repaid = $root.cosmos.base.v1beta1.Coin.toObject(message.repaid, options)
          return object
        }

        /**
         * Converts this EventRepay to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.EventRepay
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventRepay.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return EventRepay
      })()

      v1.EventLiquidate = (function () {
        /**
         * Properties of an EventLiquidate.
         * @memberof umee.leverage.v1
         * @interface IEventLiquidate
         * @property {string|null} [liquidator] EventLiquidate liquidator
         * @property {string|null} [borrower] EventLiquidate borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [liquidated] EventLiquidate liquidated
         */

        /**
         * Constructs a new EventLiquidate.
         * @memberof umee.leverage.v1
         * @classdesc Represents an EventLiquidate.
         * @implements IEventLiquidate
         * @constructor
         * @param {umee.leverage.v1.IEventLiquidate=} [properties] Properties to set
         */
        function EventLiquidate(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * EventLiquidate liquidator.
         * @member {string} liquidator
         * @memberof umee.leverage.v1.EventLiquidate
         * @instance
         */
        EventLiquidate.prototype.liquidator = ''

        /**
         * EventLiquidate borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.EventLiquidate
         * @instance
         */
        EventLiquidate.prototype.borrower = ''

        /**
         * EventLiquidate liquidated.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} liquidated
         * @memberof umee.leverage.v1.EventLiquidate
         * @instance
         */
        EventLiquidate.prototype.liquidated = null

        /**
         * Creates a new EventLiquidate instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.EventLiquidate
         * @static
         * @param {umee.leverage.v1.IEventLiquidate=} [properties] Properties to set
         * @returns {umee.leverage.v1.EventLiquidate} EventLiquidate instance
         */
        EventLiquidate.create = function create(properties) {
          return new EventLiquidate(properties)
        }

        /**
         * Encodes the specified EventLiquidate message. Does not implicitly {@link umee.leverage.v1.EventLiquidate.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.EventLiquidate
         * @static
         * @param {umee.leverage.v1.IEventLiquidate} message EventLiquidate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventLiquidate.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.liquidator != null && Object.hasOwnProperty.call(message, 'liquidator'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.liquidator)
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.borrower)
          if (message.liquidated != null && Object.hasOwnProperty.call(message, 'liquidated'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.liquidated,
              writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified EventLiquidate message, length delimited. Does not implicitly {@link umee.leverage.v1.EventLiquidate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.EventLiquidate
         * @static
         * @param {umee.leverage.v1.IEventLiquidate} message EventLiquidate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventLiquidate.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an EventLiquidate message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.EventLiquidate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.EventLiquidate} EventLiquidate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventLiquidate.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.EventLiquidate()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.liquidator = reader.string()
                break
              case 2:
                message.borrower = reader.string()
                break
              case 3:
                message.liquidated = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an EventLiquidate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.EventLiquidate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.EventLiquidate} EventLiquidate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventLiquidate.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an EventLiquidate message.
         * @function verify
         * @memberof umee.leverage.v1.EventLiquidate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventLiquidate.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.liquidator != null && message.hasOwnProperty('liquidator'))
            if (!$util.isString(message.liquidator)) return 'liquidator: string expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.liquidated != null && message.hasOwnProperty('liquidated')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.liquidated)
            if (error) return 'liquidated.' + error
          }
          return null
        }

        /**
         * Creates an EventLiquidate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.EventLiquidate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.EventLiquidate} EventLiquidate
         */
        EventLiquidate.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.EventLiquidate) return object
          let message = new $root.umee.leverage.v1.EventLiquidate()
          if (object.liquidator != null) message.liquidator = String(object.liquidator)
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.liquidated != null) {
            if (typeof object.liquidated !== 'object')
              throw TypeError('.umee.leverage.v1.EventLiquidate.liquidated: object expected')
            message.liquidated = $root.cosmos.base.v1beta1.Coin.fromObject(object.liquidated)
          }
          return message
        }

        /**
         * Creates a plain object from an EventLiquidate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.EventLiquidate
         * @static
         * @param {umee.leverage.v1.EventLiquidate} message EventLiquidate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventLiquidate.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.liquidator = ''
            object.borrower = ''
            object.liquidated = null
          }
          if (message.liquidator != null && message.hasOwnProperty('liquidator')) object.liquidator = message.liquidator
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.liquidated != null && message.hasOwnProperty('liquidated'))
            object.liquidated = $root.cosmos.base.v1beta1.Coin.toObject(message.liquidated, options)
          return object
        }

        /**
         * Converts this EventLiquidate to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.EventLiquidate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventLiquidate.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return EventLiquidate
      })()

      v1.EventInterestAccrual = (function () {
        /**
         * Properties of an EventInterestAccrual.
         * @memberof umee.leverage.v1
         * @interface IEventInterestAccrual
         * @property {number|Long|null} [blockHeight] EventInterestAccrual blockHeight
         * @property {number|Long|null} [timestamp] EventInterestAccrual timestamp
         * @property {Array.<cosmos.base.v1beta1.ICoin>|null} [totalInterest] EventInterestAccrual totalInterest
         * @property {Array.<cosmos.base.v1beta1.ICoin>|null} [reserved] EventInterestAccrual reserved
         */

        /**
         * Constructs a new EventInterestAccrual.
         * @memberof umee.leverage.v1
         * @classdesc Represents an EventInterestAccrual.
         * @implements IEventInterestAccrual
         * @constructor
         * @param {umee.leverage.v1.IEventInterestAccrual=} [properties] Properties to set
         */
        function EventInterestAccrual(properties) {
          this.totalInterest = []
          this.reserved = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * EventInterestAccrual blockHeight.
         * @member {number|Long} blockHeight
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @instance
         */
        EventInterestAccrual.prototype.blockHeight = $util.Long ? $util.Long.fromBits(0, 0, true) : 0

        /**
         * EventInterestAccrual timestamp.
         * @member {number|Long} timestamp
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @instance
         */
        EventInterestAccrual.prototype.timestamp = $util.Long ? $util.Long.fromBits(0, 0, true) : 0

        /**
         * EventInterestAccrual totalInterest.
         * @member {Array.<cosmos.base.v1beta1.ICoin>} totalInterest
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @instance
         */
        EventInterestAccrual.prototype.totalInterest = $util.emptyArray

        /**
         * EventInterestAccrual reserved.
         * @member {Array.<cosmos.base.v1beta1.ICoin>} reserved
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @instance
         */
        EventInterestAccrual.prototype.reserved = $util.emptyArray

        /**
         * Creates a new EventInterestAccrual instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @static
         * @param {umee.leverage.v1.IEventInterestAccrual=} [properties] Properties to set
         * @returns {umee.leverage.v1.EventInterestAccrual} EventInterestAccrual instance
         */
        EventInterestAccrual.create = function create(properties) {
          return new EventInterestAccrual(properties)
        }

        /**
         * Encodes the specified EventInterestAccrual message. Does not implicitly {@link umee.leverage.v1.EventInterestAccrual.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @static
         * @param {umee.leverage.v1.IEventInterestAccrual} message EventInterestAccrual message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventInterestAccrual.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.blockHeight != null && Object.hasOwnProperty.call(message, 'blockHeight'))
            writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.blockHeight)
          if (message.timestamp != null && Object.hasOwnProperty.call(message, 'timestamp'))
            writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.timestamp)
          if (message.totalInterest != null && message.totalInterest.length)
            for (let i = 0; i < message.totalInterest.length; ++i)
              $root.cosmos.base.v1beta1.Coin.encode(
                message.totalInterest[i],
                writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
              ).ldelim()
          if (message.reserved != null && message.reserved.length)
            for (let i = 0; i < message.reserved.length; ++i)
              $root.cosmos.base.v1beta1.Coin.encode(
                message.reserved[i],
                writer.uint32(/* id 4, wireType 2 =*/ 34).fork(),
              ).ldelim()
          return writer
        }

        /**
         * Encodes the specified EventInterestAccrual message, length delimited. Does not implicitly {@link umee.leverage.v1.EventInterestAccrual.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @static
         * @param {umee.leverage.v1.IEventInterestAccrual} message EventInterestAccrual message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventInterestAccrual.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an EventInterestAccrual message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.EventInterestAccrual} EventInterestAccrual
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventInterestAccrual.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.EventInterestAccrual()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.blockHeight = reader.uint64()
                break
              case 2:
                message.timestamp = reader.uint64()
                break
              case 3:
                if (!(message.totalInterest && message.totalInterest.length)) message.totalInterest = []
                message.totalInterest.push($root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32()))
                break
              case 4:
                if (!(message.reserved && message.reserved.length)) message.reserved = []
                message.reserved.push($root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32()))
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an EventInterestAccrual message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.EventInterestAccrual} EventInterestAccrual
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventInterestAccrual.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an EventInterestAccrual message.
         * @function verify
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventInterestAccrual.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.blockHeight != null && message.hasOwnProperty('blockHeight'))
            if (
              !$util.isInteger(message.blockHeight) &&
              !(
                message.blockHeight &&
                $util.isInteger(message.blockHeight.low) &&
                $util.isInteger(message.blockHeight.high)
              )
            )
              return 'blockHeight: integer|Long expected'
          if (message.timestamp != null && message.hasOwnProperty('timestamp'))
            if (
              !$util.isInteger(message.timestamp) &&
              !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high))
            )
              return 'timestamp: integer|Long expected'
          if (message.totalInterest != null && message.hasOwnProperty('totalInterest')) {
            if (!Array.isArray(message.totalInterest)) return 'totalInterest: array expected'
            for (let i = 0; i < message.totalInterest.length; ++i) {
              let error = $root.cosmos.base.v1beta1.Coin.verify(message.totalInterest[i])
              if (error) return 'totalInterest.' + error
            }
          }
          if (message.reserved != null && message.hasOwnProperty('reserved')) {
            if (!Array.isArray(message.reserved)) return 'reserved: array expected'
            for (let i = 0; i < message.reserved.length; ++i) {
              let error = $root.cosmos.base.v1beta1.Coin.verify(message.reserved[i])
              if (error) return 'reserved.' + error
            }
          }
          return null
        }

        /**
         * Creates an EventInterestAccrual message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.EventInterestAccrual} EventInterestAccrual
         */
        EventInterestAccrual.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.EventInterestAccrual) return object
          let message = new $root.umee.leverage.v1.EventInterestAccrual()
          if (object.blockHeight != null)
            if ($util.Long) (message.blockHeight = $util.Long.fromValue(object.blockHeight)).unsigned = true
            else if (typeof object.blockHeight === 'string') message.blockHeight = parseInt(object.blockHeight, 10)
            else if (typeof object.blockHeight === 'number') message.blockHeight = object.blockHeight
            else if (typeof object.blockHeight === 'object')
              message.blockHeight = new $util.LongBits(
                object.blockHeight.low >>> 0,
                object.blockHeight.high >>> 0,
              ).toNumber(true)
          if (object.timestamp != null)
            if ($util.Long) (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = true
            else if (typeof object.timestamp === 'string') message.timestamp = parseInt(object.timestamp, 10)
            else if (typeof object.timestamp === 'number') message.timestamp = object.timestamp
            else if (typeof object.timestamp === 'object')
              message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber(
                true,
              )
          if (object.totalInterest) {
            if (!Array.isArray(object.totalInterest))
              throw TypeError('.umee.leverage.v1.EventInterestAccrual.totalInterest: array expected')
            message.totalInterest = []
            for (let i = 0; i < object.totalInterest.length; ++i) {
              if (typeof object.totalInterest[i] !== 'object')
                throw TypeError('.umee.leverage.v1.EventInterestAccrual.totalInterest: object expected')
              message.totalInterest[i] = $root.cosmos.base.v1beta1.Coin.fromObject(object.totalInterest[i])
            }
          }
          if (object.reserved) {
            if (!Array.isArray(object.reserved))
              throw TypeError('.umee.leverage.v1.EventInterestAccrual.reserved: array expected')
            message.reserved = []
            for (let i = 0; i < object.reserved.length; ++i) {
              if (typeof object.reserved[i] !== 'object')
                throw TypeError('.umee.leverage.v1.EventInterestAccrual.reserved: object expected')
              message.reserved[i] = $root.cosmos.base.v1beta1.Coin.fromObject(object.reserved[i])
            }
          }
          return message
        }

        /**
         * Creates a plain object from an EventInterestAccrual message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @static
         * @param {umee.leverage.v1.EventInterestAccrual} message EventInterestAccrual
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventInterestAccrual.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) {
            object.totalInterest = []
            object.reserved = []
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.blockHeight =
                options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long
            } else object.blockHeight = options.longs === String ? '0' : 0
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.timestamp =
                options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long
            } else object.timestamp = options.longs === String ? '0' : 0
          }
          if (message.blockHeight != null && message.hasOwnProperty('blockHeight'))
            if (typeof message.blockHeight === 'number')
              object.blockHeight = options.longs === String ? String(message.blockHeight) : message.blockHeight
            else
              object.blockHeight =
                options.longs === String
                  ? $util.Long.prototype.toString.call(message.blockHeight)
                  : options.longs === Number
                  ? new $util.LongBits(message.blockHeight.low >>> 0, message.blockHeight.high >>> 0).toNumber(true)
                  : message.blockHeight
          if (message.timestamp != null && message.hasOwnProperty('timestamp'))
            if (typeof message.timestamp === 'number')
              object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp
            else
              object.timestamp =
                options.longs === String
                  ? $util.Long.prototype.toString.call(message.timestamp)
                  : options.longs === Number
                  ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber(true)
                  : message.timestamp
          if (message.totalInterest && message.totalInterest.length) {
            object.totalInterest = []
            for (let j = 0; j < message.totalInterest.length; ++j)
              object.totalInterest[j] = $root.cosmos.base.v1beta1.Coin.toObject(message.totalInterest[j], options)
          }
          if (message.reserved && message.reserved.length) {
            object.reserved = []
            for (let j = 0; j < message.reserved.length; ++j)
              object.reserved[j] = $root.cosmos.base.v1beta1.Coin.toObject(message.reserved[j], options)
          }
          return object
        }

        /**
         * Converts this EventInterestAccrual to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.EventInterestAccrual
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventInterestAccrual.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return EventInterestAccrual
      })()

      v1.EventRepayBadDebt = (function () {
        /**
         * Properties of an EventRepayBadDebt.
         * @memberof umee.leverage.v1
         * @interface IEventRepayBadDebt
         * @property {string|null} [borrower] EventRepayBadDebt borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [asset] EventRepayBadDebt asset
         */

        /**
         * Constructs a new EventRepayBadDebt.
         * @memberof umee.leverage.v1
         * @classdesc Represents an EventRepayBadDebt.
         * @implements IEventRepayBadDebt
         * @constructor
         * @param {umee.leverage.v1.IEventRepayBadDebt=} [properties] Properties to set
         */
        function EventRepayBadDebt(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * EventRepayBadDebt borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.EventRepayBadDebt
         * @instance
         */
        EventRepayBadDebt.prototype.borrower = ''

        /**
         * EventRepayBadDebt asset.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} asset
         * @memberof umee.leverage.v1.EventRepayBadDebt
         * @instance
         */
        EventRepayBadDebt.prototype.asset = null

        /**
         * Creates a new EventRepayBadDebt instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.EventRepayBadDebt
         * @static
         * @param {umee.leverage.v1.IEventRepayBadDebt=} [properties] Properties to set
         * @returns {umee.leverage.v1.EventRepayBadDebt} EventRepayBadDebt instance
         */
        EventRepayBadDebt.create = function create(properties) {
          return new EventRepayBadDebt(properties)
        }

        /**
         * Encodes the specified EventRepayBadDebt message. Does not implicitly {@link umee.leverage.v1.EventRepayBadDebt.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.EventRepayBadDebt
         * @static
         * @param {umee.leverage.v1.IEventRepayBadDebt} message EventRepayBadDebt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventRepayBadDebt.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.borrower)
          if (message.asset != null && Object.hasOwnProperty.call(message, 'asset'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.asset,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified EventRepayBadDebt message, length delimited. Does not implicitly {@link umee.leverage.v1.EventRepayBadDebt.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.EventRepayBadDebt
         * @static
         * @param {umee.leverage.v1.IEventRepayBadDebt} message EventRepayBadDebt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventRepayBadDebt.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an EventRepayBadDebt message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.EventRepayBadDebt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.EventRepayBadDebt} EventRepayBadDebt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventRepayBadDebt.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.EventRepayBadDebt()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.borrower = reader.string()
                break
              case 2:
                message.asset = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an EventRepayBadDebt message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.EventRepayBadDebt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.EventRepayBadDebt} EventRepayBadDebt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventRepayBadDebt.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an EventRepayBadDebt message.
         * @function verify
         * @memberof umee.leverage.v1.EventRepayBadDebt
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventRepayBadDebt.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.asset != null && message.hasOwnProperty('asset')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.asset)
            if (error) return 'asset.' + error
          }
          return null
        }

        /**
         * Creates an EventRepayBadDebt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.EventRepayBadDebt
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.EventRepayBadDebt} EventRepayBadDebt
         */
        EventRepayBadDebt.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.EventRepayBadDebt) return object
          let message = new $root.umee.leverage.v1.EventRepayBadDebt()
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.asset != null) {
            if (typeof object.asset !== 'object')
              throw TypeError('.umee.leverage.v1.EventRepayBadDebt.asset: object expected')
            message.asset = $root.cosmos.base.v1beta1.Coin.fromObject(object.asset)
          }
          return message
        }

        /**
         * Creates a plain object from an EventRepayBadDebt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.EventRepayBadDebt
         * @static
         * @param {umee.leverage.v1.EventRepayBadDebt} message EventRepayBadDebt
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventRepayBadDebt.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.borrower = ''
            object.asset = null
          }
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.asset != null && message.hasOwnProperty('asset'))
            object.asset = $root.cosmos.base.v1beta1.Coin.toObject(message.asset, options)
          return object
        }

        /**
         * Converts this EventRepayBadDebt to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.EventRepayBadDebt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventRepayBadDebt.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return EventRepayBadDebt
      })()

      v1.EventReservesExhausted = (function () {
        /**
         * Properties of an EventReservesExhausted.
         * @memberof umee.leverage.v1
         * @interface IEventReservesExhausted
         * @property {string|null} [borrower] EventReservesExhausted borrower
         * @property {cosmos.base.v1beta1.ICoin|null} [outstandingDebt] EventReservesExhausted outstandingDebt
         * @property {cosmos.base.v1beta1.ICoin|null} [moduleBalance] EventReservesExhausted moduleBalance
         * @property {cosmos.base.v1beta1.ICoin|null} [reserves] EventReservesExhausted reserves
         */

        /**
         * Constructs a new EventReservesExhausted.
         * @memberof umee.leverage.v1
         * @classdesc Represents an EventReservesExhausted.
         * @implements IEventReservesExhausted
         * @constructor
         * @param {umee.leverage.v1.IEventReservesExhausted=} [properties] Properties to set
         */
        function EventReservesExhausted(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * EventReservesExhausted borrower.
         * @member {string} borrower
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @instance
         */
        EventReservesExhausted.prototype.borrower = ''

        /**
         * EventReservesExhausted outstandingDebt.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} outstandingDebt
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @instance
         */
        EventReservesExhausted.prototype.outstandingDebt = null

        /**
         * EventReservesExhausted moduleBalance.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} moduleBalance
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @instance
         */
        EventReservesExhausted.prototype.moduleBalance = null

        /**
         * EventReservesExhausted reserves.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} reserves
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @instance
         */
        EventReservesExhausted.prototype.reserves = null

        /**
         * Creates a new EventReservesExhausted instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @static
         * @param {umee.leverage.v1.IEventReservesExhausted=} [properties] Properties to set
         * @returns {umee.leverage.v1.EventReservesExhausted} EventReservesExhausted instance
         */
        EventReservesExhausted.create = function create(properties) {
          return new EventReservesExhausted(properties)
        }

        /**
         * Encodes the specified EventReservesExhausted message. Does not implicitly {@link umee.leverage.v1.EventReservesExhausted.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @static
         * @param {umee.leverage.v1.IEventReservesExhausted} message EventReservesExhausted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventReservesExhausted.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.borrower != null && Object.hasOwnProperty.call(message, 'borrower'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.borrower)
          if (message.outstandingDebt != null && Object.hasOwnProperty.call(message, 'outstandingDebt'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.outstandingDebt,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          if (message.moduleBalance != null && Object.hasOwnProperty.call(message, 'moduleBalance'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.moduleBalance,
              writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
            ).ldelim()
          if (message.reserves != null && Object.hasOwnProperty.call(message, 'reserves'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.reserves,
              writer.uint32(/* id 4, wireType 2 =*/ 34).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified EventReservesExhausted message, length delimited. Does not implicitly {@link umee.leverage.v1.EventReservesExhausted.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @static
         * @param {umee.leverage.v1.IEventReservesExhausted} message EventReservesExhausted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventReservesExhausted.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an EventReservesExhausted message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.EventReservesExhausted} EventReservesExhausted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventReservesExhausted.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.EventReservesExhausted()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.borrower = reader.string()
                break
              case 2:
                message.outstandingDebt = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              case 3:
                message.moduleBalance = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              case 4:
                message.reserves = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an EventReservesExhausted message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.EventReservesExhausted} EventReservesExhausted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventReservesExhausted.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an EventReservesExhausted message.
         * @function verify
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventReservesExhausted.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.borrower != null && message.hasOwnProperty('borrower'))
            if (!$util.isString(message.borrower)) return 'borrower: string expected'
          if (message.outstandingDebt != null && message.hasOwnProperty('outstandingDebt')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.outstandingDebt)
            if (error) return 'outstandingDebt.' + error
          }
          if (message.moduleBalance != null && message.hasOwnProperty('moduleBalance')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.moduleBalance)
            if (error) return 'moduleBalance.' + error
          }
          if (message.reserves != null && message.hasOwnProperty('reserves')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.reserves)
            if (error) return 'reserves.' + error
          }
          return null
        }

        /**
         * Creates an EventReservesExhausted message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.EventReservesExhausted} EventReservesExhausted
         */
        EventReservesExhausted.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.EventReservesExhausted) return object
          let message = new $root.umee.leverage.v1.EventReservesExhausted()
          if (object.borrower != null) message.borrower = String(object.borrower)
          if (object.outstandingDebt != null) {
            if (typeof object.outstandingDebt !== 'object')
              throw TypeError('.umee.leverage.v1.EventReservesExhausted.outstandingDebt: object expected')
            message.outstandingDebt = $root.cosmos.base.v1beta1.Coin.fromObject(object.outstandingDebt)
          }
          if (object.moduleBalance != null) {
            if (typeof object.moduleBalance !== 'object')
              throw TypeError('.umee.leverage.v1.EventReservesExhausted.moduleBalance: object expected')
            message.moduleBalance = $root.cosmos.base.v1beta1.Coin.fromObject(object.moduleBalance)
          }
          if (object.reserves != null) {
            if (typeof object.reserves !== 'object')
              throw TypeError('.umee.leverage.v1.EventReservesExhausted.reserves: object expected')
            message.reserves = $root.cosmos.base.v1beta1.Coin.fromObject(object.reserves)
          }
          return message
        }

        /**
         * Creates a plain object from an EventReservesExhausted message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @static
         * @param {umee.leverage.v1.EventReservesExhausted} message EventReservesExhausted
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventReservesExhausted.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.borrower = ''
            object.outstandingDebt = null
            object.moduleBalance = null
            object.reserves = null
          }
          if (message.borrower != null && message.hasOwnProperty('borrower')) object.borrower = message.borrower
          if (message.outstandingDebt != null && message.hasOwnProperty('outstandingDebt'))
            object.outstandingDebt = $root.cosmos.base.v1beta1.Coin.toObject(message.outstandingDebt, options)
          if (message.moduleBalance != null && message.hasOwnProperty('moduleBalance'))
            object.moduleBalance = $root.cosmos.base.v1beta1.Coin.toObject(message.moduleBalance, options)
          if (message.reserves != null && message.hasOwnProperty('reserves'))
            object.reserves = $root.cosmos.base.v1beta1.Coin.toObject(message.reserves, options)
          return object
        }

        /**
         * Converts this EventReservesExhausted to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.EventReservesExhausted
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventReservesExhausted.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return EventReservesExhausted
      })()

      v1.EventFundOracle = (function () {
        /**
         * Properties of an EventFundOracle.
         * @memberof umee.leverage.v1
         * @interface IEventFundOracle
         * @property {Array.<cosmos.base.v1beta1.ICoin>|null} [assets] EventFundOracle assets
         */

        /**
         * Constructs a new EventFundOracle.
         * @memberof umee.leverage.v1
         * @classdesc Represents an EventFundOracle.
         * @implements IEventFundOracle
         * @constructor
         * @param {umee.leverage.v1.IEventFundOracle=} [properties] Properties to set
         */
        function EventFundOracle(properties) {
          this.assets = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * EventFundOracle assets.
         * @member {Array.<cosmos.base.v1beta1.ICoin>} assets
         * @memberof umee.leverage.v1.EventFundOracle
         * @instance
         */
        EventFundOracle.prototype.assets = $util.emptyArray

        /**
         * Creates a new EventFundOracle instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.EventFundOracle
         * @static
         * @param {umee.leverage.v1.IEventFundOracle=} [properties] Properties to set
         * @returns {umee.leverage.v1.EventFundOracle} EventFundOracle instance
         */
        EventFundOracle.create = function create(properties) {
          return new EventFundOracle(properties)
        }

        /**
         * Encodes the specified EventFundOracle message. Does not implicitly {@link umee.leverage.v1.EventFundOracle.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.EventFundOracle
         * @static
         * @param {umee.leverage.v1.IEventFundOracle} message EventFundOracle message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventFundOracle.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.assets != null && message.assets.length)
            for (let i = 0; i < message.assets.length; ++i)
              $root.cosmos.base.v1beta1.Coin.encode(
                message.assets[i],
                writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
              ).ldelim()
          return writer
        }

        /**
         * Encodes the specified EventFundOracle message, length delimited. Does not implicitly {@link umee.leverage.v1.EventFundOracle.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.EventFundOracle
         * @static
         * @param {umee.leverage.v1.IEventFundOracle} message EventFundOracle message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventFundOracle.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an EventFundOracle message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.EventFundOracle
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.EventFundOracle} EventFundOracle
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventFundOracle.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.EventFundOracle()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                if (!(message.assets && message.assets.length)) message.assets = []
                message.assets.push($root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32()))
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an EventFundOracle message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.EventFundOracle
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.EventFundOracle} EventFundOracle
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventFundOracle.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an EventFundOracle message.
         * @function verify
         * @memberof umee.leverage.v1.EventFundOracle
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventFundOracle.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.assets != null && message.hasOwnProperty('assets')) {
            if (!Array.isArray(message.assets)) return 'assets: array expected'
            for (let i = 0; i < message.assets.length; ++i) {
              let error = $root.cosmos.base.v1beta1.Coin.verify(message.assets[i])
              if (error) return 'assets.' + error
            }
          }
          return null
        }

        /**
         * Creates an EventFundOracle message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.EventFundOracle
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.EventFundOracle} EventFundOracle
         */
        EventFundOracle.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.EventFundOracle) return object
          let message = new $root.umee.leverage.v1.EventFundOracle()
          if (object.assets) {
            if (!Array.isArray(object.assets))
              throw TypeError('.umee.leverage.v1.EventFundOracle.assets: array expected')
            message.assets = []
            for (let i = 0; i < object.assets.length; ++i) {
              if (typeof object.assets[i] !== 'object')
                throw TypeError('.umee.leverage.v1.EventFundOracle.assets: object expected')
              message.assets[i] = $root.cosmos.base.v1beta1.Coin.fromObject(object.assets[i])
            }
          }
          return message
        }

        /**
         * Creates a plain object from an EventFundOracle message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.EventFundOracle
         * @static
         * @param {umee.leverage.v1.EventFundOracle} message EventFundOracle
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventFundOracle.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.assets = []
          if (message.assets && message.assets.length) {
            object.assets = []
            for (let j = 0; j < message.assets.length; ++j)
              object.assets[j] = $root.cosmos.base.v1beta1.Coin.toObject(message.assets[j], options)
          }
          return object
        }

        /**
         * Converts this EventFundOracle to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.EventFundOracle
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventFundOracle.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return EventFundOracle
      })()

      v1.Params = (function () {
        /**
         * Properties of a Params.
         * @memberof umee.leverage.v1
         * @interface IParams
         * @property {string|null} [completeLiquidationThreshold] Params completeLiquidationThreshold
         * @property {string|null} [minimumCloseFactor] Params minimumCloseFactor
         * @property {string|null} [oracleRewardFactor] Params oracleRewardFactor
         * @property {string|null} [smallLiquidationSize] Params smallLiquidationSize
         * @property {string|null} [directLiquidationFee] Params directLiquidationFee
         */

        /**
         * Constructs a new Params.
         * @memberof umee.leverage.v1
         * @classdesc Represents a Params.
         * @implements IParams
         * @constructor
         * @param {umee.leverage.v1.IParams=} [properties] Properties to set
         */
        function Params(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Params completeLiquidationThreshold.
         * @member {string} completeLiquidationThreshold
         * @memberof umee.leverage.v1.Params
         * @instance
         */
        Params.prototype.completeLiquidationThreshold = ''

        /**
         * Params minimumCloseFactor.
         * @member {string} minimumCloseFactor
         * @memberof umee.leverage.v1.Params
         * @instance
         */
        Params.prototype.minimumCloseFactor = ''

        /**
         * Params oracleRewardFactor.
         * @member {string} oracleRewardFactor
         * @memberof umee.leverage.v1.Params
         * @instance
         */
        Params.prototype.oracleRewardFactor = ''

        /**
         * Params smallLiquidationSize.
         * @member {string} smallLiquidationSize
         * @memberof umee.leverage.v1.Params
         * @instance
         */
        Params.prototype.smallLiquidationSize = ''

        /**
         * Params directLiquidationFee.
         * @member {string} directLiquidationFee
         * @memberof umee.leverage.v1.Params
         * @instance
         */
        Params.prototype.directLiquidationFee = ''

        /**
         * Creates a new Params instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.Params
         * @static
         * @param {umee.leverage.v1.IParams=} [properties] Properties to set
         * @returns {umee.leverage.v1.Params} Params instance
         */
        Params.create = function create(properties) {
          return new Params(properties)
        }

        /**
         * Encodes the specified Params message. Does not implicitly {@link umee.leverage.v1.Params.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.Params
         * @static
         * @param {umee.leverage.v1.IParams} message Params message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Params.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (
            message.completeLiquidationThreshold != null &&
            Object.hasOwnProperty.call(message, 'completeLiquidationThreshold')
          )
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.completeLiquidationThreshold)
          if (message.minimumCloseFactor != null && Object.hasOwnProperty.call(message, 'minimumCloseFactor'))
            writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.minimumCloseFactor)
          if (message.oracleRewardFactor != null && Object.hasOwnProperty.call(message, 'oracleRewardFactor'))
            writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.oracleRewardFactor)
          if (message.smallLiquidationSize != null && Object.hasOwnProperty.call(message, 'smallLiquidationSize'))
            writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.smallLiquidationSize)
          if (message.directLiquidationFee != null && Object.hasOwnProperty.call(message, 'directLiquidationFee'))
            writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.directLiquidationFee)
          return writer
        }

        /**
         * Encodes the specified Params message, length delimited. Does not implicitly {@link umee.leverage.v1.Params.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.Params
         * @static
         * @param {umee.leverage.v1.IParams} message Params message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Params.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a Params message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.Params
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.Params} Params
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Params.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.Params()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 2:
                message.completeLiquidationThreshold = reader.string()
                break
              case 3:
                message.minimumCloseFactor = reader.string()
                break
              case 4:
                message.oracleRewardFactor = reader.string()
                break
              case 5:
                message.smallLiquidationSize = reader.string()
                break
              case 6:
                message.directLiquidationFee = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a Params message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.Params
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.Params} Params
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Params.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a Params message.
         * @function verify
         * @memberof umee.leverage.v1.Params
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Params.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.completeLiquidationThreshold != null && message.hasOwnProperty('completeLiquidationThreshold'))
            if (!$util.isString(message.completeLiquidationThreshold))
              return 'completeLiquidationThreshold: string expected'
          if (message.minimumCloseFactor != null && message.hasOwnProperty('minimumCloseFactor'))
            if (!$util.isString(message.minimumCloseFactor)) return 'minimumCloseFactor: string expected'
          if (message.oracleRewardFactor != null && message.hasOwnProperty('oracleRewardFactor'))
            if (!$util.isString(message.oracleRewardFactor)) return 'oracleRewardFactor: string expected'
          if (message.smallLiquidationSize != null && message.hasOwnProperty('smallLiquidationSize'))
            if (!$util.isString(message.smallLiquidationSize)) return 'smallLiquidationSize: string expected'
          if (message.directLiquidationFee != null && message.hasOwnProperty('directLiquidationFee'))
            if (!$util.isString(message.directLiquidationFee)) return 'directLiquidationFee: string expected'
          return null
        }

        /**
         * Creates a Params message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.Params
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.Params} Params
         */
        Params.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.Params) return object
          let message = new $root.umee.leverage.v1.Params()
          if (object.completeLiquidationThreshold != null)
            message.completeLiquidationThreshold = String(object.completeLiquidationThreshold)
          if (object.minimumCloseFactor != null) message.minimumCloseFactor = String(object.minimumCloseFactor)
          if (object.oracleRewardFactor != null) message.oracleRewardFactor = String(object.oracleRewardFactor)
          if (object.smallLiquidationSize != null) message.smallLiquidationSize = String(object.smallLiquidationSize)
          if (object.directLiquidationFee != null) message.directLiquidationFee = String(object.directLiquidationFee)
          return message
        }

        /**
         * Creates a plain object from a Params message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.Params
         * @static
         * @param {umee.leverage.v1.Params} message Params
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Params.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.completeLiquidationThreshold = ''
            object.minimumCloseFactor = ''
            object.oracleRewardFactor = ''
            object.smallLiquidationSize = ''
            object.directLiquidationFee = ''
          }
          if (message.completeLiquidationThreshold != null && message.hasOwnProperty('completeLiquidationThreshold'))
            object.completeLiquidationThreshold = message.completeLiquidationThreshold
          if (message.minimumCloseFactor != null && message.hasOwnProperty('minimumCloseFactor'))
            object.minimumCloseFactor = message.minimumCloseFactor
          if (message.oracleRewardFactor != null && message.hasOwnProperty('oracleRewardFactor'))
            object.oracleRewardFactor = message.oracleRewardFactor
          if (message.smallLiquidationSize != null && message.hasOwnProperty('smallLiquidationSize'))
            object.smallLiquidationSize = message.smallLiquidationSize
          if (message.directLiquidationFee != null && message.hasOwnProperty('directLiquidationFee'))
            object.directLiquidationFee = message.directLiquidationFee
          return object
        }

        /**
         * Converts this Params to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.Params
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Params.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return Params
      })()

      v1.Token = (function () {
        /**
         * Properties of a Token.
         * @memberof umee.leverage.v1
         * @interface IToken
         * @property {string|null} [baseDenom] Token baseDenom
         * @property {string|null} [reserveFactor] Token reserveFactor
         * @property {string|null} [collateralWeight] Token collateralWeight
         * @property {string|null} [liquidationThreshold] Token liquidationThreshold
         * @property {string|null} [baseBorrowRate] Token baseBorrowRate
         * @property {string|null} [kinkBorrowRate] Token kinkBorrowRate
         * @property {string|null} [maxBorrowRate] Token maxBorrowRate
         * @property {string|null} [kinkUtilization] Token kinkUtilization
         * @property {string|null} [liquidationIncentive] Token liquidationIncentive
         * @property {string|null} [symbolDenom] Token symbolDenom
         * @property {number|null} [exponent] Token exponent
         * @property {boolean|null} [enableMsgSupply] Token enableMsgSupply
         * @property {boolean|null} [enableMsgBorrow] Token enableMsgBorrow
         * @property {boolean|null} [blacklist] Token blacklist
         * @property {string|null} [maxCollateralShare] Token maxCollateralShare
         * @property {string|null} [maxSupplyUtilization] Token maxSupplyUtilization
         * @property {string|null} [minCollateralLiquidity] Token minCollateralLiquidity
         * @property {string|null} [maxSupply] Token maxSupply
         */

        /**
         * Constructs a new Token.
         * @memberof umee.leverage.v1
         * @classdesc Represents a Token.
         * @implements IToken
         * @constructor
         * @param {umee.leverage.v1.IToken=} [properties] Properties to set
         */
        function Token(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Token baseDenom.
         * @member {string} baseDenom
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.baseDenom = ''

        /**
         * Token reserveFactor.
         * @member {string} reserveFactor
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.reserveFactor = ''

        /**
         * Token collateralWeight.
         * @member {string} collateralWeight
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.collateralWeight = ''

        /**
         * Token liquidationThreshold.
         * @member {string} liquidationThreshold
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.liquidationThreshold = ''

        /**
         * Token baseBorrowRate.
         * @member {string} baseBorrowRate
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.baseBorrowRate = ''

        /**
         * Token kinkBorrowRate.
         * @member {string} kinkBorrowRate
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.kinkBorrowRate = ''

        /**
         * Token maxBorrowRate.
         * @member {string} maxBorrowRate
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.maxBorrowRate = ''

        /**
         * Token kinkUtilization.
         * @member {string} kinkUtilization
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.kinkUtilization = ''

        /**
         * Token liquidationIncentive.
         * @member {string} liquidationIncentive
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.liquidationIncentive = ''

        /**
         * Token symbolDenom.
         * @member {string} symbolDenom
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.symbolDenom = ''

        /**
         * Token exponent.
         * @member {number} exponent
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.exponent = 0

        /**
         * Token enableMsgSupply.
         * @member {boolean} enableMsgSupply
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.enableMsgSupply = false

        /**
         * Token enableMsgBorrow.
         * @member {boolean} enableMsgBorrow
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.enableMsgBorrow = false

        /**
         * Token blacklist.
         * @member {boolean} blacklist
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.blacklist = false

        /**
         * Token maxCollateralShare.
         * @member {string} maxCollateralShare
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.maxCollateralShare = ''

        /**
         * Token maxSupplyUtilization.
         * @member {string} maxSupplyUtilization
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.maxSupplyUtilization = ''

        /**
         * Token minCollateralLiquidity.
         * @member {string} minCollateralLiquidity
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.minCollateralLiquidity = ''

        /**
         * Token maxSupply.
         * @member {string} maxSupply
         * @memberof umee.leverage.v1.Token
         * @instance
         */
        Token.prototype.maxSupply = ''

        /**
         * Creates a new Token instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.Token
         * @static
         * @param {umee.leverage.v1.IToken=} [properties] Properties to set
         * @returns {umee.leverage.v1.Token} Token instance
         */
        Token.create = function create(properties) {
          return new Token(properties)
        }

        /**
         * Encodes the specified Token message. Does not implicitly {@link umee.leverage.v1.Token.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.Token
         * @static
         * @param {umee.leverage.v1.IToken} message Token message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Token.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.baseDenom != null && Object.hasOwnProperty.call(message, 'baseDenom'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.baseDenom)
          if (message.reserveFactor != null && Object.hasOwnProperty.call(message, 'reserveFactor'))
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.reserveFactor)
          if (message.collateralWeight != null && Object.hasOwnProperty.call(message, 'collateralWeight'))
            writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.collateralWeight)
          if (message.liquidationThreshold != null && Object.hasOwnProperty.call(message, 'liquidationThreshold'))
            writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.liquidationThreshold)
          if (message.baseBorrowRate != null && Object.hasOwnProperty.call(message, 'baseBorrowRate'))
            writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.baseBorrowRate)
          if (message.kinkBorrowRate != null && Object.hasOwnProperty.call(message, 'kinkBorrowRate'))
            writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.kinkBorrowRate)
          if (message.maxBorrowRate != null && Object.hasOwnProperty.call(message, 'maxBorrowRate'))
            writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.maxBorrowRate)
          if (message.kinkUtilization != null && Object.hasOwnProperty.call(message, 'kinkUtilization'))
            writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.kinkUtilization)
          if (message.liquidationIncentive != null && Object.hasOwnProperty.call(message, 'liquidationIncentive'))
            writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.liquidationIncentive)
          if (message.symbolDenom != null && Object.hasOwnProperty.call(message, 'symbolDenom'))
            writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.symbolDenom)
          if (message.exponent != null && Object.hasOwnProperty.call(message, 'exponent'))
            writer.uint32(/* id 11, wireType 0 =*/ 88).uint32(message.exponent)
          if (message.enableMsgSupply != null && Object.hasOwnProperty.call(message, 'enableMsgSupply'))
            writer.uint32(/* id 12, wireType 0 =*/ 96).bool(message.enableMsgSupply)
          if (message.enableMsgBorrow != null && Object.hasOwnProperty.call(message, 'enableMsgBorrow'))
            writer.uint32(/* id 13, wireType 0 =*/ 104).bool(message.enableMsgBorrow)
          if (message.blacklist != null && Object.hasOwnProperty.call(message, 'blacklist'))
            writer.uint32(/* id 14, wireType 0 =*/ 112).bool(message.blacklist)
          if (message.maxCollateralShare != null && Object.hasOwnProperty.call(message, 'maxCollateralShare'))
            writer.uint32(/* id 15, wireType 2 =*/ 122).string(message.maxCollateralShare)
          if (message.maxSupplyUtilization != null && Object.hasOwnProperty.call(message, 'maxSupplyUtilization'))
            writer.uint32(/* id 16, wireType 2 =*/ 130).string(message.maxSupplyUtilization)
          if (message.minCollateralLiquidity != null && Object.hasOwnProperty.call(message, 'minCollateralLiquidity'))
            writer.uint32(/* id 17, wireType 2 =*/ 138).string(message.minCollateralLiquidity)
          if (message.maxSupply != null && Object.hasOwnProperty.call(message, 'maxSupply'))
            writer.uint32(/* id 18, wireType 2 =*/ 146).string(message.maxSupply)
          return writer
        }

        /**
         * Encodes the specified Token message, length delimited. Does not implicitly {@link umee.leverage.v1.Token.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.Token
         * @static
         * @param {umee.leverage.v1.IToken} message Token message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Token.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a Token message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.Token
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.Token} Token
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Token.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.Token()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.baseDenom = reader.string()
                break
              case 2:
                message.reserveFactor = reader.string()
                break
              case 3:
                message.collateralWeight = reader.string()
                break
              case 4:
                message.liquidationThreshold = reader.string()
                break
              case 5:
                message.baseBorrowRate = reader.string()
                break
              case 6:
                message.kinkBorrowRate = reader.string()
                break
              case 7:
                message.maxBorrowRate = reader.string()
                break
              case 8:
                message.kinkUtilization = reader.string()
                break
              case 9:
                message.liquidationIncentive = reader.string()
                break
              case 10:
                message.symbolDenom = reader.string()
                break
              case 11:
                message.exponent = reader.uint32()
                break
              case 12:
                message.enableMsgSupply = reader.bool()
                break
              case 13:
                message.enableMsgBorrow = reader.bool()
                break
              case 14:
                message.blacklist = reader.bool()
                break
              case 15:
                message.maxCollateralShare = reader.string()
                break
              case 16:
                message.maxSupplyUtilization = reader.string()
                break
              case 17:
                message.minCollateralLiquidity = reader.string()
                break
              case 18:
                message.maxSupply = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a Token message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.Token
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.Token} Token
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Token.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a Token message.
         * @function verify
         * @memberof umee.leverage.v1.Token
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Token.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.baseDenom != null && message.hasOwnProperty('baseDenom'))
            if (!$util.isString(message.baseDenom)) return 'baseDenom: string expected'
          if (message.reserveFactor != null && message.hasOwnProperty('reserveFactor'))
            if (!$util.isString(message.reserveFactor)) return 'reserveFactor: string expected'
          if (message.collateralWeight != null && message.hasOwnProperty('collateralWeight'))
            if (!$util.isString(message.collateralWeight)) return 'collateralWeight: string expected'
          if (message.liquidationThreshold != null && message.hasOwnProperty('liquidationThreshold'))
            if (!$util.isString(message.liquidationThreshold)) return 'liquidationThreshold: string expected'
          if (message.baseBorrowRate != null && message.hasOwnProperty('baseBorrowRate'))
            if (!$util.isString(message.baseBorrowRate)) return 'baseBorrowRate: string expected'
          if (message.kinkBorrowRate != null && message.hasOwnProperty('kinkBorrowRate'))
            if (!$util.isString(message.kinkBorrowRate)) return 'kinkBorrowRate: string expected'
          if (message.maxBorrowRate != null && message.hasOwnProperty('maxBorrowRate'))
            if (!$util.isString(message.maxBorrowRate)) return 'maxBorrowRate: string expected'
          if (message.kinkUtilization != null && message.hasOwnProperty('kinkUtilization'))
            if (!$util.isString(message.kinkUtilization)) return 'kinkUtilization: string expected'
          if (message.liquidationIncentive != null && message.hasOwnProperty('liquidationIncentive'))
            if (!$util.isString(message.liquidationIncentive)) return 'liquidationIncentive: string expected'
          if (message.symbolDenom != null && message.hasOwnProperty('symbolDenom'))
            if (!$util.isString(message.symbolDenom)) return 'symbolDenom: string expected'
          if (message.exponent != null && message.hasOwnProperty('exponent'))
            if (!$util.isInteger(message.exponent)) return 'exponent: integer expected'
          if (message.enableMsgSupply != null && message.hasOwnProperty('enableMsgSupply'))
            if (typeof message.enableMsgSupply !== 'boolean') return 'enableMsgSupply: boolean expected'
          if (message.enableMsgBorrow != null && message.hasOwnProperty('enableMsgBorrow'))
            if (typeof message.enableMsgBorrow !== 'boolean') return 'enableMsgBorrow: boolean expected'
          if (message.blacklist != null && message.hasOwnProperty('blacklist'))
            if (typeof message.blacklist !== 'boolean') return 'blacklist: boolean expected'
          if (message.maxCollateralShare != null && message.hasOwnProperty('maxCollateralShare'))
            if (!$util.isString(message.maxCollateralShare)) return 'maxCollateralShare: string expected'
          if (message.maxSupplyUtilization != null && message.hasOwnProperty('maxSupplyUtilization'))
            if (!$util.isString(message.maxSupplyUtilization)) return 'maxSupplyUtilization: string expected'
          if (message.minCollateralLiquidity != null && message.hasOwnProperty('minCollateralLiquidity'))
            if (!$util.isString(message.minCollateralLiquidity)) return 'minCollateralLiquidity: string expected'
          if (message.maxSupply != null && message.hasOwnProperty('maxSupply'))
            if (!$util.isString(message.maxSupply)) return 'maxSupply: string expected'
          return null
        }

        /**
         * Creates a Token message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.Token
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.Token} Token
         */
        Token.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.Token) return object
          let message = new $root.umee.leverage.v1.Token()
          if (object.baseDenom != null) message.baseDenom = String(object.baseDenom)
          if (object.reserveFactor != null) message.reserveFactor = String(object.reserveFactor)
          if (object.collateralWeight != null) message.collateralWeight = String(object.collateralWeight)
          if (object.liquidationThreshold != null) message.liquidationThreshold = String(object.liquidationThreshold)
          if (object.baseBorrowRate != null) message.baseBorrowRate = String(object.baseBorrowRate)
          if (object.kinkBorrowRate != null) message.kinkBorrowRate = String(object.kinkBorrowRate)
          if (object.maxBorrowRate != null) message.maxBorrowRate = String(object.maxBorrowRate)
          if (object.kinkUtilization != null) message.kinkUtilization = String(object.kinkUtilization)
          if (object.liquidationIncentive != null) message.liquidationIncentive = String(object.liquidationIncentive)
          if (object.symbolDenom != null) message.symbolDenom = String(object.symbolDenom)
          if (object.exponent != null) message.exponent = object.exponent >>> 0
          if (object.enableMsgSupply != null) message.enableMsgSupply = Boolean(object.enableMsgSupply)
          if (object.enableMsgBorrow != null) message.enableMsgBorrow = Boolean(object.enableMsgBorrow)
          if (object.blacklist != null) message.blacklist = Boolean(object.blacklist)
          if (object.maxCollateralShare != null) message.maxCollateralShare = String(object.maxCollateralShare)
          if (object.maxSupplyUtilization != null) message.maxSupplyUtilization = String(object.maxSupplyUtilization)
          if (object.minCollateralLiquidity != null)
            message.minCollateralLiquidity = String(object.minCollateralLiquidity)
          if (object.maxSupply != null) message.maxSupply = String(object.maxSupply)
          return message
        }

        /**
         * Creates a plain object from a Token message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.Token
         * @static
         * @param {umee.leverage.v1.Token} message Token
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Token.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.baseDenom = ''
            object.reserveFactor = ''
            object.collateralWeight = ''
            object.liquidationThreshold = ''
            object.baseBorrowRate = ''
            object.kinkBorrowRate = ''
            object.maxBorrowRate = ''
            object.kinkUtilization = ''
            object.liquidationIncentive = ''
            object.symbolDenom = ''
            object.exponent = 0
            object.enableMsgSupply = false
            object.enableMsgBorrow = false
            object.blacklist = false
            object.maxCollateralShare = ''
            object.maxSupplyUtilization = ''
            object.minCollateralLiquidity = ''
            object.maxSupply = ''
          }
          if (message.baseDenom != null && message.hasOwnProperty('baseDenom')) object.baseDenom = message.baseDenom
          if (message.reserveFactor != null && message.hasOwnProperty('reserveFactor'))
            object.reserveFactor = message.reserveFactor
          if (message.collateralWeight != null && message.hasOwnProperty('collateralWeight'))
            object.collateralWeight = message.collateralWeight
          if (message.liquidationThreshold != null && message.hasOwnProperty('liquidationThreshold'))
            object.liquidationThreshold = message.liquidationThreshold
          if (message.baseBorrowRate != null && message.hasOwnProperty('baseBorrowRate'))
            object.baseBorrowRate = message.baseBorrowRate
          if (message.kinkBorrowRate != null && message.hasOwnProperty('kinkBorrowRate'))
            object.kinkBorrowRate = message.kinkBorrowRate
          if (message.maxBorrowRate != null && message.hasOwnProperty('maxBorrowRate'))
            object.maxBorrowRate = message.maxBorrowRate
          if (message.kinkUtilization != null && message.hasOwnProperty('kinkUtilization'))
            object.kinkUtilization = message.kinkUtilization
          if (message.liquidationIncentive != null && message.hasOwnProperty('liquidationIncentive'))
            object.liquidationIncentive = message.liquidationIncentive
          if (message.symbolDenom != null && message.hasOwnProperty('symbolDenom'))
            object.symbolDenom = message.symbolDenom
          if (message.exponent != null && message.hasOwnProperty('exponent')) object.exponent = message.exponent
          if (message.enableMsgSupply != null && message.hasOwnProperty('enableMsgSupply'))
            object.enableMsgSupply = message.enableMsgSupply
          if (message.enableMsgBorrow != null && message.hasOwnProperty('enableMsgBorrow'))
            object.enableMsgBorrow = message.enableMsgBorrow
          if (message.blacklist != null && message.hasOwnProperty('blacklist')) object.blacklist = message.blacklist
          if (message.maxCollateralShare != null && message.hasOwnProperty('maxCollateralShare'))
            object.maxCollateralShare = message.maxCollateralShare
          if (message.maxSupplyUtilization != null && message.hasOwnProperty('maxSupplyUtilization'))
            object.maxSupplyUtilization = message.maxSupplyUtilization
          if (message.minCollateralLiquidity != null && message.hasOwnProperty('minCollateralLiquidity'))
            object.minCollateralLiquidity = message.minCollateralLiquidity
          if (message.maxSupply != null && message.hasOwnProperty('maxSupply')) object.maxSupply = message.maxSupply
          return object
        }

        /**
         * Converts this Token to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.Token
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Token.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return Token
      })()

      v1.Query = (function () {
        /**
         * Constructs a new Query service.
         * @memberof umee.leverage.v1
         * @classdesc Represents a Query
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function Query(rpcImpl, requestDelimited, responseDelimited) {
          $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited)
        }

        ;(Query.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Query

        /**
         * Creates new Query service using the specified rpc implementation.
         * @function create
         * @memberof umee.leverage.v1.Query
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {Query} RPC service. Useful where requests and/or responses are streamed.
         */
        Query.create = function create(rpcImpl, requestDelimited, responseDelimited) {
          return new this(rpcImpl, requestDelimited, responseDelimited)
        }

        /**
         * Callback as used by {@link umee.leverage.v1.Query#params}.
         * @memberof umee.leverage.v1.Query
         * @typedef ParamsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.QueryParamsResponse} [response] QueryParamsResponse
         */

        /**
         * Calls Params.
         * @function params
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryParams} request QueryParams message or plain object
         * @param {umee.leverage.v1.Query.ParamsCallback} callback Node-style callback called with the error, if any, and QueryParamsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Query.prototype.params = function params(request, callback) {
            return this.rpcCall(
              params,
              $root.umee.leverage.v1.QueryParams,
              $root.umee.leverage.v1.QueryParamsResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'Params' },
        )

        /**
         * Calls Params.
         * @function params
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryParams} request QueryParams message or plain object
         * @returns {Promise<umee.leverage.v1.QueryParamsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Query#registeredTokens}.
         * @memberof umee.leverage.v1.Query
         * @typedef RegisteredTokensCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.QueryRegisteredTokensResponse} [response] QueryRegisteredTokensResponse
         */

        /**
         * Calls RegisteredTokens.
         * @function registeredTokens
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryRegisteredTokens} request QueryRegisteredTokens message or plain object
         * @param {umee.leverage.v1.Query.RegisteredTokensCallback} callback Node-style callback called with the error, if any, and QueryRegisteredTokensResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Query.prototype.registeredTokens = function registeredTokens(request, callback) {
            return this.rpcCall(
              registeredTokens,
              $root.umee.leverage.v1.QueryRegisteredTokens,
              $root.umee.leverage.v1.QueryRegisteredTokensResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'RegisteredTokens' },
        )

        /**
         * Calls RegisteredTokens.
         * @function registeredTokens
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryRegisteredTokens} request QueryRegisteredTokens message or plain object
         * @returns {Promise<umee.leverage.v1.QueryRegisteredTokensResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Query#marketSummary}.
         * @memberof umee.leverage.v1.Query
         * @typedef MarketSummaryCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.QueryMarketSummaryResponse} [response] QueryMarketSummaryResponse
         */

        /**
         * Calls MarketSummary.
         * @function marketSummary
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryMarketSummary} request QueryMarketSummary message or plain object
         * @param {umee.leverage.v1.Query.MarketSummaryCallback} callback Node-style callback called with the error, if any, and QueryMarketSummaryResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Query.prototype.marketSummary = function marketSummary(request, callback) {
            return this.rpcCall(
              marketSummary,
              $root.umee.leverage.v1.QueryMarketSummary,
              $root.umee.leverage.v1.QueryMarketSummaryResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'MarketSummary' },
        )

        /**
         * Calls MarketSummary.
         * @function marketSummary
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryMarketSummary} request QueryMarketSummary message or plain object
         * @returns {Promise<umee.leverage.v1.QueryMarketSummaryResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Query#accountBalances}.
         * @memberof umee.leverage.v1.Query
         * @typedef AccountBalancesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.QueryAccountBalancesResponse} [response] QueryAccountBalancesResponse
         */

        /**
         * Calls AccountBalances.
         * @function accountBalances
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryAccountBalances} request QueryAccountBalances message or plain object
         * @param {umee.leverage.v1.Query.AccountBalancesCallback} callback Node-style callback called with the error, if any, and QueryAccountBalancesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Query.prototype.accountBalances = function accountBalances(request, callback) {
            return this.rpcCall(
              accountBalances,
              $root.umee.leverage.v1.QueryAccountBalances,
              $root.umee.leverage.v1.QueryAccountBalancesResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'AccountBalances' },
        )

        /**
         * Calls AccountBalances.
         * @function accountBalances
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryAccountBalances} request QueryAccountBalances message or plain object
         * @returns {Promise<umee.leverage.v1.QueryAccountBalancesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Query#accountSummary}.
         * @memberof umee.leverage.v1.Query
         * @typedef AccountSummaryCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.QueryAccountSummaryResponse} [response] QueryAccountSummaryResponse
         */

        /**
         * Calls AccountSummary.
         * @function accountSummary
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryAccountSummary} request QueryAccountSummary message or plain object
         * @param {umee.leverage.v1.Query.AccountSummaryCallback} callback Node-style callback called with the error, if any, and QueryAccountSummaryResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Query.prototype.accountSummary = function accountSummary(request, callback) {
            return this.rpcCall(
              accountSummary,
              $root.umee.leverage.v1.QueryAccountSummary,
              $root.umee.leverage.v1.QueryAccountSummaryResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'AccountSummary' },
        )

        /**
         * Calls AccountSummary.
         * @function accountSummary
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryAccountSummary} request QueryAccountSummary message or plain object
         * @returns {Promise<umee.leverage.v1.QueryAccountSummaryResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Query#liquidationTargets}.
         * @memberof umee.leverage.v1.Query
         * @typedef LiquidationTargetsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.QueryLiquidationTargetsResponse} [response] QueryLiquidationTargetsResponse
         */

        /**
         * Calls LiquidationTargets.
         * @function liquidationTargets
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryLiquidationTargets} request QueryLiquidationTargets message or plain object
         * @param {umee.leverage.v1.Query.LiquidationTargetsCallback} callback Node-style callback called with the error, if any, and QueryLiquidationTargetsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Query.prototype.liquidationTargets = function liquidationTargets(request, callback) {
            return this.rpcCall(
              liquidationTargets,
              $root.umee.leverage.v1.QueryLiquidationTargets,
              $root.umee.leverage.v1.QueryLiquidationTargetsResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'LiquidationTargets' },
        )

        /**
         * Calls LiquidationTargets.
         * @function liquidationTargets
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryLiquidationTargets} request QueryLiquidationTargets message or plain object
         * @returns {Promise<umee.leverage.v1.QueryLiquidationTargetsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link umee.leverage.v1.Query#badDebts}.
         * @memberof umee.leverage.v1.Query
         * @typedef BadDebtsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {umee.leverage.v1.QueryBadDebtsResponse} [response] QueryBadDebtsResponse
         */

        /**
         * Calls BadDebts.
         * @function badDebts
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryBadDebts} request QueryBadDebts message or plain object
         * @param {umee.leverage.v1.Query.BadDebtsCallback} callback Node-style callback called with the error, if any, and QueryBadDebtsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(
          (Query.prototype.badDebts = function badDebts(request, callback) {
            return this.rpcCall(
              badDebts,
              $root.umee.leverage.v1.QueryBadDebts,
              $root.umee.leverage.v1.QueryBadDebtsResponse,
              request,
              callback,
            )
          }),
          'name',
          { value: 'BadDebts' },
        )

        /**
         * Calls BadDebts.
         * @function badDebts
         * @memberof umee.leverage.v1.Query
         * @instance
         * @param {umee.leverage.v1.IQueryBadDebts} request QueryBadDebts message or plain object
         * @returns {Promise<umee.leverage.v1.QueryBadDebtsResponse>} Promise
         * @variation 2
         */

        return Query
      })()

      v1.QueryParams = (function () {
        /**
         * Properties of a QueryParams.
         * @memberof umee.leverage.v1
         * @interface IQueryParams
         */

        /**
         * Constructs a new QueryParams.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryParams.
         * @implements IQueryParams
         * @constructor
         * @param {umee.leverage.v1.IQueryParams=} [properties] Properties to set
         */
        function QueryParams(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Creates a new QueryParams instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryParams
         * @static
         * @param {umee.leverage.v1.IQueryParams=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryParams} QueryParams instance
         */
        QueryParams.create = function create(properties) {
          return new QueryParams(properties)
        }

        /**
         * Encodes the specified QueryParams message. Does not implicitly {@link umee.leverage.v1.QueryParams.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryParams
         * @static
         * @param {umee.leverage.v1.IQueryParams} message QueryParams message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryParams.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          return writer
        }

        /**
         * Encodes the specified QueryParams message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryParams.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryParams
         * @static
         * @param {umee.leverage.v1.IQueryParams} message QueryParams message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryParams.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryParams message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryParams
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryParams} QueryParams
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryParams.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryParams()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryParams message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryParams
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryParams} QueryParams
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryParams.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryParams message.
         * @function verify
         * @memberof umee.leverage.v1.QueryParams
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryParams.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          return null
        }

        /**
         * Creates a QueryParams message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryParams
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryParams} QueryParams
         */
        QueryParams.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryParams) return object
          return new $root.umee.leverage.v1.QueryParams()
        }

        /**
         * Creates a plain object from a QueryParams message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryParams
         * @static
         * @param {umee.leverage.v1.QueryParams} message QueryParams
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryParams.toObject = function toObject() {
          return {}
        }

        /**
         * Converts this QueryParams to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryParams
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryParams.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryParams
      })()

      v1.QueryParamsResponse = (function () {
        /**
         * Properties of a QueryParamsResponse.
         * @memberof umee.leverage.v1
         * @interface IQueryParamsResponse
         * @property {umee.leverage.v1.IParams|null} [params] QueryParamsResponse params
         */

        /**
         * Constructs a new QueryParamsResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryParamsResponse.
         * @implements IQueryParamsResponse
         * @constructor
         * @param {umee.leverage.v1.IQueryParamsResponse=} [properties] Properties to set
         */
        function QueryParamsResponse(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * QueryParamsResponse params.
         * @member {umee.leverage.v1.IParams|null|undefined} params
         * @memberof umee.leverage.v1.QueryParamsResponse
         * @instance
         */
        QueryParamsResponse.prototype.params = null

        /**
         * Creates a new QueryParamsResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryParamsResponse
         * @static
         * @param {umee.leverage.v1.IQueryParamsResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryParamsResponse} QueryParamsResponse instance
         */
        QueryParamsResponse.create = function create(properties) {
          return new QueryParamsResponse(properties)
        }

        /**
         * Encodes the specified QueryParamsResponse message. Does not implicitly {@link umee.leverage.v1.QueryParamsResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryParamsResponse
         * @static
         * @param {umee.leverage.v1.IQueryParamsResponse} message QueryParamsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryParamsResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.params != null && Object.hasOwnProperty.call(message, 'params'))
            $root.umee.leverage.v1.Params.encode(
              message.params,
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified QueryParamsResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryParamsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryParamsResponse
         * @static
         * @param {umee.leverage.v1.IQueryParamsResponse} message QueryParamsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryParamsResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryParamsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryParamsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryParamsResponse} QueryParamsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryParamsResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryParamsResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.params = $root.umee.leverage.v1.Params.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryParamsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryParamsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryParamsResponse} QueryParamsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryParamsResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryParamsResponse message.
         * @function verify
         * @memberof umee.leverage.v1.QueryParamsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryParamsResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.params != null && message.hasOwnProperty('params')) {
            let error = $root.umee.leverage.v1.Params.verify(message.params)
            if (error) return 'params.' + error
          }
          return null
        }

        /**
         * Creates a QueryParamsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryParamsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryParamsResponse} QueryParamsResponse
         */
        QueryParamsResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryParamsResponse) return object
          let message = new $root.umee.leverage.v1.QueryParamsResponse()
          if (object.params != null) {
            if (typeof object.params !== 'object')
              throw TypeError('.umee.leverage.v1.QueryParamsResponse.params: object expected')
            message.params = $root.umee.leverage.v1.Params.fromObject(object.params)
          }
          return message
        }

        /**
         * Creates a plain object from a QueryParamsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryParamsResponse
         * @static
         * @param {umee.leverage.v1.QueryParamsResponse} message QueryParamsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryParamsResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) object.params = null
          if (message.params != null && message.hasOwnProperty('params'))
            object.params = $root.umee.leverage.v1.Params.toObject(message.params, options)
          return object
        }

        /**
         * Converts this QueryParamsResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryParamsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryParamsResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryParamsResponse
      })()

      v1.QueryRegisteredTokens = (function () {
        /**
         * Properties of a QueryRegisteredTokens.
         * @memberof umee.leverage.v1
         * @interface IQueryRegisteredTokens
         */

        /**
         * Constructs a new QueryRegisteredTokens.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryRegisteredTokens.
         * @implements IQueryRegisteredTokens
         * @constructor
         * @param {umee.leverage.v1.IQueryRegisteredTokens=} [properties] Properties to set
         */
        function QueryRegisteredTokens(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Creates a new QueryRegisteredTokens instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryRegisteredTokens
         * @static
         * @param {umee.leverage.v1.IQueryRegisteredTokens=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryRegisteredTokens} QueryRegisteredTokens instance
         */
        QueryRegisteredTokens.create = function create(properties) {
          return new QueryRegisteredTokens(properties)
        }

        /**
         * Encodes the specified QueryRegisteredTokens message. Does not implicitly {@link umee.leverage.v1.QueryRegisteredTokens.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryRegisteredTokens
         * @static
         * @param {umee.leverage.v1.IQueryRegisteredTokens} message QueryRegisteredTokens message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryRegisteredTokens.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          return writer
        }

        /**
         * Encodes the specified QueryRegisteredTokens message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryRegisteredTokens.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryRegisteredTokens
         * @static
         * @param {umee.leverage.v1.IQueryRegisteredTokens} message QueryRegisteredTokens message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryRegisteredTokens.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryRegisteredTokens message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryRegisteredTokens
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryRegisteredTokens} QueryRegisteredTokens
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryRegisteredTokens.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryRegisteredTokens()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryRegisteredTokens message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryRegisteredTokens
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryRegisteredTokens} QueryRegisteredTokens
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryRegisteredTokens.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryRegisteredTokens message.
         * @function verify
         * @memberof umee.leverage.v1.QueryRegisteredTokens
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryRegisteredTokens.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          return null
        }

        /**
         * Creates a QueryRegisteredTokens message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryRegisteredTokens
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryRegisteredTokens} QueryRegisteredTokens
         */
        QueryRegisteredTokens.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryRegisteredTokens) return object
          return new $root.umee.leverage.v1.QueryRegisteredTokens()
        }

        /**
         * Creates a plain object from a QueryRegisteredTokens message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryRegisteredTokens
         * @static
         * @param {umee.leverage.v1.QueryRegisteredTokens} message QueryRegisteredTokens
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryRegisteredTokens.toObject = function toObject() {
          return {}
        }

        /**
         * Converts this QueryRegisteredTokens to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryRegisteredTokens
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryRegisteredTokens.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryRegisteredTokens
      })()

      v1.QueryRegisteredTokensResponse = (function () {
        /**
         * Properties of a QueryRegisteredTokensResponse.
         * @memberof umee.leverage.v1
         * @interface IQueryRegisteredTokensResponse
         * @property {Array.<umee.leverage.v1.IToken>|null} [registry] QueryRegisteredTokensResponse registry
         */

        /**
         * Constructs a new QueryRegisteredTokensResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryRegisteredTokensResponse.
         * @implements IQueryRegisteredTokensResponse
         * @constructor
         * @param {umee.leverage.v1.IQueryRegisteredTokensResponse=} [properties] Properties to set
         */
        function QueryRegisteredTokensResponse(properties) {
          this.registry = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * QueryRegisteredTokensResponse registry.
         * @member {Array.<umee.leverage.v1.IToken>} registry
         * @memberof umee.leverage.v1.QueryRegisteredTokensResponse
         * @instance
         */
        QueryRegisteredTokensResponse.prototype.registry = $util.emptyArray

        /**
         * Creates a new QueryRegisteredTokensResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryRegisteredTokensResponse
         * @static
         * @param {umee.leverage.v1.IQueryRegisteredTokensResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryRegisteredTokensResponse} QueryRegisteredTokensResponse instance
         */
        QueryRegisteredTokensResponse.create = function create(properties) {
          return new QueryRegisteredTokensResponse(properties)
        }

        /**
         * Encodes the specified QueryRegisteredTokensResponse message. Does not implicitly {@link umee.leverage.v1.QueryRegisteredTokensResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryRegisteredTokensResponse
         * @static
         * @param {umee.leverage.v1.IQueryRegisteredTokensResponse} message QueryRegisteredTokensResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryRegisteredTokensResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.registry != null && message.registry.length)
            for (let i = 0; i < message.registry.length; ++i)
              $root.umee.leverage.v1.Token.encode(
                message.registry[i],
                writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
              ).ldelim()
          return writer
        }

        /**
         * Encodes the specified QueryRegisteredTokensResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryRegisteredTokensResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryRegisteredTokensResponse
         * @static
         * @param {umee.leverage.v1.IQueryRegisteredTokensResponse} message QueryRegisteredTokensResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryRegisteredTokensResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryRegisteredTokensResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryRegisteredTokensResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryRegisteredTokensResponse} QueryRegisteredTokensResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryRegisteredTokensResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryRegisteredTokensResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                if (!(message.registry && message.registry.length)) message.registry = []
                message.registry.push($root.umee.leverage.v1.Token.decode(reader, reader.uint32()))
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryRegisteredTokensResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryRegisteredTokensResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryRegisteredTokensResponse} QueryRegisteredTokensResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryRegisteredTokensResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryRegisteredTokensResponse message.
         * @function verify
         * @memberof umee.leverage.v1.QueryRegisteredTokensResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryRegisteredTokensResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.registry != null && message.hasOwnProperty('registry')) {
            if (!Array.isArray(message.registry)) return 'registry: array expected'
            for (let i = 0; i < message.registry.length; ++i) {
              let error = $root.umee.leverage.v1.Token.verify(message.registry[i])
              if (error) return 'registry.' + error
            }
          }
          return null
        }

        /**
         * Creates a QueryRegisteredTokensResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryRegisteredTokensResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryRegisteredTokensResponse} QueryRegisteredTokensResponse
         */
        QueryRegisteredTokensResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryRegisteredTokensResponse) return object
          let message = new $root.umee.leverage.v1.QueryRegisteredTokensResponse()
          if (object.registry) {
            if (!Array.isArray(object.registry))
              throw TypeError('.umee.leverage.v1.QueryRegisteredTokensResponse.registry: array expected')
            message.registry = []
            for (let i = 0; i < object.registry.length; ++i) {
              if (typeof object.registry[i] !== 'object')
                throw TypeError('.umee.leverage.v1.QueryRegisteredTokensResponse.registry: object expected')
              message.registry[i] = $root.umee.leverage.v1.Token.fromObject(object.registry[i])
            }
          }
          return message
        }

        /**
         * Creates a plain object from a QueryRegisteredTokensResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryRegisteredTokensResponse
         * @static
         * @param {umee.leverage.v1.QueryRegisteredTokensResponse} message QueryRegisteredTokensResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryRegisteredTokensResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.registry = []
          if (message.registry && message.registry.length) {
            object.registry = []
            for (let j = 0; j < message.registry.length; ++j)
              object.registry[j] = $root.umee.leverage.v1.Token.toObject(message.registry[j], options)
          }
          return object
        }

        /**
         * Converts this QueryRegisteredTokensResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryRegisteredTokensResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryRegisteredTokensResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryRegisteredTokensResponse
      })()

      v1.QueryMarketSummary = (function () {
        /**
         * Properties of a QueryMarketSummary.
         * @memberof umee.leverage.v1
         * @interface IQueryMarketSummary
         * @property {string|null} [denom] QueryMarketSummary denom
         */

        /**
         * Constructs a new QueryMarketSummary.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryMarketSummary.
         * @implements IQueryMarketSummary
         * @constructor
         * @param {umee.leverage.v1.IQueryMarketSummary=} [properties] Properties to set
         */
        function QueryMarketSummary(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * QueryMarketSummary denom.
         * @member {string} denom
         * @memberof umee.leverage.v1.QueryMarketSummary
         * @instance
         */
        QueryMarketSummary.prototype.denom = ''

        /**
         * Creates a new QueryMarketSummary instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryMarketSummary
         * @static
         * @param {umee.leverage.v1.IQueryMarketSummary=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryMarketSummary} QueryMarketSummary instance
         */
        QueryMarketSummary.create = function create(properties) {
          return new QueryMarketSummary(properties)
        }

        /**
         * Encodes the specified QueryMarketSummary message. Does not implicitly {@link umee.leverage.v1.QueryMarketSummary.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryMarketSummary
         * @static
         * @param {umee.leverage.v1.IQueryMarketSummary} message QueryMarketSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryMarketSummary.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.denom != null && Object.hasOwnProperty.call(message, 'denom'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.denom)
          return writer
        }

        /**
         * Encodes the specified QueryMarketSummary message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryMarketSummary.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryMarketSummary
         * @static
         * @param {umee.leverage.v1.IQueryMarketSummary} message QueryMarketSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryMarketSummary.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryMarketSummary message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryMarketSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryMarketSummary} QueryMarketSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryMarketSummary.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryMarketSummary()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.denom = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryMarketSummary message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryMarketSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryMarketSummary} QueryMarketSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryMarketSummary.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryMarketSummary message.
         * @function verify
         * @memberof umee.leverage.v1.QueryMarketSummary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryMarketSummary.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.denom != null && message.hasOwnProperty('denom'))
            if (!$util.isString(message.denom)) return 'denom: string expected'
          return null
        }

        /**
         * Creates a QueryMarketSummary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryMarketSummary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryMarketSummary} QueryMarketSummary
         */
        QueryMarketSummary.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryMarketSummary) return object
          let message = new $root.umee.leverage.v1.QueryMarketSummary()
          if (object.denom != null) message.denom = String(object.denom)
          return message
        }

        /**
         * Creates a plain object from a QueryMarketSummary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryMarketSummary
         * @static
         * @param {umee.leverage.v1.QueryMarketSummary} message QueryMarketSummary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryMarketSummary.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) object.denom = ''
          if (message.denom != null && message.hasOwnProperty('denom')) object.denom = message.denom
          return object
        }

        /**
         * Converts this QueryMarketSummary to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryMarketSummary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryMarketSummary.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryMarketSummary
      })()

      v1.QueryMarketSummaryResponse = (function () {
        /**
         * Properties of a QueryMarketSummaryResponse.
         * @memberof umee.leverage.v1
         * @interface IQueryMarketSummaryResponse
         * @property {string|null} [symbolDenom] QueryMarketSummaryResponse symbolDenom
         * @property {number|null} [exponent] QueryMarketSummaryResponse exponent
         * @property {string|null} [oraclePrice] QueryMarketSummaryResponse oraclePrice
         * @property {string|null} [uTokenExchangeRate] QueryMarketSummaryResponse uTokenExchangeRate
         * @property {string|null} [supply_APY] QueryMarketSummaryResponse supply_APY
         * @property {string|null} [borrow_APY] QueryMarketSummaryResponse borrow_APY
         * @property {string|null} [supplied] QueryMarketSummaryResponse supplied
         * @property {string|null} [reserved] QueryMarketSummaryResponse reserved
         * @property {string|null} [collateral] QueryMarketSummaryResponse collateral
         * @property {string|null} [borrowed] QueryMarketSummaryResponse borrowed
         * @property {string|null} [liquidity] QueryMarketSummaryResponse liquidity
         * @property {string|null} [maximumBorrow] QueryMarketSummaryResponse maximumBorrow
         * @property {string|null} [maximumCollateral] QueryMarketSummaryResponse maximumCollateral
         * @property {string|null} [minimumLiquidity] QueryMarketSummaryResponse minimumLiquidity
         * @property {string|null} [uTokenSupply] QueryMarketSummaryResponse uTokenSupply
         * @property {string|null} [availableBorrow] QueryMarketSummaryResponse availableBorrow
         * @property {string|null} [availableWithdraw] QueryMarketSummaryResponse availableWithdraw
         * @property {string|null} [availableCollateralize] QueryMarketSummaryResponse availableCollateralize
         */

        /**
         * Constructs a new QueryMarketSummaryResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryMarketSummaryResponse.
         * @implements IQueryMarketSummaryResponse
         * @constructor
         * @param {umee.leverage.v1.IQueryMarketSummaryResponse=} [properties] Properties to set
         */
        function QueryMarketSummaryResponse(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * QueryMarketSummaryResponse symbolDenom.
         * @member {string} symbolDenom
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.symbolDenom = ''

        /**
         * QueryMarketSummaryResponse exponent.
         * @member {number} exponent
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.exponent = 0

        /**
         * QueryMarketSummaryResponse oraclePrice.
         * @member {string} oraclePrice
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.oraclePrice = ''

        /**
         * QueryMarketSummaryResponse uTokenExchangeRate.
         * @member {string} uTokenExchangeRate
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.uTokenExchangeRate = ''

        /**
         * QueryMarketSummaryResponse supply_APY.
         * @member {string} supply_APY
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.supply_APY = ''

        /**
         * QueryMarketSummaryResponse borrow_APY.
         * @member {string} borrow_APY
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.borrow_APY = ''

        /**
         * QueryMarketSummaryResponse supplied.
         * @member {string} supplied
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.supplied = ''

        /**
         * QueryMarketSummaryResponse reserved.
         * @member {string} reserved
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.reserved = ''

        /**
         * QueryMarketSummaryResponse collateral.
         * @member {string} collateral
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.collateral = ''

        /**
         * QueryMarketSummaryResponse borrowed.
         * @member {string} borrowed
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.borrowed = ''

        /**
         * QueryMarketSummaryResponse liquidity.
         * @member {string} liquidity
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.liquidity = ''

        /**
         * QueryMarketSummaryResponse maximumBorrow.
         * @member {string} maximumBorrow
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.maximumBorrow = ''

        /**
         * QueryMarketSummaryResponse maximumCollateral.
         * @member {string} maximumCollateral
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.maximumCollateral = ''

        /**
         * QueryMarketSummaryResponse minimumLiquidity.
         * @member {string} minimumLiquidity
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.minimumLiquidity = ''

        /**
         * QueryMarketSummaryResponse uTokenSupply.
         * @member {string} uTokenSupply
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.uTokenSupply = ''

        /**
         * QueryMarketSummaryResponse availableBorrow.
         * @member {string} availableBorrow
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.availableBorrow = ''

        /**
         * QueryMarketSummaryResponse availableWithdraw.
         * @member {string} availableWithdraw
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.availableWithdraw = ''

        /**
         * QueryMarketSummaryResponse availableCollateralize.
         * @member {string} availableCollateralize
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         */
        QueryMarketSummaryResponse.prototype.availableCollateralize = ''

        /**
         * Creates a new QueryMarketSummaryResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @static
         * @param {umee.leverage.v1.IQueryMarketSummaryResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryMarketSummaryResponse} QueryMarketSummaryResponse instance
         */
        QueryMarketSummaryResponse.create = function create(properties) {
          return new QueryMarketSummaryResponse(properties)
        }

        /**
         * Encodes the specified QueryMarketSummaryResponse message. Does not implicitly {@link umee.leverage.v1.QueryMarketSummaryResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @static
         * @param {umee.leverage.v1.IQueryMarketSummaryResponse} message QueryMarketSummaryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryMarketSummaryResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.symbolDenom != null && Object.hasOwnProperty.call(message, 'symbolDenom'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.symbolDenom)
          if (message.exponent != null && Object.hasOwnProperty.call(message, 'exponent'))
            writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.exponent)
          if (message.oraclePrice != null && Object.hasOwnProperty.call(message, 'oraclePrice'))
            writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.oraclePrice)
          if (message.uTokenExchangeRate != null && Object.hasOwnProperty.call(message, 'uTokenExchangeRate'))
            writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.uTokenExchangeRate)
          if (message.supply_APY != null && Object.hasOwnProperty.call(message, 'supply_APY'))
            writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.supply_APY)
          if (message.borrow_APY != null && Object.hasOwnProperty.call(message, 'borrow_APY'))
            writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.borrow_APY)
          if (message.supplied != null && Object.hasOwnProperty.call(message, 'supplied'))
            writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.supplied)
          if (message.reserved != null && Object.hasOwnProperty.call(message, 'reserved'))
            writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.reserved)
          if (message.collateral != null && Object.hasOwnProperty.call(message, 'collateral'))
            writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.collateral)
          if (message.borrowed != null && Object.hasOwnProperty.call(message, 'borrowed'))
            writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.borrowed)
          if (message.liquidity != null && Object.hasOwnProperty.call(message, 'liquidity'))
            writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.liquidity)
          if (message.maximumBorrow != null && Object.hasOwnProperty.call(message, 'maximumBorrow'))
            writer.uint32(/* id 12, wireType 2 =*/ 98).string(message.maximumBorrow)
          if (message.maximumCollateral != null && Object.hasOwnProperty.call(message, 'maximumCollateral'))
            writer.uint32(/* id 13, wireType 2 =*/ 106).string(message.maximumCollateral)
          if (message.minimumLiquidity != null && Object.hasOwnProperty.call(message, 'minimumLiquidity'))
            writer.uint32(/* id 14, wireType 2 =*/ 114).string(message.minimumLiquidity)
          if (message.uTokenSupply != null && Object.hasOwnProperty.call(message, 'uTokenSupply'))
            writer.uint32(/* id 15, wireType 2 =*/ 122).string(message.uTokenSupply)
          if (message.availableBorrow != null && Object.hasOwnProperty.call(message, 'availableBorrow'))
            writer.uint32(/* id 16, wireType 2 =*/ 130).string(message.availableBorrow)
          if (message.availableWithdraw != null && Object.hasOwnProperty.call(message, 'availableWithdraw'))
            writer.uint32(/* id 17, wireType 2 =*/ 138).string(message.availableWithdraw)
          if (message.availableCollateralize != null && Object.hasOwnProperty.call(message, 'availableCollateralize'))
            writer.uint32(/* id 18, wireType 2 =*/ 146).string(message.availableCollateralize)
          return writer
        }

        /**
         * Encodes the specified QueryMarketSummaryResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryMarketSummaryResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @static
         * @param {umee.leverage.v1.IQueryMarketSummaryResponse} message QueryMarketSummaryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryMarketSummaryResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryMarketSummaryResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryMarketSummaryResponse} QueryMarketSummaryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryMarketSummaryResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryMarketSummaryResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.symbolDenom = reader.string()
                break
              case 2:
                message.exponent = reader.uint32()
                break
              case 3:
                message.oraclePrice = reader.string()
                break
              case 4:
                message.uTokenExchangeRate = reader.string()
                break
              case 5:
                message.supply_APY = reader.string()
                break
              case 6:
                message.borrow_APY = reader.string()
                break
              case 7:
                message.supplied = reader.string()
                break
              case 8:
                message.reserved = reader.string()
                break
              case 9:
                message.collateral = reader.string()
                break
              case 10:
                message.borrowed = reader.string()
                break
              case 11:
                message.liquidity = reader.string()
                break
              case 12:
                message.maximumBorrow = reader.string()
                break
              case 13:
                message.maximumCollateral = reader.string()
                break
              case 14:
                message.minimumLiquidity = reader.string()
                break
              case 15:
                message.uTokenSupply = reader.string()
                break
              case 16:
                message.availableBorrow = reader.string()
                break
              case 17:
                message.availableWithdraw = reader.string()
                break
              case 18:
                message.availableCollateralize = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryMarketSummaryResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryMarketSummaryResponse} QueryMarketSummaryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryMarketSummaryResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryMarketSummaryResponse message.
         * @function verify
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryMarketSummaryResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.symbolDenom != null && message.hasOwnProperty('symbolDenom'))
            if (!$util.isString(message.symbolDenom)) return 'symbolDenom: string expected'
          if (message.exponent != null && message.hasOwnProperty('exponent'))
            if (!$util.isInteger(message.exponent)) return 'exponent: integer expected'
          if (message.oraclePrice != null && message.hasOwnProperty('oraclePrice'))
            if (!$util.isString(message.oraclePrice)) return 'oraclePrice: string expected'
          if (message.uTokenExchangeRate != null && message.hasOwnProperty('uTokenExchangeRate'))
            if (!$util.isString(message.uTokenExchangeRate)) return 'uTokenExchangeRate: string expected'
          if (message.supply_APY != null && message.hasOwnProperty('supply_APY'))
            if (!$util.isString(message.supply_APY)) return 'supply_APY: string expected'
          if (message.borrow_APY != null && message.hasOwnProperty('borrow_APY'))
            if (!$util.isString(message.borrow_APY)) return 'borrow_APY: string expected'
          if (message.supplied != null && message.hasOwnProperty('supplied'))
            if (!$util.isString(message.supplied)) return 'supplied: string expected'
          if (message.reserved != null && message.hasOwnProperty('reserved'))
            if (!$util.isString(message.reserved)) return 'reserved: string expected'
          if (message.collateral != null && message.hasOwnProperty('collateral'))
            if (!$util.isString(message.collateral)) return 'collateral: string expected'
          if (message.borrowed != null && message.hasOwnProperty('borrowed'))
            if (!$util.isString(message.borrowed)) return 'borrowed: string expected'
          if (message.liquidity != null && message.hasOwnProperty('liquidity'))
            if (!$util.isString(message.liquidity)) return 'liquidity: string expected'
          if (message.maximumBorrow != null && message.hasOwnProperty('maximumBorrow'))
            if (!$util.isString(message.maximumBorrow)) return 'maximumBorrow: string expected'
          if (message.maximumCollateral != null && message.hasOwnProperty('maximumCollateral'))
            if (!$util.isString(message.maximumCollateral)) return 'maximumCollateral: string expected'
          if (message.minimumLiquidity != null && message.hasOwnProperty('minimumLiquidity'))
            if (!$util.isString(message.minimumLiquidity)) return 'minimumLiquidity: string expected'
          if (message.uTokenSupply != null && message.hasOwnProperty('uTokenSupply'))
            if (!$util.isString(message.uTokenSupply)) return 'uTokenSupply: string expected'
          if (message.availableBorrow != null && message.hasOwnProperty('availableBorrow'))
            if (!$util.isString(message.availableBorrow)) return 'availableBorrow: string expected'
          if (message.availableWithdraw != null && message.hasOwnProperty('availableWithdraw'))
            if (!$util.isString(message.availableWithdraw)) return 'availableWithdraw: string expected'
          if (message.availableCollateralize != null && message.hasOwnProperty('availableCollateralize'))
            if (!$util.isString(message.availableCollateralize)) return 'availableCollateralize: string expected'
          return null
        }

        /**
         * Creates a QueryMarketSummaryResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryMarketSummaryResponse} QueryMarketSummaryResponse
         */
        QueryMarketSummaryResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryMarketSummaryResponse) return object
          let message = new $root.umee.leverage.v1.QueryMarketSummaryResponse()
          if (object.symbolDenom != null) message.symbolDenom = String(object.symbolDenom)
          if (object.exponent != null) message.exponent = object.exponent >>> 0
          if (object.oraclePrice != null) message.oraclePrice = String(object.oraclePrice)
          if (object.uTokenExchangeRate != null) message.uTokenExchangeRate = String(object.uTokenExchangeRate)
          if (object.supply_APY != null) message.supply_APY = String(object.supply_APY)
          if (object.borrow_APY != null) message.borrow_APY = String(object.borrow_APY)
          if (object.supplied != null) message.supplied = String(object.supplied)
          if (object.reserved != null) message.reserved = String(object.reserved)
          if (object.collateral != null) message.collateral = String(object.collateral)
          if (object.borrowed != null) message.borrowed = String(object.borrowed)
          if (object.liquidity != null) message.liquidity = String(object.liquidity)
          if (object.maximumBorrow != null) message.maximumBorrow = String(object.maximumBorrow)
          if (object.maximumCollateral != null) message.maximumCollateral = String(object.maximumCollateral)
          if (object.minimumLiquidity != null) message.minimumLiquidity = String(object.minimumLiquidity)
          if (object.uTokenSupply != null) message.uTokenSupply = String(object.uTokenSupply)
          if (object.availableBorrow != null) message.availableBorrow = String(object.availableBorrow)
          if (object.availableWithdraw != null) message.availableWithdraw = String(object.availableWithdraw)
          if (object.availableCollateralize != null)
            message.availableCollateralize = String(object.availableCollateralize)
          return message
        }

        /**
         * Creates a plain object from a QueryMarketSummaryResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @static
         * @param {umee.leverage.v1.QueryMarketSummaryResponse} message QueryMarketSummaryResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryMarketSummaryResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.symbolDenom = ''
            object.exponent = 0
            object.oraclePrice = ''
            object.uTokenExchangeRate = ''
            object.supply_APY = ''
            object.borrow_APY = ''
            object.supplied = ''
            object.reserved = ''
            object.collateral = ''
            object.borrowed = ''
            object.liquidity = ''
            object.maximumBorrow = ''
            object.maximumCollateral = ''
            object.minimumLiquidity = ''
            object.uTokenSupply = ''
            object.availableBorrow = ''
            object.availableWithdraw = ''
            object.availableCollateralize = ''
          }
          if (message.symbolDenom != null && message.hasOwnProperty('symbolDenom'))
            object.symbolDenom = message.symbolDenom
          if (message.exponent != null && message.hasOwnProperty('exponent')) object.exponent = message.exponent
          if (message.oraclePrice != null && message.hasOwnProperty('oraclePrice'))
            object.oraclePrice = message.oraclePrice
          if (message.uTokenExchangeRate != null && message.hasOwnProperty('uTokenExchangeRate'))
            object.uTokenExchangeRate = message.uTokenExchangeRate
          if (message.supply_APY != null && message.hasOwnProperty('supply_APY')) object.supply_APY = message.supply_APY
          if (message.borrow_APY != null && message.hasOwnProperty('borrow_APY')) object.borrow_APY = message.borrow_APY
          if (message.supplied != null && message.hasOwnProperty('supplied')) object.supplied = message.supplied
          if (message.reserved != null && message.hasOwnProperty('reserved')) object.reserved = message.reserved
          if (message.collateral != null && message.hasOwnProperty('collateral')) object.collateral = message.collateral
          if (message.borrowed != null && message.hasOwnProperty('borrowed')) object.borrowed = message.borrowed
          if (message.liquidity != null && message.hasOwnProperty('liquidity')) object.liquidity = message.liquidity
          if (message.maximumBorrow != null && message.hasOwnProperty('maximumBorrow'))
            object.maximumBorrow = message.maximumBorrow
          if (message.maximumCollateral != null && message.hasOwnProperty('maximumCollateral'))
            object.maximumCollateral = message.maximumCollateral
          if (message.minimumLiquidity != null && message.hasOwnProperty('minimumLiquidity'))
            object.minimumLiquidity = message.minimumLiquidity
          if (message.uTokenSupply != null && message.hasOwnProperty('uTokenSupply'))
            object.uTokenSupply = message.uTokenSupply
          if (message.availableBorrow != null && message.hasOwnProperty('availableBorrow'))
            object.availableBorrow = message.availableBorrow
          if (message.availableWithdraw != null && message.hasOwnProperty('availableWithdraw'))
            object.availableWithdraw = message.availableWithdraw
          if (message.availableCollateralize != null && message.hasOwnProperty('availableCollateralize'))
            object.availableCollateralize = message.availableCollateralize
          return object
        }

        /**
         * Converts this QueryMarketSummaryResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryMarketSummaryResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryMarketSummaryResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryMarketSummaryResponse
      })()

      v1.QueryAccountBalances = (function () {
        /**
         * Properties of a QueryAccountBalances.
         * @memberof umee.leverage.v1
         * @interface IQueryAccountBalances
         * @property {string|null} [address] QueryAccountBalances address
         */

        /**
         * Constructs a new QueryAccountBalances.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryAccountBalances.
         * @implements IQueryAccountBalances
         * @constructor
         * @param {umee.leverage.v1.IQueryAccountBalances=} [properties] Properties to set
         */
        function QueryAccountBalances(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * QueryAccountBalances address.
         * @member {string} address
         * @memberof umee.leverage.v1.QueryAccountBalances
         * @instance
         */
        QueryAccountBalances.prototype.address = ''

        /**
         * Creates a new QueryAccountBalances instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryAccountBalances
         * @static
         * @param {umee.leverage.v1.IQueryAccountBalances=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryAccountBalances} QueryAccountBalances instance
         */
        QueryAccountBalances.create = function create(properties) {
          return new QueryAccountBalances(properties)
        }

        /**
         * Encodes the specified QueryAccountBalances message. Does not implicitly {@link umee.leverage.v1.QueryAccountBalances.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryAccountBalances
         * @static
         * @param {umee.leverage.v1.IQueryAccountBalances} message QueryAccountBalances message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryAccountBalances.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.address != null && Object.hasOwnProperty.call(message, 'address'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.address)
          return writer
        }

        /**
         * Encodes the specified QueryAccountBalances message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryAccountBalances.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryAccountBalances
         * @static
         * @param {umee.leverage.v1.IQueryAccountBalances} message QueryAccountBalances message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryAccountBalances.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryAccountBalances message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryAccountBalances
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryAccountBalances} QueryAccountBalances
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryAccountBalances.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryAccountBalances()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.address = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryAccountBalances message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryAccountBalances
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryAccountBalances} QueryAccountBalances
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryAccountBalances.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryAccountBalances message.
         * @function verify
         * @memberof umee.leverage.v1.QueryAccountBalances
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryAccountBalances.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.address != null && message.hasOwnProperty('address'))
            if (!$util.isString(message.address)) return 'address: string expected'
          return null
        }

        /**
         * Creates a QueryAccountBalances message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryAccountBalances
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryAccountBalances} QueryAccountBalances
         */
        QueryAccountBalances.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryAccountBalances) return object
          let message = new $root.umee.leverage.v1.QueryAccountBalances()
          if (object.address != null) message.address = String(object.address)
          return message
        }

        /**
         * Creates a plain object from a QueryAccountBalances message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryAccountBalances
         * @static
         * @param {umee.leverage.v1.QueryAccountBalances} message QueryAccountBalances
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryAccountBalances.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) object.address = ''
          if (message.address != null && message.hasOwnProperty('address')) object.address = message.address
          return object
        }

        /**
         * Converts this QueryAccountBalances to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryAccountBalances
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryAccountBalances.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryAccountBalances
      })()

      v1.QueryAccountBalancesResponse = (function () {
        /**
         * Properties of a QueryAccountBalancesResponse.
         * @memberof umee.leverage.v1
         * @interface IQueryAccountBalancesResponse
         * @property {Array.<cosmos.base.v1beta1.ICoin>|null} [supplied] QueryAccountBalancesResponse supplied
         * @property {Array.<cosmos.base.v1beta1.ICoin>|null} [collateral] QueryAccountBalancesResponse collateral
         * @property {Array.<cosmos.base.v1beta1.ICoin>|null} [borrowed] QueryAccountBalancesResponse borrowed
         */

        /**
         * Constructs a new QueryAccountBalancesResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryAccountBalancesResponse.
         * @implements IQueryAccountBalancesResponse
         * @constructor
         * @param {umee.leverage.v1.IQueryAccountBalancesResponse=} [properties] Properties to set
         */
        function QueryAccountBalancesResponse(properties) {
          this.supplied = []
          this.collateral = []
          this.borrowed = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * QueryAccountBalancesResponse supplied.
         * @member {Array.<cosmos.base.v1beta1.ICoin>} supplied
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @instance
         */
        QueryAccountBalancesResponse.prototype.supplied = $util.emptyArray

        /**
         * QueryAccountBalancesResponse collateral.
         * @member {Array.<cosmos.base.v1beta1.ICoin>} collateral
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @instance
         */
        QueryAccountBalancesResponse.prototype.collateral = $util.emptyArray

        /**
         * QueryAccountBalancesResponse borrowed.
         * @member {Array.<cosmos.base.v1beta1.ICoin>} borrowed
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @instance
         */
        QueryAccountBalancesResponse.prototype.borrowed = $util.emptyArray

        /**
         * Creates a new QueryAccountBalancesResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @static
         * @param {umee.leverage.v1.IQueryAccountBalancesResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryAccountBalancesResponse} QueryAccountBalancesResponse instance
         */
        QueryAccountBalancesResponse.create = function create(properties) {
          return new QueryAccountBalancesResponse(properties)
        }

        /**
         * Encodes the specified QueryAccountBalancesResponse message. Does not implicitly {@link umee.leverage.v1.QueryAccountBalancesResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @static
         * @param {umee.leverage.v1.IQueryAccountBalancesResponse} message QueryAccountBalancesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryAccountBalancesResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.supplied != null && message.supplied.length)
            for (let i = 0; i < message.supplied.length; ++i)
              $root.cosmos.base.v1beta1.Coin.encode(
                message.supplied[i],
                writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
              ).ldelim()
          if (message.collateral != null && message.collateral.length)
            for (let i = 0; i < message.collateral.length; ++i)
              $root.cosmos.base.v1beta1.Coin.encode(
                message.collateral[i],
                writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
              ).ldelim()
          if (message.borrowed != null && message.borrowed.length)
            for (let i = 0; i < message.borrowed.length; ++i)
              $root.cosmos.base.v1beta1.Coin.encode(
                message.borrowed[i],
                writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
              ).ldelim()
          return writer
        }

        /**
         * Encodes the specified QueryAccountBalancesResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryAccountBalancesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @static
         * @param {umee.leverage.v1.IQueryAccountBalancesResponse} message QueryAccountBalancesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryAccountBalancesResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryAccountBalancesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryAccountBalancesResponse} QueryAccountBalancesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryAccountBalancesResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryAccountBalancesResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                if (!(message.supplied && message.supplied.length)) message.supplied = []
                message.supplied.push($root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32()))
                break
              case 2:
                if (!(message.collateral && message.collateral.length)) message.collateral = []
                message.collateral.push($root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32()))
                break
              case 3:
                if (!(message.borrowed && message.borrowed.length)) message.borrowed = []
                message.borrowed.push($root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32()))
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryAccountBalancesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryAccountBalancesResponse} QueryAccountBalancesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryAccountBalancesResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryAccountBalancesResponse message.
         * @function verify
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryAccountBalancesResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.supplied != null && message.hasOwnProperty('supplied')) {
            if (!Array.isArray(message.supplied)) return 'supplied: array expected'
            for (let i = 0; i < message.supplied.length; ++i) {
              let error = $root.cosmos.base.v1beta1.Coin.verify(message.supplied[i])
              if (error) return 'supplied.' + error
            }
          }
          if (message.collateral != null && message.hasOwnProperty('collateral')) {
            if (!Array.isArray(message.collateral)) return 'collateral: array expected'
            for (let i = 0; i < message.collateral.length; ++i) {
              let error = $root.cosmos.base.v1beta1.Coin.verify(message.collateral[i])
              if (error) return 'collateral.' + error
            }
          }
          if (message.borrowed != null && message.hasOwnProperty('borrowed')) {
            if (!Array.isArray(message.borrowed)) return 'borrowed: array expected'
            for (let i = 0; i < message.borrowed.length; ++i) {
              let error = $root.cosmos.base.v1beta1.Coin.verify(message.borrowed[i])
              if (error) return 'borrowed.' + error
            }
          }
          return null
        }

        /**
         * Creates a QueryAccountBalancesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryAccountBalancesResponse} QueryAccountBalancesResponse
         */
        QueryAccountBalancesResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryAccountBalancesResponse) return object
          let message = new $root.umee.leverage.v1.QueryAccountBalancesResponse()
          if (object.supplied) {
            if (!Array.isArray(object.supplied))
              throw TypeError('.umee.leverage.v1.QueryAccountBalancesResponse.supplied: array expected')
            message.supplied = []
            for (let i = 0; i < object.supplied.length; ++i) {
              if (typeof object.supplied[i] !== 'object')
                throw TypeError('.umee.leverage.v1.QueryAccountBalancesResponse.supplied: object expected')
              message.supplied[i] = $root.cosmos.base.v1beta1.Coin.fromObject(object.supplied[i])
            }
          }
          if (object.collateral) {
            if (!Array.isArray(object.collateral))
              throw TypeError('.umee.leverage.v1.QueryAccountBalancesResponse.collateral: array expected')
            message.collateral = []
            for (let i = 0; i < object.collateral.length; ++i) {
              if (typeof object.collateral[i] !== 'object')
                throw TypeError('.umee.leverage.v1.QueryAccountBalancesResponse.collateral: object expected')
              message.collateral[i] = $root.cosmos.base.v1beta1.Coin.fromObject(object.collateral[i])
            }
          }
          if (object.borrowed) {
            if (!Array.isArray(object.borrowed))
              throw TypeError('.umee.leverage.v1.QueryAccountBalancesResponse.borrowed: array expected')
            message.borrowed = []
            for (let i = 0; i < object.borrowed.length; ++i) {
              if (typeof object.borrowed[i] !== 'object')
                throw TypeError('.umee.leverage.v1.QueryAccountBalancesResponse.borrowed: object expected')
              message.borrowed[i] = $root.cosmos.base.v1beta1.Coin.fromObject(object.borrowed[i])
            }
          }
          return message
        }

        /**
         * Creates a plain object from a QueryAccountBalancesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @static
         * @param {umee.leverage.v1.QueryAccountBalancesResponse} message QueryAccountBalancesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryAccountBalancesResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) {
            object.supplied = []
            object.collateral = []
            object.borrowed = []
          }
          if (message.supplied && message.supplied.length) {
            object.supplied = []
            for (let j = 0; j < message.supplied.length; ++j)
              object.supplied[j] = $root.cosmos.base.v1beta1.Coin.toObject(message.supplied[j], options)
          }
          if (message.collateral && message.collateral.length) {
            object.collateral = []
            for (let j = 0; j < message.collateral.length; ++j)
              object.collateral[j] = $root.cosmos.base.v1beta1.Coin.toObject(message.collateral[j], options)
          }
          if (message.borrowed && message.borrowed.length) {
            object.borrowed = []
            for (let j = 0; j < message.borrowed.length; ++j)
              object.borrowed[j] = $root.cosmos.base.v1beta1.Coin.toObject(message.borrowed[j], options)
          }
          return object
        }

        /**
         * Converts this QueryAccountBalancesResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryAccountBalancesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryAccountBalancesResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryAccountBalancesResponse
      })()

      v1.QueryAccountSummary = (function () {
        /**
         * Properties of a QueryAccountSummary.
         * @memberof umee.leverage.v1
         * @interface IQueryAccountSummary
         * @property {string|null} [address] QueryAccountSummary address
         */

        /**
         * Constructs a new QueryAccountSummary.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryAccountSummary.
         * @implements IQueryAccountSummary
         * @constructor
         * @param {umee.leverage.v1.IQueryAccountSummary=} [properties] Properties to set
         */
        function QueryAccountSummary(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * QueryAccountSummary address.
         * @member {string} address
         * @memberof umee.leverage.v1.QueryAccountSummary
         * @instance
         */
        QueryAccountSummary.prototype.address = ''

        /**
         * Creates a new QueryAccountSummary instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryAccountSummary
         * @static
         * @param {umee.leverage.v1.IQueryAccountSummary=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryAccountSummary} QueryAccountSummary instance
         */
        QueryAccountSummary.create = function create(properties) {
          return new QueryAccountSummary(properties)
        }

        /**
         * Encodes the specified QueryAccountSummary message. Does not implicitly {@link umee.leverage.v1.QueryAccountSummary.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryAccountSummary
         * @static
         * @param {umee.leverage.v1.IQueryAccountSummary} message QueryAccountSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryAccountSummary.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.address != null && Object.hasOwnProperty.call(message, 'address'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.address)
          return writer
        }

        /**
         * Encodes the specified QueryAccountSummary message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryAccountSummary.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryAccountSummary
         * @static
         * @param {umee.leverage.v1.IQueryAccountSummary} message QueryAccountSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryAccountSummary.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryAccountSummary message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryAccountSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryAccountSummary} QueryAccountSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryAccountSummary.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryAccountSummary()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.address = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryAccountSummary message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryAccountSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryAccountSummary} QueryAccountSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryAccountSummary.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryAccountSummary message.
         * @function verify
         * @memberof umee.leverage.v1.QueryAccountSummary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryAccountSummary.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.address != null && message.hasOwnProperty('address'))
            if (!$util.isString(message.address)) return 'address: string expected'
          return null
        }

        /**
         * Creates a QueryAccountSummary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryAccountSummary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryAccountSummary} QueryAccountSummary
         */
        QueryAccountSummary.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryAccountSummary) return object
          let message = new $root.umee.leverage.v1.QueryAccountSummary()
          if (object.address != null) message.address = String(object.address)
          return message
        }

        /**
         * Creates a plain object from a QueryAccountSummary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryAccountSummary
         * @static
         * @param {umee.leverage.v1.QueryAccountSummary} message QueryAccountSummary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryAccountSummary.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) object.address = ''
          if (message.address != null && message.hasOwnProperty('address')) object.address = message.address
          return object
        }

        /**
         * Converts this QueryAccountSummary to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryAccountSummary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryAccountSummary.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryAccountSummary
      })()

      v1.QueryAccountSummaryResponse = (function () {
        /**
         * Properties of a QueryAccountSummaryResponse.
         * @memberof umee.leverage.v1
         * @interface IQueryAccountSummaryResponse
         * @property {string|null} [suppliedValue] QueryAccountSummaryResponse suppliedValue
         * @property {string|null} [collateralValue] QueryAccountSummaryResponse collateralValue
         * @property {string|null} [borrowedValue] QueryAccountSummaryResponse borrowedValue
         * @property {string|null} [borrowLimit] QueryAccountSummaryResponse borrowLimit
         * @property {string|null} [liquidationThreshold] QueryAccountSummaryResponse liquidationThreshold
         */

        /**
         * Constructs a new QueryAccountSummaryResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryAccountSummaryResponse.
         * @implements IQueryAccountSummaryResponse
         * @constructor
         * @param {umee.leverage.v1.IQueryAccountSummaryResponse=} [properties] Properties to set
         */
        function QueryAccountSummaryResponse(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * QueryAccountSummaryResponse suppliedValue.
         * @member {string} suppliedValue
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @instance
         */
        QueryAccountSummaryResponse.prototype.suppliedValue = ''

        /**
         * QueryAccountSummaryResponse collateralValue.
         * @member {string} collateralValue
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @instance
         */
        QueryAccountSummaryResponse.prototype.collateralValue = ''

        /**
         * QueryAccountSummaryResponse borrowedValue.
         * @member {string} borrowedValue
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @instance
         */
        QueryAccountSummaryResponse.prototype.borrowedValue = ''

        /**
         * QueryAccountSummaryResponse borrowLimit.
         * @member {string} borrowLimit
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @instance
         */
        QueryAccountSummaryResponse.prototype.borrowLimit = ''

        /**
         * QueryAccountSummaryResponse liquidationThreshold.
         * @member {string} liquidationThreshold
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @instance
         */
        QueryAccountSummaryResponse.prototype.liquidationThreshold = ''

        /**
         * Creates a new QueryAccountSummaryResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @static
         * @param {umee.leverage.v1.IQueryAccountSummaryResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryAccountSummaryResponse} QueryAccountSummaryResponse instance
         */
        QueryAccountSummaryResponse.create = function create(properties) {
          return new QueryAccountSummaryResponse(properties)
        }

        /**
         * Encodes the specified QueryAccountSummaryResponse message. Does not implicitly {@link umee.leverage.v1.QueryAccountSummaryResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @static
         * @param {umee.leverage.v1.IQueryAccountSummaryResponse} message QueryAccountSummaryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryAccountSummaryResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.suppliedValue != null && Object.hasOwnProperty.call(message, 'suppliedValue'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.suppliedValue)
          if (message.collateralValue != null && Object.hasOwnProperty.call(message, 'collateralValue'))
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.collateralValue)
          if (message.borrowedValue != null && Object.hasOwnProperty.call(message, 'borrowedValue'))
            writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.borrowedValue)
          if (message.borrowLimit != null && Object.hasOwnProperty.call(message, 'borrowLimit'))
            writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.borrowLimit)
          if (message.liquidationThreshold != null && Object.hasOwnProperty.call(message, 'liquidationThreshold'))
            writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.liquidationThreshold)
          return writer
        }

        /**
         * Encodes the specified QueryAccountSummaryResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryAccountSummaryResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @static
         * @param {umee.leverage.v1.IQueryAccountSummaryResponse} message QueryAccountSummaryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryAccountSummaryResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryAccountSummaryResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryAccountSummaryResponse} QueryAccountSummaryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryAccountSummaryResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryAccountSummaryResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.suppliedValue = reader.string()
                break
              case 2:
                message.collateralValue = reader.string()
                break
              case 3:
                message.borrowedValue = reader.string()
                break
              case 4:
                message.borrowLimit = reader.string()
                break
              case 5:
                message.liquidationThreshold = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryAccountSummaryResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryAccountSummaryResponse} QueryAccountSummaryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryAccountSummaryResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryAccountSummaryResponse message.
         * @function verify
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryAccountSummaryResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.suppliedValue != null && message.hasOwnProperty('suppliedValue'))
            if (!$util.isString(message.suppliedValue)) return 'suppliedValue: string expected'
          if (message.collateralValue != null && message.hasOwnProperty('collateralValue'))
            if (!$util.isString(message.collateralValue)) return 'collateralValue: string expected'
          if (message.borrowedValue != null && message.hasOwnProperty('borrowedValue'))
            if (!$util.isString(message.borrowedValue)) return 'borrowedValue: string expected'
          if (message.borrowLimit != null && message.hasOwnProperty('borrowLimit'))
            if (!$util.isString(message.borrowLimit)) return 'borrowLimit: string expected'
          if (message.liquidationThreshold != null && message.hasOwnProperty('liquidationThreshold'))
            if (!$util.isString(message.liquidationThreshold)) return 'liquidationThreshold: string expected'
          return null
        }

        /**
         * Creates a QueryAccountSummaryResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryAccountSummaryResponse} QueryAccountSummaryResponse
         */
        QueryAccountSummaryResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryAccountSummaryResponse) return object
          let message = new $root.umee.leverage.v1.QueryAccountSummaryResponse()
          if (object.suppliedValue != null) message.suppliedValue = String(object.suppliedValue)
          if (object.collateralValue != null) message.collateralValue = String(object.collateralValue)
          if (object.borrowedValue != null) message.borrowedValue = String(object.borrowedValue)
          if (object.borrowLimit != null) message.borrowLimit = String(object.borrowLimit)
          if (object.liquidationThreshold != null) message.liquidationThreshold = String(object.liquidationThreshold)
          return message
        }

        /**
         * Creates a plain object from a QueryAccountSummaryResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @static
         * @param {umee.leverage.v1.QueryAccountSummaryResponse} message QueryAccountSummaryResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryAccountSummaryResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.suppliedValue = ''
            object.collateralValue = ''
            object.borrowedValue = ''
            object.borrowLimit = ''
            object.liquidationThreshold = ''
          }
          if (message.suppliedValue != null && message.hasOwnProperty('suppliedValue'))
            object.suppliedValue = message.suppliedValue
          if (message.collateralValue != null && message.hasOwnProperty('collateralValue'))
            object.collateralValue = message.collateralValue
          if (message.borrowedValue != null && message.hasOwnProperty('borrowedValue'))
            object.borrowedValue = message.borrowedValue
          if (message.borrowLimit != null && message.hasOwnProperty('borrowLimit'))
            object.borrowLimit = message.borrowLimit
          if (message.liquidationThreshold != null && message.hasOwnProperty('liquidationThreshold'))
            object.liquidationThreshold = message.liquidationThreshold
          return object
        }

        /**
         * Converts this QueryAccountSummaryResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryAccountSummaryResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryAccountSummaryResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryAccountSummaryResponse
      })()

      v1.QueryLiquidationTargets = (function () {
        /**
         * Properties of a QueryLiquidationTargets.
         * @memberof umee.leverage.v1
         * @interface IQueryLiquidationTargets
         */

        /**
         * Constructs a new QueryLiquidationTargets.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryLiquidationTargets.
         * @implements IQueryLiquidationTargets
         * @constructor
         * @param {umee.leverage.v1.IQueryLiquidationTargets=} [properties] Properties to set
         */
        function QueryLiquidationTargets(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Creates a new QueryLiquidationTargets instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryLiquidationTargets
         * @static
         * @param {umee.leverage.v1.IQueryLiquidationTargets=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryLiquidationTargets} QueryLiquidationTargets instance
         */
        QueryLiquidationTargets.create = function create(properties) {
          return new QueryLiquidationTargets(properties)
        }

        /**
         * Encodes the specified QueryLiquidationTargets message. Does not implicitly {@link umee.leverage.v1.QueryLiquidationTargets.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryLiquidationTargets
         * @static
         * @param {umee.leverage.v1.IQueryLiquidationTargets} message QueryLiquidationTargets message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryLiquidationTargets.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          return writer
        }

        /**
         * Encodes the specified QueryLiquidationTargets message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryLiquidationTargets.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryLiquidationTargets
         * @static
         * @param {umee.leverage.v1.IQueryLiquidationTargets} message QueryLiquidationTargets message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryLiquidationTargets.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryLiquidationTargets message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryLiquidationTargets
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryLiquidationTargets} QueryLiquidationTargets
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryLiquidationTargets.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryLiquidationTargets()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryLiquidationTargets message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryLiquidationTargets
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryLiquidationTargets} QueryLiquidationTargets
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryLiquidationTargets.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryLiquidationTargets message.
         * @function verify
         * @memberof umee.leverage.v1.QueryLiquidationTargets
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryLiquidationTargets.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          return null
        }

        /**
         * Creates a QueryLiquidationTargets message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryLiquidationTargets
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryLiquidationTargets} QueryLiquidationTargets
         */
        QueryLiquidationTargets.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryLiquidationTargets) return object
          return new $root.umee.leverage.v1.QueryLiquidationTargets()
        }

        /**
         * Creates a plain object from a QueryLiquidationTargets message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryLiquidationTargets
         * @static
         * @param {umee.leverage.v1.QueryLiquidationTargets} message QueryLiquidationTargets
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryLiquidationTargets.toObject = function toObject() {
          return {}
        }

        /**
         * Converts this QueryLiquidationTargets to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryLiquidationTargets
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryLiquidationTargets.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryLiquidationTargets
      })()

      v1.QueryLiquidationTargetsResponse = (function () {
        /**
         * Properties of a QueryLiquidationTargetsResponse.
         * @memberof umee.leverage.v1
         * @interface IQueryLiquidationTargetsResponse
         * @property {Array.<string>|null} [targets] QueryLiquidationTargetsResponse targets
         */

        /**
         * Constructs a new QueryLiquidationTargetsResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryLiquidationTargetsResponse.
         * @implements IQueryLiquidationTargetsResponse
         * @constructor
         * @param {umee.leverage.v1.IQueryLiquidationTargetsResponse=} [properties] Properties to set
         */
        function QueryLiquidationTargetsResponse(properties) {
          this.targets = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * QueryLiquidationTargetsResponse targets.
         * @member {Array.<string>} targets
         * @memberof umee.leverage.v1.QueryLiquidationTargetsResponse
         * @instance
         */
        QueryLiquidationTargetsResponse.prototype.targets = $util.emptyArray

        /**
         * Creates a new QueryLiquidationTargetsResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryLiquidationTargetsResponse
         * @static
         * @param {umee.leverage.v1.IQueryLiquidationTargetsResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryLiquidationTargetsResponse} QueryLiquidationTargetsResponse instance
         */
        QueryLiquidationTargetsResponse.create = function create(properties) {
          return new QueryLiquidationTargetsResponse(properties)
        }

        /**
         * Encodes the specified QueryLiquidationTargetsResponse message. Does not implicitly {@link umee.leverage.v1.QueryLiquidationTargetsResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryLiquidationTargetsResponse
         * @static
         * @param {umee.leverage.v1.IQueryLiquidationTargetsResponse} message QueryLiquidationTargetsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryLiquidationTargetsResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.targets != null && message.targets.length)
            for (let i = 0; i < message.targets.length; ++i)
              writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.targets[i])
          return writer
        }

        /**
         * Encodes the specified QueryLiquidationTargetsResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryLiquidationTargetsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryLiquidationTargetsResponse
         * @static
         * @param {umee.leverage.v1.IQueryLiquidationTargetsResponse} message QueryLiquidationTargetsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryLiquidationTargetsResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryLiquidationTargetsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryLiquidationTargetsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryLiquidationTargetsResponse} QueryLiquidationTargetsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryLiquidationTargetsResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryLiquidationTargetsResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                if (!(message.targets && message.targets.length)) message.targets = []
                message.targets.push(reader.string())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryLiquidationTargetsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryLiquidationTargetsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryLiquidationTargetsResponse} QueryLiquidationTargetsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryLiquidationTargetsResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryLiquidationTargetsResponse message.
         * @function verify
         * @memberof umee.leverage.v1.QueryLiquidationTargetsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryLiquidationTargetsResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.targets != null && message.hasOwnProperty('targets')) {
            if (!Array.isArray(message.targets)) return 'targets: array expected'
            for (let i = 0; i < message.targets.length; ++i)
              if (!$util.isString(message.targets[i])) return 'targets: string[] expected'
          }
          return null
        }

        /**
         * Creates a QueryLiquidationTargetsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryLiquidationTargetsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryLiquidationTargetsResponse} QueryLiquidationTargetsResponse
         */
        QueryLiquidationTargetsResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryLiquidationTargetsResponse) return object
          let message = new $root.umee.leverage.v1.QueryLiquidationTargetsResponse()
          if (object.targets) {
            if (!Array.isArray(object.targets))
              throw TypeError('.umee.leverage.v1.QueryLiquidationTargetsResponse.targets: array expected')
            message.targets = []
            for (let i = 0; i < object.targets.length; ++i) message.targets[i] = String(object.targets[i])
          }
          return message
        }

        /**
         * Creates a plain object from a QueryLiquidationTargetsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryLiquidationTargetsResponse
         * @static
         * @param {umee.leverage.v1.QueryLiquidationTargetsResponse} message QueryLiquidationTargetsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryLiquidationTargetsResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.targets = []
          if (message.targets && message.targets.length) {
            object.targets = []
            for (let j = 0; j < message.targets.length; ++j) object.targets[j] = message.targets[j]
          }
          return object
        }

        /**
         * Converts this QueryLiquidationTargetsResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryLiquidationTargetsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryLiquidationTargetsResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryLiquidationTargetsResponse
      })()

      v1.QueryBadDebts = (function () {
        /**
         * Properties of a QueryBadDebts.
         * @memberof umee.leverage.v1
         * @interface IQueryBadDebts
         */

        /**
         * Constructs a new QueryBadDebts.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryBadDebts.
         * @implements IQueryBadDebts
         * @constructor
         * @param {umee.leverage.v1.IQueryBadDebts=} [properties] Properties to set
         */
        function QueryBadDebts(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Creates a new QueryBadDebts instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryBadDebts
         * @static
         * @param {umee.leverage.v1.IQueryBadDebts=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryBadDebts} QueryBadDebts instance
         */
        QueryBadDebts.create = function create(properties) {
          return new QueryBadDebts(properties)
        }

        /**
         * Encodes the specified QueryBadDebts message. Does not implicitly {@link umee.leverage.v1.QueryBadDebts.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryBadDebts
         * @static
         * @param {umee.leverage.v1.IQueryBadDebts} message QueryBadDebts message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryBadDebts.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          return writer
        }

        /**
         * Encodes the specified QueryBadDebts message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryBadDebts.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryBadDebts
         * @static
         * @param {umee.leverage.v1.IQueryBadDebts} message QueryBadDebts message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryBadDebts.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryBadDebts message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryBadDebts
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryBadDebts} QueryBadDebts
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryBadDebts.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryBadDebts()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryBadDebts message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryBadDebts
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryBadDebts} QueryBadDebts
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryBadDebts.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryBadDebts message.
         * @function verify
         * @memberof umee.leverage.v1.QueryBadDebts
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryBadDebts.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          return null
        }

        /**
         * Creates a QueryBadDebts message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryBadDebts
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryBadDebts} QueryBadDebts
         */
        QueryBadDebts.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryBadDebts) return object
          return new $root.umee.leverage.v1.QueryBadDebts()
        }

        /**
         * Creates a plain object from a QueryBadDebts message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryBadDebts
         * @static
         * @param {umee.leverage.v1.QueryBadDebts} message QueryBadDebts
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryBadDebts.toObject = function toObject() {
          return {}
        }

        /**
         * Converts this QueryBadDebts to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryBadDebts
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryBadDebts.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryBadDebts
      })()

      v1.QueryBadDebtsResponse = (function () {
        /**
         * Properties of a QueryBadDebtsResponse.
         * @memberof umee.leverage.v1
         * @interface IQueryBadDebtsResponse
         * @property {Array.<umee.leverage.v1.IBadDebt>|null} [targets] QueryBadDebtsResponse targets
         */

        /**
         * Constructs a new QueryBadDebtsResponse.
         * @memberof umee.leverage.v1
         * @classdesc Represents a QueryBadDebtsResponse.
         * @implements IQueryBadDebtsResponse
         * @constructor
         * @param {umee.leverage.v1.IQueryBadDebtsResponse=} [properties] Properties to set
         */
        function QueryBadDebtsResponse(properties) {
          this.targets = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * QueryBadDebtsResponse targets.
         * @member {Array.<umee.leverage.v1.IBadDebt>} targets
         * @memberof umee.leverage.v1.QueryBadDebtsResponse
         * @instance
         */
        QueryBadDebtsResponse.prototype.targets = $util.emptyArray

        /**
         * Creates a new QueryBadDebtsResponse instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.QueryBadDebtsResponse
         * @static
         * @param {umee.leverage.v1.IQueryBadDebtsResponse=} [properties] Properties to set
         * @returns {umee.leverage.v1.QueryBadDebtsResponse} QueryBadDebtsResponse instance
         */
        QueryBadDebtsResponse.create = function create(properties) {
          return new QueryBadDebtsResponse(properties)
        }

        /**
         * Encodes the specified QueryBadDebtsResponse message. Does not implicitly {@link umee.leverage.v1.QueryBadDebtsResponse.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.QueryBadDebtsResponse
         * @static
         * @param {umee.leverage.v1.IQueryBadDebtsResponse} message QueryBadDebtsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryBadDebtsResponse.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.targets != null && message.targets.length)
            for (let i = 0; i < message.targets.length; ++i)
              $root.umee.leverage.v1.BadDebt.encode(
                message.targets[i],
                writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
              ).ldelim()
          return writer
        }

        /**
         * Encodes the specified QueryBadDebtsResponse message, length delimited. Does not implicitly {@link umee.leverage.v1.QueryBadDebtsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.QueryBadDebtsResponse
         * @static
         * @param {umee.leverage.v1.IQueryBadDebtsResponse} message QueryBadDebtsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryBadDebtsResponse.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a QueryBadDebtsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.QueryBadDebtsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.QueryBadDebtsResponse} QueryBadDebtsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryBadDebtsResponse.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.QueryBadDebtsResponse()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                if (!(message.targets && message.targets.length)) message.targets = []
                message.targets.push($root.umee.leverage.v1.BadDebt.decode(reader, reader.uint32()))
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a QueryBadDebtsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.QueryBadDebtsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.QueryBadDebtsResponse} QueryBadDebtsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryBadDebtsResponse.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a QueryBadDebtsResponse message.
         * @function verify
         * @memberof umee.leverage.v1.QueryBadDebtsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryBadDebtsResponse.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.targets != null && message.hasOwnProperty('targets')) {
            if (!Array.isArray(message.targets)) return 'targets: array expected'
            for (let i = 0; i < message.targets.length; ++i) {
              let error = $root.umee.leverage.v1.BadDebt.verify(message.targets[i])
              if (error) return 'targets.' + error
            }
          }
          return null
        }

        /**
         * Creates a QueryBadDebtsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.QueryBadDebtsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.QueryBadDebtsResponse} QueryBadDebtsResponse
         */
        QueryBadDebtsResponse.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.QueryBadDebtsResponse) return object
          let message = new $root.umee.leverage.v1.QueryBadDebtsResponse()
          if (object.targets) {
            if (!Array.isArray(object.targets))
              throw TypeError('.umee.leverage.v1.QueryBadDebtsResponse.targets: array expected')
            message.targets = []
            for (let i = 0; i < object.targets.length; ++i) {
              if (typeof object.targets[i] !== 'object')
                throw TypeError('.umee.leverage.v1.QueryBadDebtsResponse.targets: object expected')
              message.targets[i] = $root.umee.leverage.v1.BadDebt.fromObject(object.targets[i])
            }
          }
          return message
        }

        /**
         * Creates a plain object from a QueryBadDebtsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.QueryBadDebtsResponse
         * @static
         * @param {umee.leverage.v1.QueryBadDebtsResponse} message QueryBadDebtsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryBadDebtsResponse.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.targets = []
          if (message.targets && message.targets.length) {
            object.targets = []
            for (let j = 0; j < message.targets.length; ++j)
              object.targets[j] = $root.umee.leverage.v1.BadDebt.toObject(message.targets[j], options)
          }
          return object
        }

        /**
         * Converts this QueryBadDebtsResponse to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.QueryBadDebtsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryBadDebtsResponse.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return QueryBadDebtsResponse
      })()

      v1.GenesisState = (function () {
        /**
         * Properties of a GenesisState.
         * @memberof umee.leverage.v1
         * @interface IGenesisState
         * @property {umee.leverage.v1.IParams|null} [params] GenesisState params
         * @property {Array.<umee.leverage.v1.IToken>|null} [registry] GenesisState registry
         * @property {Array.<umee.leverage.v1.IAdjustedBorrow>|null} [adjustedBorrows] GenesisState adjustedBorrows
         * @property {Array.<umee.leverage.v1.ICollateral>|null} [collateral] GenesisState collateral
         * @property {Array.<cosmos.base.v1beta1.ICoin>|null} [reserves] GenesisState reserves
         * @property {number|Long|null} [lastInterestTime] GenesisState lastInterestTime
         * @property {Array.<umee.leverage.v1.IBadDebt>|null} [badDebts] GenesisState badDebts
         * @property {Array.<umee.leverage.v1.IInterestScalar>|null} [interestScalars] GenesisState interestScalars
         * @property {Array.<cosmos.base.v1beta1.ICoin>|null} [utokenSupply] GenesisState utokenSupply
         */

        /**
         * Constructs a new GenesisState.
         * @memberof umee.leverage.v1
         * @classdesc Represents a GenesisState.
         * @implements IGenesisState
         * @constructor
         * @param {umee.leverage.v1.IGenesisState=} [properties] Properties to set
         */
        function GenesisState(properties) {
          this.registry = []
          this.adjustedBorrows = []
          this.collateral = []
          this.reserves = []
          this.badDebts = []
          this.interestScalars = []
          this.utokenSupply = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * GenesisState params.
         * @member {umee.leverage.v1.IParams|null|undefined} params
         * @memberof umee.leverage.v1.GenesisState
         * @instance
         */
        GenesisState.prototype.params = null

        /**
         * GenesisState registry.
         * @member {Array.<umee.leverage.v1.IToken>} registry
         * @memberof umee.leverage.v1.GenesisState
         * @instance
         */
        GenesisState.prototype.registry = $util.emptyArray

        /**
         * GenesisState adjustedBorrows.
         * @member {Array.<umee.leverage.v1.IAdjustedBorrow>} adjustedBorrows
         * @memberof umee.leverage.v1.GenesisState
         * @instance
         */
        GenesisState.prototype.adjustedBorrows = $util.emptyArray

        /**
         * GenesisState collateral.
         * @member {Array.<umee.leverage.v1.ICollateral>} collateral
         * @memberof umee.leverage.v1.GenesisState
         * @instance
         */
        GenesisState.prototype.collateral = $util.emptyArray

        /**
         * GenesisState reserves.
         * @member {Array.<cosmos.base.v1beta1.ICoin>} reserves
         * @memberof umee.leverage.v1.GenesisState
         * @instance
         */
        GenesisState.prototype.reserves = $util.emptyArray

        /**
         * GenesisState lastInterestTime.
         * @member {number|Long} lastInterestTime
         * @memberof umee.leverage.v1.GenesisState
         * @instance
         */
        GenesisState.prototype.lastInterestTime = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

        /**
         * GenesisState badDebts.
         * @member {Array.<umee.leverage.v1.IBadDebt>} badDebts
         * @memberof umee.leverage.v1.GenesisState
         * @instance
         */
        GenesisState.prototype.badDebts = $util.emptyArray

        /**
         * GenesisState interestScalars.
         * @member {Array.<umee.leverage.v1.IInterestScalar>} interestScalars
         * @memberof umee.leverage.v1.GenesisState
         * @instance
         */
        GenesisState.prototype.interestScalars = $util.emptyArray

        /**
         * GenesisState utokenSupply.
         * @member {Array.<cosmos.base.v1beta1.ICoin>} utokenSupply
         * @memberof umee.leverage.v1.GenesisState
         * @instance
         */
        GenesisState.prototype.utokenSupply = $util.emptyArray

        /**
         * Creates a new GenesisState instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.GenesisState
         * @static
         * @param {umee.leverage.v1.IGenesisState=} [properties] Properties to set
         * @returns {umee.leverage.v1.GenesisState} GenesisState instance
         */
        GenesisState.create = function create(properties) {
          return new GenesisState(properties)
        }

        /**
         * Encodes the specified GenesisState message. Does not implicitly {@link umee.leverage.v1.GenesisState.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.GenesisState
         * @static
         * @param {umee.leverage.v1.IGenesisState} message GenesisState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenesisState.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.params != null && Object.hasOwnProperty.call(message, 'params'))
            $root.umee.leverage.v1.Params.encode(
              message.params,
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
            ).ldelim()
          if (message.registry != null && message.registry.length)
            for (let i = 0; i < message.registry.length; ++i)
              $root.umee.leverage.v1.Token.encode(
                message.registry[i],
                writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
              ).ldelim()
          if (message.adjustedBorrows != null && message.adjustedBorrows.length)
            for (let i = 0; i < message.adjustedBorrows.length; ++i)
              $root.umee.leverage.v1.AdjustedBorrow.encode(
                message.adjustedBorrows[i],
                writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
              ).ldelim()
          if (message.collateral != null && message.collateral.length)
            for (let i = 0; i < message.collateral.length; ++i)
              $root.umee.leverage.v1.Collateral.encode(
                message.collateral[i],
                writer.uint32(/* id 4, wireType 2 =*/ 34).fork(),
              ).ldelim()
          if (message.reserves != null && message.reserves.length)
            for (let i = 0; i < message.reserves.length; ++i)
              $root.cosmos.base.v1beta1.Coin.encode(
                message.reserves[i],
                writer.uint32(/* id 5, wireType 2 =*/ 42).fork(),
              ).ldelim()
          if (message.lastInterestTime != null && Object.hasOwnProperty.call(message, 'lastInterestTime'))
            writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.lastInterestTime)
          if (message.badDebts != null && message.badDebts.length)
            for (let i = 0; i < message.badDebts.length; ++i)
              $root.umee.leverage.v1.BadDebt.encode(
                message.badDebts[i],
                writer.uint32(/* id 7, wireType 2 =*/ 58).fork(),
              ).ldelim()
          if (message.interestScalars != null && message.interestScalars.length)
            for (let i = 0; i < message.interestScalars.length; ++i)
              $root.umee.leverage.v1.InterestScalar.encode(
                message.interestScalars[i],
                writer.uint32(/* id 8, wireType 2 =*/ 66).fork(),
              ).ldelim()
          if (message.utokenSupply != null && message.utokenSupply.length)
            for (let i = 0; i < message.utokenSupply.length; ++i)
              $root.cosmos.base.v1beta1.Coin.encode(
                message.utokenSupply[i],
                writer.uint32(/* id 9, wireType 2 =*/ 74).fork(),
              ).ldelim()
          return writer
        }

        /**
         * Encodes the specified GenesisState message, length delimited. Does not implicitly {@link umee.leverage.v1.GenesisState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.GenesisState
         * @static
         * @param {umee.leverage.v1.IGenesisState} message GenesisState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenesisState.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a GenesisState message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.GenesisState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.GenesisState} GenesisState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenesisState.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.GenesisState()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.params = $root.umee.leverage.v1.Params.decode(reader, reader.uint32())
                break
              case 2:
                if (!(message.registry && message.registry.length)) message.registry = []
                message.registry.push($root.umee.leverage.v1.Token.decode(reader, reader.uint32()))
                break
              case 3:
                if (!(message.adjustedBorrows && message.adjustedBorrows.length)) message.adjustedBorrows = []
                message.adjustedBorrows.push($root.umee.leverage.v1.AdjustedBorrow.decode(reader, reader.uint32()))
                break
              case 4:
                if (!(message.collateral && message.collateral.length)) message.collateral = []
                message.collateral.push($root.umee.leverage.v1.Collateral.decode(reader, reader.uint32()))
                break
              case 5:
                if (!(message.reserves && message.reserves.length)) message.reserves = []
                message.reserves.push($root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32()))
                break
              case 6:
                message.lastInterestTime = reader.int64()
                break
              case 7:
                if (!(message.badDebts && message.badDebts.length)) message.badDebts = []
                message.badDebts.push($root.umee.leverage.v1.BadDebt.decode(reader, reader.uint32()))
                break
              case 8:
                if (!(message.interestScalars && message.interestScalars.length)) message.interestScalars = []
                message.interestScalars.push($root.umee.leverage.v1.InterestScalar.decode(reader, reader.uint32()))
                break
              case 9:
                if (!(message.utokenSupply && message.utokenSupply.length)) message.utokenSupply = []
                message.utokenSupply.push($root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32()))
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a GenesisState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.GenesisState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.GenesisState} GenesisState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenesisState.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a GenesisState message.
         * @function verify
         * @memberof umee.leverage.v1.GenesisState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenesisState.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.params != null && message.hasOwnProperty('params')) {
            let error = $root.umee.leverage.v1.Params.verify(message.params)
            if (error) return 'params.' + error
          }
          if (message.registry != null && message.hasOwnProperty('registry')) {
            if (!Array.isArray(message.registry)) return 'registry: array expected'
            for (let i = 0; i < message.registry.length; ++i) {
              let error = $root.umee.leverage.v1.Token.verify(message.registry[i])
              if (error) return 'registry.' + error
            }
          }
          if (message.adjustedBorrows != null && message.hasOwnProperty('adjustedBorrows')) {
            if (!Array.isArray(message.adjustedBorrows)) return 'adjustedBorrows: array expected'
            for (let i = 0; i < message.adjustedBorrows.length; ++i) {
              let error = $root.umee.leverage.v1.AdjustedBorrow.verify(message.adjustedBorrows[i])
              if (error) return 'adjustedBorrows.' + error
            }
          }
          if (message.collateral != null && message.hasOwnProperty('collateral')) {
            if (!Array.isArray(message.collateral)) return 'collateral: array expected'
            for (let i = 0; i < message.collateral.length; ++i) {
              let error = $root.umee.leverage.v1.Collateral.verify(message.collateral[i])
              if (error) return 'collateral.' + error
            }
          }
          if (message.reserves != null && message.hasOwnProperty('reserves')) {
            if (!Array.isArray(message.reserves)) return 'reserves: array expected'
            for (let i = 0; i < message.reserves.length; ++i) {
              let error = $root.cosmos.base.v1beta1.Coin.verify(message.reserves[i])
              if (error) return 'reserves.' + error
            }
          }
          if (message.lastInterestTime != null && message.hasOwnProperty('lastInterestTime'))
            if (
              !$util.isInteger(message.lastInterestTime) &&
              !(
                message.lastInterestTime &&
                $util.isInteger(message.lastInterestTime.low) &&
                $util.isInteger(message.lastInterestTime.high)
              )
            )
              return 'lastInterestTime: integer|Long expected'
          if (message.badDebts != null && message.hasOwnProperty('badDebts')) {
            if (!Array.isArray(message.badDebts)) return 'badDebts: array expected'
            for (let i = 0; i < message.badDebts.length; ++i) {
              let error = $root.umee.leverage.v1.BadDebt.verify(message.badDebts[i])
              if (error) return 'badDebts.' + error
            }
          }
          if (message.interestScalars != null && message.hasOwnProperty('interestScalars')) {
            if (!Array.isArray(message.interestScalars)) return 'interestScalars: array expected'
            for (let i = 0; i < message.interestScalars.length; ++i) {
              let error = $root.umee.leverage.v1.InterestScalar.verify(message.interestScalars[i])
              if (error) return 'interestScalars.' + error
            }
          }
          if (message.utokenSupply != null && message.hasOwnProperty('utokenSupply')) {
            if (!Array.isArray(message.utokenSupply)) return 'utokenSupply: array expected'
            for (let i = 0; i < message.utokenSupply.length; ++i) {
              let error = $root.cosmos.base.v1beta1.Coin.verify(message.utokenSupply[i])
              if (error) return 'utokenSupply.' + error
            }
          }
          return null
        }

        /**
         * Creates a GenesisState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.GenesisState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.GenesisState} GenesisState
         */
        GenesisState.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.GenesisState) return object
          let message = new $root.umee.leverage.v1.GenesisState()
          if (object.params != null) {
            if (typeof object.params !== 'object')
              throw TypeError('.umee.leverage.v1.GenesisState.params: object expected')
            message.params = $root.umee.leverage.v1.Params.fromObject(object.params)
          }
          if (object.registry) {
            if (!Array.isArray(object.registry))
              throw TypeError('.umee.leverage.v1.GenesisState.registry: array expected')
            message.registry = []
            for (let i = 0; i < object.registry.length; ++i) {
              if (typeof object.registry[i] !== 'object')
                throw TypeError('.umee.leverage.v1.GenesisState.registry: object expected')
              message.registry[i] = $root.umee.leverage.v1.Token.fromObject(object.registry[i])
            }
          }
          if (object.adjustedBorrows) {
            if (!Array.isArray(object.adjustedBorrows))
              throw TypeError('.umee.leverage.v1.GenesisState.adjustedBorrows: array expected')
            message.adjustedBorrows = []
            for (let i = 0; i < object.adjustedBorrows.length; ++i) {
              if (typeof object.adjustedBorrows[i] !== 'object')
                throw TypeError('.umee.leverage.v1.GenesisState.adjustedBorrows: object expected')
              message.adjustedBorrows[i] = $root.umee.leverage.v1.AdjustedBorrow.fromObject(object.adjustedBorrows[i])
            }
          }
          if (object.collateral) {
            if (!Array.isArray(object.collateral))
              throw TypeError('.umee.leverage.v1.GenesisState.collateral: array expected')
            message.collateral = []
            for (let i = 0; i < object.collateral.length; ++i) {
              if (typeof object.collateral[i] !== 'object')
                throw TypeError('.umee.leverage.v1.GenesisState.collateral: object expected')
              message.collateral[i] = $root.umee.leverage.v1.Collateral.fromObject(object.collateral[i])
            }
          }
          if (object.reserves) {
            if (!Array.isArray(object.reserves))
              throw TypeError('.umee.leverage.v1.GenesisState.reserves: array expected')
            message.reserves = []
            for (let i = 0; i < object.reserves.length; ++i) {
              if (typeof object.reserves[i] !== 'object')
                throw TypeError('.umee.leverage.v1.GenesisState.reserves: object expected')
              message.reserves[i] = $root.cosmos.base.v1beta1.Coin.fromObject(object.reserves[i])
            }
          }
          if (object.lastInterestTime != null)
            if ($util.Long) (message.lastInterestTime = $util.Long.fromValue(object.lastInterestTime)).unsigned = false
            else if (typeof object.lastInterestTime === 'string')
              message.lastInterestTime = parseInt(object.lastInterestTime, 10)
            else if (typeof object.lastInterestTime === 'number') message.lastInterestTime = object.lastInterestTime
            else if (typeof object.lastInterestTime === 'object')
              message.lastInterestTime = new $util.LongBits(
                object.lastInterestTime.low >>> 0,
                object.lastInterestTime.high >>> 0,
              ).toNumber()
          if (object.badDebts) {
            if (!Array.isArray(object.badDebts))
              throw TypeError('.umee.leverage.v1.GenesisState.badDebts: array expected')
            message.badDebts = []
            for (let i = 0; i < object.badDebts.length; ++i) {
              if (typeof object.badDebts[i] !== 'object')
                throw TypeError('.umee.leverage.v1.GenesisState.badDebts: object expected')
              message.badDebts[i] = $root.umee.leverage.v1.BadDebt.fromObject(object.badDebts[i])
            }
          }
          if (object.interestScalars) {
            if (!Array.isArray(object.interestScalars))
              throw TypeError('.umee.leverage.v1.GenesisState.interestScalars: array expected')
            message.interestScalars = []
            for (let i = 0; i < object.interestScalars.length; ++i) {
              if (typeof object.interestScalars[i] !== 'object')
                throw TypeError('.umee.leverage.v1.GenesisState.interestScalars: object expected')
              message.interestScalars[i] = $root.umee.leverage.v1.InterestScalar.fromObject(object.interestScalars[i])
            }
          }
          if (object.utokenSupply) {
            if (!Array.isArray(object.utokenSupply))
              throw TypeError('.umee.leverage.v1.GenesisState.utokenSupply: array expected')
            message.utokenSupply = []
            for (let i = 0; i < object.utokenSupply.length; ++i) {
              if (typeof object.utokenSupply[i] !== 'object')
                throw TypeError('.umee.leverage.v1.GenesisState.utokenSupply: object expected')
              message.utokenSupply[i] = $root.cosmos.base.v1beta1.Coin.fromObject(object.utokenSupply[i])
            }
          }
          return message
        }

        /**
         * Creates a plain object from a GenesisState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.GenesisState
         * @static
         * @param {umee.leverage.v1.GenesisState} message GenesisState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenesisState.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) {
            object.registry = []
            object.adjustedBorrows = []
            object.collateral = []
            object.reserves = []
            object.badDebts = []
            object.interestScalars = []
            object.utokenSupply = []
          }
          if (options.defaults) {
            object.params = null
            if ($util.Long) {
              let long = new $util.Long(0, 0, false)
              object.lastInterestTime =
                options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long
            } else object.lastInterestTime = options.longs === String ? '0' : 0
          }
          if (message.params != null && message.hasOwnProperty('params'))
            object.params = $root.umee.leverage.v1.Params.toObject(message.params, options)
          if (message.registry && message.registry.length) {
            object.registry = []
            for (let j = 0; j < message.registry.length; ++j)
              object.registry[j] = $root.umee.leverage.v1.Token.toObject(message.registry[j], options)
          }
          if (message.adjustedBorrows && message.adjustedBorrows.length) {
            object.adjustedBorrows = []
            for (let j = 0; j < message.adjustedBorrows.length; ++j)
              object.adjustedBorrows[j] = $root.umee.leverage.v1.AdjustedBorrow.toObject(
                message.adjustedBorrows[j],
                options,
              )
          }
          if (message.collateral && message.collateral.length) {
            object.collateral = []
            for (let j = 0; j < message.collateral.length; ++j)
              object.collateral[j] = $root.umee.leverage.v1.Collateral.toObject(message.collateral[j], options)
          }
          if (message.reserves && message.reserves.length) {
            object.reserves = []
            for (let j = 0; j < message.reserves.length; ++j)
              object.reserves[j] = $root.cosmos.base.v1beta1.Coin.toObject(message.reserves[j], options)
          }
          if (message.lastInterestTime != null && message.hasOwnProperty('lastInterestTime'))
            if (typeof message.lastInterestTime === 'number')
              object.lastInterestTime =
                options.longs === String ? String(message.lastInterestTime) : message.lastInterestTime
            else
              object.lastInterestTime =
                options.longs === String
                  ? $util.Long.prototype.toString.call(message.lastInterestTime)
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.lastInterestTime.low >>> 0,
                      message.lastInterestTime.high >>> 0,
                    ).toNumber()
                  : message.lastInterestTime
          if (message.badDebts && message.badDebts.length) {
            object.badDebts = []
            for (let j = 0; j < message.badDebts.length; ++j)
              object.badDebts[j] = $root.umee.leverage.v1.BadDebt.toObject(message.badDebts[j], options)
          }
          if (message.interestScalars && message.interestScalars.length) {
            object.interestScalars = []
            for (let j = 0; j < message.interestScalars.length; ++j)
              object.interestScalars[j] = $root.umee.leverage.v1.InterestScalar.toObject(
                message.interestScalars[j],
                options,
              )
          }
          if (message.utokenSupply && message.utokenSupply.length) {
            object.utokenSupply = []
            for (let j = 0; j < message.utokenSupply.length; ++j)
              object.utokenSupply[j] = $root.cosmos.base.v1beta1.Coin.toObject(message.utokenSupply[j], options)
          }
          return object
        }

        /**
         * Converts this GenesisState to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.GenesisState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenesisState.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return GenesisState
      })()

      v1.AdjustedBorrow = (function () {
        /**
         * Properties of an AdjustedBorrow.
         * @memberof umee.leverage.v1
         * @interface IAdjustedBorrow
         * @property {string|null} [address] AdjustedBorrow address
         * @property {cosmos.base.v1beta1.IDecCoin|null} [amount] AdjustedBorrow amount
         */

        /**
         * Constructs a new AdjustedBorrow.
         * @memberof umee.leverage.v1
         * @classdesc Represents an AdjustedBorrow.
         * @implements IAdjustedBorrow
         * @constructor
         * @param {umee.leverage.v1.IAdjustedBorrow=} [properties] Properties to set
         */
        function AdjustedBorrow(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * AdjustedBorrow address.
         * @member {string} address
         * @memberof umee.leverage.v1.AdjustedBorrow
         * @instance
         */
        AdjustedBorrow.prototype.address = ''

        /**
         * AdjustedBorrow amount.
         * @member {cosmos.base.v1beta1.IDecCoin|null|undefined} amount
         * @memberof umee.leverage.v1.AdjustedBorrow
         * @instance
         */
        AdjustedBorrow.prototype.amount = null

        /**
         * Creates a new AdjustedBorrow instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.AdjustedBorrow
         * @static
         * @param {umee.leverage.v1.IAdjustedBorrow=} [properties] Properties to set
         * @returns {umee.leverage.v1.AdjustedBorrow} AdjustedBorrow instance
         */
        AdjustedBorrow.create = function create(properties) {
          return new AdjustedBorrow(properties)
        }

        /**
         * Encodes the specified AdjustedBorrow message. Does not implicitly {@link umee.leverage.v1.AdjustedBorrow.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.AdjustedBorrow
         * @static
         * @param {umee.leverage.v1.IAdjustedBorrow} message AdjustedBorrow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdjustedBorrow.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.address != null && Object.hasOwnProperty.call(message, 'address'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.address)
          if (message.amount != null && Object.hasOwnProperty.call(message, 'amount'))
            $root.cosmos.base.v1beta1.DecCoin.encode(
              message.amount,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified AdjustedBorrow message, length delimited. Does not implicitly {@link umee.leverage.v1.AdjustedBorrow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.AdjustedBorrow
         * @static
         * @param {umee.leverage.v1.IAdjustedBorrow} message AdjustedBorrow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdjustedBorrow.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an AdjustedBorrow message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.AdjustedBorrow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.AdjustedBorrow} AdjustedBorrow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdjustedBorrow.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.AdjustedBorrow()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.address = reader.string()
                break
              case 2:
                message.amount = $root.cosmos.base.v1beta1.DecCoin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an AdjustedBorrow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.AdjustedBorrow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.AdjustedBorrow} AdjustedBorrow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdjustedBorrow.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an AdjustedBorrow message.
         * @function verify
         * @memberof umee.leverage.v1.AdjustedBorrow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdjustedBorrow.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.address != null && message.hasOwnProperty('address'))
            if (!$util.isString(message.address)) return 'address: string expected'
          if (message.amount != null && message.hasOwnProperty('amount')) {
            let error = $root.cosmos.base.v1beta1.DecCoin.verify(message.amount)
            if (error) return 'amount.' + error
          }
          return null
        }

        /**
         * Creates an AdjustedBorrow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.AdjustedBorrow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.AdjustedBorrow} AdjustedBorrow
         */
        AdjustedBorrow.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.AdjustedBorrow) return object
          let message = new $root.umee.leverage.v1.AdjustedBorrow()
          if (object.address != null) message.address = String(object.address)
          if (object.amount != null) {
            if (typeof object.amount !== 'object')
              throw TypeError('.umee.leverage.v1.AdjustedBorrow.amount: object expected')
            message.amount = $root.cosmos.base.v1beta1.DecCoin.fromObject(object.amount)
          }
          return message
        }

        /**
         * Creates a plain object from an AdjustedBorrow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.AdjustedBorrow
         * @static
         * @param {umee.leverage.v1.AdjustedBorrow} message AdjustedBorrow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdjustedBorrow.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.address = ''
            object.amount = null
          }
          if (message.address != null && message.hasOwnProperty('address')) object.address = message.address
          if (message.amount != null && message.hasOwnProperty('amount'))
            object.amount = $root.cosmos.base.v1beta1.DecCoin.toObject(message.amount, options)
          return object
        }

        /**
         * Converts this AdjustedBorrow to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.AdjustedBorrow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdjustedBorrow.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return AdjustedBorrow
      })()

      v1.Collateral = (function () {
        /**
         * Properties of a Collateral.
         * @memberof umee.leverage.v1
         * @interface ICollateral
         * @property {string|null} [address] Collateral address
         * @property {cosmos.base.v1beta1.ICoin|null} [amount] Collateral amount
         */

        /**
         * Constructs a new Collateral.
         * @memberof umee.leverage.v1
         * @classdesc Represents a Collateral.
         * @implements ICollateral
         * @constructor
         * @param {umee.leverage.v1.ICollateral=} [properties] Properties to set
         */
        function Collateral(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Collateral address.
         * @member {string} address
         * @memberof umee.leverage.v1.Collateral
         * @instance
         */
        Collateral.prototype.address = ''

        /**
         * Collateral amount.
         * @member {cosmos.base.v1beta1.ICoin|null|undefined} amount
         * @memberof umee.leverage.v1.Collateral
         * @instance
         */
        Collateral.prototype.amount = null

        /**
         * Creates a new Collateral instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.Collateral
         * @static
         * @param {umee.leverage.v1.ICollateral=} [properties] Properties to set
         * @returns {umee.leverage.v1.Collateral} Collateral instance
         */
        Collateral.create = function create(properties) {
          return new Collateral(properties)
        }

        /**
         * Encodes the specified Collateral message. Does not implicitly {@link umee.leverage.v1.Collateral.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.Collateral
         * @static
         * @param {umee.leverage.v1.ICollateral} message Collateral message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Collateral.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.address != null && Object.hasOwnProperty.call(message, 'address'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.address)
          if (message.amount != null && Object.hasOwnProperty.call(message, 'amount'))
            $root.cosmos.base.v1beta1.Coin.encode(
              message.amount,
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
          return writer
        }

        /**
         * Encodes the specified Collateral message, length delimited. Does not implicitly {@link umee.leverage.v1.Collateral.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.Collateral
         * @static
         * @param {umee.leverage.v1.ICollateral} message Collateral message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Collateral.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a Collateral message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.Collateral
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.Collateral} Collateral
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Collateral.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.Collateral()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.address = reader.string()
                break
              case 2:
                message.amount = $root.cosmos.base.v1beta1.Coin.decode(reader, reader.uint32())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a Collateral message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.Collateral
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.Collateral} Collateral
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Collateral.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a Collateral message.
         * @function verify
         * @memberof umee.leverage.v1.Collateral
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Collateral.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.address != null && message.hasOwnProperty('address'))
            if (!$util.isString(message.address)) return 'address: string expected'
          if (message.amount != null && message.hasOwnProperty('amount')) {
            let error = $root.cosmos.base.v1beta1.Coin.verify(message.amount)
            if (error) return 'amount.' + error
          }
          return null
        }

        /**
         * Creates a Collateral message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.Collateral
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.Collateral} Collateral
         */
        Collateral.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.Collateral) return object
          let message = new $root.umee.leverage.v1.Collateral()
          if (object.address != null) message.address = String(object.address)
          if (object.amount != null) {
            if (typeof object.amount !== 'object')
              throw TypeError('.umee.leverage.v1.Collateral.amount: object expected')
            message.amount = $root.cosmos.base.v1beta1.Coin.fromObject(object.amount)
          }
          return message
        }

        /**
         * Creates a plain object from a Collateral message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.Collateral
         * @static
         * @param {umee.leverage.v1.Collateral} message Collateral
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Collateral.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.address = ''
            object.amount = null
          }
          if (message.address != null && message.hasOwnProperty('address')) object.address = message.address
          if (message.amount != null && message.hasOwnProperty('amount'))
            object.amount = $root.cosmos.base.v1beta1.Coin.toObject(message.amount, options)
          return object
        }

        /**
         * Converts this Collateral to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.Collateral
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Collateral.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return Collateral
      })()

      v1.BadDebt = (function () {
        /**
         * Properties of a BadDebt.
         * @memberof umee.leverage.v1
         * @interface IBadDebt
         * @property {string|null} [address] BadDebt address
         * @property {string|null} [denom] BadDebt denom
         */

        /**
         * Constructs a new BadDebt.
         * @memberof umee.leverage.v1
         * @classdesc Represents a BadDebt.
         * @implements IBadDebt
         * @constructor
         * @param {umee.leverage.v1.IBadDebt=} [properties] Properties to set
         */
        function BadDebt(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * BadDebt address.
         * @member {string} address
         * @memberof umee.leverage.v1.BadDebt
         * @instance
         */
        BadDebt.prototype.address = ''

        /**
         * BadDebt denom.
         * @member {string} denom
         * @memberof umee.leverage.v1.BadDebt
         * @instance
         */
        BadDebt.prototype.denom = ''

        /**
         * Creates a new BadDebt instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.BadDebt
         * @static
         * @param {umee.leverage.v1.IBadDebt=} [properties] Properties to set
         * @returns {umee.leverage.v1.BadDebt} BadDebt instance
         */
        BadDebt.create = function create(properties) {
          return new BadDebt(properties)
        }

        /**
         * Encodes the specified BadDebt message. Does not implicitly {@link umee.leverage.v1.BadDebt.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.BadDebt
         * @static
         * @param {umee.leverage.v1.IBadDebt} message BadDebt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BadDebt.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.address != null && Object.hasOwnProperty.call(message, 'address'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.address)
          if (message.denom != null && Object.hasOwnProperty.call(message, 'denom'))
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.denom)
          return writer
        }

        /**
         * Encodes the specified BadDebt message, length delimited. Does not implicitly {@link umee.leverage.v1.BadDebt.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.BadDebt
         * @static
         * @param {umee.leverage.v1.IBadDebt} message BadDebt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BadDebt.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a BadDebt message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.BadDebt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.BadDebt} BadDebt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BadDebt.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.BadDebt()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.address = reader.string()
                break
              case 2:
                message.denom = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a BadDebt message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.BadDebt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.BadDebt} BadDebt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BadDebt.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a BadDebt message.
         * @function verify
         * @memberof umee.leverage.v1.BadDebt
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BadDebt.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.address != null && message.hasOwnProperty('address'))
            if (!$util.isString(message.address)) return 'address: string expected'
          if (message.denom != null && message.hasOwnProperty('denom'))
            if (!$util.isString(message.denom)) return 'denom: string expected'
          return null
        }

        /**
         * Creates a BadDebt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.BadDebt
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.BadDebt} BadDebt
         */
        BadDebt.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.BadDebt) return object
          let message = new $root.umee.leverage.v1.BadDebt()
          if (object.address != null) message.address = String(object.address)
          if (object.denom != null) message.denom = String(object.denom)
          return message
        }

        /**
         * Creates a plain object from a BadDebt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.BadDebt
         * @static
         * @param {umee.leverage.v1.BadDebt} message BadDebt
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BadDebt.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.address = ''
            object.denom = ''
          }
          if (message.address != null && message.hasOwnProperty('address')) object.address = message.address
          if (message.denom != null && message.hasOwnProperty('denom')) object.denom = message.denom
          return object
        }

        /**
         * Converts this BadDebt to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.BadDebt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BadDebt.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return BadDebt
      })()

      v1.InterestScalar = (function () {
        /**
         * Properties of an InterestScalar.
         * @memberof umee.leverage.v1
         * @interface IInterestScalar
         * @property {string|null} [denom] InterestScalar denom
         * @property {string|null} [scalar] InterestScalar scalar
         */

        /**
         * Constructs a new InterestScalar.
         * @memberof umee.leverage.v1
         * @classdesc Represents an InterestScalar.
         * @implements IInterestScalar
         * @constructor
         * @param {umee.leverage.v1.IInterestScalar=} [properties] Properties to set
         */
        function InterestScalar(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * InterestScalar denom.
         * @member {string} denom
         * @memberof umee.leverage.v1.InterestScalar
         * @instance
         */
        InterestScalar.prototype.denom = ''

        /**
         * InterestScalar scalar.
         * @member {string} scalar
         * @memberof umee.leverage.v1.InterestScalar
         * @instance
         */
        InterestScalar.prototype.scalar = ''

        /**
         * Creates a new InterestScalar instance using the specified properties.
         * @function create
         * @memberof umee.leverage.v1.InterestScalar
         * @static
         * @param {umee.leverage.v1.IInterestScalar=} [properties] Properties to set
         * @returns {umee.leverage.v1.InterestScalar} InterestScalar instance
         */
        InterestScalar.create = function create(properties) {
          return new InterestScalar(properties)
        }

        /**
         * Encodes the specified InterestScalar message. Does not implicitly {@link umee.leverage.v1.InterestScalar.verify|verify} messages.
         * @function encode
         * @memberof umee.leverage.v1.InterestScalar
         * @static
         * @param {umee.leverage.v1.IInterestScalar} message InterestScalar message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InterestScalar.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.denom != null && Object.hasOwnProperty.call(message, 'denom'))
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.denom)
          if (message.scalar != null && Object.hasOwnProperty.call(message, 'scalar'))
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.scalar)
          return writer
        }

        /**
         * Encodes the specified InterestScalar message, length delimited. Does not implicitly {@link umee.leverage.v1.InterestScalar.verify|verify} messages.
         * @function encodeDelimited
         * @memberof umee.leverage.v1.InterestScalar
         * @static
         * @param {umee.leverage.v1.IInterestScalar} message InterestScalar message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InterestScalar.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an InterestScalar message from the specified reader or buffer.
         * @function decode
         * @memberof umee.leverage.v1.InterestScalar
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {umee.leverage.v1.InterestScalar} InterestScalar
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InterestScalar.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.umee.leverage.v1.InterestScalar()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.denom = reader.string()
                break
              case 2:
                message.scalar = reader.string()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an InterestScalar message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof umee.leverage.v1.InterestScalar
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {umee.leverage.v1.InterestScalar} InterestScalar
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InterestScalar.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an InterestScalar message.
         * @function verify
         * @memberof umee.leverage.v1.InterestScalar
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InterestScalar.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.denom != null && message.hasOwnProperty('denom'))
            if (!$util.isString(message.denom)) return 'denom: string expected'
          if (message.scalar != null && message.hasOwnProperty('scalar'))
            if (!$util.isString(message.scalar)) return 'scalar: string expected'
          return null
        }

        /**
         * Creates an InterestScalar message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof umee.leverage.v1.InterestScalar
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {umee.leverage.v1.InterestScalar} InterestScalar
         */
        InterestScalar.fromObject = function fromObject(object) {
          if (object instanceof $root.umee.leverage.v1.InterestScalar) return object
          let message = new $root.umee.leverage.v1.InterestScalar()
          if (object.denom != null) message.denom = String(object.denom)
          if (object.scalar != null) message.scalar = String(object.scalar)
          return message
        }

        /**
         * Creates a plain object from an InterestScalar message. Also converts values to other types if specified.
         * @function toObject
         * @memberof umee.leverage.v1.InterestScalar
         * @static
         * @param {umee.leverage.v1.InterestScalar} message InterestScalar
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InterestScalar.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.denom = ''
            object.scalar = ''
          }
          if (message.denom != null && message.hasOwnProperty('denom')) object.denom = message.denom
          if (message.scalar != null && message.hasOwnProperty('scalar')) object.scalar = message.scalar
          return object
        }

        /**
         * Converts this InterestScalar to JSON.
         * @function toJSON
         * @memberof umee.leverage.v1.InterestScalar
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InterestScalar.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return InterestScalar
      })()

      return v1
    })()

    return leverage
  })()

  return umee
})())

export const google = ($root.google = (() => {
  /**
   * Namespace google.
   * @exports google
   * @namespace
   */
  const google = {}

  google.api = (function () {
    /**
     * Namespace api.
     * @memberof google
     * @namespace
     */
    const api = {}

    api.Http = (function () {
      /**
       * Properties of a Http.
       * @memberof google.api
       * @interface IHttp
       * @property {Array.<google.api.IHttpRule>|null} [rules] Http rules
       */

      /**
       * Constructs a new Http.
       * @memberof google.api
       * @classdesc Represents a Http.
       * @implements IHttp
       * @constructor
       * @param {google.api.IHttp=} [properties] Properties to set
       */
      function Http(properties) {
        this.rules = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * Http rules.
       * @member {Array.<google.api.IHttpRule>} rules
       * @memberof google.api.Http
       * @instance
       */
      Http.prototype.rules = $util.emptyArray

      /**
       * Creates a new Http instance using the specified properties.
       * @function create
       * @memberof google.api.Http
       * @static
       * @param {google.api.IHttp=} [properties] Properties to set
       * @returns {google.api.Http} Http instance
       */
      Http.create = function create(properties) {
        return new Http(properties)
      }

      /**
       * Encodes the specified Http message. Does not implicitly {@link google.api.Http.verify|verify} messages.
       * @function encode
       * @memberof google.api.Http
       * @static
       * @param {google.api.IHttp} message Http message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Http.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.rules != null && message.rules.length)
          for (let i = 0; i < message.rules.length; ++i)
            $root.google.api.HttpRule.encode(
              message.rules[i],
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified Http message, length delimited. Does not implicitly {@link google.api.Http.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.api.Http
       * @static
       * @param {google.api.IHttp} message Http message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Http.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a Http message from the specified reader or buffer.
       * @function decode
       * @memberof google.api.Http
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.api.Http} Http
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Http.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.api.Http()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              if (!(message.rules && message.rules.length)) message.rules = []
              message.rules.push($root.google.api.HttpRule.decode(reader, reader.uint32()))
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a Http message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.api.Http
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.api.Http} Http
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Http.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a Http message.
       * @function verify
       * @memberof google.api.Http
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      Http.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.rules != null && message.hasOwnProperty('rules')) {
          if (!Array.isArray(message.rules)) return 'rules: array expected'
          for (let i = 0; i < message.rules.length; ++i) {
            let error = $root.google.api.HttpRule.verify(message.rules[i])
            if (error) return 'rules.' + error
          }
        }
        return null
      }

      /**
       * Creates a Http message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.api.Http
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.api.Http} Http
       */
      Http.fromObject = function fromObject(object) {
        if (object instanceof $root.google.api.Http) return object
        let message = new $root.google.api.Http()
        if (object.rules) {
          if (!Array.isArray(object.rules)) throw TypeError('.google.api.Http.rules: array expected')
          message.rules = []
          for (let i = 0; i < object.rules.length; ++i) {
            if (typeof object.rules[i] !== 'object') throw TypeError('.google.api.Http.rules: object expected')
            message.rules[i] = $root.google.api.HttpRule.fromObject(object.rules[i])
          }
        }
        return message
      }

      /**
       * Creates a plain object from a Http message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.api.Http
       * @static
       * @param {google.api.Http} message Http
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      Http.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.rules = []
        if (message.rules && message.rules.length) {
          object.rules = []
          for (let j = 0; j < message.rules.length; ++j)
            object.rules[j] = $root.google.api.HttpRule.toObject(message.rules[j], options)
        }
        return object
      }

      /**
       * Converts this Http to JSON.
       * @function toJSON
       * @memberof google.api.Http
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      Http.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return Http
    })()

    api.HttpRule = (function () {
      /**
       * Properties of a HttpRule.
       * @memberof google.api
       * @interface IHttpRule
       * @property {string|null} [get] HttpRule get
       * @property {string|null} [put] HttpRule put
       * @property {string|null} [post] HttpRule post
       * @property {string|null} ["delete"] HttpRule delete
       * @property {string|null} [patch] HttpRule patch
       * @property {google.api.ICustomHttpPattern|null} [custom] HttpRule custom
       * @property {string|null} [selector] HttpRule selector
       * @property {string|null} [body] HttpRule body
       * @property {Array.<google.api.IHttpRule>|null} [additionalBindings] HttpRule additionalBindings
       */

      /**
       * Constructs a new HttpRule.
       * @memberof google.api
       * @classdesc Represents a HttpRule.
       * @implements IHttpRule
       * @constructor
       * @param {google.api.IHttpRule=} [properties] Properties to set
       */
      function HttpRule(properties) {
        this.additionalBindings = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * HttpRule get.
       * @member {string} get
       * @memberof google.api.HttpRule
       * @instance
       */
      HttpRule.prototype.get = ''

      /**
       * HttpRule put.
       * @member {string} put
       * @memberof google.api.HttpRule
       * @instance
       */
      HttpRule.prototype.put = ''

      /**
       * HttpRule post.
       * @member {string} post
       * @memberof google.api.HttpRule
       * @instance
       */
      HttpRule.prototype.post = ''

      /**
       * HttpRule delete.
       * @member {string} delete
       * @memberof google.api.HttpRule
       * @instance
       */
      HttpRule.prototype['delete'] = ''

      /**
       * HttpRule patch.
       * @member {string} patch
       * @memberof google.api.HttpRule
       * @instance
       */
      HttpRule.prototype.patch = ''

      /**
       * HttpRule custom.
       * @member {google.api.ICustomHttpPattern|null|undefined} custom
       * @memberof google.api.HttpRule
       * @instance
       */
      HttpRule.prototype.custom = null

      /**
       * HttpRule selector.
       * @member {string} selector
       * @memberof google.api.HttpRule
       * @instance
       */
      HttpRule.prototype.selector = ''

      /**
       * HttpRule body.
       * @member {string} body
       * @memberof google.api.HttpRule
       * @instance
       */
      HttpRule.prototype.body = ''

      /**
       * HttpRule additionalBindings.
       * @member {Array.<google.api.IHttpRule>} additionalBindings
       * @memberof google.api.HttpRule
       * @instance
       */
      HttpRule.prototype.additionalBindings = $util.emptyArray

      // OneOf field names bound to virtual getters and setters
      let $oneOfFields

      /**
       * HttpRule pattern.
       * @member {"get"|"put"|"post"|"delete"|"patch"|"custom"|undefined} pattern
       * @memberof google.api.HttpRule
       * @instance
       */
      Object.defineProperty(HttpRule.prototype, 'pattern', {
        get: $util.oneOfGetter(($oneOfFields = ['get', 'put', 'post', 'delete', 'patch', 'custom'])),
        set: $util.oneOfSetter($oneOfFields),
      })

      /**
       * Creates a new HttpRule instance using the specified properties.
       * @function create
       * @memberof google.api.HttpRule
       * @static
       * @param {google.api.IHttpRule=} [properties] Properties to set
       * @returns {google.api.HttpRule} HttpRule instance
       */
      HttpRule.create = function create(properties) {
        return new HttpRule(properties)
      }

      /**
       * Encodes the specified HttpRule message. Does not implicitly {@link google.api.HttpRule.verify|verify} messages.
       * @function encode
       * @memberof google.api.HttpRule
       * @static
       * @param {google.api.IHttpRule} message HttpRule message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      HttpRule.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.selector != null && Object.hasOwnProperty.call(message, 'selector'))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.selector)
        if (message.get != null && Object.hasOwnProperty.call(message, 'get'))
          writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.get)
        if (message.put != null && Object.hasOwnProperty.call(message, 'put'))
          writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.put)
        if (message.post != null && Object.hasOwnProperty.call(message, 'post'))
          writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.post)
        if (message['delete'] != null && Object.hasOwnProperty.call(message, 'delete'))
          writer.uint32(/* id 5, wireType 2 =*/ 42).string(message['delete'])
        if (message.patch != null && Object.hasOwnProperty.call(message, 'patch'))
          writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.patch)
        if (message.body != null && Object.hasOwnProperty.call(message, 'body'))
          writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.body)
        if (message.custom != null && Object.hasOwnProperty.call(message, 'custom'))
          $root.google.api.CustomHttpPattern.encode(
            message.custom,
            writer.uint32(/* id 8, wireType 2 =*/ 66).fork(),
          ).ldelim()
        if (message.additionalBindings != null && message.additionalBindings.length)
          for (let i = 0; i < message.additionalBindings.length; ++i)
            $root.google.api.HttpRule.encode(
              message.additionalBindings[i],
              writer.uint32(/* id 11, wireType 2 =*/ 90).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified HttpRule message, length delimited. Does not implicitly {@link google.api.HttpRule.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.api.HttpRule
       * @static
       * @param {google.api.IHttpRule} message HttpRule message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      HttpRule.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a HttpRule message from the specified reader or buffer.
       * @function decode
       * @memberof google.api.HttpRule
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.api.HttpRule} HttpRule
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      HttpRule.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.api.HttpRule()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 2:
              message.get = reader.string()
              break
            case 3:
              message.put = reader.string()
              break
            case 4:
              message.post = reader.string()
              break
            case 5:
              message['delete'] = reader.string()
              break
            case 6:
              message.patch = reader.string()
              break
            case 8:
              message.custom = $root.google.api.CustomHttpPattern.decode(reader, reader.uint32())
              break
            case 1:
              message.selector = reader.string()
              break
            case 7:
              message.body = reader.string()
              break
            case 11:
              if (!(message.additionalBindings && message.additionalBindings.length)) message.additionalBindings = []
              message.additionalBindings.push($root.google.api.HttpRule.decode(reader, reader.uint32()))
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a HttpRule message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.api.HttpRule
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.api.HttpRule} HttpRule
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      HttpRule.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a HttpRule message.
       * @function verify
       * @memberof google.api.HttpRule
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      HttpRule.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        let properties = {}
        if (message.get != null && message.hasOwnProperty('get')) {
          properties.pattern = 1
          if (!$util.isString(message.get)) return 'get: string expected'
        }
        if (message.put != null && message.hasOwnProperty('put')) {
          if (properties.pattern === 1) return 'pattern: multiple values'
          properties.pattern = 1
          if (!$util.isString(message.put)) return 'put: string expected'
        }
        if (message.post != null && message.hasOwnProperty('post')) {
          if (properties.pattern === 1) return 'pattern: multiple values'
          properties.pattern = 1
          if (!$util.isString(message.post)) return 'post: string expected'
        }
        if (message['delete'] != null && message.hasOwnProperty('delete')) {
          if (properties.pattern === 1) return 'pattern: multiple values'
          properties.pattern = 1
          if (!$util.isString(message['delete'])) return 'delete: string expected'
        }
        if (message.patch != null && message.hasOwnProperty('patch')) {
          if (properties.pattern === 1) return 'pattern: multiple values'
          properties.pattern = 1
          if (!$util.isString(message.patch)) return 'patch: string expected'
        }
        if (message.custom != null && message.hasOwnProperty('custom')) {
          if (properties.pattern === 1) return 'pattern: multiple values'
          properties.pattern = 1
          {
            let error = $root.google.api.CustomHttpPattern.verify(message.custom)
            if (error) return 'custom.' + error
          }
        }
        if (message.selector != null && message.hasOwnProperty('selector'))
          if (!$util.isString(message.selector)) return 'selector: string expected'
        if (message.body != null && message.hasOwnProperty('body'))
          if (!$util.isString(message.body)) return 'body: string expected'
        if (message.additionalBindings != null && message.hasOwnProperty('additionalBindings')) {
          if (!Array.isArray(message.additionalBindings)) return 'additionalBindings: array expected'
          for (let i = 0; i < message.additionalBindings.length; ++i) {
            let error = $root.google.api.HttpRule.verify(message.additionalBindings[i])
            if (error) return 'additionalBindings.' + error
          }
        }
        return null
      }

      /**
       * Creates a HttpRule message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.api.HttpRule
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.api.HttpRule} HttpRule
       */
      HttpRule.fromObject = function fromObject(object) {
        if (object instanceof $root.google.api.HttpRule) return object
        let message = new $root.google.api.HttpRule()
        if (object.get != null) message.get = String(object.get)
        if (object.put != null) message.put = String(object.put)
        if (object.post != null) message.post = String(object.post)
        if (object['delete'] != null) message['delete'] = String(object['delete'])
        if (object.patch != null) message.patch = String(object.patch)
        if (object.custom != null) {
          if (typeof object.custom !== 'object') throw TypeError('.google.api.HttpRule.custom: object expected')
          message.custom = $root.google.api.CustomHttpPattern.fromObject(object.custom)
        }
        if (object.selector != null) message.selector = String(object.selector)
        if (object.body != null) message.body = String(object.body)
        if (object.additionalBindings) {
          if (!Array.isArray(object.additionalBindings))
            throw TypeError('.google.api.HttpRule.additionalBindings: array expected')
          message.additionalBindings = []
          for (let i = 0; i < object.additionalBindings.length; ++i) {
            if (typeof object.additionalBindings[i] !== 'object')
              throw TypeError('.google.api.HttpRule.additionalBindings: object expected')
            message.additionalBindings[i] = $root.google.api.HttpRule.fromObject(object.additionalBindings[i])
          }
        }
        return message
      }

      /**
       * Creates a plain object from a HttpRule message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.api.HttpRule
       * @static
       * @param {google.api.HttpRule} message HttpRule
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      HttpRule.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.additionalBindings = []
        if (options.defaults) {
          object.selector = ''
          object.body = ''
        }
        if (message.selector != null && message.hasOwnProperty('selector')) object.selector = message.selector
        if (message.get != null && message.hasOwnProperty('get')) {
          object.get = message.get
          if (options.oneofs) object.pattern = 'get'
        }
        if (message.put != null && message.hasOwnProperty('put')) {
          object.put = message.put
          if (options.oneofs) object.pattern = 'put'
        }
        if (message.post != null && message.hasOwnProperty('post')) {
          object.post = message.post
          if (options.oneofs) object.pattern = 'post'
        }
        if (message['delete'] != null && message.hasOwnProperty('delete')) {
          object['delete'] = message['delete']
          if (options.oneofs) object.pattern = 'delete'
        }
        if (message.patch != null && message.hasOwnProperty('patch')) {
          object.patch = message.patch
          if (options.oneofs) object.pattern = 'patch'
        }
        if (message.body != null && message.hasOwnProperty('body')) object.body = message.body
        if (message.custom != null && message.hasOwnProperty('custom')) {
          object.custom = $root.google.api.CustomHttpPattern.toObject(message.custom, options)
          if (options.oneofs) object.pattern = 'custom'
        }
        if (message.additionalBindings && message.additionalBindings.length) {
          object.additionalBindings = []
          for (let j = 0; j < message.additionalBindings.length; ++j)
            object.additionalBindings[j] = $root.google.api.HttpRule.toObject(message.additionalBindings[j], options)
        }
        return object
      }

      /**
       * Converts this HttpRule to JSON.
       * @function toJSON
       * @memberof google.api.HttpRule
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      HttpRule.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return HttpRule
    })()

    api.CustomHttpPattern = (function () {
      /**
       * Properties of a CustomHttpPattern.
       * @memberof google.api
       * @interface ICustomHttpPattern
       * @property {string|null} [kind] CustomHttpPattern kind
       * @property {string|null} [path] CustomHttpPattern path
       */

      /**
       * Constructs a new CustomHttpPattern.
       * @memberof google.api
       * @classdesc Represents a CustomHttpPattern.
       * @implements ICustomHttpPattern
       * @constructor
       * @param {google.api.ICustomHttpPattern=} [properties] Properties to set
       */
      function CustomHttpPattern(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * CustomHttpPattern kind.
       * @member {string} kind
       * @memberof google.api.CustomHttpPattern
       * @instance
       */
      CustomHttpPattern.prototype.kind = ''

      /**
       * CustomHttpPattern path.
       * @member {string} path
       * @memberof google.api.CustomHttpPattern
       * @instance
       */
      CustomHttpPattern.prototype.path = ''

      /**
       * Creates a new CustomHttpPattern instance using the specified properties.
       * @function create
       * @memberof google.api.CustomHttpPattern
       * @static
       * @param {google.api.ICustomHttpPattern=} [properties] Properties to set
       * @returns {google.api.CustomHttpPattern} CustomHttpPattern instance
       */
      CustomHttpPattern.create = function create(properties) {
        return new CustomHttpPattern(properties)
      }

      /**
       * Encodes the specified CustomHttpPattern message. Does not implicitly {@link google.api.CustomHttpPattern.verify|verify} messages.
       * @function encode
       * @memberof google.api.CustomHttpPattern
       * @static
       * @param {google.api.ICustomHttpPattern} message CustomHttpPattern message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      CustomHttpPattern.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.kind != null && Object.hasOwnProperty.call(message, 'kind'))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.kind)
        if (message.path != null && Object.hasOwnProperty.call(message, 'path'))
          writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.path)
        return writer
      }

      /**
       * Encodes the specified CustomHttpPattern message, length delimited. Does not implicitly {@link google.api.CustomHttpPattern.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.api.CustomHttpPattern
       * @static
       * @param {google.api.ICustomHttpPattern} message CustomHttpPattern message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      CustomHttpPattern.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a CustomHttpPattern message from the specified reader or buffer.
       * @function decode
       * @memberof google.api.CustomHttpPattern
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.api.CustomHttpPattern} CustomHttpPattern
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      CustomHttpPattern.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.api.CustomHttpPattern()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.kind = reader.string()
              break
            case 2:
              message.path = reader.string()
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a CustomHttpPattern message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.api.CustomHttpPattern
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.api.CustomHttpPattern} CustomHttpPattern
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      CustomHttpPattern.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a CustomHttpPattern message.
       * @function verify
       * @memberof google.api.CustomHttpPattern
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      CustomHttpPattern.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.kind != null && message.hasOwnProperty('kind'))
          if (!$util.isString(message.kind)) return 'kind: string expected'
        if (message.path != null && message.hasOwnProperty('path'))
          if (!$util.isString(message.path)) return 'path: string expected'
        return null
      }

      /**
       * Creates a CustomHttpPattern message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.api.CustomHttpPattern
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.api.CustomHttpPattern} CustomHttpPattern
       */
      CustomHttpPattern.fromObject = function fromObject(object) {
        if (object instanceof $root.google.api.CustomHttpPattern) return object
        let message = new $root.google.api.CustomHttpPattern()
        if (object.kind != null) message.kind = String(object.kind)
        if (object.path != null) message.path = String(object.path)
        return message
      }

      /**
       * Creates a plain object from a CustomHttpPattern message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.api.CustomHttpPattern
       * @static
       * @param {google.api.CustomHttpPattern} message CustomHttpPattern
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      CustomHttpPattern.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.defaults) {
          object.kind = ''
          object.path = ''
        }
        if (message.kind != null && message.hasOwnProperty('kind')) object.kind = message.kind
        if (message.path != null && message.hasOwnProperty('path')) object.path = message.path
        return object
      }

      /**
       * Converts this CustomHttpPattern to JSON.
       * @function toJSON
       * @memberof google.api.CustomHttpPattern
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      CustomHttpPattern.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return CustomHttpPattern
    })()

    return api
  })()

  google.protobuf = (function () {
    /**
     * Namespace protobuf.
     * @memberof google
     * @namespace
     */
    const protobuf = {}

    protobuf.FileDescriptorSet = (function () {
      /**
       * Properties of a FileDescriptorSet.
       * @memberof google.protobuf
       * @interface IFileDescriptorSet
       * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
       */

      /**
       * Constructs a new FileDescriptorSet.
       * @memberof google.protobuf
       * @classdesc Represents a FileDescriptorSet.
       * @implements IFileDescriptorSet
       * @constructor
       * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
       */
      function FileDescriptorSet(properties) {
        this.file = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * FileDescriptorSet file.
       * @member {Array.<google.protobuf.IFileDescriptorProto>} file
       * @memberof google.protobuf.FileDescriptorSet
       * @instance
       */
      FileDescriptorSet.prototype.file = $util.emptyArray

      /**
       * Creates a new FileDescriptorSet instance using the specified properties.
       * @function create
       * @memberof google.protobuf.FileDescriptorSet
       * @static
       * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
       * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
       */
      FileDescriptorSet.create = function create(properties) {
        return new FileDescriptorSet(properties)
      }

      /**
       * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.FileDescriptorSet
       * @static
       * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FileDescriptorSet.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.file != null && message.file.length)
          for (let i = 0; i < message.file.length; ++i)
            $root.google.protobuf.FileDescriptorProto.encode(
              message.file[i],
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified FileDescriptorSet message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.FileDescriptorSet
       * @static
       * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FileDescriptorSet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a FileDescriptorSet message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.FileDescriptorSet
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FileDescriptorSet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.FileDescriptorSet()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              if (!(message.file && message.file.length)) message.file = []
              message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()))
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a FileDescriptorSet message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.FileDescriptorSet
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FileDescriptorSet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a FileDescriptorSet message.
       * @function verify
       * @memberof google.protobuf.FileDescriptorSet
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      FileDescriptorSet.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.file != null && message.hasOwnProperty('file')) {
          if (!Array.isArray(message.file)) return 'file: array expected'
          for (let i = 0; i < message.file.length; ++i) {
            let error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i])
            if (error) return 'file.' + error
          }
        }
        return null
      }

      /**
       * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.FileDescriptorSet
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
       */
      FileDescriptorSet.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.FileDescriptorSet) return object
        let message = new $root.google.protobuf.FileDescriptorSet()
        if (object.file) {
          if (!Array.isArray(object.file)) throw TypeError('.google.protobuf.FileDescriptorSet.file: array expected')
          message.file = []
          for (let i = 0; i < object.file.length; ++i) {
            if (typeof object.file[i] !== 'object')
              throw TypeError('.google.protobuf.FileDescriptorSet.file: object expected')
            message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i])
          }
        }
        return message
      }

      /**
       * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.FileDescriptorSet
       * @static
       * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      FileDescriptorSet.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.file = []
        if (message.file && message.file.length) {
          object.file = []
          for (let j = 0; j < message.file.length; ++j)
            object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options)
        }
        return object
      }

      /**
       * Converts this FileDescriptorSet to JSON.
       * @function toJSON
       * @memberof google.protobuf.FileDescriptorSet
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      FileDescriptorSet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return FileDescriptorSet
    })()

    protobuf.FileDescriptorProto = (function () {
      /**
       * Properties of a FileDescriptorProto.
       * @memberof google.protobuf
       * @interface IFileDescriptorProto
       * @property {string|null} [name] FileDescriptorProto name
       * @property {string|null} ["package"] FileDescriptorProto package
       * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
       * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
       * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
       * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
       * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
       * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
       * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
       * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
       * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
       * @property {string|null} [syntax] FileDescriptorProto syntax
       */

      /**
       * Constructs a new FileDescriptorProto.
       * @memberof google.protobuf
       * @classdesc Represents a FileDescriptorProto.
       * @implements IFileDescriptorProto
       * @constructor
       * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
       */
      function FileDescriptorProto(properties) {
        this.dependency = []
        this.publicDependency = []
        this.weakDependency = []
        this.messageType = []
        this.enumType = []
        this.service = []
        this.extension = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * FileDescriptorProto name.
       * @member {string} name
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype.name = ''

      /**
       * FileDescriptorProto package.
       * @member {string} package
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype['package'] = ''

      /**
       * FileDescriptorProto dependency.
       * @member {Array.<string>} dependency
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype.dependency = $util.emptyArray

      /**
       * FileDescriptorProto publicDependency.
       * @member {Array.<number>} publicDependency
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype.publicDependency = $util.emptyArray

      /**
       * FileDescriptorProto weakDependency.
       * @member {Array.<number>} weakDependency
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype.weakDependency = $util.emptyArray

      /**
       * FileDescriptorProto messageType.
       * @member {Array.<google.protobuf.IDescriptorProto>} messageType
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype.messageType = $util.emptyArray

      /**
       * FileDescriptorProto enumType.
       * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype.enumType = $util.emptyArray

      /**
       * FileDescriptorProto service.
       * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype.service = $util.emptyArray

      /**
       * FileDescriptorProto extension.
       * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype.extension = $util.emptyArray

      /**
       * FileDescriptorProto options.
       * @member {google.protobuf.IFileOptions|null|undefined} options
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype.options = null

      /**
       * FileDescriptorProto sourceCodeInfo.
       * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype.sourceCodeInfo = null

      /**
       * FileDescriptorProto syntax.
       * @member {string} syntax
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       */
      FileDescriptorProto.prototype.syntax = ''

      /**
       * Creates a new FileDescriptorProto instance using the specified properties.
       * @function create
       * @memberof google.protobuf.FileDescriptorProto
       * @static
       * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
       * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
       */
      FileDescriptorProto.create = function create(properties) {
        return new FileDescriptorProto(properties)
      }

      /**
       * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.FileDescriptorProto
       * @static
       * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FileDescriptorProto.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name)
        if (message['package'] != null && Object.hasOwnProperty.call(message, 'package'))
          writer.uint32(/* id 2, wireType 2 =*/ 18).string(message['package'])
        if (message.dependency != null && message.dependency.length)
          for (let i = 0; i < message.dependency.length; ++i)
            writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.dependency[i])
        if (message.messageType != null && message.messageType.length)
          for (let i = 0; i < message.messageType.length; ++i)
            $root.google.protobuf.DescriptorProto.encode(
              message.messageType[i],
              writer.uint32(/* id 4, wireType 2 =*/ 34).fork(),
            ).ldelim()
        if (message.enumType != null && message.enumType.length)
          for (let i = 0; i < message.enumType.length; ++i)
            $root.google.protobuf.EnumDescriptorProto.encode(
              message.enumType[i],
              writer.uint32(/* id 5, wireType 2 =*/ 42).fork(),
            ).ldelim()
        if (message.service != null && message.service.length)
          for (let i = 0; i < message.service.length; ++i)
            $root.google.protobuf.ServiceDescriptorProto.encode(
              message.service[i],
              writer.uint32(/* id 6, wireType 2 =*/ 50).fork(),
            ).ldelim()
        if (message.extension != null && message.extension.length)
          for (let i = 0; i < message.extension.length; ++i)
            $root.google.protobuf.FieldDescriptorProto.encode(
              message.extension[i],
              writer.uint32(/* id 7, wireType 2 =*/ 58).fork(),
            ).ldelim()
        if (message.options != null && Object.hasOwnProperty.call(message, 'options'))
          $root.google.protobuf.FileOptions.encode(
            message.options,
            writer.uint32(/* id 8, wireType 2 =*/ 66).fork(),
          ).ldelim()
        if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, 'sourceCodeInfo'))
          $root.google.protobuf.SourceCodeInfo.encode(
            message.sourceCodeInfo,
            writer.uint32(/* id 9, wireType 2 =*/ 74).fork(),
          ).ldelim()
        if (message.publicDependency != null && message.publicDependency.length)
          for (let i = 0; i < message.publicDependency.length; ++i)
            writer.uint32(/* id 10, wireType 0 =*/ 80).int32(message.publicDependency[i])
        if (message.weakDependency != null && message.weakDependency.length)
          for (let i = 0; i < message.weakDependency.length; ++i)
            writer.uint32(/* id 11, wireType 0 =*/ 88).int32(message.weakDependency[i])
        if (message.syntax != null && Object.hasOwnProperty.call(message, 'syntax'))
          writer.uint32(/* id 12, wireType 2 =*/ 98).string(message.syntax)
        return writer
      }

      /**
       * Encodes the specified FileDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.FileDescriptorProto
       * @static
       * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FileDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a FileDescriptorProto message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.FileDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FileDescriptorProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.FileDescriptorProto()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.name = reader.string()
              break
            case 2:
              message['package'] = reader.string()
              break
            case 3:
              if (!(message.dependency && message.dependency.length)) message.dependency = []
              message.dependency.push(reader.string())
              break
            case 10:
              if (!(message.publicDependency && message.publicDependency.length)) message.publicDependency = []
              if ((tag & 7) === 2) {
                let end2 = reader.uint32() + reader.pos
                while (reader.pos < end2) message.publicDependency.push(reader.int32())
              } else message.publicDependency.push(reader.int32())
              break
            case 11:
              if (!(message.weakDependency && message.weakDependency.length)) message.weakDependency = []
              if ((tag & 7) === 2) {
                let end2 = reader.uint32() + reader.pos
                while (reader.pos < end2) message.weakDependency.push(reader.int32())
              } else message.weakDependency.push(reader.int32())
              break
            case 4:
              if (!(message.messageType && message.messageType.length)) message.messageType = []
              message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()))
              break
            case 5:
              if (!(message.enumType && message.enumType.length)) message.enumType = []
              message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()))
              break
            case 6:
              if (!(message.service && message.service.length)) message.service = []
              message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()))
              break
            case 7:
              if (!(message.extension && message.extension.length)) message.extension = []
              message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()))
              break
            case 8:
              message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32())
              break
            case 9:
              message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32())
              break
            case 12:
              message.syntax = reader.string()
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a FileDescriptorProto message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.FileDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FileDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a FileDescriptorProto message.
       * @function verify
       * @memberof google.protobuf.FileDescriptorProto
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      FileDescriptorProto.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.name != null && message.hasOwnProperty('name'))
          if (!$util.isString(message.name)) return 'name: string expected'
        if (message['package'] != null && message.hasOwnProperty('package'))
          if (!$util.isString(message['package'])) return 'package: string expected'
        if (message.dependency != null && message.hasOwnProperty('dependency')) {
          if (!Array.isArray(message.dependency)) return 'dependency: array expected'
          for (let i = 0; i < message.dependency.length; ++i)
            if (!$util.isString(message.dependency[i])) return 'dependency: string[] expected'
        }
        if (message.publicDependency != null && message.hasOwnProperty('publicDependency')) {
          if (!Array.isArray(message.publicDependency)) return 'publicDependency: array expected'
          for (let i = 0; i < message.publicDependency.length; ++i)
            if (!$util.isInteger(message.publicDependency[i])) return 'publicDependency: integer[] expected'
        }
        if (message.weakDependency != null && message.hasOwnProperty('weakDependency')) {
          if (!Array.isArray(message.weakDependency)) return 'weakDependency: array expected'
          for (let i = 0; i < message.weakDependency.length; ++i)
            if (!$util.isInteger(message.weakDependency[i])) return 'weakDependency: integer[] expected'
        }
        if (message.messageType != null && message.hasOwnProperty('messageType')) {
          if (!Array.isArray(message.messageType)) return 'messageType: array expected'
          for (let i = 0; i < message.messageType.length; ++i) {
            let error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i])
            if (error) return 'messageType.' + error
          }
        }
        if (message.enumType != null && message.hasOwnProperty('enumType')) {
          if (!Array.isArray(message.enumType)) return 'enumType: array expected'
          for (let i = 0; i < message.enumType.length; ++i) {
            let error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i])
            if (error) return 'enumType.' + error
          }
        }
        if (message.service != null && message.hasOwnProperty('service')) {
          if (!Array.isArray(message.service)) return 'service: array expected'
          for (let i = 0; i < message.service.length; ++i) {
            let error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i])
            if (error) return 'service.' + error
          }
        }
        if (message.extension != null && message.hasOwnProperty('extension')) {
          if (!Array.isArray(message.extension)) return 'extension: array expected'
          for (let i = 0; i < message.extension.length; ++i) {
            let error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i])
            if (error) return 'extension.' + error
          }
        }
        if (message.options != null && message.hasOwnProperty('options')) {
          let error = $root.google.protobuf.FileOptions.verify(message.options)
          if (error) return 'options.' + error
        }
        if (message.sourceCodeInfo != null && message.hasOwnProperty('sourceCodeInfo')) {
          let error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo)
          if (error) return 'sourceCodeInfo.' + error
        }
        if (message.syntax != null && message.hasOwnProperty('syntax'))
          if (!$util.isString(message.syntax)) return 'syntax: string expected'
        return null
      }

      /**
       * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.FileDescriptorProto
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
       */
      FileDescriptorProto.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.FileDescriptorProto) return object
        let message = new $root.google.protobuf.FileDescriptorProto()
        if (object.name != null) message.name = String(object.name)
        if (object['package'] != null) message['package'] = String(object['package'])
        if (object.dependency) {
          if (!Array.isArray(object.dependency))
            throw TypeError('.google.protobuf.FileDescriptorProto.dependency: array expected')
          message.dependency = []
          for (let i = 0; i < object.dependency.length; ++i) message.dependency[i] = String(object.dependency[i])
        }
        if (object.publicDependency) {
          if (!Array.isArray(object.publicDependency))
            throw TypeError('.google.protobuf.FileDescriptorProto.publicDependency: array expected')
          message.publicDependency = []
          for (let i = 0; i < object.publicDependency.length; ++i)
            message.publicDependency[i] = object.publicDependency[i] | 0
        }
        if (object.weakDependency) {
          if (!Array.isArray(object.weakDependency))
            throw TypeError('.google.protobuf.FileDescriptorProto.weakDependency: array expected')
          message.weakDependency = []
          for (let i = 0; i < object.weakDependency.length; ++i)
            message.weakDependency[i] = object.weakDependency[i] | 0
        }
        if (object.messageType) {
          if (!Array.isArray(object.messageType))
            throw TypeError('.google.protobuf.FileDescriptorProto.messageType: array expected')
          message.messageType = []
          for (let i = 0; i < object.messageType.length; ++i) {
            if (typeof object.messageType[i] !== 'object')
              throw TypeError('.google.protobuf.FileDescriptorProto.messageType: object expected')
            message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i])
          }
        }
        if (object.enumType) {
          if (!Array.isArray(object.enumType))
            throw TypeError('.google.protobuf.FileDescriptorProto.enumType: array expected')
          message.enumType = []
          for (let i = 0; i < object.enumType.length; ++i) {
            if (typeof object.enumType[i] !== 'object')
              throw TypeError('.google.protobuf.FileDescriptorProto.enumType: object expected')
            message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i])
          }
        }
        if (object.service) {
          if (!Array.isArray(object.service))
            throw TypeError('.google.protobuf.FileDescriptorProto.service: array expected')
          message.service = []
          for (let i = 0; i < object.service.length; ++i) {
            if (typeof object.service[i] !== 'object')
              throw TypeError('.google.protobuf.FileDescriptorProto.service: object expected')
            message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i])
          }
        }
        if (object.extension) {
          if (!Array.isArray(object.extension))
            throw TypeError('.google.protobuf.FileDescriptorProto.extension: array expected')
          message.extension = []
          for (let i = 0; i < object.extension.length; ++i) {
            if (typeof object.extension[i] !== 'object')
              throw TypeError('.google.protobuf.FileDescriptorProto.extension: object expected')
            message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i])
          }
        }
        if (object.options != null) {
          if (typeof object.options !== 'object')
            throw TypeError('.google.protobuf.FileDescriptorProto.options: object expected')
          message.options = $root.google.protobuf.FileOptions.fromObject(object.options)
        }
        if (object.sourceCodeInfo != null) {
          if (typeof object.sourceCodeInfo !== 'object')
            throw TypeError('.google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected')
          message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo)
        }
        if (object.syntax != null) message.syntax = String(object.syntax)
        return message
      }

      /**
       * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.FileDescriptorProto
       * @static
       * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      FileDescriptorProto.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) {
          object.dependency = []
          object.messageType = []
          object.enumType = []
          object.service = []
          object.extension = []
          object.publicDependency = []
          object.weakDependency = []
        }
        if (options.defaults) {
          object.name = ''
          object['package'] = ''
          object.options = null
          object.sourceCodeInfo = null
          object.syntax = ''
        }
        if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
        if (message['package'] != null && message.hasOwnProperty('package')) object['package'] = message['package']
        if (message.dependency && message.dependency.length) {
          object.dependency = []
          for (let j = 0; j < message.dependency.length; ++j) object.dependency[j] = message.dependency[j]
        }
        if (message.messageType && message.messageType.length) {
          object.messageType = []
          for (let j = 0; j < message.messageType.length; ++j)
            object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options)
        }
        if (message.enumType && message.enumType.length) {
          object.enumType = []
          for (let j = 0; j < message.enumType.length; ++j)
            object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options)
        }
        if (message.service && message.service.length) {
          object.service = []
          for (let j = 0; j < message.service.length; ++j)
            object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options)
        }
        if (message.extension && message.extension.length) {
          object.extension = []
          for (let j = 0; j < message.extension.length; ++j)
            object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options)
        }
        if (message.options != null && message.hasOwnProperty('options'))
          object.options = $root.google.protobuf.FileOptions.toObject(message.options, options)
        if (message.sourceCodeInfo != null && message.hasOwnProperty('sourceCodeInfo'))
          object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options)
        if (message.publicDependency && message.publicDependency.length) {
          object.publicDependency = []
          for (let j = 0; j < message.publicDependency.length; ++j)
            object.publicDependency[j] = message.publicDependency[j]
        }
        if (message.weakDependency && message.weakDependency.length) {
          object.weakDependency = []
          for (let j = 0; j < message.weakDependency.length; ++j) object.weakDependency[j] = message.weakDependency[j]
        }
        if (message.syntax != null && message.hasOwnProperty('syntax')) object.syntax = message.syntax
        return object
      }

      /**
       * Converts this FileDescriptorProto to JSON.
       * @function toJSON
       * @memberof google.protobuf.FileDescriptorProto
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      FileDescriptorProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return FileDescriptorProto
    })()

    protobuf.DescriptorProto = (function () {
      /**
       * Properties of a DescriptorProto.
       * @memberof google.protobuf
       * @interface IDescriptorProto
       * @property {string|null} [name] DescriptorProto name
       * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
       * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
       * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
       * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
       * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
       * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
       * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
       * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
       * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
       */

      /**
       * Constructs a new DescriptorProto.
       * @memberof google.protobuf
       * @classdesc Represents a DescriptorProto.
       * @implements IDescriptorProto
       * @constructor
       * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
       */
      function DescriptorProto(properties) {
        this.field = []
        this.extension = []
        this.nestedType = []
        this.enumType = []
        this.extensionRange = []
        this.oneofDecl = []
        this.reservedRange = []
        this.reservedName = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * DescriptorProto name.
       * @member {string} name
       * @memberof google.protobuf.DescriptorProto
       * @instance
       */
      DescriptorProto.prototype.name = ''

      /**
       * DescriptorProto field.
       * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
       * @memberof google.protobuf.DescriptorProto
       * @instance
       */
      DescriptorProto.prototype.field = $util.emptyArray

      /**
       * DescriptorProto extension.
       * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
       * @memberof google.protobuf.DescriptorProto
       * @instance
       */
      DescriptorProto.prototype.extension = $util.emptyArray

      /**
       * DescriptorProto nestedType.
       * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
       * @memberof google.protobuf.DescriptorProto
       * @instance
       */
      DescriptorProto.prototype.nestedType = $util.emptyArray

      /**
       * DescriptorProto enumType.
       * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
       * @memberof google.protobuf.DescriptorProto
       * @instance
       */
      DescriptorProto.prototype.enumType = $util.emptyArray

      /**
       * DescriptorProto extensionRange.
       * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
       * @memberof google.protobuf.DescriptorProto
       * @instance
       */
      DescriptorProto.prototype.extensionRange = $util.emptyArray

      /**
       * DescriptorProto oneofDecl.
       * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
       * @memberof google.protobuf.DescriptorProto
       * @instance
       */
      DescriptorProto.prototype.oneofDecl = $util.emptyArray

      /**
       * DescriptorProto options.
       * @member {google.protobuf.IMessageOptions|null|undefined} options
       * @memberof google.protobuf.DescriptorProto
       * @instance
       */
      DescriptorProto.prototype.options = null

      /**
       * DescriptorProto reservedRange.
       * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
       * @memberof google.protobuf.DescriptorProto
       * @instance
       */
      DescriptorProto.prototype.reservedRange = $util.emptyArray

      /**
       * DescriptorProto reservedName.
       * @member {Array.<string>} reservedName
       * @memberof google.protobuf.DescriptorProto
       * @instance
       */
      DescriptorProto.prototype.reservedName = $util.emptyArray

      /**
       * Creates a new DescriptorProto instance using the specified properties.
       * @function create
       * @memberof google.protobuf.DescriptorProto
       * @static
       * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
       * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
       */
      DescriptorProto.create = function create(properties) {
        return new DescriptorProto(properties)
      }

      /**
       * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.DescriptorProto
       * @static
       * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DescriptorProto.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name)
        if (message.field != null && message.field.length)
          for (let i = 0; i < message.field.length; ++i)
            $root.google.protobuf.FieldDescriptorProto.encode(
              message.field[i],
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
        if (message.nestedType != null && message.nestedType.length)
          for (let i = 0; i < message.nestedType.length; ++i)
            $root.google.protobuf.DescriptorProto.encode(
              message.nestedType[i],
              writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
            ).ldelim()
        if (message.enumType != null && message.enumType.length)
          for (let i = 0; i < message.enumType.length; ++i)
            $root.google.protobuf.EnumDescriptorProto.encode(
              message.enumType[i],
              writer.uint32(/* id 4, wireType 2 =*/ 34).fork(),
            ).ldelim()
        if (message.extensionRange != null && message.extensionRange.length)
          for (let i = 0; i < message.extensionRange.length; ++i)
            $root.google.protobuf.DescriptorProto.ExtensionRange.encode(
              message.extensionRange[i],
              writer.uint32(/* id 5, wireType 2 =*/ 42).fork(),
            ).ldelim()
        if (message.extension != null && message.extension.length)
          for (let i = 0; i < message.extension.length; ++i)
            $root.google.protobuf.FieldDescriptorProto.encode(
              message.extension[i],
              writer.uint32(/* id 6, wireType 2 =*/ 50).fork(),
            ).ldelim()
        if (message.options != null && Object.hasOwnProperty.call(message, 'options'))
          $root.google.protobuf.MessageOptions.encode(
            message.options,
            writer.uint32(/* id 7, wireType 2 =*/ 58).fork(),
          ).ldelim()
        if (message.oneofDecl != null && message.oneofDecl.length)
          for (let i = 0; i < message.oneofDecl.length; ++i)
            $root.google.protobuf.OneofDescriptorProto.encode(
              message.oneofDecl[i],
              writer.uint32(/* id 8, wireType 2 =*/ 66).fork(),
            ).ldelim()
        if (message.reservedRange != null && message.reservedRange.length)
          for (let i = 0; i < message.reservedRange.length; ++i)
            $root.google.protobuf.DescriptorProto.ReservedRange.encode(
              message.reservedRange[i],
              writer.uint32(/* id 9, wireType 2 =*/ 74).fork(),
            ).ldelim()
        if (message.reservedName != null && message.reservedName.length)
          for (let i = 0; i < message.reservedName.length; ++i)
            writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.reservedName[i])
        return writer
      }

      /**
       * Encodes the specified DescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.DescriptorProto
       * @static
       * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a DescriptorProto message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.DescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.DescriptorProto} DescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DescriptorProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.DescriptorProto()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.name = reader.string()
              break
            case 2:
              if (!(message.field && message.field.length)) message.field = []
              message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()))
              break
            case 6:
              if (!(message.extension && message.extension.length)) message.extension = []
              message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()))
              break
            case 3:
              if (!(message.nestedType && message.nestedType.length)) message.nestedType = []
              message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()))
              break
            case 4:
              if (!(message.enumType && message.enumType.length)) message.enumType = []
              message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()))
              break
            case 5:
              if (!(message.extensionRange && message.extensionRange.length)) message.extensionRange = []
              message.extensionRange.push(
                $root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()),
              )
              break
            case 8:
              if (!(message.oneofDecl && message.oneofDecl.length)) message.oneofDecl = []
              message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()))
              break
            case 7:
              message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32())
              break
            case 9:
              if (!(message.reservedRange && message.reservedRange.length)) message.reservedRange = []
              message.reservedRange.push(
                $root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()),
              )
              break
            case 10:
              if (!(message.reservedName && message.reservedName.length)) message.reservedName = []
              message.reservedName.push(reader.string())
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a DescriptorProto message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.DescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.DescriptorProto} DescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DescriptorProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a DescriptorProto message.
       * @function verify
       * @memberof google.protobuf.DescriptorProto
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      DescriptorProto.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.name != null && message.hasOwnProperty('name'))
          if (!$util.isString(message.name)) return 'name: string expected'
        if (message.field != null && message.hasOwnProperty('field')) {
          if (!Array.isArray(message.field)) return 'field: array expected'
          for (let i = 0; i < message.field.length; ++i) {
            let error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i])
            if (error) return 'field.' + error
          }
        }
        if (message.extension != null && message.hasOwnProperty('extension')) {
          if (!Array.isArray(message.extension)) return 'extension: array expected'
          for (let i = 0; i < message.extension.length; ++i) {
            let error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i])
            if (error) return 'extension.' + error
          }
        }
        if (message.nestedType != null && message.hasOwnProperty('nestedType')) {
          if (!Array.isArray(message.nestedType)) return 'nestedType: array expected'
          for (let i = 0; i < message.nestedType.length; ++i) {
            let error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i])
            if (error) return 'nestedType.' + error
          }
        }
        if (message.enumType != null && message.hasOwnProperty('enumType')) {
          if (!Array.isArray(message.enumType)) return 'enumType: array expected'
          for (let i = 0; i < message.enumType.length; ++i) {
            let error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i])
            if (error) return 'enumType.' + error
          }
        }
        if (message.extensionRange != null && message.hasOwnProperty('extensionRange')) {
          if (!Array.isArray(message.extensionRange)) return 'extensionRange: array expected'
          for (let i = 0; i < message.extensionRange.length; ++i) {
            let error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i])
            if (error) return 'extensionRange.' + error
          }
        }
        if (message.oneofDecl != null && message.hasOwnProperty('oneofDecl')) {
          if (!Array.isArray(message.oneofDecl)) return 'oneofDecl: array expected'
          for (let i = 0; i < message.oneofDecl.length; ++i) {
            let error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i])
            if (error) return 'oneofDecl.' + error
          }
        }
        if (message.options != null && message.hasOwnProperty('options')) {
          let error = $root.google.protobuf.MessageOptions.verify(message.options)
          if (error) return 'options.' + error
        }
        if (message.reservedRange != null && message.hasOwnProperty('reservedRange')) {
          if (!Array.isArray(message.reservedRange)) return 'reservedRange: array expected'
          for (let i = 0; i < message.reservedRange.length; ++i) {
            let error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i])
            if (error) return 'reservedRange.' + error
          }
        }
        if (message.reservedName != null && message.hasOwnProperty('reservedName')) {
          if (!Array.isArray(message.reservedName)) return 'reservedName: array expected'
          for (let i = 0; i < message.reservedName.length; ++i)
            if (!$util.isString(message.reservedName[i])) return 'reservedName: string[] expected'
        }
        return null
      }

      /**
       * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.DescriptorProto
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.DescriptorProto} DescriptorProto
       */
      DescriptorProto.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.DescriptorProto) return object
        let message = new $root.google.protobuf.DescriptorProto()
        if (object.name != null) message.name = String(object.name)
        if (object.field) {
          if (!Array.isArray(object.field)) throw TypeError('.google.protobuf.DescriptorProto.field: array expected')
          message.field = []
          for (let i = 0; i < object.field.length; ++i) {
            if (typeof object.field[i] !== 'object')
              throw TypeError('.google.protobuf.DescriptorProto.field: object expected')
            message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i])
          }
        }
        if (object.extension) {
          if (!Array.isArray(object.extension))
            throw TypeError('.google.protobuf.DescriptorProto.extension: array expected')
          message.extension = []
          for (let i = 0; i < object.extension.length; ++i) {
            if (typeof object.extension[i] !== 'object')
              throw TypeError('.google.protobuf.DescriptorProto.extension: object expected')
            message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i])
          }
        }
        if (object.nestedType) {
          if (!Array.isArray(object.nestedType))
            throw TypeError('.google.protobuf.DescriptorProto.nestedType: array expected')
          message.nestedType = []
          for (let i = 0; i < object.nestedType.length; ++i) {
            if (typeof object.nestedType[i] !== 'object')
              throw TypeError('.google.protobuf.DescriptorProto.nestedType: object expected')
            message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i])
          }
        }
        if (object.enumType) {
          if (!Array.isArray(object.enumType))
            throw TypeError('.google.protobuf.DescriptorProto.enumType: array expected')
          message.enumType = []
          for (let i = 0; i < object.enumType.length; ++i) {
            if (typeof object.enumType[i] !== 'object')
              throw TypeError('.google.protobuf.DescriptorProto.enumType: object expected')
            message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i])
          }
        }
        if (object.extensionRange) {
          if (!Array.isArray(object.extensionRange))
            throw TypeError('.google.protobuf.DescriptorProto.extensionRange: array expected')
          message.extensionRange = []
          for (let i = 0; i < object.extensionRange.length; ++i) {
            if (typeof object.extensionRange[i] !== 'object')
              throw TypeError('.google.protobuf.DescriptorProto.extensionRange: object expected')
            message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(
              object.extensionRange[i],
            )
          }
        }
        if (object.oneofDecl) {
          if (!Array.isArray(object.oneofDecl))
            throw TypeError('.google.protobuf.DescriptorProto.oneofDecl: array expected')
          message.oneofDecl = []
          for (let i = 0; i < object.oneofDecl.length; ++i) {
            if (typeof object.oneofDecl[i] !== 'object')
              throw TypeError('.google.protobuf.DescriptorProto.oneofDecl: object expected')
            message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i])
          }
        }
        if (object.options != null) {
          if (typeof object.options !== 'object')
            throw TypeError('.google.protobuf.DescriptorProto.options: object expected')
          message.options = $root.google.protobuf.MessageOptions.fromObject(object.options)
        }
        if (object.reservedRange) {
          if (!Array.isArray(object.reservedRange))
            throw TypeError('.google.protobuf.DescriptorProto.reservedRange: array expected')
          message.reservedRange = []
          for (let i = 0; i < object.reservedRange.length; ++i) {
            if (typeof object.reservedRange[i] !== 'object')
              throw TypeError('.google.protobuf.DescriptorProto.reservedRange: object expected')
            message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(
              object.reservedRange[i],
            )
          }
        }
        if (object.reservedName) {
          if (!Array.isArray(object.reservedName))
            throw TypeError('.google.protobuf.DescriptorProto.reservedName: array expected')
          message.reservedName = []
          for (let i = 0; i < object.reservedName.length; ++i) message.reservedName[i] = String(object.reservedName[i])
        }
        return message
      }

      /**
       * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.DescriptorProto
       * @static
       * @param {google.protobuf.DescriptorProto} message DescriptorProto
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      DescriptorProto.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) {
          object.field = []
          object.nestedType = []
          object.enumType = []
          object.extensionRange = []
          object.extension = []
          object.oneofDecl = []
          object.reservedRange = []
          object.reservedName = []
        }
        if (options.defaults) {
          object.name = ''
          object.options = null
        }
        if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
        if (message.field && message.field.length) {
          object.field = []
          for (let j = 0; j < message.field.length; ++j)
            object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options)
        }
        if (message.nestedType && message.nestedType.length) {
          object.nestedType = []
          for (let j = 0; j < message.nestedType.length; ++j)
            object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options)
        }
        if (message.enumType && message.enumType.length) {
          object.enumType = []
          for (let j = 0; j < message.enumType.length; ++j)
            object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options)
        }
        if (message.extensionRange && message.extensionRange.length) {
          object.extensionRange = []
          for (let j = 0; j < message.extensionRange.length; ++j)
            object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(
              message.extensionRange[j],
              options,
            )
        }
        if (message.extension && message.extension.length) {
          object.extension = []
          for (let j = 0; j < message.extension.length; ++j)
            object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options)
        }
        if (message.options != null && message.hasOwnProperty('options'))
          object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options)
        if (message.oneofDecl && message.oneofDecl.length) {
          object.oneofDecl = []
          for (let j = 0; j < message.oneofDecl.length; ++j)
            object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options)
        }
        if (message.reservedRange && message.reservedRange.length) {
          object.reservedRange = []
          for (let j = 0; j < message.reservedRange.length; ++j)
            object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(
              message.reservedRange[j],
              options,
            )
        }
        if (message.reservedName && message.reservedName.length) {
          object.reservedName = []
          for (let j = 0; j < message.reservedName.length; ++j) object.reservedName[j] = message.reservedName[j]
        }
        return object
      }

      /**
       * Converts this DescriptorProto to JSON.
       * @function toJSON
       * @memberof google.protobuf.DescriptorProto
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      DescriptorProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      DescriptorProto.ExtensionRange = (function () {
        /**
         * Properties of an ExtensionRange.
         * @memberof google.protobuf.DescriptorProto
         * @interface IExtensionRange
         * @property {number|null} [start] ExtensionRange start
         * @property {number|null} [end] ExtensionRange end
         */

        /**
         * Constructs a new ExtensionRange.
         * @memberof google.protobuf.DescriptorProto
         * @classdesc Represents an ExtensionRange.
         * @implements IExtensionRange
         * @constructor
         * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
         */
        function ExtensionRange(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * ExtensionRange start.
         * @member {number} start
         * @memberof google.protobuf.DescriptorProto.ExtensionRange
         * @instance
         */
        ExtensionRange.prototype.start = 0

        /**
         * ExtensionRange end.
         * @member {number} end
         * @memberof google.protobuf.DescriptorProto.ExtensionRange
         * @instance
         */
        ExtensionRange.prototype.end = 0

        /**
         * Creates a new ExtensionRange instance using the specified properties.
         * @function create
         * @memberof google.protobuf.DescriptorProto.ExtensionRange
         * @static
         * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
         * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
         */
        ExtensionRange.create = function create(properties) {
          return new ExtensionRange(properties)
        }

        /**
         * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
         * @function encode
         * @memberof google.protobuf.DescriptorProto.ExtensionRange
         * @static
         * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtensionRange.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.start != null && Object.hasOwnProperty.call(message, 'start'))
            writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.start)
          if (message.end != null && Object.hasOwnProperty.call(message, 'end'))
            writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.end)
          return writer
        }

        /**
         * Encodes the specified ExtensionRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google.protobuf.DescriptorProto.ExtensionRange
         * @static
         * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExtensionRange.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an ExtensionRange message from the specified reader or buffer.
         * @function decode
         * @memberof google.protobuf.DescriptorProto.ExtensionRange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtensionRange.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.google.protobuf.DescriptorProto.ExtensionRange()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.start = reader.int32()
                break
              case 2:
                message.end = reader.int32()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an ExtensionRange message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google.protobuf.DescriptorProto.ExtensionRange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExtensionRange.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an ExtensionRange message.
         * @function verify
         * @memberof google.protobuf.DescriptorProto.ExtensionRange
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExtensionRange.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.start != null && message.hasOwnProperty('start'))
            if (!$util.isInteger(message.start)) return 'start: integer expected'
          if (message.end != null && message.hasOwnProperty('end'))
            if (!$util.isInteger(message.end)) return 'end: integer expected'
          return null
        }

        /**
         * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google.protobuf.DescriptorProto.ExtensionRange
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
         */
        ExtensionRange.fromObject = function fromObject(object) {
          if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange) return object
          let message = new $root.google.protobuf.DescriptorProto.ExtensionRange()
          if (object.start != null) message.start = object.start | 0
          if (object.end != null) message.end = object.end | 0
          return message
        }

        /**
         * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google.protobuf.DescriptorProto.ExtensionRange
         * @static
         * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExtensionRange.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.start = 0
            object.end = 0
          }
          if (message.start != null && message.hasOwnProperty('start')) object.start = message.start
          if (message.end != null && message.hasOwnProperty('end')) object.end = message.end
          return object
        }

        /**
         * Converts this ExtensionRange to JSON.
         * @function toJSON
         * @memberof google.protobuf.DescriptorProto.ExtensionRange
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExtensionRange.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return ExtensionRange
      })()

      DescriptorProto.ReservedRange = (function () {
        /**
         * Properties of a ReservedRange.
         * @memberof google.protobuf.DescriptorProto
         * @interface IReservedRange
         * @property {number|null} [start] ReservedRange start
         * @property {number|null} [end] ReservedRange end
         */

        /**
         * Constructs a new ReservedRange.
         * @memberof google.protobuf.DescriptorProto
         * @classdesc Represents a ReservedRange.
         * @implements IReservedRange
         * @constructor
         * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
         */
        function ReservedRange(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * ReservedRange start.
         * @member {number} start
         * @memberof google.protobuf.DescriptorProto.ReservedRange
         * @instance
         */
        ReservedRange.prototype.start = 0

        /**
         * ReservedRange end.
         * @member {number} end
         * @memberof google.protobuf.DescriptorProto.ReservedRange
         * @instance
         */
        ReservedRange.prototype.end = 0

        /**
         * Creates a new ReservedRange instance using the specified properties.
         * @function create
         * @memberof google.protobuf.DescriptorProto.ReservedRange
         * @static
         * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
         * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
         */
        ReservedRange.create = function create(properties) {
          return new ReservedRange(properties)
        }

        /**
         * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
         * @function encode
         * @memberof google.protobuf.DescriptorProto.ReservedRange
         * @static
         * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReservedRange.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.start != null && Object.hasOwnProperty.call(message, 'start'))
            writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.start)
          if (message.end != null && Object.hasOwnProperty.call(message, 'end'))
            writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.end)
          return writer
        }

        /**
         * Encodes the specified ReservedRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google.protobuf.DescriptorProto.ReservedRange
         * @static
         * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReservedRange.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a ReservedRange message from the specified reader or buffer.
         * @function decode
         * @memberof google.protobuf.DescriptorProto.ReservedRange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReservedRange.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.google.protobuf.DescriptorProto.ReservedRange()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.start = reader.int32()
                break
              case 2:
                message.end = reader.int32()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a ReservedRange message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google.protobuf.DescriptorProto.ReservedRange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReservedRange.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a ReservedRange message.
         * @function verify
         * @memberof google.protobuf.DescriptorProto.ReservedRange
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReservedRange.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.start != null && message.hasOwnProperty('start'))
            if (!$util.isInteger(message.start)) return 'start: integer expected'
          if (message.end != null && message.hasOwnProperty('end'))
            if (!$util.isInteger(message.end)) return 'end: integer expected'
          return null
        }

        /**
         * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google.protobuf.DescriptorProto.ReservedRange
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
         */
        ReservedRange.fromObject = function fromObject(object) {
          if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange) return object
          let message = new $root.google.protobuf.DescriptorProto.ReservedRange()
          if (object.start != null) message.start = object.start | 0
          if (object.end != null) message.end = object.end | 0
          return message
        }

        /**
         * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google.protobuf.DescriptorProto.ReservedRange
         * @static
         * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReservedRange.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.start = 0
            object.end = 0
          }
          if (message.start != null && message.hasOwnProperty('start')) object.start = message.start
          if (message.end != null && message.hasOwnProperty('end')) object.end = message.end
          return object
        }

        /**
         * Converts this ReservedRange to JSON.
         * @function toJSON
         * @memberof google.protobuf.DescriptorProto.ReservedRange
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReservedRange.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return ReservedRange
      })()

      return DescriptorProto
    })()

    protobuf.FieldDescriptorProto = (function () {
      /**
       * Properties of a FieldDescriptorProto.
       * @memberof google.protobuf
       * @interface IFieldDescriptorProto
       * @property {string|null} [name] FieldDescriptorProto name
       * @property {number|null} [number] FieldDescriptorProto number
       * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
       * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
       * @property {string|null} [typeName] FieldDescriptorProto typeName
       * @property {string|null} [extendee] FieldDescriptorProto extendee
       * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
       * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
       * @property {string|null} [jsonName] FieldDescriptorProto jsonName
       * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
       */

      /**
       * Constructs a new FieldDescriptorProto.
       * @memberof google.protobuf
       * @classdesc Represents a FieldDescriptorProto.
       * @implements IFieldDescriptorProto
       * @constructor
       * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
       */
      function FieldDescriptorProto(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * FieldDescriptorProto name.
       * @member {string} name
       * @memberof google.protobuf.FieldDescriptorProto
       * @instance
       */
      FieldDescriptorProto.prototype.name = ''

      /**
       * FieldDescriptorProto number.
       * @member {number} number
       * @memberof google.protobuf.FieldDescriptorProto
       * @instance
       */
      FieldDescriptorProto.prototype.number = 0

      /**
       * FieldDescriptorProto label.
       * @member {google.protobuf.FieldDescriptorProto.Label} label
       * @memberof google.protobuf.FieldDescriptorProto
       * @instance
       */
      FieldDescriptorProto.prototype.label = 1

      /**
       * FieldDescriptorProto type.
       * @member {google.protobuf.FieldDescriptorProto.Type} type
       * @memberof google.protobuf.FieldDescriptorProto
       * @instance
       */
      FieldDescriptorProto.prototype.type = 1

      /**
       * FieldDescriptorProto typeName.
       * @member {string} typeName
       * @memberof google.protobuf.FieldDescriptorProto
       * @instance
       */
      FieldDescriptorProto.prototype.typeName = ''

      /**
       * FieldDescriptorProto extendee.
       * @member {string} extendee
       * @memberof google.protobuf.FieldDescriptorProto
       * @instance
       */
      FieldDescriptorProto.prototype.extendee = ''

      /**
       * FieldDescriptorProto defaultValue.
       * @member {string} defaultValue
       * @memberof google.protobuf.FieldDescriptorProto
       * @instance
       */
      FieldDescriptorProto.prototype.defaultValue = ''

      /**
       * FieldDescriptorProto oneofIndex.
       * @member {number} oneofIndex
       * @memberof google.protobuf.FieldDescriptorProto
       * @instance
       */
      FieldDescriptorProto.prototype.oneofIndex = 0

      /**
       * FieldDescriptorProto jsonName.
       * @member {string} jsonName
       * @memberof google.protobuf.FieldDescriptorProto
       * @instance
       */
      FieldDescriptorProto.prototype.jsonName = ''

      /**
       * FieldDescriptorProto options.
       * @member {google.protobuf.IFieldOptions|null|undefined} options
       * @memberof google.protobuf.FieldDescriptorProto
       * @instance
       */
      FieldDescriptorProto.prototype.options = null

      /**
       * Creates a new FieldDescriptorProto instance using the specified properties.
       * @function create
       * @memberof google.protobuf.FieldDescriptorProto
       * @static
       * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
       * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
       */
      FieldDescriptorProto.create = function create(properties) {
        return new FieldDescriptorProto(properties)
      }

      /**
       * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.FieldDescriptorProto
       * @static
       * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FieldDescriptorProto.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name)
        if (message.extendee != null && Object.hasOwnProperty.call(message, 'extendee'))
          writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.extendee)
        if (message.number != null && Object.hasOwnProperty.call(message, 'number'))
          writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.number)
        if (message.label != null && Object.hasOwnProperty.call(message, 'label'))
          writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.label)
        if (message.type != null && Object.hasOwnProperty.call(message, 'type'))
          writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.type)
        if (message.typeName != null && Object.hasOwnProperty.call(message, 'typeName'))
          writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.typeName)
        if (message.defaultValue != null && Object.hasOwnProperty.call(message, 'defaultValue'))
          writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.defaultValue)
        if (message.options != null && Object.hasOwnProperty.call(message, 'options'))
          $root.google.protobuf.FieldOptions.encode(
            message.options,
            writer.uint32(/* id 8, wireType 2 =*/ 66).fork(),
          ).ldelim()
        if (message.oneofIndex != null && Object.hasOwnProperty.call(message, 'oneofIndex'))
          writer.uint32(/* id 9, wireType 0 =*/ 72).int32(message.oneofIndex)
        if (message.jsonName != null && Object.hasOwnProperty.call(message, 'jsonName'))
          writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.jsonName)
        return writer
      }

      /**
       * Encodes the specified FieldDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.FieldDescriptorProto
       * @static
       * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FieldDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a FieldDescriptorProto message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.FieldDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FieldDescriptorProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.FieldDescriptorProto()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.name = reader.string()
              break
            case 3:
              message.number = reader.int32()
              break
            case 4:
              message.label = reader.int32()
              break
            case 5:
              message.type = reader.int32()
              break
            case 6:
              message.typeName = reader.string()
              break
            case 2:
              message.extendee = reader.string()
              break
            case 7:
              message.defaultValue = reader.string()
              break
            case 9:
              message.oneofIndex = reader.int32()
              break
            case 10:
              message.jsonName = reader.string()
              break
            case 8:
              message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32())
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a FieldDescriptorProto message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.FieldDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FieldDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a FieldDescriptorProto message.
       * @function verify
       * @memberof google.protobuf.FieldDescriptorProto
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      FieldDescriptorProto.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.name != null && message.hasOwnProperty('name'))
          if (!$util.isString(message.name)) return 'name: string expected'
        if (message.number != null && message.hasOwnProperty('number'))
          if (!$util.isInteger(message.number)) return 'number: integer expected'
        if (message.label != null && message.hasOwnProperty('label'))
          switch (message.label) {
            default:
              return 'label: enum value expected'
            case 1:
            case 2:
            case 3:
              break
          }
        if (message.type != null && message.hasOwnProperty('type'))
          switch (message.type) {
            default:
              return 'type: enum value expected'
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
              break
          }
        if (message.typeName != null && message.hasOwnProperty('typeName'))
          if (!$util.isString(message.typeName)) return 'typeName: string expected'
        if (message.extendee != null && message.hasOwnProperty('extendee'))
          if (!$util.isString(message.extendee)) return 'extendee: string expected'
        if (message.defaultValue != null && message.hasOwnProperty('defaultValue'))
          if (!$util.isString(message.defaultValue)) return 'defaultValue: string expected'
        if (message.oneofIndex != null && message.hasOwnProperty('oneofIndex'))
          if (!$util.isInteger(message.oneofIndex)) return 'oneofIndex: integer expected'
        if (message.jsonName != null && message.hasOwnProperty('jsonName'))
          if (!$util.isString(message.jsonName)) return 'jsonName: string expected'
        if (message.options != null && message.hasOwnProperty('options')) {
          let error = $root.google.protobuf.FieldOptions.verify(message.options)
          if (error) return 'options.' + error
        }
        return null
      }

      /**
       * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.FieldDescriptorProto
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
       */
      FieldDescriptorProto.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.FieldDescriptorProto) return object
        let message = new $root.google.protobuf.FieldDescriptorProto()
        if (object.name != null) message.name = String(object.name)
        if (object.number != null) message.number = object.number | 0
        switch (object.label) {
          case 'LABEL_OPTIONAL':
          case 1:
            message.label = 1
            break
          case 'LABEL_REQUIRED':
          case 2:
            message.label = 2
            break
          case 'LABEL_REPEATED':
          case 3:
            message.label = 3
            break
        }
        switch (object.type) {
          case 'TYPE_DOUBLE':
          case 1:
            message.type = 1
            break
          case 'TYPE_FLOAT':
          case 2:
            message.type = 2
            break
          case 'TYPE_INT64':
          case 3:
            message.type = 3
            break
          case 'TYPE_UINT64':
          case 4:
            message.type = 4
            break
          case 'TYPE_INT32':
          case 5:
            message.type = 5
            break
          case 'TYPE_FIXED64':
          case 6:
            message.type = 6
            break
          case 'TYPE_FIXED32':
          case 7:
            message.type = 7
            break
          case 'TYPE_BOOL':
          case 8:
            message.type = 8
            break
          case 'TYPE_STRING':
          case 9:
            message.type = 9
            break
          case 'TYPE_GROUP':
          case 10:
            message.type = 10
            break
          case 'TYPE_MESSAGE':
          case 11:
            message.type = 11
            break
          case 'TYPE_BYTES':
          case 12:
            message.type = 12
            break
          case 'TYPE_UINT32':
          case 13:
            message.type = 13
            break
          case 'TYPE_ENUM':
          case 14:
            message.type = 14
            break
          case 'TYPE_SFIXED32':
          case 15:
            message.type = 15
            break
          case 'TYPE_SFIXED64':
          case 16:
            message.type = 16
            break
          case 'TYPE_SINT32':
          case 17:
            message.type = 17
            break
          case 'TYPE_SINT64':
          case 18:
            message.type = 18
            break
        }
        if (object.typeName != null) message.typeName = String(object.typeName)
        if (object.extendee != null) message.extendee = String(object.extendee)
        if (object.defaultValue != null) message.defaultValue = String(object.defaultValue)
        if (object.oneofIndex != null) message.oneofIndex = object.oneofIndex | 0
        if (object.jsonName != null) message.jsonName = String(object.jsonName)
        if (object.options != null) {
          if (typeof object.options !== 'object')
            throw TypeError('.google.protobuf.FieldDescriptorProto.options: object expected')
          message.options = $root.google.protobuf.FieldOptions.fromObject(object.options)
        }
        return message
      }

      /**
       * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.FieldDescriptorProto
       * @static
       * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      FieldDescriptorProto.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.defaults) {
          object.name = ''
          object.extendee = ''
          object.number = 0
          object.label = options.enums === String ? 'LABEL_OPTIONAL' : 1
          object.type = options.enums === String ? 'TYPE_DOUBLE' : 1
          object.typeName = ''
          object.defaultValue = ''
          object.options = null
          object.oneofIndex = 0
          object.jsonName = ''
        }
        if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
        if (message.extendee != null && message.hasOwnProperty('extendee')) object.extendee = message.extendee
        if (message.number != null && message.hasOwnProperty('number')) object.number = message.number
        if (message.label != null && message.hasOwnProperty('label'))
          object.label =
            options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label
        if (message.type != null && message.hasOwnProperty('type'))
          object.type =
            options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type
        if (message.typeName != null && message.hasOwnProperty('typeName')) object.typeName = message.typeName
        if (message.defaultValue != null && message.hasOwnProperty('defaultValue'))
          object.defaultValue = message.defaultValue
        if (message.options != null && message.hasOwnProperty('options'))
          object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options)
        if (message.oneofIndex != null && message.hasOwnProperty('oneofIndex')) object.oneofIndex = message.oneofIndex
        if (message.jsonName != null && message.hasOwnProperty('jsonName')) object.jsonName = message.jsonName
        return object
      }

      /**
       * Converts this FieldDescriptorProto to JSON.
       * @function toJSON
       * @memberof google.protobuf.FieldDescriptorProto
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      FieldDescriptorProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      /**
       * Type enum.
       * @name google.protobuf.FieldDescriptorProto.Type
       * @enum {number}
       * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
       * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
       * @property {number} TYPE_INT64=3 TYPE_INT64 value
       * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
       * @property {number} TYPE_INT32=5 TYPE_INT32 value
       * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
       * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
       * @property {number} TYPE_BOOL=8 TYPE_BOOL value
       * @property {number} TYPE_STRING=9 TYPE_STRING value
       * @property {number} TYPE_GROUP=10 TYPE_GROUP value
       * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
       * @property {number} TYPE_BYTES=12 TYPE_BYTES value
       * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
       * @property {number} TYPE_ENUM=14 TYPE_ENUM value
       * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
       * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
       * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
       * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
       */
      FieldDescriptorProto.Type = (function () {
        const valuesById = {},
          values = Object.create(valuesById)
        values[(valuesById[1] = 'TYPE_DOUBLE')] = 1
        values[(valuesById[2] = 'TYPE_FLOAT')] = 2
        values[(valuesById[3] = 'TYPE_INT64')] = 3
        values[(valuesById[4] = 'TYPE_UINT64')] = 4
        values[(valuesById[5] = 'TYPE_INT32')] = 5
        values[(valuesById[6] = 'TYPE_FIXED64')] = 6
        values[(valuesById[7] = 'TYPE_FIXED32')] = 7
        values[(valuesById[8] = 'TYPE_BOOL')] = 8
        values[(valuesById[9] = 'TYPE_STRING')] = 9
        values[(valuesById[10] = 'TYPE_GROUP')] = 10
        values[(valuesById[11] = 'TYPE_MESSAGE')] = 11
        values[(valuesById[12] = 'TYPE_BYTES')] = 12
        values[(valuesById[13] = 'TYPE_UINT32')] = 13
        values[(valuesById[14] = 'TYPE_ENUM')] = 14
        values[(valuesById[15] = 'TYPE_SFIXED32')] = 15
        values[(valuesById[16] = 'TYPE_SFIXED64')] = 16
        values[(valuesById[17] = 'TYPE_SINT32')] = 17
        values[(valuesById[18] = 'TYPE_SINT64')] = 18
        return values
      })()

      /**
       * Label enum.
       * @name google.protobuf.FieldDescriptorProto.Label
       * @enum {number}
       * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
       * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
       * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
       */
      FieldDescriptorProto.Label = (function () {
        const valuesById = {},
          values = Object.create(valuesById)
        values[(valuesById[1] = 'LABEL_OPTIONAL')] = 1
        values[(valuesById[2] = 'LABEL_REQUIRED')] = 2
        values[(valuesById[3] = 'LABEL_REPEATED')] = 3
        return values
      })()

      return FieldDescriptorProto
    })()

    protobuf.OneofDescriptorProto = (function () {
      /**
       * Properties of an OneofDescriptorProto.
       * @memberof google.protobuf
       * @interface IOneofDescriptorProto
       * @property {string|null} [name] OneofDescriptorProto name
       * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
       */

      /**
       * Constructs a new OneofDescriptorProto.
       * @memberof google.protobuf
       * @classdesc Represents an OneofDescriptorProto.
       * @implements IOneofDescriptorProto
       * @constructor
       * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
       */
      function OneofDescriptorProto(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * OneofDescriptorProto name.
       * @member {string} name
       * @memberof google.protobuf.OneofDescriptorProto
       * @instance
       */
      OneofDescriptorProto.prototype.name = ''

      /**
       * OneofDescriptorProto options.
       * @member {google.protobuf.IOneofOptions|null|undefined} options
       * @memberof google.protobuf.OneofDescriptorProto
       * @instance
       */
      OneofDescriptorProto.prototype.options = null

      /**
       * Creates a new OneofDescriptorProto instance using the specified properties.
       * @function create
       * @memberof google.protobuf.OneofDescriptorProto
       * @static
       * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
       * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
       */
      OneofDescriptorProto.create = function create(properties) {
        return new OneofDescriptorProto(properties)
      }

      /**
       * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.OneofDescriptorProto
       * @static
       * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      OneofDescriptorProto.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name)
        if (message.options != null && Object.hasOwnProperty.call(message, 'options'))
          $root.google.protobuf.OneofOptions.encode(
            message.options,
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
          ).ldelim()
        return writer
      }

      /**
       * Encodes the specified OneofDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.OneofDescriptorProto
       * @static
       * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      OneofDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes an OneofDescriptorProto message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.OneofDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      OneofDescriptorProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.OneofDescriptorProto()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.name = reader.string()
              break
            case 2:
              message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32())
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes an OneofDescriptorProto message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.OneofDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      OneofDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies an OneofDescriptorProto message.
       * @function verify
       * @memberof google.protobuf.OneofDescriptorProto
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      OneofDescriptorProto.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.name != null && message.hasOwnProperty('name'))
          if (!$util.isString(message.name)) return 'name: string expected'
        if (message.options != null && message.hasOwnProperty('options')) {
          let error = $root.google.protobuf.OneofOptions.verify(message.options)
          if (error) return 'options.' + error
        }
        return null
      }

      /**
       * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.OneofDescriptorProto
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
       */
      OneofDescriptorProto.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.OneofDescriptorProto) return object
        let message = new $root.google.protobuf.OneofDescriptorProto()
        if (object.name != null) message.name = String(object.name)
        if (object.options != null) {
          if (typeof object.options !== 'object')
            throw TypeError('.google.protobuf.OneofDescriptorProto.options: object expected')
          message.options = $root.google.protobuf.OneofOptions.fromObject(object.options)
        }
        return message
      }

      /**
       * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.OneofDescriptorProto
       * @static
       * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      OneofDescriptorProto.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.defaults) {
          object.name = ''
          object.options = null
        }
        if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
        if (message.options != null && message.hasOwnProperty('options'))
          object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options)
        return object
      }

      /**
       * Converts this OneofDescriptorProto to JSON.
       * @function toJSON
       * @memberof google.protobuf.OneofDescriptorProto
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      OneofDescriptorProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return OneofDescriptorProto
    })()

    protobuf.EnumDescriptorProto = (function () {
      /**
       * Properties of an EnumDescriptorProto.
       * @memberof google.protobuf
       * @interface IEnumDescriptorProto
       * @property {string|null} [name] EnumDescriptorProto name
       * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
       * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
       */

      /**
       * Constructs a new EnumDescriptorProto.
       * @memberof google.protobuf
       * @classdesc Represents an EnumDescriptorProto.
       * @implements IEnumDescriptorProto
       * @constructor
       * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
       */
      function EnumDescriptorProto(properties) {
        this.value = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * EnumDescriptorProto name.
       * @member {string} name
       * @memberof google.protobuf.EnumDescriptorProto
       * @instance
       */
      EnumDescriptorProto.prototype.name = ''

      /**
       * EnumDescriptorProto value.
       * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
       * @memberof google.protobuf.EnumDescriptorProto
       * @instance
       */
      EnumDescriptorProto.prototype.value = $util.emptyArray

      /**
       * EnumDescriptorProto options.
       * @member {google.protobuf.IEnumOptions|null|undefined} options
       * @memberof google.protobuf.EnumDescriptorProto
       * @instance
       */
      EnumDescriptorProto.prototype.options = null

      /**
       * Creates a new EnumDescriptorProto instance using the specified properties.
       * @function create
       * @memberof google.protobuf.EnumDescriptorProto
       * @static
       * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
       * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
       */
      EnumDescriptorProto.create = function create(properties) {
        return new EnumDescriptorProto(properties)
      }

      /**
       * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.EnumDescriptorProto
       * @static
       * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      EnumDescriptorProto.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name)
        if (message.value != null && message.value.length)
          for (let i = 0; i < message.value.length; ++i)
            $root.google.protobuf.EnumValueDescriptorProto.encode(
              message.value[i],
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
        if (message.options != null && Object.hasOwnProperty.call(message, 'options'))
          $root.google.protobuf.EnumOptions.encode(
            message.options,
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
          ).ldelim()
        return writer
      }

      /**
       * Encodes the specified EnumDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.EnumDescriptorProto
       * @static
       * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      EnumDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes an EnumDescriptorProto message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.EnumDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      EnumDescriptorProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.EnumDescriptorProto()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.name = reader.string()
              break
            case 2:
              if (!(message.value && message.value.length)) message.value = []
              message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()))
              break
            case 3:
              message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32())
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes an EnumDescriptorProto message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.EnumDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      EnumDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies an EnumDescriptorProto message.
       * @function verify
       * @memberof google.protobuf.EnumDescriptorProto
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      EnumDescriptorProto.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.name != null && message.hasOwnProperty('name'))
          if (!$util.isString(message.name)) return 'name: string expected'
        if (message.value != null && message.hasOwnProperty('value')) {
          if (!Array.isArray(message.value)) return 'value: array expected'
          for (let i = 0; i < message.value.length; ++i) {
            let error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i])
            if (error) return 'value.' + error
          }
        }
        if (message.options != null && message.hasOwnProperty('options')) {
          let error = $root.google.protobuf.EnumOptions.verify(message.options)
          if (error) return 'options.' + error
        }
        return null
      }

      /**
       * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.EnumDescriptorProto
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
       */
      EnumDescriptorProto.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.EnumDescriptorProto) return object
        let message = new $root.google.protobuf.EnumDescriptorProto()
        if (object.name != null) message.name = String(object.name)
        if (object.value) {
          if (!Array.isArray(object.value))
            throw TypeError('.google.protobuf.EnumDescriptorProto.value: array expected')
          message.value = []
          for (let i = 0; i < object.value.length; ++i) {
            if (typeof object.value[i] !== 'object')
              throw TypeError('.google.protobuf.EnumDescriptorProto.value: object expected')
            message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i])
          }
        }
        if (object.options != null) {
          if (typeof object.options !== 'object')
            throw TypeError('.google.protobuf.EnumDescriptorProto.options: object expected')
          message.options = $root.google.protobuf.EnumOptions.fromObject(object.options)
        }
        return message
      }

      /**
       * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.EnumDescriptorProto
       * @static
       * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      EnumDescriptorProto.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.value = []
        if (options.defaults) {
          object.name = ''
          object.options = null
        }
        if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
        if (message.value && message.value.length) {
          object.value = []
          for (let j = 0; j < message.value.length; ++j)
            object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options)
        }
        if (message.options != null && message.hasOwnProperty('options'))
          object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options)
        return object
      }

      /**
       * Converts this EnumDescriptorProto to JSON.
       * @function toJSON
       * @memberof google.protobuf.EnumDescriptorProto
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      EnumDescriptorProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return EnumDescriptorProto
    })()

    protobuf.EnumValueDescriptorProto = (function () {
      /**
       * Properties of an EnumValueDescriptorProto.
       * @memberof google.protobuf
       * @interface IEnumValueDescriptorProto
       * @property {string|null} [name] EnumValueDescriptorProto name
       * @property {number|null} [number] EnumValueDescriptorProto number
       * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
       */

      /**
       * Constructs a new EnumValueDescriptorProto.
       * @memberof google.protobuf
       * @classdesc Represents an EnumValueDescriptorProto.
       * @implements IEnumValueDescriptorProto
       * @constructor
       * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
       */
      function EnumValueDescriptorProto(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * EnumValueDescriptorProto name.
       * @member {string} name
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @instance
       */
      EnumValueDescriptorProto.prototype.name = ''

      /**
       * EnumValueDescriptorProto number.
       * @member {number} number
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @instance
       */
      EnumValueDescriptorProto.prototype.number = 0

      /**
       * EnumValueDescriptorProto options.
       * @member {google.protobuf.IEnumValueOptions|null|undefined} options
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @instance
       */
      EnumValueDescriptorProto.prototype.options = null

      /**
       * Creates a new EnumValueDescriptorProto instance using the specified properties.
       * @function create
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @static
       * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
       * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
       */
      EnumValueDescriptorProto.create = function create(properties) {
        return new EnumValueDescriptorProto(properties)
      }

      /**
       * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @static
       * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      EnumValueDescriptorProto.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name)
        if (message.number != null && Object.hasOwnProperty.call(message, 'number'))
          writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.number)
        if (message.options != null && Object.hasOwnProperty.call(message, 'options'))
          $root.google.protobuf.EnumValueOptions.encode(
            message.options,
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
          ).ldelim()
        return writer
      }

      /**
       * Encodes the specified EnumValueDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @static
       * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      EnumValueDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      EnumValueDescriptorProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.EnumValueDescriptorProto()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.name = reader.string()
              break
            case 2:
              message.number = reader.int32()
              break
            case 3:
              message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32())
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes an EnumValueDescriptorProto message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      EnumValueDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies an EnumValueDescriptorProto message.
       * @function verify
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      EnumValueDescriptorProto.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.name != null && message.hasOwnProperty('name'))
          if (!$util.isString(message.name)) return 'name: string expected'
        if (message.number != null && message.hasOwnProperty('number'))
          if (!$util.isInteger(message.number)) return 'number: integer expected'
        if (message.options != null && message.hasOwnProperty('options')) {
          let error = $root.google.protobuf.EnumValueOptions.verify(message.options)
          if (error) return 'options.' + error
        }
        return null
      }

      /**
       * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
       */
      EnumValueDescriptorProto.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.EnumValueDescriptorProto) return object
        let message = new $root.google.protobuf.EnumValueDescriptorProto()
        if (object.name != null) message.name = String(object.name)
        if (object.number != null) message.number = object.number | 0
        if (object.options != null) {
          if (typeof object.options !== 'object')
            throw TypeError('.google.protobuf.EnumValueDescriptorProto.options: object expected')
          message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options)
        }
        return message
      }

      /**
       * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @static
       * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      EnumValueDescriptorProto.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.defaults) {
          object.name = ''
          object.number = 0
          object.options = null
        }
        if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
        if (message.number != null && message.hasOwnProperty('number')) object.number = message.number
        if (message.options != null && message.hasOwnProperty('options'))
          object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options)
        return object
      }

      /**
       * Converts this EnumValueDescriptorProto to JSON.
       * @function toJSON
       * @memberof google.protobuf.EnumValueDescriptorProto
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return EnumValueDescriptorProto
    })()

    protobuf.ServiceDescriptorProto = (function () {
      /**
       * Properties of a ServiceDescriptorProto.
       * @memberof google.protobuf
       * @interface IServiceDescriptorProto
       * @property {string|null} [name] ServiceDescriptorProto name
       * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
       * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
       */

      /**
       * Constructs a new ServiceDescriptorProto.
       * @memberof google.protobuf
       * @classdesc Represents a ServiceDescriptorProto.
       * @implements IServiceDescriptorProto
       * @constructor
       * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
       */
      function ServiceDescriptorProto(properties) {
        this.method = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * ServiceDescriptorProto name.
       * @member {string} name
       * @memberof google.protobuf.ServiceDescriptorProto
       * @instance
       */
      ServiceDescriptorProto.prototype.name = ''

      /**
       * ServiceDescriptorProto method.
       * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
       * @memberof google.protobuf.ServiceDescriptorProto
       * @instance
       */
      ServiceDescriptorProto.prototype.method = $util.emptyArray

      /**
       * ServiceDescriptorProto options.
       * @member {google.protobuf.IServiceOptions|null|undefined} options
       * @memberof google.protobuf.ServiceDescriptorProto
       * @instance
       */
      ServiceDescriptorProto.prototype.options = null

      /**
       * Creates a new ServiceDescriptorProto instance using the specified properties.
       * @function create
       * @memberof google.protobuf.ServiceDescriptorProto
       * @static
       * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
       * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
       */
      ServiceDescriptorProto.create = function create(properties) {
        return new ServiceDescriptorProto(properties)
      }

      /**
       * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.ServiceDescriptorProto
       * @static
       * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ServiceDescriptorProto.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name)
        if (message.method != null && message.method.length)
          for (let i = 0; i < message.method.length; ++i)
            $root.google.protobuf.MethodDescriptorProto.encode(
              message.method[i],
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
        if (message.options != null && Object.hasOwnProperty.call(message, 'options'))
          $root.google.protobuf.ServiceOptions.encode(
            message.options,
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
          ).ldelim()
        return writer
      }

      /**
       * Encodes the specified ServiceDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.ServiceDescriptorProto
       * @static
       * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ServiceDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.ServiceDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ServiceDescriptorProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.ServiceDescriptorProto()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.name = reader.string()
              break
            case 2:
              if (!(message.method && message.method.length)) message.method = []
              message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()))
              break
            case 3:
              message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32())
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a ServiceDescriptorProto message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.ServiceDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ServiceDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a ServiceDescriptorProto message.
       * @function verify
       * @memberof google.protobuf.ServiceDescriptorProto
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ServiceDescriptorProto.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.name != null && message.hasOwnProperty('name'))
          if (!$util.isString(message.name)) return 'name: string expected'
        if (message.method != null && message.hasOwnProperty('method')) {
          if (!Array.isArray(message.method)) return 'method: array expected'
          for (let i = 0; i < message.method.length; ++i) {
            let error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i])
            if (error) return 'method.' + error
          }
        }
        if (message.options != null && message.hasOwnProperty('options')) {
          let error = $root.google.protobuf.ServiceOptions.verify(message.options)
          if (error) return 'options.' + error
        }
        return null
      }

      /**
       * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.ServiceDescriptorProto
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
       */
      ServiceDescriptorProto.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.ServiceDescriptorProto) return object
        let message = new $root.google.protobuf.ServiceDescriptorProto()
        if (object.name != null) message.name = String(object.name)
        if (object.method) {
          if (!Array.isArray(object.method))
            throw TypeError('.google.protobuf.ServiceDescriptorProto.method: array expected')
          message.method = []
          for (let i = 0; i < object.method.length; ++i) {
            if (typeof object.method[i] !== 'object')
              throw TypeError('.google.protobuf.ServiceDescriptorProto.method: object expected')
            message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i])
          }
        }
        if (object.options != null) {
          if (typeof object.options !== 'object')
            throw TypeError('.google.protobuf.ServiceDescriptorProto.options: object expected')
          message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options)
        }
        return message
      }

      /**
       * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.ServiceDescriptorProto
       * @static
       * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ServiceDescriptorProto.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.method = []
        if (options.defaults) {
          object.name = ''
          object.options = null
        }
        if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
        if (message.method && message.method.length) {
          object.method = []
          for (let j = 0; j < message.method.length; ++j)
            object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options)
        }
        if (message.options != null && message.hasOwnProperty('options'))
          object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options)
        return object
      }

      /**
       * Converts this ServiceDescriptorProto to JSON.
       * @function toJSON
       * @memberof google.protobuf.ServiceDescriptorProto
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ServiceDescriptorProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return ServiceDescriptorProto
    })()

    protobuf.MethodDescriptorProto = (function () {
      /**
       * Properties of a MethodDescriptorProto.
       * @memberof google.protobuf
       * @interface IMethodDescriptorProto
       * @property {string|null} [name] MethodDescriptorProto name
       * @property {string|null} [inputType] MethodDescriptorProto inputType
       * @property {string|null} [outputType] MethodDescriptorProto outputType
       * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
       * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
       * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
       */

      /**
       * Constructs a new MethodDescriptorProto.
       * @memberof google.protobuf
       * @classdesc Represents a MethodDescriptorProto.
       * @implements IMethodDescriptorProto
       * @constructor
       * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
       */
      function MethodDescriptorProto(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * MethodDescriptorProto name.
       * @member {string} name
       * @memberof google.protobuf.MethodDescriptorProto
       * @instance
       */
      MethodDescriptorProto.prototype.name = ''

      /**
       * MethodDescriptorProto inputType.
       * @member {string} inputType
       * @memberof google.protobuf.MethodDescriptorProto
       * @instance
       */
      MethodDescriptorProto.prototype.inputType = ''

      /**
       * MethodDescriptorProto outputType.
       * @member {string} outputType
       * @memberof google.protobuf.MethodDescriptorProto
       * @instance
       */
      MethodDescriptorProto.prototype.outputType = ''

      /**
       * MethodDescriptorProto options.
       * @member {google.protobuf.IMethodOptions|null|undefined} options
       * @memberof google.protobuf.MethodDescriptorProto
       * @instance
       */
      MethodDescriptorProto.prototype.options = null

      /**
       * MethodDescriptorProto clientStreaming.
       * @member {boolean} clientStreaming
       * @memberof google.protobuf.MethodDescriptorProto
       * @instance
       */
      MethodDescriptorProto.prototype.clientStreaming = false

      /**
       * MethodDescriptorProto serverStreaming.
       * @member {boolean} serverStreaming
       * @memberof google.protobuf.MethodDescriptorProto
       * @instance
       */
      MethodDescriptorProto.prototype.serverStreaming = false

      /**
       * Creates a new MethodDescriptorProto instance using the specified properties.
       * @function create
       * @memberof google.protobuf.MethodDescriptorProto
       * @static
       * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
       * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
       */
      MethodDescriptorProto.create = function create(properties) {
        return new MethodDescriptorProto(properties)
      }

      /**
       * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.MethodDescriptorProto
       * @static
       * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      MethodDescriptorProto.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name)
        if (message.inputType != null && Object.hasOwnProperty.call(message, 'inputType'))
          writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.inputType)
        if (message.outputType != null && Object.hasOwnProperty.call(message, 'outputType'))
          writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.outputType)
        if (message.options != null && Object.hasOwnProperty.call(message, 'options'))
          $root.google.protobuf.MethodOptions.encode(
            message.options,
            writer.uint32(/* id 4, wireType 2 =*/ 34).fork(),
          ).ldelim()
        if (message.clientStreaming != null && Object.hasOwnProperty.call(message, 'clientStreaming'))
          writer.uint32(/* id 5, wireType 0 =*/ 40).bool(message.clientStreaming)
        if (message.serverStreaming != null && Object.hasOwnProperty.call(message, 'serverStreaming'))
          writer.uint32(/* id 6, wireType 0 =*/ 48).bool(message.serverStreaming)
        return writer
      }

      /**
       * Encodes the specified MethodDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.MethodDescriptorProto
       * @static
       * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      MethodDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a MethodDescriptorProto message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.MethodDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      MethodDescriptorProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.MethodDescriptorProto()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.name = reader.string()
              break
            case 2:
              message.inputType = reader.string()
              break
            case 3:
              message.outputType = reader.string()
              break
            case 4:
              message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32())
              break
            case 5:
              message.clientStreaming = reader.bool()
              break
            case 6:
              message.serverStreaming = reader.bool()
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a MethodDescriptorProto message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.MethodDescriptorProto
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      MethodDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a MethodDescriptorProto message.
       * @function verify
       * @memberof google.protobuf.MethodDescriptorProto
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      MethodDescriptorProto.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.name != null && message.hasOwnProperty('name'))
          if (!$util.isString(message.name)) return 'name: string expected'
        if (message.inputType != null && message.hasOwnProperty('inputType'))
          if (!$util.isString(message.inputType)) return 'inputType: string expected'
        if (message.outputType != null && message.hasOwnProperty('outputType'))
          if (!$util.isString(message.outputType)) return 'outputType: string expected'
        if (message.options != null && message.hasOwnProperty('options')) {
          let error = $root.google.protobuf.MethodOptions.verify(message.options)
          if (error) return 'options.' + error
        }
        if (message.clientStreaming != null && message.hasOwnProperty('clientStreaming'))
          if (typeof message.clientStreaming !== 'boolean') return 'clientStreaming: boolean expected'
        if (message.serverStreaming != null && message.hasOwnProperty('serverStreaming'))
          if (typeof message.serverStreaming !== 'boolean') return 'serverStreaming: boolean expected'
        return null
      }

      /**
       * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.MethodDescriptorProto
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
       */
      MethodDescriptorProto.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.MethodDescriptorProto) return object
        let message = new $root.google.protobuf.MethodDescriptorProto()
        if (object.name != null) message.name = String(object.name)
        if (object.inputType != null) message.inputType = String(object.inputType)
        if (object.outputType != null) message.outputType = String(object.outputType)
        if (object.options != null) {
          if (typeof object.options !== 'object')
            throw TypeError('.google.protobuf.MethodDescriptorProto.options: object expected')
          message.options = $root.google.protobuf.MethodOptions.fromObject(object.options)
        }
        if (object.clientStreaming != null) message.clientStreaming = Boolean(object.clientStreaming)
        if (object.serverStreaming != null) message.serverStreaming = Boolean(object.serverStreaming)
        return message
      }

      /**
       * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.MethodDescriptorProto
       * @static
       * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      MethodDescriptorProto.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.defaults) {
          object.name = ''
          object.inputType = ''
          object.outputType = ''
          object.options = null
          object.clientStreaming = false
          object.serverStreaming = false
        }
        if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
        if (message.inputType != null && message.hasOwnProperty('inputType')) object.inputType = message.inputType
        if (message.outputType != null && message.hasOwnProperty('outputType')) object.outputType = message.outputType
        if (message.options != null && message.hasOwnProperty('options'))
          object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options)
        if (message.clientStreaming != null && message.hasOwnProperty('clientStreaming'))
          object.clientStreaming = message.clientStreaming
        if (message.serverStreaming != null && message.hasOwnProperty('serverStreaming'))
          object.serverStreaming = message.serverStreaming
        return object
      }

      /**
       * Converts this MethodDescriptorProto to JSON.
       * @function toJSON
       * @memberof google.protobuf.MethodDescriptorProto
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      MethodDescriptorProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return MethodDescriptorProto
    })()

    protobuf.FileOptions = (function () {
      /**
       * Properties of a FileOptions.
       * @memberof google.protobuf
       * @interface IFileOptions
       * @property {string|null} [javaPackage] FileOptions javaPackage
       * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
       * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
       * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
       * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
       * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
       * @property {string|null} [goPackage] FileOptions goPackage
       * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
       * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
       * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
       * @property {boolean|null} [deprecated] FileOptions deprecated
       * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
       * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
       * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
       * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
       */

      /**
       * Constructs a new FileOptions.
       * @memberof google.protobuf
       * @classdesc Represents a FileOptions.
       * @implements IFileOptions
       * @constructor
       * @param {google.protobuf.IFileOptions=} [properties] Properties to set
       */
      function FileOptions(properties) {
        this.uninterpretedOption = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * FileOptions javaPackage.
       * @member {string} javaPackage
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.javaPackage = ''

      /**
       * FileOptions javaOuterClassname.
       * @member {string} javaOuterClassname
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.javaOuterClassname = ''

      /**
       * FileOptions javaMultipleFiles.
       * @member {boolean} javaMultipleFiles
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.javaMultipleFiles = false

      /**
       * FileOptions javaGenerateEqualsAndHash.
       * @member {boolean} javaGenerateEqualsAndHash
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.javaGenerateEqualsAndHash = false

      /**
       * FileOptions javaStringCheckUtf8.
       * @member {boolean} javaStringCheckUtf8
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.javaStringCheckUtf8 = false

      /**
       * FileOptions optimizeFor.
       * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.optimizeFor = 1

      /**
       * FileOptions goPackage.
       * @member {string} goPackage
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.goPackage = ''

      /**
       * FileOptions ccGenericServices.
       * @member {boolean} ccGenericServices
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.ccGenericServices = false

      /**
       * FileOptions javaGenericServices.
       * @member {boolean} javaGenericServices
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.javaGenericServices = false

      /**
       * FileOptions pyGenericServices.
       * @member {boolean} pyGenericServices
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.pyGenericServices = false

      /**
       * FileOptions deprecated.
       * @member {boolean} deprecated
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.deprecated = false

      /**
       * FileOptions ccEnableArenas.
       * @member {boolean} ccEnableArenas
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.ccEnableArenas = false

      /**
       * FileOptions objcClassPrefix.
       * @member {string} objcClassPrefix
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.objcClassPrefix = ''

      /**
       * FileOptions csharpNamespace.
       * @member {string} csharpNamespace
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.csharpNamespace = ''

      /**
       * FileOptions uninterpretedOption.
       * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
       * @memberof google.protobuf.FileOptions
       * @instance
       */
      FileOptions.prototype.uninterpretedOption = $util.emptyArray

      /**
       * Creates a new FileOptions instance using the specified properties.
       * @function create
       * @memberof google.protobuf.FileOptions
       * @static
       * @param {google.protobuf.IFileOptions=} [properties] Properties to set
       * @returns {google.protobuf.FileOptions} FileOptions instance
       */
      FileOptions.create = function create(properties) {
        return new FileOptions(properties)
      }

      /**
       * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.FileOptions
       * @static
       * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FileOptions.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.javaPackage != null && Object.hasOwnProperty.call(message, 'javaPackage'))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.javaPackage)
        if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, 'javaOuterClassname'))
          writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.javaOuterClassname)
        if (message.optimizeFor != null && Object.hasOwnProperty.call(message, 'optimizeFor'))
          writer.uint32(/* id 9, wireType 0 =*/ 72).int32(message.optimizeFor)
        if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, 'javaMultipleFiles'))
          writer.uint32(/* id 10, wireType 0 =*/ 80).bool(message.javaMultipleFiles)
        if (message.goPackage != null && Object.hasOwnProperty.call(message, 'goPackage'))
          writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.goPackage)
        if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, 'ccGenericServices'))
          writer.uint32(/* id 16, wireType 0 =*/ 128).bool(message.ccGenericServices)
        if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, 'javaGenericServices'))
          writer.uint32(/* id 17, wireType 0 =*/ 136).bool(message.javaGenericServices)
        if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, 'pyGenericServices'))
          writer.uint32(/* id 18, wireType 0 =*/ 144).bool(message.pyGenericServices)
        if (
          message.javaGenerateEqualsAndHash != null &&
          Object.hasOwnProperty.call(message, 'javaGenerateEqualsAndHash')
        )
          writer.uint32(/* id 20, wireType 0 =*/ 160).bool(message.javaGenerateEqualsAndHash)
        if (message.deprecated != null && Object.hasOwnProperty.call(message, 'deprecated'))
          writer.uint32(/* id 23, wireType 0 =*/ 184).bool(message.deprecated)
        if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, 'javaStringCheckUtf8'))
          writer.uint32(/* id 27, wireType 0 =*/ 216).bool(message.javaStringCheckUtf8)
        if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, 'ccEnableArenas'))
          writer.uint32(/* id 31, wireType 0 =*/ 248).bool(message.ccEnableArenas)
        if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, 'objcClassPrefix'))
          writer.uint32(/* id 36, wireType 2 =*/ 290).string(message.objcClassPrefix)
        if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, 'csharpNamespace'))
          writer.uint32(/* id 37, wireType 2 =*/ 298).string(message.csharpNamespace)
        if (message.uninterpretedOption != null && message.uninterpretedOption.length)
          for (let i = 0; i < message.uninterpretedOption.length; ++i)
            $root.google.protobuf.UninterpretedOption.encode(
              message.uninterpretedOption[i],
              writer.uint32(/* id 999, wireType 2 =*/ 7994).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified FileOptions message, length delimited. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.FileOptions
       * @static
       * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FileOptions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a FileOptions message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.FileOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.FileOptions} FileOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FileOptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.FileOptions()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.javaPackage = reader.string()
              break
            case 8:
              message.javaOuterClassname = reader.string()
              break
            case 10:
              message.javaMultipleFiles = reader.bool()
              break
            case 20:
              message.javaGenerateEqualsAndHash = reader.bool()
              break
            case 27:
              message.javaStringCheckUtf8 = reader.bool()
              break
            case 9:
              message.optimizeFor = reader.int32()
              break
            case 11:
              message.goPackage = reader.string()
              break
            case 16:
              message.ccGenericServices = reader.bool()
              break
            case 17:
              message.javaGenericServices = reader.bool()
              break
            case 18:
              message.pyGenericServices = reader.bool()
              break
            case 23:
              message.deprecated = reader.bool()
              break
            case 31:
              message.ccEnableArenas = reader.bool()
              break
            case 36:
              message.objcClassPrefix = reader.string()
              break
            case 37:
              message.csharpNamespace = reader.string()
              break
            case 999:
              if (!(message.uninterpretedOption && message.uninterpretedOption.length)) message.uninterpretedOption = []
              message.uninterpretedOption.push(
                $root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()),
              )
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a FileOptions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.FileOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.FileOptions} FileOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FileOptions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a FileOptions message.
       * @function verify
       * @memberof google.protobuf.FileOptions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      FileOptions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.javaPackage != null && message.hasOwnProperty('javaPackage'))
          if (!$util.isString(message.javaPackage)) return 'javaPackage: string expected'
        if (message.javaOuterClassname != null && message.hasOwnProperty('javaOuterClassname'))
          if (!$util.isString(message.javaOuterClassname)) return 'javaOuterClassname: string expected'
        if (message.javaMultipleFiles != null && message.hasOwnProperty('javaMultipleFiles'))
          if (typeof message.javaMultipleFiles !== 'boolean') return 'javaMultipleFiles: boolean expected'
        if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty('javaGenerateEqualsAndHash'))
          if (typeof message.javaGenerateEqualsAndHash !== 'boolean')
            return 'javaGenerateEqualsAndHash: boolean expected'
        if (message.javaStringCheckUtf8 != null && message.hasOwnProperty('javaStringCheckUtf8'))
          if (typeof message.javaStringCheckUtf8 !== 'boolean') return 'javaStringCheckUtf8: boolean expected'
        if (message.optimizeFor != null && message.hasOwnProperty('optimizeFor'))
          switch (message.optimizeFor) {
            default:
              return 'optimizeFor: enum value expected'
            case 1:
            case 2:
            case 3:
              break
          }
        if (message.goPackage != null && message.hasOwnProperty('goPackage'))
          if (!$util.isString(message.goPackage)) return 'goPackage: string expected'
        if (message.ccGenericServices != null && message.hasOwnProperty('ccGenericServices'))
          if (typeof message.ccGenericServices !== 'boolean') return 'ccGenericServices: boolean expected'
        if (message.javaGenericServices != null && message.hasOwnProperty('javaGenericServices'))
          if (typeof message.javaGenericServices !== 'boolean') return 'javaGenericServices: boolean expected'
        if (message.pyGenericServices != null && message.hasOwnProperty('pyGenericServices'))
          if (typeof message.pyGenericServices !== 'boolean') return 'pyGenericServices: boolean expected'
        if (message.deprecated != null && message.hasOwnProperty('deprecated'))
          if (typeof message.deprecated !== 'boolean') return 'deprecated: boolean expected'
        if (message.ccEnableArenas != null && message.hasOwnProperty('ccEnableArenas'))
          if (typeof message.ccEnableArenas !== 'boolean') return 'ccEnableArenas: boolean expected'
        if (message.objcClassPrefix != null && message.hasOwnProperty('objcClassPrefix'))
          if (!$util.isString(message.objcClassPrefix)) return 'objcClassPrefix: string expected'
        if (message.csharpNamespace != null && message.hasOwnProperty('csharpNamespace'))
          if (!$util.isString(message.csharpNamespace)) return 'csharpNamespace: string expected'
        if (message.uninterpretedOption != null && message.hasOwnProperty('uninterpretedOption')) {
          if (!Array.isArray(message.uninterpretedOption)) return 'uninterpretedOption: array expected'
          for (let i = 0; i < message.uninterpretedOption.length; ++i) {
            let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i])
            if (error) return 'uninterpretedOption.' + error
          }
        }
        return null
      }

      /**
       * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.FileOptions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.FileOptions} FileOptions
       */
      FileOptions.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.FileOptions) return object
        let message = new $root.google.protobuf.FileOptions()
        if (object.javaPackage != null) message.javaPackage = String(object.javaPackage)
        if (object.javaOuterClassname != null) message.javaOuterClassname = String(object.javaOuterClassname)
        if (object.javaMultipleFiles != null) message.javaMultipleFiles = Boolean(object.javaMultipleFiles)
        if (object.javaGenerateEqualsAndHash != null)
          message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash)
        if (object.javaStringCheckUtf8 != null) message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8)
        switch (object.optimizeFor) {
          case 'SPEED':
          case 1:
            message.optimizeFor = 1
            break
          case 'CODE_SIZE':
          case 2:
            message.optimizeFor = 2
            break
          case 'LITE_RUNTIME':
          case 3:
            message.optimizeFor = 3
            break
        }
        if (object.goPackage != null) message.goPackage = String(object.goPackage)
        if (object.ccGenericServices != null) message.ccGenericServices = Boolean(object.ccGenericServices)
        if (object.javaGenericServices != null) message.javaGenericServices = Boolean(object.javaGenericServices)
        if (object.pyGenericServices != null) message.pyGenericServices = Boolean(object.pyGenericServices)
        if (object.deprecated != null) message.deprecated = Boolean(object.deprecated)
        if (object.ccEnableArenas != null) message.ccEnableArenas = Boolean(object.ccEnableArenas)
        if (object.objcClassPrefix != null) message.objcClassPrefix = String(object.objcClassPrefix)
        if (object.csharpNamespace != null) message.csharpNamespace = String(object.csharpNamespace)
        if (object.uninterpretedOption) {
          if (!Array.isArray(object.uninterpretedOption))
            throw TypeError('.google.protobuf.FileOptions.uninterpretedOption: array expected')
          message.uninterpretedOption = []
          for (let i = 0; i < object.uninterpretedOption.length; ++i) {
            if (typeof object.uninterpretedOption[i] !== 'object')
              throw TypeError('.google.protobuf.FileOptions.uninterpretedOption: object expected')
            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(
              object.uninterpretedOption[i],
            )
          }
        }
        return message
      }

      /**
       * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.FileOptions
       * @static
       * @param {google.protobuf.FileOptions} message FileOptions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      FileOptions.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.uninterpretedOption = []
        if (options.defaults) {
          object.javaPackage = ''
          object.javaOuterClassname = ''
          object.optimizeFor = options.enums === String ? 'SPEED' : 1
          object.javaMultipleFiles = false
          object.goPackage = ''
          object.ccGenericServices = false
          object.javaGenericServices = false
          object.pyGenericServices = false
          object.javaGenerateEqualsAndHash = false
          object.deprecated = false
          object.javaStringCheckUtf8 = false
          object.ccEnableArenas = false
          object.objcClassPrefix = ''
          object.csharpNamespace = ''
        }
        if (message.javaPackage != null && message.hasOwnProperty('javaPackage'))
          object.javaPackage = message.javaPackage
        if (message.javaOuterClassname != null && message.hasOwnProperty('javaOuterClassname'))
          object.javaOuterClassname = message.javaOuterClassname
        if (message.optimizeFor != null && message.hasOwnProperty('optimizeFor'))
          object.optimizeFor =
            options.enums === String
              ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor]
              : message.optimizeFor
        if (message.javaMultipleFiles != null && message.hasOwnProperty('javaMultipleFiles'))
          object.javaMultipleFiles = message.javaMultipleFiles
        if (message.goPackage != null && message.hasOwnProperty('goPackage')) object.goPackage = message.goPackage
        if (message.ccGenericServices != null && message.hasOwnProperty('ccGenericServices'))
          object.ccGenericServices = message.ccGenericServices
        if (message.javaGenericServices != null && message.hasOwnProperty('javaGenericServices'))
          object.javaGenericServices = message.javaGenericServices
        if (message.pyGenericServices != null && message.hasOwnProperty('pyGenericServices'))
          object.pyGenericServices = message.pyGenericServices
        if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty('javaGenerateEqualsAndHash'))
          object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash
        if (message.deprecated != null && message.hasOwnProperty('deprecated')) object.deprecated = message.deprecated
        if (message.javaStringCheckUtf8 != null && message.hasOwnProperty('javaStringCheckUtf8'))
          object.javaStringCheckUtf8 = message.javaStringCheckUtf8
        if (message.ccEnableArenas != null && message.hasOwnProperty('ccEnableArenas'))
          object.ccEnableArenas = message.ccEnableArenas
        if (message.objcClassPrefix != null && message.hasOwnProperty('objcClassPrefix'))
          object.objcClassPrefix = message.objcClassPrefix
        if (message.csharpNamespace != null && message.hasOwnProperty('csharpNamespace'))
          object.csharpNamespace = message.csharpNamespace
        if (message.uninterpretedOption && message.uninterpretedOption.length) {
          object.uninterpretedOption = []
          for (let j = 0; j < message.uninterpretedOption.length; ++j)
            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(
              message.uninterpretedOption[j],
              options,
            )
        }
        return object
      }

      /**
       * Converts this FileOptions to JSON.
       * @function toJSON
       * @memberof google.protobuf.FileOptions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      FileOptions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      /**
       * OptimizeMode enum.
       * @name google.protobuf.FileOptions.OptimizeMode
       * @enum {number}
       * @property {number} SPEED=1 SPEED value
       * @property {number} CODE_SIZE=2 CODE_SIZE value
       * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
       */
      FileOptions.OptimizeMode = (function () {
        const valuesById = {},
          values = Object.create(valuesById)
        values[(valuesById[1] = 'SPEED')] = 1
        values[(valuesById[2] = 'CODE_SIZE')] = 2
        values[(valuesById[3] = 'LITE_RUNTIME')] = 3
        return values
      })()

      return FileOptions
    })()

    protobuf.MessageOptions = (function () {
      /**
       * Properties of a MessageOptions.
       * @memberof google.protobuf
       * @interface IMessageOptions
       * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
       * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
       * @property {boolean|null} [deprecated] MessageOptions deprecated
       * @property {boolean|null} [mapEntry] MessageOptions mapEntry
       * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
       */

      /**
       * Constructs a new MessageOptions.
       * @memberof google.protobuf
       * @classdesc Represents a MessageOptions.
       * @implements IMessageOptions
       * @constructor
       * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
       */
      function MessageOptions(properties) {
        this.uninterpretedOption = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * MessageOptions messageSetWireFormat.
       * @member {boolean} messageSetWireFormat
       * @memberof google.protobuf.MessageOptions
       * @instance
       */
      MessageOptions.prototype.messageSetWireFormat = false

      /**
       * MessageOptions noStandardDescriptorAccessor.
       * @member {boolean} noStandardDescriptorAccessor
       * @memberof google.protobuf.MessageOptions
       * @instance
       */
      MessageOptions.prototype.noStandardDescriptorAccessor = false

      /**
       * MessageOptions deprecated.
       * @member {boolean} deprecated
       * @memberof google.protobuf.MessageOptions
       * @instance
       */
      MessageOptions.prototype.deprecated = false

      /**
       * MessageOptions mapEntry.
       * @member {boolean} mapEntry
       * @memberof google.protobuf.MessageOptions
       * @instance
       */
      MessageOptions.prototype.mapEntry = false

      /**
       * MessageOptions uninterpretedOption.
       * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
       * @memberof google.protobuf.MessageOptions
       * @instance
       */
      MessageOptions.prototype.uninterpretedOption = $util.emptyArray

      /**
       * Creates a new MessageOptions instance using the specified properties.
       * @function create
       * @memberof google.protobuf.MessageOptions
       * @static
       * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
       * @returns {google.protobuf.MessageOptions} MessageOptions instance
       */
      MessageOptions.create = function create(properties) {
        return new MessageOptions(properties)
      }

      /**
       * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.MessageOptions
       * @static
       * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      MessageOptions.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, 'messageSetWireFormat'))
          writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.messageSetWireFormat)
        if (
          message.noStandardDescriptorAccessor != null &&
          Object.hasOwnProperty.call(message, 'noStandardDescriptorAccessor')
        )
          writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.noStandardDescriptorAccessor)
        if (message.deprecated != null && Object.hasOwnProperty.call(message, 'deprecated'))
          writer.uint32(/* id 3, wireType 0 =*/ 24).bool(message.deprecated)
        if (message.mapEntry != null && Object.hasOwnProperty.call(message, 'mapEntry'))
          writer.uint32(/* id 7, wireType 0 =*/ 56).bool(message.mapEntry)
        if (message.uninterpretedOption != null && message.uninterpretedOption.length)
          for (let i = 0; i < message.uninterpretedOption.length; ++i)
            $root.google.protobuf.UninterpretedOption.encode(
              message.uninterpretedOption[i],
              writer.uint32(/* id 999, wireType 2 =*/ 7994).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified MessageOptions message, length delimited. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.MessageOptions
       * @static
       * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      MessageOptions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a MessageOptions message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.MessageOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.MessageOptions} MessageOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      MessageOptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.MessageOptions()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.messageSetWireFormat = reader.bool()
              break
            case 2:
              message.noStandardDescriptorAccessor = reader.bool()
              break
            case 3:
              message.deprecated = reader.bool()
              break
            case 7:
              message.mapEntry = reader.bool()
              break
            case 999:
              if (!(message.uninterpretedOption && message.uninterpretedOption.length)) message.uninterpretedOption = []
              message.uninterpretedOption.push(
                $root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()),
              )
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a MessageOptions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.MessageOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.MessageOptions} MessageOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      MessageOptions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a MessageOptions message.
       * @function verify
       * @memberof google.protobuf.MessageOptions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      MessageOptions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.messageSetWireFormat != null && message.hasOwnProperty('messageSetWireFormat'))
          if (typeof message.messageSetWireFormat !== 'boolean') return 'messageSetWireFormat: boolean expected'
        if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty('noStandardDescriptorAccessor'))
          if (typeof message.noStandardDescriptorAccessor !== 'boolean')
            return 'noStandardDescriptorAccessor: boolean expected'
        if (message.deprecated != null && message.hasOwnProperty('deprecated'))
          if (typeof message.deprecated !== 'boolean') return 'deprecated: boolean expected'
        if (message.mapEntry != null && message.hasOwnProperty('mapEntry'))
          if (typeof message.mapEntry !== 'boolean') return 'mapEntry: boolean expected'
        if (message.uninterpretedOption != null && message.hasOwnProperty('uninterpretedOption')) {
          if (!Array.isArray(message.uninterpretedOption)) return 'uninterpretedOption: array expected'
          for (let i = 0; i < message.uninterpretedOption.length; ++i) {
            let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i])
            if (error) return 'uninterpretedOption.' + error
          }
        }
        return null
      }

      /**
       * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.MessageOptions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.MessageOptions} MessageOptions
       */
      MessageOptions.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.MessageOptions) return object
        let message = new $root.google.protobuf.MessageOptions()
        if (object.messageSetWireFormat != null) message.messageSetWireFormat = Boolean(object.messageSetWireFormat)
        if (object.noStandardDescriptorAccessor != null)
          message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor)
        if (object.deprecated != null) message.deprecated = Boolean(object.deprecated)
        if (object.mapEntry != null) message.mapEntry = Boolean(object.mapEntry)
        if (object.uninterpretedOption) {
          if (!Array.isArray(object.uninterpretedOption))
            throw TypeError('.google.protobuf.MessageOptions.uninterpretedOption: array expected')
          message.uninterpretedOption = []
          for (let i = 0; i < object.uninterpretedOption.length; ++i) {
            if (typeof object.uninterpretedOption[i] !== 'object')
              throw TypeError('.google.protobuf.MessageOptions.uninterpretedOption: object expected')
            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(
              object.uninterpretedOption[i],
            )
          }
        }
        return message
      }

      /**
       * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.MessageOptions
       * @static
       * @param {google.protobuf.MessageOptions} message MessageOptions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      MessageOptions.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.uninterpretedOption = []
        if (options.defaults) {
          object.messageSetWireFormat = false
          object.noStandardDescriptorAccessor = false
          object.deprecated = false
          object.mapEntry = false
        }
        if (message.messageSetWireFormat != null && message.hasOwnProperty('messageSetWireFormat'))
          object.messageSetWireFormat = message.messageSetWireFormat
        if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty('noStandardDescriptorAccessor'))
          object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor
        if (message.deprecated != null && message.hasOwnProperty('deprecated')) object.deprecated = message.deprecated
        if (message.mapEntry != null && message.hasOwnProperty('mapEntry')) object.mapEntry = message.mapEntry
        if (message.uninterpretedOption && message.uninterpretedOption.length) {
          object.uninterpretedOption = []
          for (let j = 0; j < message.uninterpretedOption.length; ++j)
            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(
              message.uninterpretedOption[j],
              options,
            )
        }
        return object
      }

      /**
       * Converts this MessageOptions to JSON.
       * @function toJSON
       * @memberof google.protobuf.MessageOptions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      MessageOptions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return MessageOptions
    })()

    protobuf.FieldOptions = (function () {
      /**
       * Properties of a FieldOptions.
       * @memberof google.protobuf
       * @interface IFieldOptions
       * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
       * @property {boolean|null} [packed] FieldOptions packed
       * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
       * @property {boolean|null} [lazy] FieldOptions lazy
       * @property {boolean|null} [deprecated] FieldOptions deprecated
       * @property {boolean|null} [weak] FieldOptions weak
       * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
       */

      /**
       * Constructs a new FieldOptions.
       * @memberof google.protobuf
       * @classdesc Represents a FieldOptions.
       * @implements IFieldOptions
       * @constructor
       * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
       */
      function FieldOptions(properties) {
        this.uninterpretedOption = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * FieldOptions ctype.
       * @member {google.protobuf.FieldOptions.CType} ctype
       * @memberof google.protobuf.FieldOptions
       * @instance
       */
      FieldOptions.prototype.ctype = 0

      /**
       * FieldOptions packed.
       * @member {boolean} packed
       * @memberof google.protobuf.FieldOptions
       * @instance
       */
      FieldOptions.prototype.packed = false

      /**
       * FieldOptions jstype.
       * @member {google.protobuf.FieldOptions.JSType} jstype
       * @memberof google.protobuf.FieldOptions
       * @instance
       */
      FieldOptions.prototype.jstype = 0

      /**
       * FieldOptions lazy.
       * @member {boolean} lazy
       * @memberof google.protobuf.FieldOptions
       * @instance
       */
      FieldOptions.prototype.lazy = false

      /**
       * FieldOptions deprecated.
       * @member {boolean} deprecated
       * @memberof google.protobuf.FieldOptions
       * @instance
       */
      FieldOptions.prototype.deprecated = false

      /**
       * FieldOptions weak.
       * @member {boolean} weak
       * @memberof google.protobuf.FieldOptions
       * @instance
       */
      FieldOptions.prototype.weak = false

      /**
       * FieldOptions uninterpretedOption.
       * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
       * @memberof google.protobuf.FieldOptions
       * @instance
       */
      FieldOptions.prototype.uninterpretedOption = $util.emptyArray

      /**
       * Creates a new FieldOptions instance using the specified properties.
       * @function create
       * @memberof google.protobuf.FieldOptions
       * @static
       * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
       * @returns {google.protobuf.FieldOptions} FieldOptions instance
       */
      FieldOptions.create = function create(properties) {
        return new FieldOptions(properties)
      }

      /**
       * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.FieldOptions
       * @static
       * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FieldOptions.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.ctype != null && Object.hasOwnProperty.call(message, 'ctype'))
          writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.ctype)
        if (message.packed != null && Object.hasOwnProperty.call(message, 'packed'))
          writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.packed)
        if (message.deprecated != null && Object.hasOwnProperty.call(message, 'deprecated'))
          writer.uint32(/* id 3, wireType 0 =*/ 24).bool(message.deprecated)
        if (message.lazy != null && Object.hasOwnProperty.call(message, 'lazy'))
          writer.uint32(/* id 5, wireType 0 =*/ 40).bool(message.lazy)
        if (message.jstype != null && Object.hasOwnProperty.call(message, 'jstype'))
          writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.jstype)
        if (message.weak != null && Object.hasOwnProperty.call(message, 'weak'))
          writer.uint32(/* id 10, wireType 0 =*/ 80).bool(message.weak)
        if (message.uninterpretedOption != null && message.uninterpretedOption.length)
          for (let i = 0; i < message.uninterpretedOption.length; ++i)
            $root.google.protobuf.UninterpretedOption.encode(
              message.uninterpretedOption[i],
              writer.uint32(/* id 999, wireType 2 =*/ 7994).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified FieldOptions message, length delimited. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.FieldOptions
       * @static
       * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FieldOptions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a FieldOptions message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.FieldOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.FieldOptions} FieldOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FieldOptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.FieldOptions()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.ctype = reader.int32()
              break
            case 2:
              message.packed = reader.bool()
              break
            case 6:
              message.jstype = reader.int32()
              break
            case 5:
              message.lazy = reader.bool()
              break
            case 3:
              message.deprecated = reader.bool()
              break
            case 10:
              message.weak = reader.bool()
              break
            case 999:
              if (!(message.uninterpretedOption && message.uninterpretedOption.length)) message.uninterpretedOption = []
              message.uninterpretedOption.push(
                $root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()),
              )
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a FieldOptions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.FieldOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.FieldOptions} FieldOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FieldOptions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a FieldOptions message.
       * @function verify
       * @memberof google.protobuf.FieldOptions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      FieldOptions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.ctype != null && message.hasOwnProperty('ctype'))
          switch (message.ctype) {
            default:
              return 'ctype: enum value expected'
            case 0:
            case 1:
            case 2:
              break
          }
        if (message.packed != null && message.hasOwnProperty('packed'))
          if (typeof message.packed !== 'boolean') return 'packed: boolean expected'
        if (message.jstype != null && message.hasOwnProperty('jstype'))
          switch (message.jstype) {
            default:
              return 'jstype: enum value expected'
            case 0:
            case 1:
            case 2:
              break
          }
        if (message.lazy != null && message.hasOwnProperty('lazy'))
          if (typeof message.lazy !== 'boolean') return 'lazy: boolean expected'
        if (message.deprecated != null && message.hasOwnProperty('deprecated'))
          if (typeof message.deprecated !== 'boolean') return 'deprecated: boolean expected'
        if (message.weak != null && message.hasOwnProperty('weak'))
          if (typeof message.weak !== 'boolean') return 'weak: boolean expected'
        if (message.uninterpretedOption != null && message.hasOwnProperty('uninterpretedOption')) {
          if (!Array.isArray(message.uninterpretedOption)) return 'uninterpretedOption: array expected'
          for (let i = 0; i < message.uninterpretedOption.length; ++i) {
            let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i])
            if (error) return 'uninterpretedOption.' + error
          }
        }
        return null
      }

      /**
       * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.FieldOptions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.FieldOptions} FieldOptions
       */
      FieldOptions.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.FieldOptions) return object
        let message = new $root.google.protobuf.FieldOptions()
        switch (object.ctype) {
          case 'STRING':
          case 0:
            message.ctype = 0
            break
          case 'CORD':
          case 1:
            message.ctype = 1
            break
          case 'STRING_PIECE':
          case 2:
            message.ctype = 2
            break
        }
        if (object.packed != null) message.packed = Boolean(object.packed)
        switch (object.jstype) {
          case 'JS_NORMAL':
          case 0:
            message.jstype = 0
            break
          case 'JS_STRING':
          case 1:
            message.jstype = 1
            break
          case 'JS_NUMBER':
          case 2:
            message.jstype = 2
            break
        }
        if (object.lazy != null) message.lazy = Boolean(object.lazy)
        if (object.deprecated != null) message.deprecated = Boolean(object.deprecated)
        if (object.weak != null) message.weak = Boolean(object.weak)
        if (object.uninterpretedOption) {
          if (!Array.isArray(object.uninterpretedOption))
            throw TypeError('.google.protobuf.FieldOptions.uninterpretedOption: array expected')
          message.uninterpretedOption = []
          for (let i = 0; i < object.uninterpretedOption.length; ++i) {
            if (typeof object.uninterpretedOption[i] !== 'object')
              throw TypeError('.google.protobuf.FieldOptions.uninterpretedOption: object expected')
            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(
              object.uninterpretedOption[i],
            )
          }
        }
        return message
      }

      /**
       * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.FieldOptions
       * @static
       * @param {google.protobuf.FieldOptions} message FieldOptions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      FieldOptions.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.uninterpretedOption = []
        if (options.defaults) {
          object.ctype = options.enums === String ? 'STRING' : 0
          object.packed = false
          object.deprecated = false
          object.lazy = false
          object.jstype = options.enums === String ? 'JS_NORMAL' : 0
          object.weak = false
        }
        if (message.ctype != null && message.hasOwnProperty('ctype'))
          object.ctype =
            options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype
        if (message.packed != null && message.hasOwnProperty('packed')) object.packed = message.packed
        if (message.deprecated != null && message.hasOwnProperty('deprecated')) object.deprecated = message.deprecated
        if (message.lazy != null && message.hasOwnProperty('lazy')) object.lazy = message.lazy
        if (message.jstype != null && message.hasOwnProperty('jstype'))
          object.jstype =
            options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype
        if (message.weak != null && message.hasOwnProperty('weak')) object.weak = message.weak
        if (message.uninterpretedOption && message.uninterpretedOption.length) {
          object.uninterpretedOption = []
          for (let j = 0; j < message.uninterpretedOption.length; ++j)
            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(
              message.uninterpretedOption[j],
              options,
            )
        }
        return object
      }

      /**
       * Converts this FieldOptions to JSON.
       * @function toJSON
       * @memberof google.protobuf.FieldOptions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      FieldOptions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      /**
       * CType enum.
       * @name google.protobuf.FieldOptions.CType
       * @enum {number}
       * @property {number} STRING=0 STRING value
       * @property {number} CORD=1 CORD value
       * @property {number} STRING_PIECE=2 STRING_PIECE value
       */
      FieldOptions.CType = (function () {
        const valuesById = {},
          values = Object.create(valuesById)
        values[(valuesById[0] = 'STRING')] = 0
        values[(valuesById[1] = 'CORD')] = 1
        values[(valuesById[2] = 'STRING_PIECE')] = 2
        return values
      })()

      /**
       * JSType enum.
       * @name google.protobuf.FieldOptions.JSType
       * @enum {number}
       * @property {number} JS_NORMAL=0 JS_NORMAL value
       * @property {number} JS_STRING=1 JS_STRING value
       * @property {number} JS_NUMBER=2 JS_NUMBER value
       */
      FieldOptions.JSType = (function () {
        const valuesById = {},
          values = Object.create(valuesById)
        values[(valuesById[0] = 'JS_NORMAL')] = 0
        values[(valuesById[1] = 'JS_STRING')] = 1
        values[(valuesById[2] = 'JS_NUMBER')] = 2
        return values
      })()

      return FieldOptions
    })()

    protobuf.OneofOptions = (function () {
      /**
       * Properties of an OneofOptions.
       * @memberof google.protobuf
       * @interface IOneofOptions
       * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
       */

      /**
       * Constructs a new OneofOptions.
       * @memberof google.protobuf
       * @classdesc Represents an OneofOptions.
       * @implements IOneofOptions
       * @constructor
       * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
       */
      function OneofOptions(properties) {
        this.uninterpretedOption = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * OneofOptions uninterpretedOption.
       * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
       * @memberof google.protobuf.OneofOptions
       * @instance
       */
      OneofOptions.prototype.uninterpretedOption = $util.emptyArray

      /**
       * Creates a new OneofOptions instance using the specified properties.
       * @function create
       * @memberof google.protobuf.OneofOptions
       * @static
       * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
       * @returns {google.protobuf.OneofOptions} OneofOptions instance
       */
      OneofOptions.create = function create(properties) {
        return new OneofOptions(properties)
      }

      /**
       * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.OneofOptions
       * @static
       * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      OneofOptions.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.uninterpretedOption != null && message.uninterpretedOption.length)
          for (let i = 0; i < message.uninterpretedOption.length; ++i)
            $root.google.protobuf.UninterpretedOption.encode(
              message.uninterpretedOption[i],
              writer.uint32(/* id 999, wireType 2 =*/ 7994).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified OneofOptions message, length delimited. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.OneofOptions
       * @static
       * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      OneofOptions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes an OneofOptions message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.OneofOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.OneofOptions} OneofOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      OneofOptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.OneofOptions()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 999:
              if (!(message.uninterpretedOption && message.uninterpretedOption.length)) message.uninterpretedOption = []
              message.uninterpretedOption.push(
                $root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()),
              )
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes an OneofOptions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.OneofOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.OneofOptions} OneofOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      OneofOptions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies an OneofOptions message.
       * @function verify
       * @memberof google.protobuf.OneofOptions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      OneofOptions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.uninterpretedOption != null && message.hasOwnProperty('uninterpretedOption')) {
          if (!Array.isArray(message.uninterpretedOption)) return 'uninterpretedOption: array expected'
          for (let i = 0; i < message.uninterpretedOption.length; ++i) {
            let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i])
            if (error) return 'uninterpretedOption.' + error
          }
        }
        return null
      }

      /**
       * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.OneofOptions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.OneofOptions} OneofOptions
       */
      OneofOptions.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.OneofOptions) return object
        let message = new $root.google.protobuf.OneofOptions()
        if (object.uninterpretedOption) {
          if (!Array.isArray(object.uninterpretedOption))
            throw TypeError('.google.protobuf.OneofOptions.uninterpretedOption: array expected')
          message.uninterpretedOption = []
          for (let i = 0; i < object.uninterpretedOption.length; ++i) {
            if (typeof object.uninterpretedOption[i] !== 'object')
              throw TypeError('.google.protobuf.OneofOptions.uninterpretedOption: object expected')
            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(
              object.uninterpretedOption[i],
            )
          }
        }
        return message
      }

      /**
       * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.OneofOptions
       * @static
       * @param {google.protobuf.OneofOptions} message OneofOptions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      OneofOptions.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.uninterpretedOption = []
        if (message.uninterpretedOption && message.uninterpretedOption.length) {
          object.uninterpretedOption = []
          for (let j = 0; j < message.uninterpretedOption.length; ++j)
            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(
              message.uninterpretedOption[j],
              options,
            )
        }
        return object
      }

      /**
       * Converts this OneofOptions to JSON.
       * @function toJSON
       * @memberof google.protobuf.OneofOptions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      OneofOptions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return OneofOptions
    })()

    protobuf.EnumOptions = (function () {
      /**
       * Properties of an EnumOptions.
       * @memberof google.protobuf
       * @interface IEnumOptions
       * @property {boolean|null} [allowAlias] EnumOptions allowAlias
       * @property {boolean|null} [deprecated] EnumOptions deprecated
       * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
       */

      /**
       * Constructs a new EnumOptions.
       * @memberof google.protobuf
       * @classdesc Represents an EnumOptions.
       * @implements IEnumOptions
       * @constructor
       * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
       */
      function EnumOptions(properties) {
        this.uninterpretedOption = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * EnumOptions allowAlias.
       * @member {boolean} allowAlias
       * @memberof google.protobuf.EnumOptions
       * @instance
       */
      EnumOptions.prototype.allowAlias = false

      /**
       * EnumOptions deprecated.
       * @member {boolean} deprecated
       * @memberof google.protobuf.EnumOptions
       * @instance
       */
      EnumOptions.prototype.deprecated = false

      /**
       * EnumOptions uninterpretedOption.
       * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
       * @memberof google.protobuf.EnumOptions
       * @instance
       */
      EnumOptions.prototype.uninterpretedOption = $util.emptyArray

      /**
       * Creates a new EnumOptions instance using the specified properties.
       * @function create
       * @memberof google.protobuf.EnumOptions
       * @static
       * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
       * @returns {google.protobuf.EnumOptions} EnumOptions instance
       */
      EnumOptions.create = function create(properties) {
        return new EnumOptions(properties)
      }

      /**
       * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.EnumOptions
       * @static
       * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      EnumOptions.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.allowAlias != null && Object.hasOwnProperty.call(message, 'allowAlias'))
          writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.allowAlias)
        if (message.deprecated != null && Object.hasOwnProperty.call(message, 'deprecated'))
          writer.uint32(/* id 3, wireType 0 =*/ 24).bool(message.deprecated)
        if (message.uninterpretedOption != null && message.uninterpretedOption.length)
          for (let i = 0; i < message.uninterpretedOption.length; ++i)
            $root.google.protobuf.UninterpretedOption.encode(
              message.uninterpretedOption[i],
              writer.uint32(/* id 999, wireType 2 =*/ 7994).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified EnumOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.EnumOptions
       * @static
       * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      EnumOptions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes an EnumOptions message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.EnumOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.EnumOptions} EnumOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      EnumOptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.EnumOptions()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 2:
              message.allowAlias = reader.bool()
              break
            case 3:
              message.deprecated = reader.bool()
              break
            case 999:
              if (!(message.uninterpretedOption && message.uninterpretedOption.length)) message.uninterpretedOption = []
              message.uninterpretedOption.push(
                $root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()),
              )
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes an EnumOptions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.EnumOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.EnumOptions} EnumOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      EnumOptions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies an EnumOptions message.
       * @function verify
       * @memberof google.protobuf.EnumOptions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      EnumOptions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.allowAlias != null && message.hasOwnProperty('allowAlias'))
          if (typeof message.allowAlias !== 'boolean') return 'allowAlias: boolean expected'
        if (message.deprecated != null && message.hasOwnProperty('deprecated'))
          if (typeof message.deprecated !== 'boolean') return 'deprecated: boolean expected'
        if (message.uninterpretedOption != null && message.hasOwnProperty('uninterpretedOption')) {
          if (!Array.isArray(message.uninterpretedOption)) return 'uninterpretedOption: array expected'
          for (let i = 0; i < message.uninterpretedOption.length; ++i) {
            let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i])
            if (error) return 'uninterpretedOption.' + error
          }
        }
        return null
      }

      /**
       * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.EnumOptions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.EnumOptions} EnumOptions
       */
      EnumOptions.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.EnumOptions) return object
        let message = new $root.google.protobuf.EnumOptions()
        if (object.allowAlias != null) message.allowAlias = Boolean(object.allowAlias)
        if (object.deprecated != null) message.deprecated = Boolean(object.deprecated)
        if (object.uninterpretedOption) {
          if (!Array.isArray(object.uninterpretedOption))
            throw TypeError('.google.protobuf.EnumOptions.uninterpretedOption: array expected')
          message.uninterpretedOption = []
          for (let i = 0; i < object.uninterpretedOption.length; ++i) {
            if (typeof object.uninterpretedOption[i] !== 'object')
              throw TypeError('.google.protobuf.EnumOptions.uninterpretedOption: object expected')
            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(
              object.uninterpretedOption[i],
            )
          }
        }
        return message
      }

      /**
       * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.EnumOptions
       * @static
       * @param {google.protobuf.EnumOptions} message EnumOptions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      EnumOptions.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.uninterpretedOption = []
        if (options.defaults) {
          object.allowAlias = false
          object.deprecated = false
        }
        if (message.allowAlias != null && message.hasOwnProperty('allowAlias')) object.allowAlias = message.allowAlias
        if (message.deprecated != null && message.hasOwnProperty('deprecated')) object.deprecated = message.deprecated
        if (message.uninterpretedOption && message.uninterpretedOption.length) {
          object.uninterpretedOption = []
          for (let j = 0; j < message.uninterpretedOption.length; ++j)
            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(
              message.uninterpretedOption[j],
              options,
            )
        }
        return object
      }

      /**
       * Converts this EnumOptions to JSON.
       * @function toJSON
       * @memberof google.protobuf.EnumOptions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      EnumOptions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return EnumOptions
    })()

    protobuf.EnumValueOptions = (function () {
      /**
       * Properties of an EnumValueOptions.
       * @memberof google.protobuf
       * @interface IEnumValueOptions
       * @property {boolean|null} [deprecated] EnumValueOptions deprecated
       * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
       */

      /**
       * Constructs a new EnumValueOptions.
       * @memberof google.protobuf
       * @classdesc Represents an EnumValueOptions.
       * @implements IEnumValueOptions
       * @constructor
       * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
       */
      function EnumValueOptions(properties) {
        this.uninterpretedOption = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * EnumValueOptions deprecated.
       * @member {boolean} deprecated
       * @memberof google.protobuf.EnumValueOptions
       * @instance
       */
      EnumValueOptions.prototype.deprecated = false

      /**
       * EnumValueOptions uninterpretedOption.
       * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
       * @memberof google.protobuf.EnumValueOptions
       * @instance
       */
      EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray

      /**
       * Creates a new EnumValueOptions instance using the specified properties.
       * @function create
       * @memberof google.protobuf.EnumValueOptions
       * @static
       * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
       * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
       */
      EnumValueOptions.create = function create(properties) {
        return new EnumValueOptions(properties)
      }

      /**
       * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.EnumValueOptions
       * @static
       * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      EnumValueOptions.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.deprecated != null && Object.hasOwnProperty.call(message, 'deprecated'))
          writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.deprecated)
        if (message.uninterpretedOption != null && message.uninterpretedOption.length)
          for (let i = 0; i < message.uninterpretedOption.length; ++i)
            $root.google.protobuf.UninterpretedOption.encode(
              message.uninterpretedOption[i],
              writer.uint32(/* id 999, wireType 2 =*/ 7994).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified EnumValueOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.EnumValueOptions
       * @static
       * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      EnumValueOptions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes an EnumValueOptions message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.EnumValueOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      EnumValueOptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.EnumValueOptions()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              message.deprecated = reader.bool()
              break
            case 999:
              if (!(message.uninterpretedOption && message.uninterpretedOption.length)) message.uninterpretedOption = []
              message.uninterpretedOption.push(
                $root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()),
              )
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes an EnumValueOptions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.EnumValueOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      EnumValueOptions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies an EnumValueOptions message.
       * @function verify
       * @memberof google.protobuf.EnumValueOptions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      EnumValueOptions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.deprecated != null && message.hasOwnProperty('deprecated'))
          if (typeof message.deprecated !== 'boolean') return 'deprecated: boolean expected'
        if (message.uninterpretedOption != null && message.hasOwnProperty('uninterpretedOption')) {
          if (!Array.isArray(message.uninterpretedOption)) return 'uninterpretedOption: array expected'
          for (let i = 0; i < message.uninterpretedOption.length; ++i) {
            let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i])
            if (error) return 'uninterpretedOption.' + error
          }
        }
        return null
      }

      /**
       * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.EnumValueOptions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
       */
      EnumValueOptions.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.EnumValueOptions) return object
        let message = new $root.google.protobuf.EnumValueOptions()
        if (object.deprecated != null) message.deprecated = Boolean(object.deprecated)
        if (object.uninterpretedOption) {
          if (!Array.isArray(object.uninterpretedOption))
            throw TypeError('.google.protobuf.EnumValueOptions.uninterpretedOption: array expected')
          message.uninterpretedOption = []
          for (let i = 0; i < object.uninterpretedOption.length; ++i) {
            if (typeof object.uninterpretedOption[i] !== 'object')
              throw TypeError('.google.protobuf.EnumValueOptions.uninterpretedOption: object expected')
            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(
              object.uninterpretedOption[i],
            )
          }
        }
        return message
      }

      /**
       * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.EnumValueOptions
       * @static
       * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      EnumValueOptions.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.uninterpretedOption = []
        if (options.defaults) object.deprecated = false
        if (message.deprecated != null && message.hasOwnProperty('deprecated')) object.deprecated = message.deprecated
        if (message.uninterpretedOption && message.uninterpretedOption.length) {
          object.uninterpretedOption = []
          for (let j = 0; j < message.uninterpretedOption.length; ++j)
            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(
              message.uninterpretedOption[j],
              options,
            )
        }
        return object
      }

      /**
       * Converts this EnumValueOptions to JSON.
       * @function toJSON
       * @memberof google.protobuf.EnumValueOptions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      EnumValueOptions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return EnumValueOptions
    })()

    protobuf.ServiceOptions = (function () {
      /**
       * Properties of a ServiceOptions.
       * @memberof google.protobuf
       * @interface IServiceOptions
       * @property {boolean|null} [deprecated] ServiceOptions deprecated
       * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
       */

      /**
       * Constructs a new ServiceOptions.
       * @memberof google.protobuf
       * @classdesc Represents a ServiceOptions.
       * @implements IServiceOptions
       * @constructor
       * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
       */
      function ServiceOptions(properties) {
        this.uninterpretedOption = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * ServiceOptions deprecated.
       * @member {boolean} deprecated
       * @memberof google.protobuf.ServiceOptions
       * @instance
       */
      ServiceOptions.prototype.deprecated = false

      /**
       * ServiceOptions uninterpretedOption.
       * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
       * @memberof google.protobuf.ServiceOptions
       * @instance
       */
      ServiceOptions.prototype.uninterpretedOption = $util.emptyArray

      /**
       * Creates a new ServiceOptions instance using the specified properties.
       * @function create
       * @memberof google.protobuf.ServiceOptions
       * @static
       * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
       * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
       */
      ServiceOptions.create = function create(properties) {
        return new ServiceOptions(properties)
      }

      /**
       * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.ServiceOptions
       * @static
       * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ServiceOptions.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.deprecated != null && Object.hasOwnProperty.call(message, 'deprecated'))
          writer.uint32(/* id 33, wireType 0 =*/ 264).bool(message.deprecated)
        if (message.uninterpretedOption != null && message.uninterpretedOption.length)
          for (let i = 0; i < message.uninterpretedOption.length; ++i)
            $root.google.protobuf.UninterpretedOption.encode(
              message.uninterpretedOption[i],
              writer.uint32(/* id 999, wireType 2 =*/ 7994).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified ServiceOptions message, length delimited. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.ServiceOptions
       * @static
       * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ServiceOptions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a ServiceOptions message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.ServiceOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.ServiceOptions} ServiceOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ServiceOptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.ServiceOptions()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 33:
              message.deprecated = reader.bool()
              break
            case 999:
              if (!(message.uninterpretedOption && message.uninterpretedOption.length)) message.uninterpretedOption = []
              message.uninterpretedOption.push(
                $root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()),
              )
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a ServiceOptions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.ServiceOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.ServiceOptions} ServiceOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ServiceOptions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a ServiceOptions message.
       * @function verify
       * @memberof google.protobuf.ServiceOptions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ServiceOptions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.deprecated != null && message.hasOwnProperty('deprecated'))
          if (typeof message.deprecated !== 'boolean') return 'deprecated: boolean expected'
        if (message.uninterpretedOption != null && message.hasOwnProperty('uninterpretedOption')) {
          if (!Array.isArray(message.uninterpretedOption)) return 'uninterpretedOption: array expected'
          for (let i = 0; i < message.uninterpretedOption.length; ++i) {
            let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i])
            if (error) return 'uninterpretedOption.' + error
          }
        }
        return null
      }

      /**
       * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.ServiceOptions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.ServiceOptions} ServiceOptions
       */
      ServiceOptions.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.ServiceOptions) return object
        let message = new $root.google.protobuf.ServiceOptions()
        if (object.deprecated != null) message.deprecated = Boolean(object.deprecated)
        if (object.uninterpretedOption) {
          if (!Array.isArray(object.uninterpretedOption))
            throw TypeError('.google.protobuf.ServiceOptions.uninterpretedOption: array expected')
          message.uninterpretedOption = []
          for (let i = 0; i < object.uninterpretedOption.length; ++i) {
            if (typeof object.uninterpretedOption[i] !== 'object')
              throw TypeError('.google.protobuf.ServiceOptions.uninterpretedOption: object expected')
            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(
              object.uninterpretedOption[i],
            )
          }
        }
        return message
      }

      /**
       * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.ServiceOptions
       * @static
       * @param {google.protobuf.ServiceOptions} message ServiceOptions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ServiceOptions.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.uninterpretedOption = []
        if (options.defaults) object.deprecated = false
        if (message.deprecated != null && message.hasOwnProperty('deprecated')) object.deprecated = message.deprecated
        if (message.uninterpretedOption && message.uninterpretedOption.length) {
          object.uninterpretedOption = []
          for (let j = 0; j < message.uninterpretedOption.length; ++j)
            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(
              message.uninterpretedOption[j],
              options,
            )
        }
        return object
      }

      /**
       * Converts this ServiceOptions to JSON.
       * @function toJSON
       * @memberof google.protobuf.ServiceOptions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ServiceOptions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return ServiceOptions
    })()

    protobuf.MethodOptions = (function () {
      /**
       * Properties of a MethodOptions.
       * @memberof google.protobuf
       * @interface IMethodOptions
       * @property {boolean|null} [deprecated] MethodOptions deprecated
       * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
       * @property {google.api.IHttpRule|null} [".google.api.http"] MethodOptions .google.api.http
       */

      /**
       * Constructs a new MethodOptions.
       * @memberof google.protobuf
       * @classdesc Represents a MethodOptions.
       * @implements IMethodOptions
       * @constructor
       * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
       */
      function MethodOptions(properties) {
        this.uninterpretedOption = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * MethodOptions deprecated.
       * @member {boolean} deprecated
       * @memberof google.protobuf.MethodOptions
       * @instance
       */
      MethodOptions.prototype.deprecated = false

      /**
       * MethodOptions uninterpretedOption.
       * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
       * @memberof google.protobuf.MethodOptions
       * @instance
       */
      MethodOptions.prototype.uninterpretedOption = $util.emptyArray

      /**
       * MethodOptions .google.api.http.
       * @member {google.api.IHttpRule|null|undefined} .google.api.http
       * @memberof google.protobuf.MethodOptions
       * @instance
       */
      MethodOptions.prototype['.google.api.http'] = null

      /**
       * Creates a new MethodOptions instance using the specified properties.
       * @function create
       * @memberof google.protobuf.MethodOptions
       * @static
       * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
       * @returns {google.protobuf.MethodOptions} MethodOptions instance
       */
      MethodOptions.create = function create(properties) {
        return new MethodOptions(properties)
      }

      /**
       * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.MethodOptions
       * @static
       * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      MethodOptions.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.deprecated != null && Object.hasOwnProperty.call(message, 'deprecated'))
          writer.uint32(/* id 33, wireType 0 =*/ 264).bool(message.deprecated)
        if (message.uninterpretedOption != null && message.uninterpretedOption.length)
          for (let i = 0; i < message.uninterpretedOption.length; ++i)
            $root.google.protobuf.UninterpretedOption.encode(
              message.uninterpretedOption[i],
              writer.uint32(/* id 999, wireType 2 =*/ 7994).fork(),
            ).ldelim()
        if (message['.google.api.http'] != null && Object.hasOwnProperty.call(message, '.google.api.http'))
          $root.google.api.HttpRule.encode(
            message['.google.api.http'],
            writer.uint32(/* id 72295728, wireType 2 =*/ 578365826).fork(),
          ).ldelim()
        return writer
      }

      /**
       * Encodes the specified MethodOptions message, length delimited. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.MethodOptions
       * @static
       * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      MethodOptions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a MethodOptions message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.MethodOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.MethodOptions} MethodOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      MethodOptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.MethodOptions()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 33:
              message.deprecated = reader.bool()
              break
            case 999:
              if (!(message.uninterpretedOption && message.uninterpretedOption.length)) message.uninterpretedOption = []
              message.uninterpretedOption.push(
                $root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()),
              )
              break
            case 72295728:
              message['.google.api.http'] = $root.google.api.HttpRule.decode(reader, reader.uint32())
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a MethodOptions message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.MethodOptions
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.MethodOptions} MethodOptions
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      MethodOptions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a MethodOptions message.
       * @function verify
       * @memberof google.protobuf.MethodOptions
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      MethodOptions.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.deprecated != null && message.hasOwnProperty('deprecated'))
          if (typeof message.deprecated !== 'boolean') return 'deprecated: boolean expected'
        if (message.uninterpretedOption != null && message.hasOwnProperty('uninterpretedOption')) {
          if (!Array.isArray(message.uninterpretedOption)) return 'uninterpretedOption: array expected'
          for (let i = 0; i < message.uninterpretedOption.length; ++i) {
            let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i])
            if (error) return 'uninterpretedOption.' + error
          }
        }
        if (message['.google.api.http'] != null && message.hasOwnProperty('.google.api.http')) {
          let error = $root.google.api.HttpRule.verify(message['.google.api.http'])
          if (error) return '.google.api.http.' + error
        }
        return null
      }

      /**
       * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.MethodOptions
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.MethodOptions} MethodOptions
       */
      MethodOptions.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.MethodOptions) return object
        let message = new $root.google.protobuf.MethodOptions()
        if (object.deprecated != null) message.deprecated = Boolean(object.deprecated)
        if (object.uninterpretedOption) {
          if (!Array.isArray(object.uninterpretedOption))
            throw TypeError('.google.protobuf.MethodOptions.uninterpretedOption: array expected')
          message.uninterpretedOption = []
          for (let i = 0; i < object.uninterpretedOption.length; ++i) {
            if (typeof object.uninterpretedOption[i] !== 'object')
              throw TypeError('.google.protobuf.MethodOptions.uninterpretedOption: object expected')
            message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(
              object.uninterpretedOption[i],
            )
          }
        }
        if (object['.google.api.http'] != null) {
          if (typeof object['.google.api.http'] !== 'object')
            throw TypeError('.google.protobuf.MethodOptions..google.api.http: object expected')
          message['.google.api.http'] = $root.google.api.HttpRule.fromObject(object['.google.api.http'])
        }
        return message
      }

      /**
       * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.MethodOptions
       * @static
       * @param {google.protobuf.MethodOptions} message MethodOptions
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      MethodOptions.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.uninterpretedOption = []
        if (options.defaults) {
          object.deprecated = false
          object['.google.api.http'] = null
        }
        if (message.deprecated != null && message.hasOwnProperty('deprecated')) object.deprecated = message.deprecated
        if (message.uninterpretedOption && message.uninterpretedOption.length) {
          object.uninterpretedOption = []
          for (let j = 0; j < message.uninterpretedOption.length; ++j)
            object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(
              message.uninterpretedOption[j],
              options,
            )
        }
        if (message['.google.api.http'] != null && message.hasOwnProperty('.google.api.http'))
          object['.google.api.http'] = $root.google.api.HttpRule.toObject(message['.google.api.http'], options)
        return object
      }

      /**
       * Converts this MethodOptions to JSON.
       * @function toJSON
       * @memberof google.protobuf.MethodOptions
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      MethodOptions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      return MethodOptions
    })()

    protobuf.UninterpretedOption = (function () {
      /**
       * Properties of an UninterpretedOption.
       * @memberof google.protobuf
       * @interface IUninterpretedOption
       * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
       * @property {string|null} [identifierValue] UninterpretedOption identifierValue
       * @property {number|Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
       * @property {number|Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
       * @property {number|null} [doubleValue] UninterpretedOption doubleValue
       * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
       * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
       */

      /**
       * Constructs a new UninterpretedOption.
       * @memberof google.protobuf
       * @classdesc Represents an UninterpretedOption.
       * @implements IUninterpretedOption
       * @constructor
       * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
       */
      function UninterpretedOption(properties) {
        this.name = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * UninterpretedOption name.
       * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
       * @memberof google.protobuf.UninterpretedOption
       * @instance
       */
      UninterpretedOption.prototype.name = $util.emptyArray

      /**
       * UninterpretedOption identifierValue.
       * @member {string} identifierValue
       * @memberof google.protobuf.UninterpretedOption
       * @instance
       */
      UninterpretedOption.prototype.identifierValue = ''

      /**
       * UninterpretedOption positiveIntValue.
       * @member {number|Long} positiveIntValue
       * @memberof google.protobuf.UninterpretedOption
       * @instance
       */
      UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0, 0, true) : 0

      /**
       * UninterpretedOption negativeIntValue.
       * @member {number|Long} negativeIntValue
       * @memberof google.protobuf.UninterpretedOption
       * @instance
       */
      UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

      /**
       * UninterpretedOption doubleValue.
       * @member {number} doubleValue
       * @memberof google.protobuf.UninterpretedOption
       * @instance
       */
      UninterpretedOption.prototype.doubleValue = 0

      /**
       * UninterpretedOption stringValue.
       * @member {Uint8Array} stringValue
       * @memberof google.protobuf.UninterpretedOption
       * @instance
       */
      UninterpretedOption.prototype.stringValue = $util.newBuffer([])

      /**
       * UninterpretedOption aggregateValue.
       * @member {string} aggregateValue
       * @memberof google.protobuf.UninterpretedOption
       * @instance
       */
      UninterpretedOption.prototype.aggregateValue = ''

      /**
       * Creates a new UninterpretedOption instance using the specified properties.
       * @function create
       * @memberof google.protobuf.UninterpretedOption
       * @static
       * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
       * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
       */
      UninterpretedOption.create = function create(properties) {
        return new UninterpretedOption(properties)
      }

      /**
       * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.UninterpretedOption
       * @static
       * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      UninterpretedOption.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.name != null && message.name.length)
          for (let i = 0; i < message.name.length; ++i)
            $root.google.protobuf.UninterpretedOption.NamePart.encode(
              message.name[i],
              writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
            ).ldelim()
        if (message.identifierValue != null && Object.hasOwnProperty.call(message, 'identifierValue'))
          writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.identifierValue)
        if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, 'positiveIntValue'))
          writer.uint32(/* id 4, wireType 0 =*/ 32).uint64(message.positiveIntValue)
        if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, 'negativeIntValue'))
          writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.negativeIntValue)
        if (message.doubleValue != null && Object.hasOwnProperty.call(message, 'doubleValue'))
          writer.uint32(/* id 6, wireType 1 =*/ 49).double(message.doubleValue)
        if (message.stringValue != null && Object.hasOwnProperty.call(message, 'stringValue'))
          writer.uint32(/* id 7, wireType 2 =*/ 58).bytes(message.stringValue)
        if (message.aggregateValue != null && Object.hasOwnProperty.call(message, 'aggregateValue'))
          writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.aggregateValue)
        return writer
      }

      /**
       * Encodes the specified UninterpretedOption message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.UninterpretedOption
       * @static
       * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      UninterpretedOption.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes an UninterpretedOption message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.UninterpretedOption
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      UninterpretedOption.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.UninterpretedOption()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 2:
              if (!(message.name && message.name.length)) message.name = []
              message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()))
              break
            case 3:
              message.identifierValue = reader.string()
              break
            case 4:
              message.positiveIntValue = reader.uint64()
              break
            case 5:
              message.negativeIntValue = reader.int64()
              break
            case 6:
              message.doubleValue = reader.double()
              break
            case 7:
              message.stringValue = reader.bytes()
              break
            case 8:
              message.aggregateValue = reader.string()
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes an UninterpretedOption message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.UninterpretedOption
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      UninterpretedOption.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies an UninterpretedOption message.
       * @function verify
       * @memberof google.protobuf.UninterpretedOption
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      UninterpretedOption.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.name != null && message.hasOwnProperty('name')) {
          if (!Array.isArray(message.name)) return 'name: array expected'
          for (let i = 0; i < message.name.length; ++i) {
            let error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i])
            if (error) return 'name.' + error
          }
        }
        if (message.identifierValue != null && message.hasOwnProperty('identifierValue'))
          if (!$util.isString(message.identifierValue)) return 'identifierValue: string expected'
        if (message.positiveIntValue != null && message.hasOwnProperty('positiveIntValue'))
          if (
            !$util.isInteger(message.positiveIntValue) &&
            !(
              message.positiveIntValue &&
              $util.isInteger(message.positiveIntValue.low) &&
              $util.isInteger(message.positiveIntValue.high)
            )
          )
            return 'positiveIntValue: integer|Long expected'
        if (message.negativeIntValue != null && message.hasOwnProperty('negativeIntValue'))
          if (
            !$util.isInteger(message.negativeIntValue) &&
            !(
              message.negativeIntValue &&
              $util.isInteger(message.negativeIntValue.low) &&
              $util.isInteger(message.negativeIntValue.high)
            )
          )
            return 'negativeIntValue: integer|Long expected'
        if (message.doubleValue != null && message.hasOwnProperty('doubleValue'))
          if (typeof message.doubleValue !== 'number') return 'doubleValue: number expected'
        if (message.stringValue != null && message.hasOwnProperty('stringValue'))
          if (
            !(
              (message.stringValue && typeof message.stringValue.length === 'number') ||
              $util.isString(message.stringValue)
            )
          )
            return 'stringValue: buffer expected'
        if (message.aggregateValue != null && message.hasOwnProperty('aggregateValue'))
          if (!$util.isString(message.aggregateValue)) return 'aggregateValue: string expected'
        return null
      }

      /**
       * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.UninterpretedOption
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
       */
      UninterpretedOption.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.UninterpretedOption) return object
        let message = new $root.google.protobuf.UninterpretedOption()
        if (object.name) {
          if (!Array.isArray(object.name)) throw TypeError('.google.protobuf.UninterpretedOption.name: array expected')
          message.name = []
          for (let i = 0; i < object.name.length; ++i) {
            if (typeof object.name[i] !== 'object')
              throw TypeError('.google.protobuf.UninterpretedOption.name: object expected')
            message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i])
          }
        }
        if (object.identifierValue != null) message.identifierValue = String(object.identifierValue)
        if (object.positiveIntValue != null)
          if ($util.Long) (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true
          else if (typeof object.positiveIntValue === 'string')
            message.positiveIntValue = parseInt(object.positiveIntValue, 10)
          else if (typeof object.positiveIntValue === 'number') message.positiveIntValue = object.positiveIntValue
          else if (typeof object.positiveIntValue === 'object')
            message.positiveIntValue = new $util.LongBits(
              object.positiveIntValue.low >>> 0,
              object.positiveIntValue.high >>> 0,
            ).toNumber(true)
        if (object.negativeIntValue != null)
          if ($util.Long) (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false
          else if (typeof object.negativeIntValue === 'string')
            message.negativeIntValue = parseInt(object.negativeIntValue, 10)
          else if (typeof object.negativeIntValue === 'number') message.negativeIntValue = object.negativeIntValue
          else if (typeof object.negativeIntValue === 'object')
            message.negativeIntValue = new $util.LongBits(
              object.negativeIntValue.low >>> 0,
              object.negativeIntValue.high >>> 0,
            ).toNumber()
        if (object.doubleValue != null) message.doubleValue = Number(object.doubleValue)
        if (object.stringValue != null)
          if (typeof object.stringValue === 'string')
            $util.base64.decode(
              object.stringValue,
              (message.stringValue = $util.newBuffer($util.base64.length(object.stringValue))),
              0,
            )
          else if (object.stringValue.length) message.stringValue = object.stringValue
        if (object.aggregateValue != null) message.aggregateValue = String(object.aggregateValue)
        return message
      }

      /**
       * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.UninterpretedOption
       * @static
       * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      UninterpretedOption.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.name = []
        if (options.defaults) {
          object.identifierValue = ''
          if ($util.Long) {
            let long = new $util.Long(0, 0, true)
            object.positiveIntValue =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long
          } else object.positiveIntValue = options.longs === String ? '0' : 0
          if ($util.Long) {
            let long = new $util.Long(0, 0, false)
            object.negativeIntValue =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long
          } else object.negativeIntValue = options.longs === String ? '0' : 0
          object.doubleValue = 0
          if (options.bytes === String) object.stringValue = ''
          else {
            object.stringValue = []
            if (options.bytes !== Array) object.stringValue = $util.newBuffer(object.stringValue)
          }
          object.aggregateValue = ''
        }
        if (message.name && message.name.length) {
          object.name = []
          for (let j = 0; j < message.name.length; ++j)
            object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options)
        }
        if (message.identifierValue != null && message.hasOwnProperty('identifierValue'))
          object.identifierValue = message.identifierValue
        if (message.positiveIntValue != null && message.hasOwnProperty('positiveIntValue'))
          if (typeof message.positiveIntValue === 'number')
            object.positiveIntValue =
              options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue
          else
            object.positiveIntValue =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.positiveIntValue)
                : options.longs === Number
                ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(
                    true,
                  )
                : message.positiveIntValue
        if (message.negativeIntValue != null && message.hasOwnProperty('negativeIntValue'))
          if (typeof message.negativeIntValue === 'number')
            object.negativeIntValue =
              options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue
          else
            object.negativeIntValue =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.negativeIntValue)
                : options.longs === Number
                ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber()
                : message.negativeIntValue
        if (message.doubleValue != null && message.hasOwnProperty('doubleValue'))
          object.doubleValue =
            options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue
        if (message.stringValue != null && message.hasOwnProperty('stringValue'))
          object.stringValue =
            options.bytes === String
              ? $util.base64.encode(message.stringValue, 0, message.stringValue.length)
              : options.bytes === Array
              ? Array.prototype.slice.call(message.stringValue)
              : message.stringValue
        if (message.aggregateValue != null && message.hasOwnProperty('aggregateValue'))
          object.aggregateValue = message.aggregateValue
        return object
      }

      /**
       * Converts this UninterpretedOption to JSON.
       * @function toJSON
       * @memberof google.protobuf.UninterpretedOption
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      UninterpretedOption.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      UninterpretedOption.NamePart = (function () {
        /**
         * Properties of a NamePart.
         * @memberof google.protobuf.UninterpretedOption
         * @interface INamePart
         * @property {string} namePart NamePart namePart
         * @property {boolean} isExtension NamePart isExtension
         */

        /**
         * Constructs a new NamePart.
         * @memberof google.protobuf.UninterpretedOption
         * @classdesc Represents a NamePart.
         * @implements INamePart
         * @constructor
         * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
         */
        function NamePart(properties) {
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * NamePart namePart.
         * @member {string} namePart
         * @memberof google.protobuf.UninterpretedOption.NamePart
         * @instance
         */
        NamePart.prototype.namePart = ''

        /**
         * NamePart isExtension.
         * @member {boolean} isExtension
         * @memberof google.protobuf.UninterpretedOption.NamePart
         * @instance
         */
        NamePart.prototype.isExtension = false

        /**
         * Creates a new NamePart instance using the specified properties.
         * @function create
         * @memberof google.protobuf.UninterpretedOption.NamePart
         * @static
         * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
         * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
         */
        NamePart.create = function create(properties) {
          return new NamePart(properties)
        }

        /**
         * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
         * @function encode
         * @memberof google.protobuf.UninterpretedOption.NamePart
         * @static
         * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NamePart.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.namePart)
          writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.isExtension)
          return writer
        }

        /**
         * Encodes the specified NamePart message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google.protobuf.UninterpretedOption.NamePart
         * @static
         * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NamePart.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a NamePart message from the specified reader or buffer.
         * @function decode
         * @memberof google.protobuf.UninterpretedOption.NamePart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NamePart.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.google.protobuf.UninterpretedOption.NamePart()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                message.namePart = reader.string()
                break
              case 2:
                message.isExtension = reader.bool()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          if (!message.hasOwnProperty('namePart'))
            throw $util.ProtocolError("missing required 'namePart'", { instance: message })
          if (!message.hasOwnProperty('isExtension'))
            throw $util.ProtocolError("missing required 'isExtension'", { instance: message })
          return message
        }

        /**
         * Decodes a NamePart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google.protobuf.UninterpretedOption.NamePart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NamePart.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a NamePart message.
         * @function verify
         * @memberof google.protobuf.UninterpretedOption.NamePart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NamePart.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (!$util.isString(message.namePart)) return 'namePart: string expected'
          if (typeof message.isExtension !== 'boolean') return 'isExtension: boolean expected'
          return null
        }

        /**
         * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google.protobuf.UninterpretedOption.NamePart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
         */
        NamePart.fromObject = function fromObject(object) {
          if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart) return object
          let message = new $root.google.protobuf.UninterpretedOption.NamePart()
          if (object.namePart != null) message.namePart = String(object.namePart)
          if (object.isExtension != null) message.isExtension = Boolean(object.isExtension)
          return message
        }

        /**
         * Creates a plain object from a NamePart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google.protobuf.UninterpretedOption.NamePart
         * @static
         * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NamePart.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.defaults) {
            object.namePart = ''
            object.isExtension = false
          }
          if (message.namePart != null && message.hasOwnProperty('namePart')) object.namePart = message.namePart
          if (message.isExtension != null && message.hasOwnProperty('isExtension'))
            object.isExtension = message.isExtension
          return object
        }

        /**
         * Converts this NamePart to JSON.
         * @function toJSON
         * @memberof google.protobuf.UninterpretedOption.NamePart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NamePart.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return NamePart
      })()

      return UninterpretedOption
    })()

    protobuf.SourceCodeInfo = (function () {
      /**
       * Properties of a SourceCodeInfo.
       * @memberof google.protobuf
       * @interface ISourceCodeInfo
       * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
       */

      /**
       * Constructs a new SourceCodeInfo.
       * @memberof google.protobuf
       * @classdesc Represents a SourceCodeInfo.
       * @implements ISourceCodeInfo
       * @constructor
       * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
       */
      function SourceCodeInfo(properties) {
        this.location = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * SourceCodeInfo location.
       * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
       * @memberof google.protobuf.SourceCodeInfo
       * @instance
       */
      SourceCodeInfo.prototype.location = $util.emptyArray

      /**
       * Creates a new SourceCodeInfo instance using the specified properties.
       * @function create
       * @memberof google.protobuf.SourceCodeInfo
       * @static
       * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
       * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
       */
      SourceCodeInfo.create = function create(properties) {
        return new SourceCodeInfo(properties)
      }

      /**
       * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.SourceCodeInfo
       * @static
       * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      SourceCodeInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.location != null && message.location.length)
          for (let i = 0; i < message.location.length; ++i)
            $root.google.protobuf.SourceCodeInfo.Location.encode(
              message.location[i],
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified SourceCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.SourceCodeInfo
       * @static
       * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      SourceCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a SourceCodeInfo message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.SourceCodeInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      SourceCodeInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.SourceCodeInfo()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              if (!(message.location && message.location.length)) message.location = []
              message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()))
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a SourceCodeInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.SourceCodeInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      SourceCodeInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a SourceCodeInfo message.
       * @function verify
       * @memberof google.protobuf.SourceCodeInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      SourceCodeInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.location != null && message.hasOwnProperty('location')) {
          if (!Array.isArray(message.location)) return 'location: array expected'
          for (let i = 0; i < message.location.length; ++i) {
            let error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i])
            if (error) return 'location.' + error
          }
        }
        return null
      }

      /**
       * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.SourceCodeInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
       */
      SourceCodeInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.SourceCodeInfo) return object
        let message = new $root.google.protobuf.SourceCodeInfo()
        if (object.location) {
          if (!Array.isArray(object.location))
            throw TypeError('.google.protobuf.SourceCodeInfo.location: array expected')
          message.location = []
          for (let i = 0; i < object.location.length; ++i) {
            if (typeof object.location[i] !== 'object')
              throw TypeError('.google.protobuf.SourceCodeInfo.location: object expected')
            message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i])
          }
        }
        return message
      }

      /**
       * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.SourceCodeInfo
       * @static
       * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      SourceCodeInfo.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.location = []
        if (message.location && message.location.length) {
          object.location = []
          for (let j = 0; j < message.location.length; ++j)
            object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options)
        }
        return object
      }

      /**
       * Converts this SourceCodeInfo to JSON.
       * @function toJSON
       * @memberof google.protobuf.SourceCodeInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      SourceCodeInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      SourceCodeInfo.Location = (function () {
        /**
         * Properties of a Location.
         * @memberof google.protobuf.SourceCodeInfo
         * @interface ILocation
         * @property {Array.<number>|null} [path] Location path
         * @property {Array.<number>|null} [span] Location span
         * @property {string|null} [leadingComments] Location leadingComments
         * @property {string|null} [trailingComments] Location trailingComments
         * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
         */

        /**
         * Constructs a new Location.
         * @memberof google.protobuf.SourceCodeInfo
         * @classdesc Represents a Location.
         * @implements ILocation
         * @constructor
         * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
         */
        function Location(properties) {
          this.path = []
          this.span = []
          this.leadingDetachedComments = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Location path.
         * @member {Array.<number>} path
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @instance
         */
        Location.prototype.path = $util.emptyArray

        /**
         * Location span.
         * @member {Array.<number>} span
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @instance
         */
        Location.prototype.span = $util.emptyArray

        /**
         * Location leadingComments.
         * @member {string} leadingComments
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @instance
         */
        Location.prototype.leadingComments = ''

        /**
         * Location trailingComments.
         * @member {string} trailingComments
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @instance
         */
        Location.prototype.trailingComments = ''

        /**
         * Location leadingDetachedComments.
         * @member {Array.<string>} leadingDetachedComments
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @instance
         */
        Location.prototype.leadingDetachedComments = $util.emptyArray

        /**
         * Creates a new Location instance using the specified properties.
         * @function create
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @static
         * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
         * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
         */
        Location.create = function create(properties) {
          return new Location(properties)
        }

        /**
         * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
         * @function encode
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @static
         * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Location.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.path != null && message.path.length) {
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
            for (let i = 0; i < message.path.length; ++i) writer.int32(message.path[i])
            writer.ldelim()
          }
          if (message.span != null && message.span.length) {
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
            for (let i = 0; i < message.span.length; ++i) writer.int32(message.span[i])
            writer.ldelim()
          }
          if (message.leadingComments != null && Object.hasOwnProperty.call(message, 'leadingComments'))
            writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.leadingComments)
          if (message.trailingComments != null && Object.hasOwnProperty.call(message, 'trailingComments'))
            writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.trailingComments)
          if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
            for (let i = 0; i < message.leadingDetachedComments.length; ++i)
              writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.leadingDetachedComments[i])
          return writer
        }

        /**
         * Encodes the specified Location message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @static
         * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Location.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a Location message from the specified reader or buffer.
         * @function decode
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google.protobuf.SourceCodeInfo.Location} Location
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Location.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.google.protobuf.SourceCodeInfo.Location()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                if (!(message.path && message.path.length)) message.path = []
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos
                  while (reader.pos < end2) message.path.push(reader.int32())
                } else message.path.push(reader.int32())
                break
              case 2:
                if (!(message.span && message.span.length)) message.span = []
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos
                  while (reader.pos < end2) message.span.push(reader.int32())
                } else message.span.push(reader.int32())
                break
              case 3:
                message.leadingComments = reader.string()
                break
              case 4:
                message.trailingComments = reader.string()
                break
              case 6:
                if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                  message.leadingDetachedComments = []
                message.leadingDetachedComments.push(reader.string())
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes a Location message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google.protobuf.SourceCodeInfo.Location} Location
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Location.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a Location message.
         * @function verify
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Location.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.path != null && message.hasOwnProperty('path')) {
            if (!Array.isArray(message.path)) return 'path: array expected'
            for (let i = 0; i < message.path.length; ++i)
              if (!$util.isInteger(message.path[i])) return 'path: integer[] expected'
          }
          if (message.span != null && message.hasOwnProperty('span')) {
            if (!Array.isArray(message.span)) return 'span: array expected'
            for (let i = 0; i < message.span.length; ++i)
              if (!$util.isInteger(message.span[i])) return 'span: integer[] expected'
          }
          if (message.leadingComments != null && message.hasOwnProperty('leadingComments'))
            if (!$util.isString(message.leadingComments)) return 'leadingComments: string expected'
          if (message.trailingComments != null && message.hasOwnProperty('trailingComments'))
            if (!$util.isString(message.trailingComments)) return 'trailingComments: string expected'
          if (message.leadingDetachedComments != null && message.hasOwnProperty('leadingDetachedComments')) {
            if (!Array.isArray(message.leadingDetachedComments)) return 'leadingDetachedComments: array expected'
            for (let i = 0; i < message.leadingDetachedComments.length; ++i)
              if (!$util.isString(message.leadingDetachedComments[i]))
                return 'leadingDetachedComments: string[] expected'
          }
          return null
        }

        /**
         * Creates a Location message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google.protobuf.SourceCodeInfo.Location} Location
         */
        Location.fromObject = function fromObject(object) {
          if (object instanceof $root.google.protobuf.SourceCodeInfo.Location) return object
          let message = new $root.google.protobuf.SourceCodeInfo.Location()
          if (object.path) {
            if (!Array.isArray(object.path))
              throw TypeError('.google.protobuf.SourceCodeInfo.Location.path: array expected')
            message.path = []
            for (let i = 0; i < object.path.length; ++i) message.path[i] = object.path[i] | 0
          }
          if (object.span) {
            if (!Array.isArray(object.span))
              throw TypeError('.google.protobuf.SourceCodeInfo.Location.span: array expected')
            message.span = []
            for (let i = 0; i < object.span.length; ++i) message.span[i] = object.span[i] | 0
          }
          if (object.leadingComments != null) message.leadingComments = String(object.leadingComments)
          if (object.trailingComments != null) message.trailingComments = String(object.trailingComments)
          if (object.leadingDetachedComments) {
            if (!Array.isArray(object.leadingDetachedComments))
              throw TypeError('.google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected')
            message.leadingDetachedComments = []
            for (let i = 0; i < object.leadingDetachedComments.length; ++i)
              message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i])
          }
          return message
        }

        /**
         * Creates a plain object from a Location message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @static
         * @param {google.protobuf.SourceCodeInfo.Location} message Location
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Location.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) {
            object.path = []
            object.span = []
            object.leadingDetachedComments = []
          }
          if (options.defaults) {
            object.leadingComments = ''
            object.trailingComments = ''
          }
          if (message.path && message.path.length) {
            object.path = []
            for (let j = 0; j < message.path.length; ++j) object.path[j] = message.path[j]
          }
          if (message.span && message.span.length) {
            object.span = []
            for (let j = 0; j < message.span.length; ++j) object.span[j] = message.span[j]
          }
          if (message.leadingComments != null && message.hasOwnProperty('leadingComments'))
            object.leadingComments = message.leadingComments
          if (message.trailingComments != null && message.hasOwnProperty('trailingComments'))
            object.trailingComments = message.trailingComments
          if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
            object.leadingDetachedComments = []
            for (let j = 0; j < message.leadingDetachedComments.length; ++j)
              object.leadingDetachedComments[j] = message.leadingDetachedComments[j]
          }
          return object
        }

        /**
         * Converts this Location to JSON.
         * @function toJSON
         * @memberof google.protobuf.SourceCodeInfo.Location
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Location.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return Location
      })()

      return SourceCodeInfo
    })()

    protobuf.GeneratedCodeInfo = (function () {
      /**
       * Properties of a GeneratedCodeInfo.
       * @memberof google.protobuf
       * @interface IGeneratedCodeInfo
       * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
       */

      /**
       * Constructs a new GeneratedCodeInfo.
       * @memberof google.protobuf
       * @classdesc Represents a GeneratedCodeInfo.
       * @implements IGeneratedCodeInfo
       * @constructor
       * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
       */
      function GeneratedCodeInfo(properties) {
        this.annotation = []
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
      }

      /**
       * GeneratedCodeInfo annotation.
       * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
       * @memberof google.protobuf.GeneratedCodeInfo
       * @instance
       */
      GeneratedCodeInfo.prototype.annotation = $util.emptyArray

      /**
       * Creates a new GeneratedCodeInfo instance using the specified properties.
       * @function create
       * @memberof google.protobuf.GeneratedCodeInfo
       * @static
       * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
       * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
       */
      GeneratedCodeInfo.create = function create(properties) {
        return new GeneratedCodeInfo(properties)
      }

      /**
       * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.GeneratedCodeInfo
       * @static
       * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      GeneratedCodeInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create()
        if (message.annotation != null && message.annotation.length)
          for (let i = 0; i < message.annotation.length; ++i)
            $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(
              message.annotation[i],
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
            ).ldelim()
        return writer
      }

      /**
       * Encodes the specified GeneratedCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.GeneratedCodeInfo
       * @static
       * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      GeneratedCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
      }

      /**
       * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.GeneratedCodeInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      GeneratedCodeInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.GeneratedCodeInfo()
        while (reader.pos < end) {
          let tag = reader.uint32()
          switch (tag >>> 3) {
            case 1:
              if (!(message.annotation && message.annotation.length)) message.annotation = []
              message.annotation.push(
                $root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()),
              )
              break
            default:
              reader.skipType(tag & 7)
              break
          }
        }
        return message
      }

      /**
       * Decodes a GeneratedCodeInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.GeneratedCodeInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      GeneratedCodeInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
      }

      /**
       * Verifies a GeneratedCodeInfo message.
       * @function verify
       * @memberof google.protobuf.GeneratedCodeInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      GeneratedCodeInfo.verify = function verify(message) {
        if (typeof message !== 'object' || message === null) return 'object expected'
        if (message.annotation != null && message.hasOwnProperty('annotation')) {
          if (!Array.isArray(message.annotation)) return 'annotation: array expected'
          for (let i = 0; i < message.annotation.length; ++i) {
            let error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i])
            if (error) return 'annotation.' + error
          }
        }
        return null
      }

      /**
       * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.GeneratedCodeInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
       */
      GeneratedCodeInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.GeneratedCodeInfo) return object
        let message = new $root.google.protobuf.GeneratedCodeInfo()
        if (object.annotation) {
          if (!Array.isArray(object.annotation))
            throw TypeError('.google.protobuf.GeneratedCodeInfo.annotation: array expected')
          message.annotation = []
          for (let i = 0; i < object.annotation.length; ++i) {
            if (typeof object.annotation[i] !== 'object')
              throw TypeError('.google.protobuf.GeneratedCodeInfo.annotation: object expected')
            message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i])
          }
        }
        return message
      }

      /**
       * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.GeneratedCodeInfo
       * @static
       * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      GeneratedCodeInfo.toObject = function toObject(message, options) {
        if (!options) options = {}
        let object = {}
        if (options.arrays || options.defaults) object.annotation = []
        if (message.annotation && message.annotation.length) {
          object.annotation = []
          for (let j = 0; j < message.annotation.length; ++j)
            object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(
              message.annotation[j],
              options,
            )
        }
        return object
      }

      /**
       * Converts this GeneratedCodeInfo to JSON.
       * @function toJSON
       * @memberof google.protobuf.GeneratedCodeInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      GeneratedCodeInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
      }

      GeneratedCodeInfo.Annotation = (function () {
        /**
         * Properties of an Annotation.
         * @memberof google.protobuf.GeneratedCodeInfo
         * @interface IAnnotation
         * @property {Array.<number>|null} [path] Annotation path
         * @property {string|null} [sourceFile] Annotation sourceFile
         * @property {number|null} [begin] Annotation begin
         * @property {number|null} [end] Annotation end
         */

        /**
         * Constructs a new Annotation.
         * @memberof google.protobuf.GeneratedCodeInfo
         * @classdesc Represents an Annotation.
         * @implements IAnnotation
         * @constructor
         * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
         */
        function Annotation(properties) {
          this.path = []
          if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
              if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * Annotation path.
         * @member {Array.<number>} path
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @instance
         */
        Annotation.prototype.path = $util.emptyArray

        /**
         * Annotation sourceFile.
         * @member {string} sourceFile
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @instance
         */
        Annotation.prototype.sourceFile = ''

        /**
         * Annotation begin.
         * @member {number} begin
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @instance
         */
        Annotation.prototype.begin = 0

        /**
         * Annotation end.
         * @member {number} end
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @instance
         */
        Annotation.prototype.end = 0

        /**
         * Creates a new Annotation instance using the specified properties.
         * @function create
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @static
         * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
         * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
         */
        Annotation.create = function create(properties) {
          return new Annotation(properties)
        }

        /**
         * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
         * @function encode
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @static
         * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Annotation.encode = function encode(message, writer) {
          if (!writer) writer = $Writer.create()
          if (message.path != null && message.path.length) {
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
            for (let i = 0; i < message.path.length; ++i) writer.int32(message.path[i])
            writer.ldelim()
          }
          if (message.sourceFile != null && Object.hasOwnProperty.call(message, 'sourceFile'))
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.sourceFile)
          if (message.begin != null && Object.hasOwnProperty.call(message, 'begin'))
            writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.begin)
          if (message.end != null && Object.hasOwnProperty.call(message, 'end'))
            writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.end)
          return writer
        }

        /**
         * Encodes the specified Annotation message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @static
         * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Annotation.encodeDelimited = function encodeDelimited(message, writer) {
          return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes an Annotation message from the specified reader or buffer.
         * @function decode
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Annotation.decode = function decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
          let end = length === undefined ? reader.len : reader.pos + length,
            message = new $root.google.protobuf.GeneratedCodeInfo.Annotation()
          while (reader.pos < end) {
            let tag = reader.uint32()
            switch (tag >>> 3) {
              case 1:
                if (!(message.path && message.path.length)) message.path = []
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos
                  while (reader.pos < end2) message.path.push(reader.int32())
                } else message.path.push(reader.int32())
                break
              case 2:
                message.sourceFile = reader.string()
                break
              case 3:
                message.begin = reader.int32()
                break
              case 4:
                message.end = reader.int32()
                break
              default:
                reader.skipType(tag & 7)
                break
            }
          }
          return message
        }

        /**
         * Decodes an Annotation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Annotation.decodeDelimited = function decodeDelimited(reader) {
          if (!(reader instanceof $Reader)) reader = new $Reader(reader)
          return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies an Annotation message.
         * @function verify
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Annotation.verify = function verify(message) {
          if (typeof message !== 'object' || message === null) return 'object expected'
          if (message.path != null && message.hasOwnProperty('path')) {
            if (!Array.isArray(message.path)) return 'path: array expected'
            for (let i = 0; i < message.path.length; ++i)
              if (!$util.isInteger(message.path[i])) return 'path: integer[] expected'
          }
          if (message.sourceFile != null && message.hasOwnProperty('sourceFile'))
            if (!$util.isString(message.sourceFile)) return 'sourceFile: string expected'
          if (message.begin != null && message.hasOwnProperty('begin'))
            if (!$util.isInteger(message.begin)) return 'begin: integer expected'
          if (message.end != null && message.hasOwnProperty('end'))
            if (!$util.isInteger(message.end)) return 'end: integer expected'
          return null
        }

        /**
         * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
         */
        Annotation.fromObject = function fromObject(object) {
          if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation) return object
          let message = new $root.google.protobuf.GeneratedCodeInfo.Annotation()
          if (object.path) {
            if (!Array.isArray(object.path))
              throw TypeError('.google.protobuf.GeneratedCodeInfo.Annotation.path: array expected')
            message.path = []
            for (let i = 0; i < object.path.length; ++i) message.path[i] = object.path[i] | 0
          }
          if (object.sourceFile != null) message.sourceFile = String(object.sourceFile)
          if (object.begin != null) message.begin = object.begin | 0
          if (object.end != null) message.end = object.end | 0
          return message
        }

        /**
         * Creates a plain object from an Annotation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @static
         * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Annotation.toObject = function toObject(message, options) {
          if (!options) options = {}
          let object = {}
          if (options.arrays || options.defaults) object.path = []
          if (options.defaults) {
            object.sourceFile = ''
            object.begin = 0
            object.end = 0
          }
          if (message.path && message.path.length) {
            object.path = []
            for (let j = 0; j < message.path.length; ++j) object.path[j] = message.path[j]
          }
          if (message.sourceFile != null && message.hasOwnProperty('sourceFile')) object.sourceFile = message.sourceFile
          if (message.begin != null && message.hasOwnProperty('begin')) object.begin = message.begin
          if (message.end != null && message.hasOwnProperty('end')) object.end = message.end
          return object
        }

        /**
         * Converts this Annotation to JSON.
         * @function toJSON
         * @memberof google.protobuf.GeneratedCodeInfo.Annotation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Annotation.prototype.toJSON = function toJSON() {
          return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        return Annotation
      })()

      return GeneratedCodeInfo
    })()

    return protobuf
  })()

  return google
})())
