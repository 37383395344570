import React from 'react'
import { IDataListColumn } from 'components/DataList/DataList'
import Layout from 'pages/Layout'
import { Chain, useChain } from 'lib/hooks/chain/context'
import Reflection from './reflection'
import Convexity from './convexity'

const Dashboard = () => {
  const { chainMode } = useChain()

  const depositsColumns: IDataListColumn[] = [
    { title: 'Market', size: 'flex', sortable: true, sortLabel: 'symbol' },
    { title: 'Supplied', size: 'flex', jusitfy: 'end', sortable: true, sortLabel: 'balanceUsd' },
    { title: `${chainMode === Chain.cosmos ? 'APR' : 'APY'}`, size: 'xsmall', jusitfy: 'end', sortable: true, sortLabel: 'apy' },
    { title: '', size: 'flex' },
  ]

  const availableMarketsColumns: IDataListColumn[] = [
    { title: 'Market', size: 'flex', sortable: true, sortLabel: 'symbol' },
    {
      title: 'Available',
      size: 'flex',
      tooltip: 'Assets available for you to supply and borrow based on your balances',
      jusitfy: 'end',
      sortable: true,
      sortLabel: 'balance'
    },
    { title: `${chainMode === Chain.cosmos ? 'APR' : 'APY'}`, size: 'xsmall', jusitfy: 'end', sortable: true, sortLabel: 'apy' },
    { title: '', size: 'flex' },
  ]

  const borrowsColumns: IDataListColumn[] = [
    { title: 'Market', size: 'flex', sortable: true, sortLabel: 'symbol' },
    { title: 'Borrowed', size: 'flex', jusitfy: 'end', sortable: true, sortLabel: 'balanceUsd' },
    { title: `${chainMode === Chain.cosmos ? 'APR' : 'APY'}`, size: 'xsmall', jusitfy: 'end', sortable: true, sortLabel: 'apy' },
    { title: '', size: 'flex' },
  ]

  const Comp = chainMode === Chain.ethereum ? Reflection : Convexity

  return (
    <Layout title="Dashboard" subtitle="Your portfolio of Umee assets">
      <Comp
        availableMarketsColumns={availableMarketsColumns}
        depositsColumns={depositsColumns}
        borrowsColumns={borrowsColumns}
      />
    </Layout>
  )
}

export default Dashboard
