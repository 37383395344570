import { IDataListColumn } from 'components/DataList/DataList'
import { BigNumber } from 'ethers'

export interface IMarketsData {
  name: string
  address: string
  marketSize?: string
  totalBorrowed?: string
  marketSizeUsd: string
  totalBorrowedUsd: string
  depositAPY: BigNumber | string
  variableBorrowAPR: BigNumber | string
  ltv: BigNumber
  chain?: string
  logo?: string
}

export interface MarketsDataListProps {
  columns: IDataListColumn[]
  data?: IMarketsData[]
  chainType: string
}

export type ModalType = 'menu' | 'markets' | 'bridge' | 'ibc' | 'axelar'

export type AssetType = 'collateral' | 'leverage'

export { default as MarketsDataListDesktop } from './MarketsDataListDesktop'

export { default as MarketsDataListMobile } from './MarketsDataListMobile'