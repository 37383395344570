import { ChainGetter, QueriesSetBase } from '@keplr-wallet/stores';
import { KVStore } from '@keplr-wallet/common';

export interface UmeeQueries {
	umee?: UmeeQueriesImpl;
}

export const UmeeQueries = {
  use(
    umeeChainId: string,
  ): (queriesSetBase: QueriesSetBase, kvStore: KVStore, chainId: string, chainGetter: ChainGetter) => UmeeQueries {
    return (queriesSetBase: QueriesSetBase, kvStore: KVStore, chainId: string, chainGetter: ChainGetter) => {
      return {
        umee:
          chainId === umeeChainId ? new UmeeQueriesImpl(queriesSetBase, kvStore, chainId, chainGetter) : undefined,
      }
    }
  },
}

export class UmeeQueriesImpl {
  constructor(queries: QueriesSetBase, kvStore: KVStore, chainId: string, chainGetter: ChainGetter) {}
}
