import React, { useMemo } from 'react'
import { Text } from 'grommet'

const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi

const ProposalDescription = ({ description }: { description: string }) => {
  const descriptions = useMemo(
    () =>
      description
        .replace(/\/n/g, '\n')
        .split(/\\n/)
        .join('\n')
        .split('\n')
        .map((text) => text.split(urlRegex)),
    [description],
  )

  return (
    <>
      {descriptions.map((texts, index) =>
        texts.length === 1 && !texts[0] ? (
          <br key={`row${index}`} />
        ) : (
          <Text key={`row${index}`} size="small" color="clrTextPrimary">
            {texts.map(
              (text, index1) =>
                text !== 'https' &&
                (text.match(urlRegex) ? (
                  <span key={`text${index1}`} className="hyper-link" onClick={() => window.open(text)}>
                    {text}
                  </span>
                ) : (
                  text
                )),
            )}
          </Text>
        ),
      )}
    </>
  )
}

export default ProposalDescription
