import React, { useState, useMemo } from 'react'
import { BigNumber } from 'ethers'
import { bigNumberToString } from 'lib/number-utils'
import { Box, Text } from 'grommet'
import { Chain } from 'lib/hooks/chain/context'
import { ETxnType } from 'lib/types'
import GradientBox from 'components/common/GradientBox/GradientBox'
import TransactionModal from 'components/TransactionsMobile/Transactions'
import { AssetCard } from 'components/AssetCard/AssetCard'
import orderBy from 'lodash/fp/orderBy'
import BottomMenus from 'components/common/BottomMenu/BottomMenus'
import { CollateralContent } from 'components/DataList/Collateral'
import { SkeletonBar } from 'components/Skeleton'
import { MarketsDataListProps, ModalType } from './MarketsDataList'

const aprDecimals = BigNumber.from(25)

const MarketsDataListMobile: React.FC<MarketsDataListProps> = ({ columns, data, chainType }) => {
  const [tokenAddress, setTokenAddress] = useState<string>('')
  const [tokenName, setTokenName] = useState<string>('')
  const [tokenLogo, setTokenLogo] = useState<string>('')
  const [isModalShow, setIsModalShow] = useState<ModalType | null>(null)
  const [clickedTxnType, setClickedTxnType] = useState<ETxnType | undefined>()
  const [availableMarketSort, setAvailableMarketSort] = useState<'popular' | 'apr' | 'collateral' | 'leverage'>(
    'popular',
  )

  const collateralCount = useMemo(() => {
    if (data) {
      const collaterals = data.filter((token) => !token.ltv.isZero())
      return collaterals.length
    }
  }, [data])

  const leverageCount = useMemo(() => {
    if (data) {
      const leverages = data.filter((token) => token.ltv.isZero())
      return leverages.length
    }
  }, [data])

  const setModalInfo = (name: string, address: any, modal: ModalType, logo?: string) => {
    setTokenAddress(address)
    setTokenName(name)
    setIsModalShow(modal)
    setTokenLogo(logo || '')
  }

  const closeModal = () => setIsModalShow(null)

  return (
    <>
      <BottomMenus
        open={isModalShow === 'menu'}
        symbol={tokenName}
        items={[
          ETxnType.transfer,
          ETxnType.deposit,
          ETxnType.withdraw,
          ETxnType.borrow,
          ETxnType.repay,
          ETxnType.markets,
        ]}
        setOpenSheet={closeModal}
        setSelectedMenu={setClickedTxnType}
      />
      <TransactionModal
        tokenAddress={tokenAddress}
        symbol={tokenName}
        logo={tokenLogo}
        txnType={clickedTxnType}
        onClose={() => setClickedTxnType(undefined)}
      />
      <Text
        alignSelf="center"
        margin={{ top: 'small', bottom: 'small' }}
        className="font-moret"
        color={'clrTextPrimary'}
        size="medium1"
      >
        Available Assets
      </Text>

      <Box margin={{ top: 'small', bottom: 'small' }} className="markets-filter-list">
        <GradientBox
          margin={{ right: 'small', bottom: 'small' }}
          selected={availableMarketSort === 'popular'}
          onClick={() => setAvailableMarketSort('popular')}
          filter
        >
          <Text size="small" color={'clrTextPrimary'}>
            Most Popular
          </Text>
        </GradientBox>
        <GradientBox
          margin={{ bottom: 'small', right: 'small' }}
          selected={availableMarketSort === 'apr'}
          onClick={() => setAvailableMarketSort('apr')}
          filter
        >
          <Text size="small" color={'clrTextPrimary'}>
            Highest Supply APR
          </Text>
        </GradientBox>
        <GradientBox
          onClick={() => setAvailableMarketSort('collateral')}
          margin={{ right: 'small', bottom: 'small' }}
          selected={availableMarketSort === 'collateral'}
          filter
        >
          <CollateralContent collateral count={collateralCount} textSize="small" color={'clrTextPrimary'} />
        </GradientBox>
        <GradientBox
          onClick={() => setAvailableMarketSort('leverage')}
          margin={{ bottom: 'small' }}
          selected={availableMarketSort === 'leverage'}
          filter
        >
          <CollateralContent collateral={false} count={leverageCount} textSize="small" color={'clrTextPrimary'} />
        </GradientBox>
      </Box>

      {!data ? (
        <Box margin={{ top: 'small' }}>
          <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
          <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
          <SkeletonBar width='100%' margin={{ vertical: 'small' }} height='1em' />
        </Box>
      ) : (availableMarketSort === 'popular'
        ? data
        : orderBy(
          ['depositAPY'],
          ['desc'],
        )(
          data.map((x) => ({
            ...x,
            depositAPY: Number(x.depositAPY).toFixed(2),
          })),
        )
      ).map(
        ({ name, address, depositAPY, variableBorrowAPR, ltv, chain, logo }: any) => {
          const borrowApy =
            variableBorrowAPR &&
            (chainType == Chain.ethereum ? bigNumberToString(variableBorrowAPR, aprDecimals) : variableBorrowAPR)

          if (
            (availableMarketSort === 'collateral' && ltv.isZero()) ||
            (availableMarketSort === 'leverage' && !ltv.isZero())
          ) {
            return null
          }

          return (
            <Box className="asset-card" key={`row-${name}`}>
              <AssetCard
                onClick={() => setModalInfo(name, address, 'menu', logo)}
                symbol={name}
                chainName={chain}
                logo={logo}
                noGradient={false}
                collateral={!ltv.isZero()}
                values={[
                  { label: 'supply', value: depositAPY + '%', color: 'clrSupplyText' },
                  { label: 'borrow', value: borrowApy + '%', color: 'clrBorrowText' },
                ]}
              />
            </Box>
          )
        },
      )}
    </>
  )
}

export default MarketsDataListMobile
