import React from 'react'
import styled from '@emotion/styled'
import { ProposalType } from 'api/stake/data'
import { Text } from 'grommet'

interface ProposalAvatarProps {
  title: string
  status: ProposalType
}

const Circle = styled.div`
  border-radius: 100%;
  min-width: 1.7em;
  height: 1.7em;
  text-align: center;
  display: flex;
`

const colors = {
  [ProposalType.VOTING_PERIOD]: '#dfb2ff', // active
  [ProposalType.FAILED]: '#dc3412',
  [ProposalType.PASSED]: '#5fc23c',
  [ProposalType.REJECTED]: 'red'
}

const ProposalAvatar: React.FC<ProposalAvatarProps> = ({ title, status }) => (
  <Circle style={{ backgroundColor: (colors as any)[status] }}>
    <Text size='xsmall' color='clrTextPrimary' style={{ margin: 'auto'}}>
      {title}
    </Text>
  </Circle>
)

export default ProposalAvatar
