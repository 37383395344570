import { Bech32Address } from '@keplr-wallet/cosmos'
import { ChainInfoWithExplorer } from './api/cosmosStores/chain'
import { makeIBCMinimalDenom } from './utils/ibc'

const ibcDenom = makeIBCMinimalDenom('channel-1', 'uatom')

interface Chains {
  [key: string]: string
}

export const Chains: Chains = {
  UMEE: 'canon-2',
  ATOM: 'cosmoshub-4',
}

/**
 * Determine the channel info per the chain.
 * Guide users to use the same channel for convenience.
 */
export const IBCAssetInfos: {
  counterpartyChainId: string
  // Souce channel id based on the Umee chain
  sourceChannelId: string
  // Destination channel id from Umee chain
  destChannelId: string
  counterpartyCoinMinimalDenom: string
  coinMinimalDenom: string
}[] = [
  {
    counterpartyChainId: 'cosmoshub-4',
    sourceChannelId: 'channel-1',
    destChannelId: 'channel-288',
    counterpartyCoinMinimalDenom: 'uatom',
    coinMinimalDenom: ibcDenom,
  },
]

export const EmbedChainInfos: ChainInfoWithExplorer[] = [
  {
    rpc: 'https://rpc.ruby.canon-2.network.umee.cc/',
    rest: 'https://api.ruby.canon-2.network.umee.cc/',
    chainId: 'canon-2',
    chainName: 'Canon-2',
    stakeCurrency: {
      coinDenom: 'UMEE',
      coinMinimalDenom: 'uumee',
      coinDecimals: 6,
    },
    bip44: { coinType: 118 },
    bech32Config: Bech32Address.defaultBech32Config('umee'),
    currencies: [
      {
        coinDenom: 'UMEE',
        coinMinimalDenom: 'uumee',
        coinDecimals: 6,
      },
      {
        coinDenom: 'ATOM',
        coinMinimalDenom: ibcDenom,
        coinDecimals: 6,
        coinGeckoId: 'cosmos',
      },
    ],
    feeCurrencies: [
      {
        coinDenom: 'UMEE',
        coinMinimalDenom: 'uumee',
        coinDecimals: 6,
      },
    ],
    coinType: 118,
    features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go'],
    explorerUrlToTx: 'https://explorer.network.umee.cc/canon-2/tx/{txHash}',
  },
  {
    rpc: 'https://rpc-cosmoshub.keplr.app',
    rest: 'https://lcd-cosmoshub.keplr.app',
    chainId: 'cosmoshub-4',
    chainName: 'Cosmos Hub',
    stakeCurrency: {
      coinDenom: 'ATOM',
      coinMinimalDenom: 'uatom',
      coinDecimals: 6,
      coinGeckoId: 'cosmos',
    },
    bip44: {
      coinType: 118,
    },
    bech32Config: Bech32Address.defaultBech32Config('cosmos'),
    currencies: [
      {
        coinDenom: 'ATOM',
        coinMinimalDenom: 'uatom',
        coinDecimals: 6,
        coinGeckoId: 'cosmos',
      },
    ],
    feeCurrencies: [
      {
        coinDenom: 'ATOM',
        coinMinimalDenom: 'uatom',
        coinDecimals: 6,
        coinGeckoId: 'cosmos',
      },
    ],
    coinType: 118,
    features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go'],
    explorerUrlToTx: 'https://www.mintscan.io/cosmos/txs/{txHash}',
  },
  {
    rpc: 'https://rpc.barnacle.mainnet.network.umee.cc/',
    rest: 'https://api.barnacle.mainnet.network.umee.cc/',
    chainId: 'umee-1',
    chainName: 'Umee',
    stakeCurrency: {
      coinDenom: 'UMEE',
      coinMinimalDenom: 'uumee',
      coinDecimals: 6,
    },
    bip44: { coinType: 118 },
    bech32Config: Bech32Address.defaultBech32Config('umee'),
    currencies: [
      {
        coinDenom: 'UMEE',
        coinMinimalDenom: 'uumee',
        coinDecimals: 6,
      },
    ],
    feeCurrencies: [
      {
        coinDenom: 'UMEE',
        coinMinimalDenom: 'uumee',
        coinDecimals: 6,
      },
    ],
    coinType: 118,
    features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go'],
    explorerUrlToTx: 'https://www.mintscan.io/umee/txs/{txHash}',
  },
]
