import { BridgeChain } from 'components/Axelar/BridgeDialog'
import { Chain } from './hooks/chain/context'

import EthLogo from '../public/images/chains/ethereum.png'
import OsmoLogo from '../public/images/chains/osmosis.logo.svg'
import CrescentLogo from '../public/images/chains/crescent.logo.svg'
import UmeeLogo from '../public/images/Logo.svg'

export const SMALL_SYMBOL_HW_PX = '15px'
export const GREATER_THAN_ZERO_MESSAGE = 'Please enter a value greater than zero.'
export const TXN_OVERVIEW_WIDTH_RATIO = '80%'
export const TXN_PROCESS_BORDER_ROUND = 'xsmall' // used to keep consistency between the tabs and the wrapper

export const Chains: BridgeChain[] = [
  { logo: UmeeLogo, name: 'Umee', chainId: '', type: Chain.cosmos },
  { logo: OsmoLogo, name: 'Osmosis', chainId: '', type: Chain.cosmos },
  { logo: CrescentLogo, name: 'Crescent Hub', chainId: '', type: Chain.cosmos },
  { logo: EthLogo, name: 'Ethereum', chainId: '', type: Chain.ethereum },
]
