import React, { useCallback, useState, useMemo, useContext } from 'react'
import { Operator, Validator } from 'api/stake/data'
import { IDataListColumn } from 'components/DataList/DataList'
import { DataList, DataListRow, PrimaryText, TextItem } from 'components/DataList'
import ValidatorLogo from './ValidatorLogo'
import { usePaginate } from 'lib/hooks/usePaginate'
import { Box, ResponsiveContext, Text } from 'grommet'
import ShowMore from 'components/ShowMore'
import { useStake } from 'api/stake'
import { useSort } from 'lib/hooks/sort/useSort'
import { SortOption } from 'lib/hooks/sort/types'
import { SecondaryBtn } from 'components/common'
import { SkeletonBar } from 'components/Skeleton'
import DelegateDesktop from './Modals/DelegateDesktop'
import ValidatorDetailDesktop from './Modals/DetailDesktop'
import { AssetCard } from 'components/AssetCard/AssetCard'
import GradientBox from 'components/common/GradientBox/GradientBox'
import truncate from 'lib/truncate'
import { fixedLocaleString } from 'lib/number-utils'
import { useAccountConnection } from 'api/cosmosStores/account-init-management'

interface ValidatorListProps {
  activeColumns: IDataListColumn[]
  activeValidators?: Validator[]
  setSelectedValidator: (validator: Validator) => void
  selectedValidator?: Validator
}

export const restakePossible = (validatorAddress: string, operators: Operator[]) => {
  return !!operators.find((op) => op.address === validatorAddress)
}

const ValidatorList: React.FC<ValidatorListProps> = ({
  activeColumns,
  activeValidators,
  setSelectedValidator,
  selectedValidator,
}) => {
  const [isOpenDelegate, setIsOpenDelegate] = useState<boolean>(false)
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false)
  const [validatorSort, setValidatorSort] = useState<number>(2)
  const columnSizes = activeColumns.map((col) => col.size)
  const { isShowMore, filter, goToNext } = usePaginate(activeValidators ? activeValidators.length : 0, 10, 1)
  const { TotalBonded, Operators } = useStake()
  const size = useContext(ResponsiveContext)
  const { isAccountConnected } = useAccountConnection()

  const getCustomSortValue = useMemo(
    () => ({
      'auto-compound': (validator: Validator) => {
        if (Operators) {
          return restakePossible(validator.operator_address, Operators)
        } else {
          return false
        }
      },
      'compound-apy': (validator: Validator) => {
        if (Operators === undefined || !restakePossible(validator.operator_address, Operators)) {
          return 0
        } else {
          return validator.restake_apy
        }
      },
    }),
    [Operators],
  )

  const { sortedData, sortDirection, sortKey, handleSortChange } = useSort({
    data: activeValidators || [],
    sortOptions: activeColumns,
    getCustomSortValue,
  })

  const voting_power = useCallback(
    (tokens) => {
      return TotalBonded.toNumber() > 0 ? (tokens / TotalBonded.toNumber()) * 100 : 0
    },
    [TotalBonded],
  )

  return (
    <Box>
      {isOpenDelegate && selectedValidator && (
        <DelegateDesktop
          validator={selectedValidator}
          onClose={() => {
            setIsOpenDelegate(false)
          }}
        />
      )}

      {isOpenDetail && selectedValidator && (
        <ValidatorDetailDesktop
          validator={selectedValidator}
          onClose={() => {
            setIsOpenDetail(false)
          }}
          onMenuClick={(_type, validator) => {
            setIsOpenDelegate(true)
            setSelectedValidator(validator)
          }}
          autoCompound={() => {}}
        />
      )}

      {size === 'small' && (
        <>
          <Text
            alignSelf="center"
            size="medium1"
            className="font-moret"
            color="clrTextPrimary"
            margin={{ bottom: 'medium' }}
          >
            Validators
          </Text>
          <Box margin={{ bottom: 'medium' }} flex direction="row">
            {[2, 1].map((status) => (
              <GradientBox
                margin={{ right: 'small', bottom: 'small' }}
                selected={status === validatorSort}
                onClick={() => {
                  setValidatorSort(status)
                  handleSortChange({ sortLabel: activeColumns[status].sortLabel } as SortOption<Validator>, 'desc')
                }}
                flex={{ grow: 1 }}
                align="center"
                key={status}
                filter
              >
                <Text size="small" color="clrTextPrimary">
                  {status === 1 ? 'Highest Annual APY' : 'Highest Voting Power'}
                </Text>
              </GradientBox>
            ))}
          </Box>
        </>
      )}

      {activeValidators === undefined ? (
        <DataList<Validator> columns={activeColumns}>
          <SkeletonBar width="100%" margin={{ top: 'medium', bottom: 'small' }} height="1em" />
          <SkeletonBar width="100%" margin={{ vertical: 'small' }} height="1em" />
          <SkeletonBar width="100%" margin={{ vertical: 'small' }} height="1em" />
          <SkeletonBar width="100%" margin={{ vertical: 'small' }} height="1em" />
        </DataList>
      ) : (
        sortedData &&
        sortedData.length > 0 && (
          <DataList<Validator>
            columns={activeColumns}
            sortDirection={sortDirection}
            sortKey={sortKey}
            handleSortChange={(sort: string) => handleSortChange({ sortLabel: sort } as SortOption<Validator>)}
          >
            {sortedData
              .filter((_, index) => filter(index))
              .map((validator, index) =>
                size === 'small' ? (
                  <ValidatorMobile
                    key={index}
                    validator={validator}
                    voting_power={voting_power(validator.tokens).toFixed(2)}
                    setSelectedValidator={setSelectedValidator}
                  />
                ) : (
                  <DataListRow
                    key={index}
                    columnSizes={columnSizes}
                    select={() => {
                      setIsOpenDetail(true)
                      setSelectedValidator(validator)
                    }}
                  >
                    <TextItem justify="start">
                      <ValidatorLogo isList title={validator.moniker} imgUrl={validator.img_url} />
                    </TextItem>
                    <TextItem justify="end" pad={{ right: '1.8em' }}>
                      <PrimaryText color="clrTextPrimary" size="small">
                        {validator.apr}%
                      </PrimaryText>
                    </TextItem>
                    <TextItem justify="end" pad={{ right: '1.8em' }}>
                      <PrimaryText color="clrTextPrimary" size="small">
                        {voting_power(validator.tokens).toFixed(2)}%
                      </PrimaryText>
                    </TextItem>
                    <TextItem justify="end" pad={{ right: '1.8em' }}>
                      <PrimaryText color="clrTextPrimary">
                        {Operators === undefined ? (
                          <SkeletonBar width="2em" />
                        ) : restakePossible(validator.operator_address, Operators) ? (
                          'Yes'
                        ) : (
                          'No'
                        )}
                      </PrimaryText>
                    </TextItem>
                    <TextItem justify="end" pad={{ right: '1.8em' }}>
                      <PrimaryText color="clrTextPrimary" size="small">
                        {Operators === undefined || !restakePossible(validator.operator_address, Operators)
                          ? '-'
                          : validator.restake_apy + '%'}
                      </PrimaryText>
                    </TextItem>
                    <TextItem justify="center">
                      <SecondaryBtn
                        text="DELEGATE"
                        round="large"
                        pad={{ vertical: 'small', horizontal: 'small' }}
                        textSize="xsmall"
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsOpenDelegate(true)
                          setSelectedValidator(validator)
                        }}
                      />
                    </TextItem>
                  </DataListRow>
                ),
              )}
          </DataList>
        )
      )}
      <Box margin={{ top: 'large' }}>
        <ShowMore isShow={isShowMore} onClick={() => goToNext()} />
      </Box>
    </Box>
  )
}

const ValidatorMobile = ({
  validator,
  voting_power,
  setSelectedValidator,
}: {
  validator: Validator
  voting_power: string
  setSelectedValidator: (validator: Validator) => void
}) => (
  <Box className="asset-card">
    <AssetCard
      symbol={validator.moniker.length > 10 ? truncate(validator.moniker, 6, 5) : validator.moniker}
      noGradient={true}
      validator
      logo={validator.img_url}
      chainName={`Rank: ${validator.rank}`}
      onClick={() => setSelectedValidator(validator)}
      values={[
        { label: 'Annual APY', value: `${validator.apr}%` },
        { label: 'Voting Power', value: `${voting_power}%` },
      ]}
    />
  </Box>
)

export default ValidatorList
