import React, { createContext } from 'react'
import { useStore } from 'api/cosmosStores'
import { BigNumber } from 'ethers'
import { observer } from 'mobx-react-lite'
import { useProposals, useTotalBonded } from './proposals'
import { useAPR, useDelegations, useRewards, useTotalStaked, useValidators } from './staking'
import { Delegation, DelegatorReward, Rewards } from '@keplr-wallet/stores/build/query/cosmos/staking/types'
import { useGrants, useOperators } from './grants'
import { ActiveDelegation } from 'pages/Stake/components/DelegationList'

export enum VotedType {
  YES = '1',
  ABSTAIN = '2',
  NO = '3',
  NOWITHVETO = '4',
  NEVER = '5',
}

export enum PassedProposalVotedType {
  YES = 'VOTE_OPTION_YES',
  ABSTAIN = 'VOTE_OPTION_ABSTAIN',
  NO = 'VOTE_OPTION_NO',
  NOWITHVETO = 'VOTE_OPTION_NO_WITH_VETO',
}

export type VotedResult = {
  [key in VotedType]: string
}

export const VoteResultStatus: VotedResult = {
  1: 'Yes',
  2: 'Abstain',
  3: 'No',
  4: 'NoWithVeto',
  5: "Didn't Vote",
}

export const VoteResultColor: VotedResult = {
  1: '#4EFFE5',
  2: '#A0A1BA',
  3: '#DFB2FF',
  4: '#FF0000',
  5: '#A0A1BA',
}

export enum ProposalType {
  DEPOSIT_PERIOD = 'PROPOSAL_STATUS_DEPOSIT_PERIOD',
  VOTING_PERIOD = 'PROPOSAL_STATUS_VOTING_PERIOD',
  PASSED = 'PROPOSAL_STATUS_PASSED',
  REJECTED = 'PROPOSAL_STATUS_REJECTED',
  FAILED = 'PROPOSAL_STATUS_FAILED',
}

type Proposal = {
  [key in ProposalType]: string
}

export const ProposalDataStatus: Proposal = {
  PROPOSAL_STATUS_DEPOSIT_PERIOD: 'Deposit',
  PROPOSAL_STATUS_VOTING_PERIOD: 'Voting',
  PROPOSAL_STATUS_PASSED: 'Passed',
  PROPOSAL_STATUS_REJECTED: 'Rejected',
  PROPOSAL_STATUS_FAILED: 'Failed',
}

export const ProposalStatusColor: Proposal = {
  PROPOSAL_STATUS_DEPOSIT_PERIOD: '',
  PROPOSAL_STATUS_VOTING_PERIOD: '',
  PROPOSAL_STATUS_PASSED: '#4EFFE5',
  PROPOSAL_STATUS_REJECTED: '#FF0000',
  PROPOSAL_STATUS_FAILED: '#FF0000',
}

export interface TallyResult {
  label: VotedType
  value: string
}

export interface TallyType {
  [key: string]: string
}

export interface ProposalData {
  proposal_id: number
  title: string
  description: string
  status: ProposalType
  final_tally_result: TallyResult[]
  total_vote: BigNumber
  submit_time: string
  deposit_end_time: string
  total_deposit: BigNumber
  voting_start_time: string
  voting_end_time: string
  voted?: VotedType
}

export interface Restake {
  address: string
  run_time: string | string[]
  minimum_reward: number
}

export interface Validator {
  address: string
  operator_address: string
  tokens: number
  moniker: string
  start_height: number
  commission_rates: {
    rate: string
    max_rate: string
    max_change_rate: string
  }
  last_commission_update: string
  description: string
  website: string
  status: number
  img_url?: string
  rank: number
  restake?: Restake
  apr: string
  apy: string
  compound_period: string
  restake_apy: string
  voting_power: string
}

export interface Operator {
  name: string
  address: string
  restake: string | Restake
}

export interface OperatorGrant {
  claimGrant: any
  stakeGrant: any
  validators: any
  maxTokens?: BigNumber
  grantsValid?: boolean
  grantsExist?: boolean
}

export interface Grant {
  [operatorAddress: string]: OperatorGrant
}

export enum BondStatus {
  Unbonded = 'Unbonded',
  Unbonding = 'Unbonding',
  Bonded = 'Bonded',
}

export interface Data {
  Proposals: ProposalData[] | undefined
  fetchProposals: (address: string) => void
  updateProposals: (address: string, id: number, voted: VotedType) => void
  TotalBonded: BigNumber
  Delegations: Delegation[] | undefined
  Validators: Validator[] | undefined
  Rewards: Rewards | undefined
  Operators: Operator[] | undefined
  Grants: any[] | undefined
  apr: number | undefined
  TotalStaked: number
  fetchAPR: () => void
  validatorAPR: (operators: Operator[] | undefined, validator: Validator) => string
  userAPR: (totalStaked: number, delegations?: ActiveDelegation[]) => number
  fetchValidators: () => void
  fetchRewards: () => void
  fetchDelegations: () => void
  fetchOperators: () => void
  fetchGrants: () => void
}

export const StakeContext = createContext<Data | null>(null)

export default observer(({ children }) => {
  const { accountStore, chainStore, queriesStore } = useStore()
  const accountAddress = accountStore.getAccount(chainStore.current.chainId).bech32Address
  const queries = queriesStore.get('umee-1').cosmos
  const chain = chainStore.getChain('umee-1')
  const chainName = chain.chainName
  const coinDenom = chain.stakeCurrency.coinMinimalDenom

  const { totalBonded } = useTotalBonded(queries)
  const { delegations, fetchDelegations } = useDelegations(accountAddress, queries)
  const { validators, fetchValidators } = useValidators()
  const { proposals, fetchProposals, updateProposals } = useProposals()
  const { rewards, fetchRewards } = useRewards(accountAddress, queries)
  const { operators, fetchOperators } = useOperators(chainName)
  const { grants, fetchGrants } = useGrants(operators, accountAddress, coinDenom)
  const { apr, fetchAPR, validatorAPR, userAPR } = useAPR()
  const totalStaked = useTotalStaked(delegations)

  const data: Data = {
    Proposals: proposals,
    fetchProposals,
    updateProposals,
    TotalBonded: totalBonded,
    Delegations: delegations,
    Validators: validators,
    Rewards: rewards,
    Operators: operators,
    Grants: grants,
    apr,
    TotalStaked: totalStaked,
    validatorAPR,
    userAPR,
    fetchAPR,
    fetchValidators,
    fetchRewards,
    fetchDelegations,
    fetchOperators,
    fetchGrants,
  }

  return <StakeContext.Provider value={data}>{children}</StakeContext.Provider>
})
