import { useCallback, useState } from 'react'
import { Operator, Validator } from './data'
import { CosmosQueriesImpl } from '@keplr-wallet/stores'
import parse from 'parse-duration'
import { Delegation } from '@keplr-wallet/stores/build/query/cosmos/staking/types'
import { bigNumberToNumber } from 'lib/number-utils'
import { BigNumber } from 'ethers'
import { ActiveDelegation } from 'pages/Stake/components/DelegationList'

const API_URL = process.env.API_ENDPOINT || 'http://127.0.0.1:3001'
const API = `${API_URL}/pages`

export const useAPR = () => {
  const [apr, setAPR] = useState<number>()

  const fetchAPR = useCallback(() => {
    fetch(`${API}/chain`)
      .then((res) => res.json())
      .then((res) => {
        setAPR(res.chain.params.calculated_apr)
      })
  }, [])

  const runsPerDay = (max: number | undefined, runTime: any) => {
    let runs = 0
    if (Array.isArray(runTime)) {
      runs = runTime.length
    } else {
      if (runTime.startsWith('every')) {
        const interval = parse(runTime.replace('every ', ''))
        runs = (1000 * 60 * 60 * 24) / interval
      } else {
        runs = 1
      }
    }
    return max && runs > max ? max : runs
  }

  const validatorAPR = (operators: Operator[] | undefined, validator: Validator) => {
    const operator = operators?.find((operator) => operator.address === validator.operator_address)
    const periodPerYear = operator && validator.restake ? runsPerDay(undefined, validator.restake.run_time) * 365 : 1
    const realApr = (apr ?? 0) * (1 - parseFloat(validator.commission_rates.rate))
    const apy = (1 + realApr / periodPerYear) ** periodPerYear - 1

    return (apy * 100).toFixed(2)
  }

  const userAPR = (totalStaked: number, delegations?: ActiveDelegation[]) => {
    if (totalStaked <= 0 || !delegations) return 0
    else {
      const total = delegations.reduce((sum, delegation) => {
        sum += bigNumberToNumber(BigNumber.from(delegation.staked_amount.toString()), 6) * (delegation.apr || 0)
        return sum
      }, 0)

      return total / totalStaked
    }
  }

  return { apr, fetchAPR, validatorAPR, userAPR }
}

export const useTotalStaked = (delegations: Delegation[] | undefined) => {
  if (delegations) {
    let total = 0

    if (delegations.length > 0) {
      delegations.map((delegation) => {
        total += Number(delegation.balance.amount)
      })
      return bigNumberToNumber(BigNumber.from(total.toString()), 6)
    } else {
      return 0
    }
  }
  return 0
}

export const useDelegations = (address: string, queries: CosmosQueriesImpl) => {
  const data = queries.queryDelegations.getQueryBech32Address(address).delegations
  const delegations = data.filter((item) => {
    const balance = typeof item.balance === 'string' ? item.balance : item.balance.amount
    return Number(balance) > 0
  })

  return {
    delegations: address.length > 0 ? delegations : undefined,
    fetchDelegations: useCallback(() => {
      if (address) queries.queryDelegations.getQueryBech32Address(address).fetch()
    }, [address, queries.queryDelegations]),
  }
}

export const useRewards = (address: string, queries: CosmosQueriesImpl) => {
  const res = queries.queryRewards.getQueryBech32Address(address).response

  return {
    rewards: res?.data,
    fetchRewards: useCallback(() => {
      if (address) queries.queryRewards.getQueryBech32Address(address).fetch()
    }, [address, queries.queryRewards]),
  }
}

export const useValidators = () => {
  const [validators, setValidators] = useState<Validator[]>()

  const fetchValidators = useCallback(async () => {
    const validatorsRes = await fetch(`${API_URL}/validators`)
    const validatorsArr = await validatorsRes.json()
    const validators = validatorsArr ?? []
    const validatorArr = validators
      .filter((val: any) => val.status === 'BOND_STATUS_BONDED')
      .map((validator: any) => ({
        address: validator.address,
        start_height: Number(validator.start_height),
        operator_address: validator.operator_address,
        tokens: validator.tokens,
        moniker: validator.moniker,
        website: validator.website,
        commission_rates: validator.comission_rates,
        last_commission_update: validator.last_commission_update,
        description: validator.description,
        status: validator.status,
        rank: validator.rank,
        img_url: validator.img_url || '',
        apr: (validator.apr * 100).toFixed(2),
        apy: (validator.apy * 100).toFixed(2),
        compound_period: validator.compound_period,
        restake_apy: (validator.restake_apy * 100).toFixed(2),
        voting_power: (validator.votingPower * 100).toFixed(2),
        restake: validator.restake,
      }))

    setValidators(validatorArr)
  }, [])

  // useEffect(() => {
  //   fetchValidators();
  // }, []);

  return {
    validators,
    fetchValidators,
  }
}
