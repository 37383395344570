import React from 'react'
import { Validator } from 'api/stake/data'
import Modal from 'components/common/Modals/Modal'
import { observer } from 'mobx-react-lite'
import Delegate from './Delegate'

interface DelegateProps {
  validator: Validator
  onClose: () => void
}

const DelegateDesktop = ({ validator, onClose }: DelegateProps) => {
  return (
    <Modal onClose={onClose} className="delegate-modal">
      <Delegate validator={validator} onClose={onClose} />
    </Modal>
  )
}

export default observer(DelegateDesktop)
