import React, { useContext, useEffect, useState } from 'react'
import { OperatorGrant, Validator } from 'api/stake/data'
import { ResponsiveContext } from 'grommet'
import { useStore } from 'api/cosmosStores'
import { observer } from 'mobx-react-lite'
import { BigNumber } from 'ethers'
import { Currency } from '@keplr-wallet/types'
import { WalletStatus } from '@keplr-wallet/stores'
import { displayToast, TToastType } from 'components/common/toasts'
import { useFakeFeeConfig } from 'lib/hooks/fake-fee-config'
import { ETxnSteps } from 'lib/types'
import DelegateInputMobile from './DelegateInputMobile'
import DelegateInput from './DelegateInput'
import { defaultMsgOpts } from 'api/cosmosStores/cosmos/account/types'

interface DelegateProps {
  validator: Validator
  onClose: () => void
  grants?: OperatorGrant
}

const tokenDecimals = BigNumber.from('6')

const Delegate = ({ validator, onClose, grants }: DelegateProps) => {
  const [txnAmount, setTxnAmount] = useState<string>('')
  const { accountStore, chainStore, queriesStore } = useStore()
  const [step, setStep] = useState<ETxnSteps>(ETxnSteps.Input)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const queries = queriesStore.get(chainStore.current.chainId)
  const account = accountStore.getAccount(chainStore.current.chainId)
  const originCurrency = chainStore.current.currencies.find((cur) => cur.coinMinimalDenom === 'uumee') as Currency
  const feeConfig = useFakeFeeConfig(chainStore, chainStore.current.chainId, defaultMsgOpts.compoundReward.gas)
  const size = useContext(ResponsiveContext)

  useEffect(() => {
    if (account.walletStatus === WalletStatus.NotInit) account.init()
  }, [account])

  const walletBalance = BigNumber.from(
    queries.queryBalances.getQueryBech32Address(account.bech32Address).getBalanceFromCurrency(originCurrency).toCoin()
      .amount,
  )

  const avaliableBalance = BigNumber.from(
    queries.queryBalances
      .getQueryBech32Address(account.bech32Address)
      .getBalanceFromCurrency(originCurrency)
      .sub(feeConfig.fee)
      .toCoin().amount,
  )

  const delegate = async () => {
    if (!txnAmount) return
    setStep(ETxnSteps.Pending)

    try {
      await account.cosmos.sendDelegateMsg(txnAmount.toString(), validator.operator_address, '', undefined, undefined, {
        onBroadcasted: (txHash: Uint8Array) => {
          displayToast('Delegating', TToastType.TX_BROADCASTING)
        },
        onFulfill: (tx) => {
          if (!tx.code) {
            displayToast('Delegate Successful', TToastType.TX_SUCCESSFUL, {
              customLink: chainStore
                .getChain(chainStore.current.chainId)
                .raw.explorerUrlToTx.replace('{txHash}', tx.hash.toUpperCase()),
            })
            setStep(ETxnSteps.Input)
          }
        },
      })
    } catch (e) {
      console.log(e)
    }

    onClose()
  }

  return (
    <>
      {size === 'small' ? (
        <DelegateInputMobile
          validator={validator}
          walletBalance={walletBalance}
          availableBalance={avaliableBalance}
          txnStep={step}
          setTxnAmount={setTxnAmount}
          txnAmount={txnAmount}
          isPreview={isPreview}
          setIsPreview={setIsPreview}
          tokenDecimals={tokenDecimals}
          delegate={delegate}
          grants={grants}
        />
      ) : (
        <DelegateInput
          validator={validator}
          walletBalance={walletBalance}
          availableBalance={avaliableBalance}
          step={step}
          setTxnAmount={setTxnAmount}
          txnAmount={txnAmount}
          delegate={delegate}
        />
      )}
    </>
  )
}

export default observer(Delegate)
