import React, { useContext, useEffect, useMemo } from 'react'
import { useStake } from 'api/stake'
import { Box, BoxProps, Text, ResponsiveContext } from 'grommet'
import Overview from 'components/common/Overview'
import { InfoBarBody } from 'components'
import { VotedType } from 'api/stake/data'
import TotalStaked from 'components/TotalStaked'
import { observer } from 'mobx-react-lite'
import { useStore } from 'api/cosmosStores'
import { BigNumber } from 'ethers'
import { formatShowNumber } from 'utils/format'
import { bigNumberToNumber } from 'lib/number-utils'
import { useConvexityData } from 'api/convexity'
import GradientBox from 'components/common/GradientBox/GradientBox'
import { SkeletonBar } from 'components/Skeleton'
import { useAccountConnection } from 'api/cosmosStores/account-init-management'

const formatFixed = (val: number) => (val > 0 ? val.toFixed(2) : val)
//FIX ME
const VoteOverview: React.FC<BoxProps> = (props) => {
  const { accountStore, chainStore } = useStore()
  const { RegisteredTokens } = useConvexityData()
  const account = accountStore.getAccount(chainStore.current.chainId)
  const { Proposals, Delegations } = useStake()
  const { isAccountConnected } = useAccountConnection()
  const size = useContext(ResponsiveContext)

  const pendingProposals = useMemo(() => {
    let num = 0
    if (Proposals) {
      const pending = account.bech32Address
        ? Proposals.filter((proposal) => proposal.status === 2 && proposal.voted === VotedType.NEVER)
        : Proposals.filter((proposal) => proposal.status === 2)
      num = pending.length
    }
    return num
  }, [Proposals, account.bech32Address])

  const votedProposals = useMemo(() => {
    let num = 0
    if (Proposals) {
      const pending = Proposals.filter((proposal) => proposal.voted !== undefined && proposal.voted !== VotedType.NEVER)
      num = account.bech32Address ? pending.length : 0
    }
    return num
  }, [Proposals, account.bech32Address])

  const totalStaked = useMemo(() => {
    if (Delegations) {
      return bigNumberToNumber(
        BigNumber.from(Delegations.reduce((acc, cur) => acc + Number(cur.balance.amount), 0).toString()),
        6,
      )
    } else {
      return 0
    }
  }, [Delegations])

  const umeePrice = Number(RegisteredTokens.find((token) => token.name === 'UMEE')?.usdPrice ?? 0)

  if (size === 'small') {
    return (
      <InfoBarBody gap="medium" direction="column" {...props}>
        <GradientBox pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Text size="small" color="clrTextPrimary">
            Total Staked
          </Text>
          <Box justify="between" flex direction="row" align="baseline">
            {isAccountConnected ? (
              <>
                <Text className="gradient-text" size="medium1" weight="normal">
                  ${formatFixed(totalStaked * umeePrice)}
                </Text>
                <Box direction="row" align="baseline">
                  <Text size="small" color="clrTextPrimary">
                    {formatShowNumber(totalStaked)}
                  </Text>
                  <Text size="xsmall" color="clrDarkGreyOnNavy">
                    &nbsp;UMEE
                  </Text>
                </Box>
              </>
            ) : (
              <Box margin={{ vertical: '.5em' }}>
                <Text size="small" color="clrTextPrimary">
                  -
                </Text>
              </Box>
            )}
          </Box>
        </GradientBox>

        <GradientBox pad={{ horizontal: 'medium', vertical: 'small' }} flex direction="row" justify="between">
          <Box>
            <Text size="small" color="clrTextPrimary">
              Pending Votes
            </Text>
            <Text className="gradient-text" size="medium1" weight="normal">
              {pendingProposals}
            </Text>
          </Box>
          <Box>
            <Text size="small" color="clrTextPrimary">
              Total Record
            </Text>
            <Text className="gradient-text" size="medium1" weight="normal">
              {Proposals ? `${Proposals.length}` : '0'}
            </Text>
          </Box>
        </GradientBox>
      </InfoBarBody>
    )
  }

  return (
    <InfoBarBody gap="medium" direction={size === 'medium' ? 'column' : 'row'} {...props}>
      <Overview pad="medium" title="Total Staked" value={<TotalStaked size="medium1" />} flex />
      <Overview
        pad={size === 'small' ? 'large' : 'medium'}
        title="Pending Votes"
        value={Proposals ? pendingProposals.toString() : <SkeletonBar margin={{ top: 'small' }} />}
        flex
      />
      <Overview
        pad={size === 'small' ? 'large' : 'medium'}
        title="Total Proposals"
        value={Proposals ? `${Proposals.length}` : <SkeletonBar margin={{ top: 'small' }} />}
        color="clrTextPrimary"
        flex
      />
    </InfoBarBody>
  )
}

export default observer(VoteOverview)
